import {
  AppLanguageTranslationType,
  AppTranslationKeyDescriptionType,
  AppTranslationKeyType,
  NewTranslation,
  TranslationItems,
  TranslationsTypes,
} from "../../types/translations";
import { db } from "../../contexts/FirebaseContext";
import {
  collections,
  LANGUAGES,
  ROLES,
  SupportedLanguagesEnum,
} from "../constants";
import { SupportedLanguages } from "../../types/invitation/code";
import { CCRolesPossibleVals } from "../../types/role/CCRoles";
import { useEffect, useRef, useState } from "react";
import { isObject, orderBy as sortBy } from "lodash";
import { AudioContentAccessType } from "../../types/audioMeditationGenerations";
import firebase from "firebase";
import { deleteCollectionInBatch } from "./queryUtils";
import {
  TranslationSearchType,
  TranslationStatus,
} from "../../pages/dashboard/app-side-translations/AppSideTranslationQuickEdit";

export const defaultTranslation = {
  en: {},
  de: {},
  es: {},
  fr: {},
};

export function useTranslationsKeyWithDescription(
  pageNo: number,
  pageSize: number,
  sortingField: string,
  sortingOrder: string,
  searchText: string,
  initialSnapShot = {},
  isSuggestedTranslation?: boolean
) {
  const [loading, setLoading] = useState<boolean>(true);
  const [isMoreTranslationsAvailable, setIsMoreTranslationsAvailable] =
    useState<boolean>(true);
  const lastSnapShot = useRef<any>(initialSnapShot);
  const [translationsKeyWithDesc = [], setTranslationsKeyWithDesc] = useState<
    Array<AppTranslationKeyDescriptionType>
  >([]);

  useEffect(() => {
    let collectionName = collections.APP_TRANSLATIONS;

    if (isSuggestedTranslation) {
      collectionName = collections.SUGGESTED_TRANSLATIONS_META_DATA;
    }

    let dbRef:
      | firebase.firestore.CollectionReference
      | firebase.firestore.Query = db.collection(collectionName);

    if (searchText?.trim()?.length) {
      dbRef = dbRef.where("key", "==", searchText);
    } else {
      dbRef = dbRef.orderBy(
        sortingField,
        sortingOrder as firebase.firestore.OrderByDirection
      );
    }

    if (lastSnapShot?.current[pageNo]) {
      dbRef = dbRef.startAfter(lastSnapShot?.current[pageNo] ?? 0);
    }

    setLoading(true);
    let unsub = dbRef.limit(pageSize).onSnapshot(async (snapshot) => {
      const snapShotKey = pageNo + 1;
      lastSnapShot.current[snapShotKey] =
        snapshot.docs[snapshot.docs.length - 1];
      const dbTranslationsKeyWithDesc = [];
      for (const doc of snapshot.docs) {
        const meditationItem = doc.data();
        dbTranslationsKeyWithDesc.push({
          ...(meditationItem as AppTranslationKeyDescriptionType),
          id: doc.id,
          createdAt: (
            meditationItem.createdAt as firebase.firestore.Timestamp
          )?.toDate(),
        });
      }

      setTranslationsKeyWithDesc(dbTranslationsKeyWithDesc ?? []);

      setIsMoreTranslationsAvailable(
        dbTranslationsKeyWithDesc.length === pageSize
      );

      setLoading(false);
    });
    return () => unsub();
  }, [
    pageNo,
    pageSize,
    sortingField,
    sortingOrder,
    searchText,
    isSuggestedTranslation,
  ]);

  return {
    translationsKeyWithDesc,
    loading,
    isMoreTranslationsAvailable,
    lastSnapShot: lastSnapShot.current,
  };
}

export function useTranslationsKeyWithDescriptionByLocale(
  locale: string,
  pageNo: number,
  pageSize: number,
  searchText: string,
  searchBy: TranslationSearchType,
  initialSnapShot = {},
  order: string,
  orderBy: string,
  approvalStatus: string,
  translationPriority: string,
  isEmptyDescription: boolean,
  translationStatus?: TranslationStatus
) {
  if (searchText?.trim()?.length || isEmptyDescription) {
    pageSize = 10;
  }

  const [loading, setLoading] = useState<boolean>(false);
  const [isMoreTranslationsAvailable, setIsMoreTranslationsAvailable] =
    useState<boolean>(true);
  const lastSnapShot = useRef<any>(initialSnapShot);
  const parentAppTranslationSnapShot = useRef<any>({});
  const [translationsKeyWithDesc = [], setTranslationsKeyWithDesc] = useState<
    Array<AppTranslationKeyType>
  >([]);

  useEffect(() => {
    const fetchAppTranslationList = async () => {
      try {
        setLoading(true);
        setTranslationsKeyWithDesc([]);
        let dbRef:
          | firebase.firestore.CollectionReference
          | firebase.firestore.Query = db
          .collectionGroup(collections.APP_TRANSLATIONS_LANGUAGES)
          .where("locale", "==", locale.toLowerCase());

        let queryByParentIds = false;
        let parentTranslationDocIds: string | any[] = [];

        if (searchText?.trim()?.length) {
          const translationKeys = searchText?.split("|").slice(0, 10);
          const translationDocIds = translationKeys?.map(
            (translationKey) =>
              `/${collections.APP_TRANSLATIONS}/${translationKey}/${collections.APP_TRANSLATIONS_LANGUAGES}/${locale}`
          );
          parentTranslationDocIds.push(...translationDocIds);
          queryByParentIds = true;
        }

        if (approvalStatus === TranslationStatus.APPROVED) {
          dbRef = dbRef.where("approvedBy", "!=", null);
        } else if (approvalStatus === TranslationStatus.UNAPPROVED) {
          dbRef = dbRef.where("approvedBy", "==", null);
        }

        const queryByType: string[] = [];
        if (isEmptyDescription) {
          queryByType.push(TranslationStatus.EMPTY_DESCRIPTION);
        }

        const isTranslationPriorityFilterSelected =
          translationPriority === TranslationStatus.CRITICAL ||
          translationPriority === TranslationStatus.HIGH ||
          translationPriority === TranslationStatus.MEDIUM ||
          translationPriority === TranslationStatus.LOW;
        if (isEmptyDescription || isTranslationPriorityFilterSelected) {
          if (isTranslationPriorityFilterSelected) {
            queryByType.push(TranslationSearchType.PRIORITY);
          }
          parentTranslationDocIds = await fetchParentAppTranslationList(
            queryByType?.join?.(",") ?? String(queryByType),
            translationPriority,
            parentAppTranslationSnapShot,
            pageNo,
            pageSize,
            locale,
            order,
            orderBy
          );
          queryByParentIds = true;
        } else if (
          approvalStatus === TranslationStatus.ALL &&
          !searchText?.trim()?.length &&
          (orderBy === "createdAt" || orderBy === "key")
        ) {
          parentTranslationDocIds = await fetchParentAppTranslationList(
            "",
            "",
            parentAppTranslationSnapShot,
            pageNo,
            pageSize,
            locale,
            order,
            orderBy
          );
          queryByParentIds = true;
        }

        if (queryByParentIds) {
          if (!parentTranslationDocIds.length) {
            setTranslationsKeyWithDesc([]);
            setIsMoreTranslationsAvailable(false);
            setLoading(false);
            return;
          }

          dbRef = dbRef.where(
            firebase.firestore.FieldPath.documentId(),
            "in",
            parentTranslationDocIds.slice(0, 10)
          );
        }

        if (lastSnapShot?.current[pageNo] && !queryByParentIds) {
          dbRef = dbRef.startAfter(lastSnapShot?.current[pageNo] ?? 0);
        }

        dbRef.limit(pageSize).onSnapshot(
          async (snapshot) => {
            const snapShotKey = pageNo + 1;
            lastSnapShot.current[snapShotKey] =
              snapshot.docs[snapshot.docs.length - 1];
            const dbTranslationsKeyWithDesc = [];
            for (const doc of snapshot.docs) {
              const translationItem = doc.data();
              if (translationStatus === TranslationStatus.APPROVED) {
                if (!translationItem.approvedBy) {
                  continue;
                }
              } else if (translationStatus === TranslationStatus.UNAPPROVED) {
                if (translationItem.approvedBy) {
                  continue;
                }
              }

              const translationId = doc.ref.parent.parent?.id ?? "";
              let translationKeyDescDoc:
                | firebase.firestore.QueryDocumentSnapshot
                | any;
              if (translationId) {
                translationKeyDescDoc = await db
                  .collection(collections.APP_TRANSLATIONS)
                  .doc(translationId)
                  .get();
              }

              const translationKeyDescData = translationKeyDescDoc?.data?.();

              if (translationStatus === TranslationStatus.EMPTY_DESCRIPTION) {
                if (translationKeyDescData?.description) {
                  continue;
                }
              }

              if (
                translationStatus === TranslationStatus.CRITICAL ||
                translationStatus === TranslationStatus.HIGH ||
                translationStatus === TranslationStatus.MEDIUM ||
                translationStatus === TranslationStatus.LOW
              ) {
                if (translationKeyDescData?.priority !== translationStatus) {
                  continue;
                }
              }

              if (isObject(translationItem.value)) {
                translationItem.value = JSON.stringify(translationItem);
              }

              dbTranslationsKeyWithDesc.push({
                ...(translationItem as AppLanguageTranslationType),
                translationId,
                key: translationId,
                description: translationKeyDescData?.description,
                priority: translationKeyDescData?.priority,
                updatedAt: (
                  translationItem?.updatedAt as firebase.firestore.Timestamp
                )?.toDate(),
                createdAt: (
                  translationKeyDescData?.createdAt as firebase.firestore.Timestamp
                )?.toDate(),
              });
            }

            setTranslationsKeyWithDesc(
              sortBy(
                dbTranslationsKeyWithDesc ?? [],
                [orderBy],
                [order as "desc" | "asc"]
              )
            );

            let isMoreDataAvailable =
              dbTranslationsKeyWithDesc.length === pageSize;
            if (queryByParentIds && dbTranslationsKeyWithDesc.length) {
              isMoreDataAvailable = true;
            }

            setIsMoreTranslationsAvailable(isMoreDataAvailable);

            setLoading(false);
          },
          () => {
            setLoading(false);
          }
        );
      } catch (e) {
        console.error(e);
        setLoading(false);
      }
    };

    fetchAppTranslationList();
  }, [
    isEmptyDescription,
    approvalStatus,
    translationPriority,
    order,
    orderBy,
    pageNo,
    pageSize,
    searchText,
    searchBy,
    translationStatus,
    locale,
  ]);

  return {
    translationsKeyWithDesc,
    loading,
    isMoreTranslationsAvailable,
    lastSnapShot: lastSnapShot.current,
  };
}

export function useTranslationsValueAndApprovedBy(
  currentRoles: CCRolesPossibleVals[],
  translationId: string,
  isSuggestedTranslation?: boolean
) {
  const [loading, setLoading] = useState<boolean>(false);
  const [showEnglishTranslationViewOnly, setShowEnglishTranslationViewOnly] =
    useState<boolean>(false);
  const [translationsValueAndApprovedBy, setTranslationsValueAndApprovedBy] =
    useState<Array<AppLanguageTranslationType>>();
  const [accessToAllLanguagesTranslation, setAccessToAllLanguagesTranslation] =
    useState(false);
  const [accessLanguagesTranslationList, setAccessLanguagesTranslationList] =
    useState<string[]>([]);

  useEffect(() => {
    let { accessToAllLanguages, accessLanguages }: AudioContentAccessType =
      hasPermissionToTranslation(currentRoles);

    setAccessToAllLanguagesTranslation(accessToAllLanguages);
    setAccessLanguagesTranslationList(accessLanguages);

    if (!translationId?.length || !currentRoles?.length) {
      return;
    }

    let collectionName = collections.APP_TRANSLATIONS;
    let subCollectionName = collections.APP_TRANSLATIONS_LANGUAGES;

    if (isSuggestedTranslation) {
      collectionName = collections.SUGGESTED_TRANSLATIONS_META_DATA;
      subCollectionName = collections.SUGGESTED_TRANSLATIONS_LANGUAGES;
    }

    let dbRef:
      | firebase.firestore.CollectionReference
      | firebase.firestore.Query = db.collection(
      `${collectionName}/${translationId}/${subCollectionName}`
    );

    if (!accessToAllLanguages && !accessLanguages?.length) {
      setLoading(false);
      return;
    }

    if (accessLanguages?.length) {
      if (!accessLanguages.includes(SupportedLanguagesEnum.ENGLISH)) {
        accessLanguages = [SupportedLanguagesEnum.ENGLISH, ...accessLanguages];
        setShowEnglishTranslationViewOnly(true);
      }

      dbRef = dbRef.where("locale", "in", accessLanguages);
    }

    setLoading(true);
    const unsub = dbRef.onSnapshot(async (snapshot) => {
      setTranslationsValueAndApprovedBy(
        snapshot.docs.map(
          (doc) => {
            const docData = doc.data();
            return {
              id: doc.id,
              ...docData,
              value: isObject(docData?.value) ? JSON.stringify(docData?.value) : docData?.value,
            } as AppLanguageTranslationType
          }
        )
      );
      setLoading(false);
    });

    return () => unsub();
  }, [currentRoles, isSuggestedTranslation, translationId]);

  return {
    translationsValueAndApprovedBy,
    loading,
    accessToAllLanguagesTranslation,
    accessLanguagesTranslationList,
    showEnglishTranslationViewOnly,
  };
}

export function useTranslationKeyWithDescription(
  translationId: string,
  isSuggestedTranslation?: boolean
) {
  const [loading, setLoading] = useState<boolean>(true);
  const [translationKeyWithDescription, setTranslationKeyWithDescription] =
    useState<AppTranslationKeyDescriptionType>();

  useEffect(() => {
    if (!translationId?.length) {
      setLoading(false);
      return;
    }
    let collectionName = collections.APP_TRANSLATIONS;

    if (isSuggestedTranslation) {
      collectionName = collections.SUGGESTED_TRANSLATIONS_META_DATA;
    }
    let collectionRef = db.collection(collectionName);

    setLoading(true);
    const unsub = collectionRef.doc(translationId).onSnapshot((doc) => {
      if (doc?.exists) {
        setTranslationKeyWithDescription({
          ...doc.data(),
          id: doc.id,
        } as AppTranslationKeyDescriptionType);
      }
      setLoading(false);
    });

    return () => unsub();
  }, [isSuggestedTranslation, translationId]);

  return { translationKeyWithDescription, loading };
}

export async function getTranslationsData() {
  const snapshot = await db.collection("translations").get();

  let translations: TranslationsTypes = defaultTranslation;

  snapshot.docs.forEach((doc) => {
    translations[doc.id as SupportedLanguages] = doc.data() as TranslationItems;
  });

  return translations;
}

export function saveTranslations(
  language: SupportedLanguages,
  data: TranslationItems
) {
  return db.collection("translations").doc(language).set(data, { merge: true });
}

export function addTranslation(translation: NewTranslation) {
  const batch = db.batch();

  LANGUAGES.forEach((language) => {
    const docRef = db.collection("translations").doc(language);
    batch.update(docRef, { [translation.keyVal]: translation[language] });
  });

  return batch.commit();
}

export function addOrUpdateTranslationKeyAndDescription(
  keyDescPayload: AppTranslationKeyDescriptionType,
  isSuggestedTranslation?: boolean
) {
  let collectionName = collections.APP_TRANSLATIONS;

  if (isSuggestedTranslation) {
    collectionName = collections.SUGGESTED_TRANSLATIONS_META_DATA;
  }

  return db
    .collection(collectionName)
    .doc(keyDescPayload.id)
    .set(keyDescPayload, { merge: true });
}

export function addOrUpdateLanguageTranslations(
  translationId: string,
  keyDescPayload: AppLanguageTranslationType,
  isSuggestedTranslation?: boolean
) {
  let collectionName = collections.APP_TRANSLATIONS;
  let subCollectionName = collections.APP_TRANSLATIONS_LANGUAGES;

  if (isSuggestedTranslation) {
    collectionName = collections.SUGGESTED_TRANSLATIONS_META_DATA;
    subCollectionName = collections.SUGGESTED_TRANSLATIONS_LANGUAGES;
  }

  return db
    .collection(`${collectionName}/${translationId}/${subCollectionName}`)
    .doc(keyDescPayload.id)
    .set(keyDescPayload, { merge: true });
}

export function deleteAppTranslation(
  translationId: string,
  isSuggestedTranslation: boolean
) {
  let collectionName = collections.APP_TRANSLATIONS;
  let subCollectionName = collections.APP_TRANSLATIONS_LANGUAGES;

  if (isSuggestedTranslation) {
    collectionName = collections.SUGGESTED_TRANSLATIONS_META_DATA;
    subCollectionName = collections.SUGGESTED_TRANSLATIONS_LANGUAGES;
  }

  return Promise.all([
    db.collection(collectionName).doc(translationId).delete(),
    deleteCollectionInBatch(
      `${collectionName}/${translationId}/${subCollectionName}`,
      10
    ),
  ]);
}

export function addOrUpdateLanguageTranslationInBulk(
  translationId: string,
  languageTranslationPayload: Array<AppLanguageTranslationType>,
  isSuggestedTranslation?: boolean
) {
  const batch = db.batch();

  let collectionName = collections.APP_TRANSLATIONS;
  let subCollectionName = collections.APP_TRANSLATIONS_LANGUAGES;

  if (isSuggestedTranslation) {
    collectionName = collections.SUGGESTED_TRANSLATIONS_META_DATA;
    subCollectionName = collections.SUGGESTED_TRANSLATIONS_LANGUAGES;
  }

  const collectionRef = db.collection(
    `${collectionName}/${translationId}/${subCollectionName}`
  );

  languageTranslationPayload.forEach((language) => {
    const docRef = collectionRef.doc(language.locale);
    batch.set(docRef, language, { merge: true });
  });

  return batch.commit();
}

export const hasPermissionToTranslation = (
  roles: Array<string> = []
): AudioContentAccessType => {
  if (
    roles.includes(ROLES.SUPER_ADMIN) ||
    roles.includes(ROLES.APP_TRANSLATION_CRUD)
  ) {
    return {
      accessToAllLanguages: true,
      accessLanguages: [],
    };
  }

  const translationRoles = roles.filter((role) =>
    role.startsWith("app_translation")
  );
  if (!translationRoles.length) {
    return {
      accessToAllLanguages: false,
      accessLanguages: [],
    };
  }

  const languageCodes = translationRoles.map(
    extractLanguageCodeFromTranslationRole
  );

  return {
    accessToAllLanguages: false,
    accessLanguages: languageCodes,
  };
};

export const metaInformationToLanguageField = (
  langKey = "",
  translationsCodeToName: Record<string, string>,
  isNew?: boolean
): string => {
  if (isNew && langKey !== SupportedLanguagesEnum.ENGLISH) {
    return `It will be Automatically translated through AI, If not entered`;
  }

  return `Enter Translated Value in ${translationsCodeToName[langKey] ?? ""}`;
};

export const isSuperAdminOrAccessToAllLanguages = (
  currentRoles: CCRolesPossibleVals[]
) => {
  return (
    currentRoles.includes(ROLES.SUPER_ADMIN) ||
    currentRoles.includes(ROLES.APP_TRANSLATION_CRUD)
  );
};

export const isSuperAdmin = (
  currentRoles: CCRolesPossibleVals[]
) => {
  return (
    currentRoles.includes(ROLES.SUPER_ADMIN)
  );
};

export const enableActionOnEnglishTranslation = (
  locale: SupportedLanguagesEnum,
  showEnglishTranslationViewOnly: boolean
) => {
  return (
    locale !== SupportedLanguagesEnum.ENGLISH || !showEnglishTranslationViewOnly
  );
};

export const extractLanguageCodeFromTranslationRole = (
  languageRoleString: string
) => {
  // Get the substring after "app_translation_"
  const separator = `app_translation_`;
  const langCode = languageRoleString.slice(
    languageRoleString.indexOf(separator) + separator.length
  );

  return langCode.toLowerCase();
};

export const checkTranslationKeyIsAlreadyExist = (
  hits: any,
  value: string,
  searchByValue?: boolean
) => {
  return hits.some((hit: any) => {
    if (isObject(hit?.valueInEN)) {
      return false;
    }

    if (searchByValue) {
      return hit?.valueInEN?.trim()?.toLowerCase() === value.toLowerCase();
    }

    return hit?.key?.trim()?.toLowerCase() === value.toLowerCase();
  });
};

const fetchParentAppTranslationList = async (
  searchBy: string,
  searchValue: string = "",
  lastSnapShot: any,
  pageNo: number,
  pageSize: number,
  locale: string,
  order: string,
  orderBy: string
) => {
  let dbRef: firebase.firestore.CollectionReference | firebase.firestore.Query =
    db.collection(collections.APP_TRANSLATIONS);

  if (searchBy.includes(TranslationStatus.EMPTY_DESCRIPTION)) {
    dbRef = dbRef.where("description", "==", "");
  }

  if (searchBy.includes(TranslationSearchType.PRIORITY)) {
    dbRef = dbRef.where("priority", "==", searchValue);
  }

  dbRef = dbRef.orderBy(orderBy, order as firebase.firestore.OrderByDirection);

  if (lastSnapShot?.current[pageNo]) {
    dbRef = dbRef.startAfter(lastSnapShot?.current[pageNo] ?? 0);
  }

  const docRefs = await dbRef.limit( pageSize > 10 ? 10: pageSize).get();

  const snapShotKey = pageNo + 1;
  lastSnapShot.current[snapShotKey] = docRefs.docs?.[docRefs?.docs?.length - 1];

  const parentIds = docRefs.docs.map((snapshot) => snapshot.id);
  return parentIds.map(
    (parentId) =>
      `/${collections.APP_TRANSLATIONS}/${parentId}/${collections.APP_TRANSLATIONS_LANGUAGES}/${locale}`
  );
};
