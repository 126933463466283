import {
  Autocomplete,
  Box,
  List,
  ListSubheader,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useState } from "react";
import AddUserField from "./components/AddUserField";
import { migrateUsers, validateUsers } from "utils/firebase/cloudFunctions";
import { UserIdentifier } from "./types/Identifier";
import createValidationStatus from "./utils/validationStatus";
import { ValidationResult } from "./types/ValidationResult";
import useGetAllInvitationCodes from "pages/invitationCode/hooks/useGetAllInvitationCodes";
import { useSnackbar } from "notistack";
import MigrationCandidateItem from "./components/MigrationCandidateItem";
import { useParams } from "react-router";

const EMPTY_VALIDATION_RESULT = {
  uid: {},
  email: {},
};
export default function MigrationForm() {
  const snackbar = useSnackbar();
  const [isMigrating, setIsMigrating] = useState<boolean>();
  const [isValidating, setIsValidating] = useState<boolean>();
  const [validationResult, setValidationResult] = useState<ValidationResult>(
    EMPTY_VALIDATION_RESULT
  );
  const validationStatus = createValidationStatus(validationResult);

  const [selectedUsers, setSelectedUsers] = useState<UserIdentifier[]>([]);

  const [data, loading] = useGetAllInvitationCodes();
  const allAvailableInvitationCodes = data
    .map((i) => i.code)
    .filter(Boolean) as string[];

  const { code } = useParams();
  const [newInvitationCode, setNewInvitationCode] = useState<string | null>(
    null
  );
  const [oldInvitationCode, setOldInvitationCode] = useState<string | null>(
    code ? code : null
  );

  function addUser(nextUserIdentifier: string) {
    if (nextUserIdentifier.trim().length === 0) {
      return;
    }

    const identifier = UserIdentifier.guess(nextUserIdentifier);

    setSelectedUsers((users) => {
      const next = users.slice();
      next.push(identifier);
      return next;
    });
  }

  function onSubmit() {
    if (!newInvitationCode) {
      snackbar.enqueueSnackbar("You have to set a target invitation code", {
        variant: "error",
      });
      return;
    }
    if (
      (selectedUsers.length === 0 && !oldInvitationCode) ||
      (selectedUsers.length > 0 && oldInvitationCode)
    ) {
      snackbar.enqueueSnackbar(
        "You have to either select users or set a source invitation code",
        {
          variant: "error",
        }
      );
    }

    setIsMigrating(true);
    const userIdsPayload =
      selectedUsers.length > 0
        ? selectedUsers.map(UserIdentifier.ToPrimitive)
        : [];

    migrateUsers(
      newInvitationCode,
      userIdsPayload,
      oldInvitationCode ? oldInvitationCode : ""
    )
      .then(() => {
        snackbar.enqueueSnackbar("Migrated users", { variant: "success" });
        setNewInvitationCode(null);
        setOldInvitationCode(null);
        setSelectedUsers([]);
      })
      .catch((e) => {
        snackbar.enqueueSnackbar("Error migrating users", { variant: "error" });
        console.error(e);
      })
      .finally(() => {
        setIsMigrating(false);
      });
  }

  function onValidate() {
    setIsValidating(true);
    if (selectedUsers.length > 0) {
      const payload = selectedUsers.map(UserIdentifier.ToPrimitive);
      validateUsers(payload)
        .then((result) => {
          snackbar.enqueueSnackbar("Validation finished", {
            variant: "success",
          });
          setValidationResult(result.data);
        })
        .catch((e) => {
          snackbar.enqueueSnackbar("An error occurred during validation", {
            variant: "error",
          });
          console.error(e);
        })
        .finally(() => {
          setIsValidating(false);
        });
    }
  }

  return (
    <>
      <Typography variant="h5">
        Choose specific users or an invitation code to migrate users from:
      </Typography>
      <Autocomplete
        loading={loading}
        options={allAvailableInvitationCodes}
        value={oldInvitationCode}
        renderInput={(params) => (
          <TextField autoFocus {...params} label="Migrate from ..." />
        )}
        onChange={(_, value) => setOldInvitationCode(value)}
        sx={{ mt: 3 }}
      />

      <Box mt={3}>
        <AddUserField onAddUser={addUser} />
      </Box>

      <Box mt={3}>
        <Paper elevation={3}>
          <List dense>
            <ListSubheader>
              Migrate the following users to invitation code '
              {newInvitationCode}'
            </ListSubheader>
            {selectedUsers.map((user, index) => (
              <MigrationCandidateItem
                key={index}
                identifier={user}
                validationStatus={validationStatus(user)}
                onDelete={() => {
                  setSelectedUsers((users) => {
                    const next = [...users];
                    next.splice(index, 1);
                    return next;
                  });
                }}
              />
            ))}
          </List>
        </Paper>
      </Box>
      <Box sx={{ mt: 6 }}>
        <Typography variant="h5">
          Choose the invitation code you would like to migrate to:
        </Typography>
        <Autocomplete
          loading={loading}
          options={allAvailableInvitationCodes}
          value={newInvitationCode}
          renderInput={(params) => (
            <TextField autoFocus {...params} label="Migrate to ..." />
          )}
          onChange={(_, value) => setNewInvitationCode(value)}
          sx={{ mt: 3 }}
        />
      </Box>
      <Box sx={{ display: "flex", justifyContent: "right", mt: 6 }}>
        <Box mr={3}>
          <LoadingButton
            loading={isValidating}
            color="success"
            variant="contained"
            onClick={onValidate}
          >
            Validate
          </LoadingButton>
        </Box>
        <LoadingButton
          variant="contained"
          loading={isMigrating}
          onClick={onSubmit}
        >
          Migrate selected users
        </LoadingButton>
      </Box>
    </>
  );
}
