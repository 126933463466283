import firebase from 'firebase/app';
import { ProviderAccessToken } from './audit-logs/log';
import { CCRolesPossibleVals } from './role/CCRoles';
import { TokenResponse } from '@react-oauth/google';

// ----------------------------------------------------------------------

export type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

export type AuthUser = null | Record<string, any>;

export type Roles = { usrRoles: Array<CCRolesPossibleVals> };

export type AuthState = {
  isAuthenticated: boolean;
  isInitialized: boolean;
  registeredMFA: boolean;
  therapistId?: string;
  user: AuthUser;
  providerAccessToken?: { uid: string; accessToken: string }; // access token retrieved via google login to perform Logs fetching.
};

export type JWTContextType = {
  isAuthenticated: boolean;
  isInitialized: boolean;
  user: AuthUser;
  method: 'jwt';
  login: (email: string, password: string) => Promise<void>;
  register: (email: string, password: string, firstName: string, lastName: string) => Promise<void>;
  logout: () => Promise<void>;
  resetPassword: (email: string) => void;
  updateProfile: VoidFunction;
};

export type FirebaseContextType = {
  isAuthenticated: boolean;
  registeredMFA: boolean;
  isInitialized: boolean;
  user: AuthUser;
  therapistId?: string;
  method: 'firebase';
  login: (email: string, password: string) => Promise<firebase.auth.UserCredential>;
  register: (email: string, password: string, firstName: string, lastName: string) => Promise<void>;
  loginWithGoogle: (
    tokenResponse: TokenResponse,
    forAuditLogs?: boolean
  ) => Promise<firebase.auth.UserCredential>;
  loginWithFaceBook: () => Promise<firebase.auth.UserCredential>;
  loginWithTwitter: () => Promise<firebase.auth.UserCredential>;
  logout: () => Promise<void>;
  resetPassword: (email: string) => Promise<void>;
  updateProfile: (user: AuthUser) => Promise<void>;
  showLoginSuccessMessage: boolean;
  attemptingLogin: boolean;
  setAttemptingLogin: (value: boolean) => void;
  providerAccessToken?: ProviderAccessToken;
};

export type AWSCognitoContextType = {
  isAuthenticated: boolean;
  isInitialized: boolean;
  user: AuthUser;
  method: 'cognito';
  login: (email: string, password: string) => Promise<unknown>;
  register: (
    email: string,
    password: string,
    firstName: string,
    lastName: string
  ) => Promise<unknown>;
  logout: VoidFunction;
  resetPassword: (email: string) => void;
  updateProfile: VoidFunction;
};

export type Auth0ContextType = {
  isAuthenticated: boolean;
  isInitialized: boolean;
  user: AuthUser;
  method: 'auth0';
  login: () => Promise<void>;
  logout: VoidFunction;
  resetPassword: (email: string) => void;
  updateProfile: VoidFunction;
};

export enum AuthErrorCode {
  CREDENTIAL_TOO_OLD_LOGIN_AGAIN = "auth/requires-recent-login",
  INVALID_CODE = "auth/invalid-verification-code",
  INVALID_PASSWORD = "auth/wrong-password",
}

export enum MFAStep {
  VERIFY = "verify",
  SUCCESS = "success",
  ERROR = "error",
  ENROLL = "enroll",
  LOGGING_IN = "loggingIn",
  REQUEST = "request",
}
