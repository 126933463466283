import {Form, FormikProvider, useFormik} from "formik";
import {Alert, Autocomplete, Box, Card, Grid, Stack, TextField} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import React from "react";
import * as yup from "yup";
import {useSnackbar} from "notistack";


import {NotificationPayload} from "../../../types/notifications";
import {sendNotificationToInvitationCodeUsers} from "../../../utils/firebase/cloudFunctions";
import useGetAllInvitationCodes from "../../invitationCode/hooks/useGetAllInvitationCodes";
import {Locale} from "../../../types/user";

const DefaultNotificationPayload = {
    invitationCode: "",
    notificationTitle: {
        en: "",
        de: "",
        es: "",
        fr: "",
    },
    notificationBody: {
        en: "",
        de: "",
        es: "",
        fr: "",
    },
    stack: "",
    screen: "",
    params: "{}"
}

type Props = {
    notification?: NotificationPayload;
};

export default function NotificationForm({notification}: Props) {
    const {enqueueSnackbar} = useSnackbar();
    const [invitationCodes, loading] = useGetAllInvitationCodes();
    const allAvailableInvitationCodes = invitationCodes
        .map((i) => i.code)
        .filter(Boolean) as string[];

    const NotificationSchema = yup.object().shape({
        invitationCode: yup.string().required('Invitation code is required.'),
        notificationTitle: yup.object().shape({
            en: yup.string().required('Title in English required.'),
            de: yup.string().required('Title in German required.'),
            es: yup.string().required('Title in Spanish required.'),
            fr: yup.string().required('Title in French required.')
        }),
        notificationBody: yup.object().shape({
            en: yup.string().required('Notification body in English required.'),
            de: yup.string().required('Notification body in German required.'),
            es: yup.string().required('Notification body in Spanish required.'),
            fr: yup.string().required('Notification body in French required.')
        }),
        stack: yup.string(),
        screen: yup.string(),
        params: yup.string(),
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: notification || DefaultNotificationPayload,
        validationSchema: NotificationSchema,
        onSubmit: async (values: NotificationPayload) => {
            try {
                const response = await sendNotificationToInvitationCodeUsers(values);
                const receiversCount = response.data;
                enqueueSnackbar(`Notifications sent to ${receiversCount} users successfully`, {variant: "success"});
            } catch (e) {
                enqueueSnackbar(e.message, {variant: "error"})
            }
        }
    });

    const {errors, touched, handleSubmit, isSubmitting, getFieldProps, setFieldValue} =
        formik;

    return (
        <FormikProvider value={formik}>
            <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
                <fieldset style={{border: 'none'}}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Card sx={{p: 3}}>
                                <Stack spacing={3}>
                                    <Stack spacing={{xs: 2, sm: 2}}>
                                        <Autocomplete
                                            loading={loading}
                                            options={allAvailableInvitationCodes}
                                            value={getFieldProps("invitationCode").value}
                                            renderInput={(params) => (
                                                <TextField autoFocus {...params} label="Invitation Code"/>
                                            )}
                                            onChange={(_, value) => setFieldValue("invitationCode", value)}
                                        />


                                        {Object.values(Locale).map((locale: Locale) => {
                                            return <TextField
                                                key={locale}
                                                fullWidth
                                                label={`Notification title in ${locale}`}
                                                {...getFieldProps(`notificationTitle.${locale}`)}
                                                error={Boolean(touched?.notificationTitle?.[locale] && errors?.notificationTitle?.[locale])}
                                                helperText={touched?.notificationTitle?.[locale] && errors?.notificationTitle?.[locale]}
                                            />
                                        })}

                                        {Object.values(Locale).map((locale: Locale) => {
                                            return <TextField
                                                key={locale}
                                                fullWidth
                                                label={`Notification body in ${locale}`}
                                                {...getFieldProps(`notificationBody.${locale}`)}
                                                error={Boolean(touched?.notificationBody?.[locale] && errors?.notificationBody?.[locale])}
                                                helperText={touched?.notificationBody?.[locale] && errors?.notificationBody?.[locale]}
                                            />
                                        })}

                                        <TextField
                                            fullWidth
                                            label="Navigation Stack"
                                            {...getFieldProps('stack')}
                                            error={Boolean(touched.stack && errors.stack)}
                                            helperText={touched.stack && errors.stack}
                                        />


                                        <TextField
                                            fullWidth
                                            label="Navigation Screen"
                                            {...getFieldProps('screen')}
                                            error={Boolean(touched.screen && errors.screen)}
                                            helperText={touched.screen && errors.screen}
                                        />

                                        <TextField
                                            fullWidth
                                            label="Navigation Params"
                                            {...getFieldProps('params')}
                                            error={Boolean(touched.params && errors.params)}
                                            helperText={touched.params && errors.params}
                                        />

                                        <Alert style={{
                                            whiteSpace: "pre-line"
                                        }} severity="info">
                                            Info: The Navigation Stack is utilized for nested stacks. If
                                            navigating to a nested stack, the Navigation Stack and Screen name
                                            is required. If there is no nested screen, the same value can be
                                            used for both the Navigation Stack and Navigation
                                            Screen.{'\n'}{'\n'}

                                            For example: To navigate to the Counselling Tab:{'\n'}
                                            - Navigation Stack: "Counseling",{'\n'}
                                            - Navigation Screen: "Therapy"{'\n'}{'\n'}

                                            To navigate to Search All Counsellors:{'\n'}
                                            - Navigation Stack: "TherapistSearch",{'\n'}
                                            - Navigation Screen: "TherapistSearch"{'\n'}
                                        </Alert>

                                    </Stack>

                                    <Box sx={{mt: 3, display: 'flex', justifyContent: 'flex-end'}}>
                                        <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                                            Send Notification
                                        </LoadingButton>
                                    </Box>
                                </Stack>
                            </Card>
                        </Grid>
                    </Grid>
                </fieldset>
            </Form>
        </FormikProvider>)

}
