import Box from "@mui/system/Box";
import { Typography } from "@mui/material";
import { WellbeingColorPalette } from "../../theme/palette";
import { styled, useTheme } from "@mui/material/styles";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import {CounsellorProgressAssessmentQuestion} from "../../types/CounsellorProgressDasboard";
import {REVERSE_SCORE_QUESTIONS} from "./utils/constants";

const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: theme.spacing(3),
}));

const InfoItem = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  marginRight: theme.spacing(3),
}));

const ColorCircle = styled(Box)(({ theme }) => ({
  height: 12,
  borderRadius: 6,
  width: 12,
  marginRight: 6,
}));

const Text = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  color: theme.palette.blue.dark,
  opacity: 0.6,
}));

const SCORE_COLORS: Array<{
  color: WellbeingColorPalette;
  value: string;
  score: string;
}> = [
  {
    color: "darkGreen",
    value: "veryHigh",
    score: "90-100",
  },
  {
    color: "lightGreen",
    value: "high",
    score: "70-80",
  },
  {
    color: "lightOrange",
    value: "medium",
    score: "50-60",
  },
  {
    color: "darkOrange",
    value: "low",
    score: "0-40",
  },
];

const SCORE_COLORS_FOR_REVERSE_QUESTIONS: Array<{
  color: WellbeingColorPalette;
  value: string;
  score: string;
}> = [
  {
    color: "darkOrange",
    value: "veryHigh",
    score: "90-100",
  },
  {
    color: "lightOrange",
    value: "high",
    score: "70-80",
  },
  {
    color: "lightGreen",
    value: "medium",
    score: "50-60",
  },
  {
    color: "darkGreen",
    value: "low",
    score: "0-40",
  },
];


type Props = {
  showScoresInfo?: boolean;
  showInReverseOrder?: boolean;
  selectedAssessment?: CounsellorProgressAssessmentQuestion;
};
export default function WellbeingColorInfo({
  showInReverseOrder,
  showScoresInfo,
  selectedAssessment
}: Props) {
  const theme = useTheme();
  const { t } = useTranslation();

  const colorsInfo = useMemo(() => {
    const isReverseScoreAssessment =
        selectedAssessment &&
        REVERSE_SCORE_QUESTIONS.includes(selectedAssessment);

    const scoresColorsInfo = isReverseScoreAssessment
        ? SCORE_COLORS_FOR_REVERSE_QUESTIONS
        : SCORE_COLORS;

    return showInReverseOrder ? scoresColorsInfo.reverse() : scoresColorsInfo;
  }, [selectedAssessment, showInReverseOrder]);


  return (
    <Container>
      {colorsInfo.map(({ color, value, score }) => {
        const translatedColorValue = t(value);
        return (
          <InfoItem key={value}>
            <ColorCircle bgcolor={theme.palette.wellbeing[color]} />
            <Text>
              {showScoresInfo
                ? `${score} ${translatedColorValue}`
                : translatedColorValue}
            </Text>
          </InfoItem>
        );
      })}
    </Container>
  );
}
