import { useEffect, useState } from "react";
import { AIPrompt } from "../types/aiPrompts";
import { db } from "../contexts/FirebaseContext";
import {AIModelType, collections} from "utils/constants";

export function useGetAIPrompts() {
  const [loading, setLoading] = useState<boolean>(false);
  const [activeAIPrompts, setActiveAIPrompts] = useState<Array<AIPrompt>>([]);
  const [archivedAIPrompts, setArchivedAIPrompts] = useState<Array<AIPrompt>>([]);
  const [anthropicAIPrompt, setAnthropicAIPrompt] = useState<Array<AIPrompt>>([]);

  useEffect(() => {
    async function getAIPrompts() {
      try {
        setLoading(true);
        const documentReference = await db
          .collection(collections.AI_PROMPTS)
          .get();


        const activePrompts: Array<AIPrompt> = [];
        const archivedPrompts: Array<AIPrompt> = [];

        documentReference.docs.forEach((snapshot) => {
          const promptData = {
              id: snapshot.id,
              ...snapshot.data(),
            } as AIPrompt;

          if (promptData.isDeleted) {
            archivedPrompts.push(promptData);
            return;
          }

          activePrompts.push(promptData);
        })

        setArchivedAIPrompts(archivedPrompts);
        setActiveAIPrompts(activePrompts);
        setAnthropicAIPrompt(activePrompts.filter((prompt) => prompt.aiModelType === AIModelType.ANTHROPIC))
        setLoading(false);
      } catch (e) {
        setLoading(false);
        console.error(e);
      }
    }

    getAIPrompts();
  }, []);

  const deletePromptAndUpdate = async (promptId: string) => {
    try {
      setLoading(true);
      const docRef  = db.collection(collections.AI_PROMPTS).doc(promptId);

      const docData = (await docRef.get()).data() as AIPrompt;

      const isCurrentlyDeleted = docData?.isDeleted;
      await docRef.set({ isDeleted: !isCurrentlyDeleted }, { merge: true });

      // Remove prompt from local state
      if (isCurrentlyDeleted) {
        setArchivedAIPrompts(archivedAIPrompts.filter((prompt) => prompt.id !== promptId))
        const updatedActivePrompts = [
          ...activeAIPrompts,
          { ...docData, isDeleted: !isCurrentlyDeleted }
        ];

        setActiveAIPrompts(updatedActivePrompts);
      } else {
        setActiveAIPrompts(activeAIPrompts.filter((prompt) => prompt.id !== promptId))
        const updatedArchivedPrompts = [
          ...archivedAIPrompts,
          { ...docData, isDeleted: !isCurrentlyDeleted }
        ];

        setArchivedAIPrompts(updatedArchivedPrompts);
      }
    } catch (e) {
      console.error("FailedToArchiveAIPrompt", e)
    } finally {
      setLoading(false);
    }
  };

  return { archivedAIPrompts, anthropicAIPrompt, activeAIPrompts, loading, deletePromptAndUpdate };
}
