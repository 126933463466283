import React, {useContext, useEffect, useState} from "react";
import {Box, Card, Grid, Stack, Typography} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import {flatten, uniq} from "lodash";
import {ContentID} from "models/ContentUnit";
import {ContentUnitsContext} from "../../../contexts/ContentUnitsContext";
import FilteredContentAutocomplete from "../../../components/_dashboard/content/FilteredContentAutocomplete";
import {savePromptContentData, usePromptContent} from "../../../utils/firebase/filterContentUtil";
import {filterUndefined} from "../../../utils/typescript";
import LoadingScreen from "../../../components/LoadingScreen";
import {useSnackbar} from "notistack";
import {styled} from "@mui/material/styles";

const CONTENT_OPTIONS: Record<string, string[]> = {
    video: ["duration", "id", "locale", "mediaUrl", "themeId", "thumbnail", "title", "type"],
    meditation: ["audioFileUri", "audioMeditationId", "duration", "id", "locale", "title", "type"],
    reflection: ["duration", "id", "reflectionCategory", "themeId", "title", "type"],
    article: ["id", "themeId", "title", "type", "locale", "thumbnail"],
    contentLink: ["id", "title", "themeId"]
};

const LoadingContainer = styled(Box)(({theme}) => ({
    paddingTop: theme.spacing(15),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}));

export default function FilterContentContainer() {
    const [filterContentLoading, setFilterContentLoading] = useState<boolean>(false)
    const [selectedFilteredContentUnit, setSelectedFilteredContentUnit] = useState<ContentID[]>([]);
    const [selectedModules, setSelectedModules] = useState<ContentID[]>([]);
    const [selectedContent, setSelectedContent] = useState<Record<string, ContentID[]>>({
        video: [],
        meditation: [],
        reflection: [],
        article: [],
        contentLink: []
    });

    const {contentUnits, modulesContent} = useContext(ContentUnitsContext);
    const {promptContent, loading} = usePromptContent();
    const {enqueueSnackbar} = useSnackbar();

    useEffect(() => {
        if (!promptContent) {
            return;
        }

        setSelectedFilteredContentUnit(promptContent.filterContentUnits || []);
        setSelectedContent({
            video: promptContent.filterContentFieldsByType?.video || [],
            meditation: promptContent.filterContentFieldsByType?.meditation || [],
            reflection: promptContent.filterContentFieldsByType?.reflection || [],
            article: promptContent.filterContentFieldsByType?.article || [],
            contentLink: promptContent.filterContentFieldsByType?.contentLink || []
        });
        setSelectedModules(promptContent.filterModules || [])
    }, [promptContent]);

    const contentIds = uniq(flatten(Object.values(contentUnits || {}).map((unit) => unit?.id.replace("-en", ""))).filter(filterUndefined));
    const moduleIds = uniq(flatten(Object.values(modulesContent || {}).map((unit) => unit?.id.replace("-en", ""))).filter(filterUndefined));

    const handleSave = async () => {
        try {
            setFilterContentLoading(true);
            const updatedContentFieldsByType = {
                video: selectedContent.video,
                meditation: selectedContent.meditation,
                reflection: selectedContent.reflection,
                article: selectedContent.article,
                contentLink: selectedContent.contentLink
            };

            await savePromptContentData({
                filterContentUnits: selectedFilteredContentUnit,
                filterContentFieldsByType: updatedContentFieldsByType,
                filterModules: selectedModules
            });
            enqueueSnackbar("Saved Successfully", {variant: "success"});
        } catch (e) {
            enqueueSnackbar("Saved Failed", {variant: "error"});
            console.error("Failed to save filter content", e);
        } finally {
            setFilterContentLoading(false)
        }
    };

    const handleContentChange = (type: string) => (content: ContentID[]) => {
        setSelectedContent((prev) => ({...prev, [type]: content}));
    };

    if (loading) {
        return (
            <LoadingContainer>
                <LoadingScreen/>
            </LoadingContainer>
        )
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Stack spacing={3}>
                    <Typography variant="h6">Filter Content Units</Typography>
                    <Card sx={{p: 3}}>
                        <FilteredContentAutocomplete
                            label="Content Units"
                            contentOptions={contentIds}
                            placeholder="Type another content unit ID"
                            selectedContent={selectedFilteredContentUnit}
                            setSelectedContent={setSelectedFilteredContentUnit}
                        />
                    </Card>

                    <Typography variant="h6" pt={2}>Filter Content Fields by Type</Typography>
                    <Card sx={{p: 3}}>
                        {Object.entries(CONTENT_OPTIONS).map(([type, options]) => (
                            <FilteredContentAutocomplete
                                key={type}
                                label={type.charAt(0).toUpperCase() + type.slice(1)}
                                placeholder={`Type another ID for ${type}`}
                                contentOptions={options}
                                selectedContent={selectedContent[type]}
                                setSelectedContent={handleContentChange(type)}
                            />
                        ))}
                    </Card>

                    <Typography variant="h6">Filter Modules</Typography>
                    <Card sx={{p: 3}}>
                        <FilteredContentAutocomplete
                            label="Modules"
                            contentOptions={moduleIds}
                            placeholder="Type another module ID"
                            selectedContent={selectedModules}
                            setSelectedContent={setSelectedModules}
                        />
                    </Card>
                </Stack>

                <Box sx={{mt: 3, display: "flex", justifyContent: "flex-end"}}>
                    <LoadingButton
                        type="submit"
                        variant="contained"
                        onClick={handleSave}
                        loading={filterContentLoading}
                    >
                        Save Changes
                    </LoadingButton>
                </Box>
            </Grid>
        </Grid>
    );
}
