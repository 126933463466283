import React from "react";
import { Icon } from "@iconify/react";
import searchFill from "@iconify/icons-eva/search-fill";
import roundFilterList from "@iconify/icons-ic/round-filter-list";
// material
import { styled, useTheme } from "@mui/material/styles";
import {
  Box,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import DropDownSelector from "../DropdownSelector";
import { TranslationStatus } from "../../pages/dashboard/app-side-translations/AppSideTranslationQuickEdit";

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: "flex",
  justifyContent: "space-between",
  padding: theme.spacing(0, 1, 0, 3),
}));

const searchBarWidth = 380;
const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: searchBarWidth,
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  "&.Mui-focused": {
    width: searchBarWidth + 30,
    boxShadow: theme.customShadows.z8,
  },
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

// ----------------------------------------------------------------------

type TableToolbarProps = {
  numSelected: number;
  filterName: string;
  onFilterName: (value: string) => void;
  handleKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  searchPlaceholder?: string;
  showSearchBar?: boolean;
  onChange?: (status: string) => void;
  onSelectOptionValue?: (value: string) => void;
  selectedOptionValue?: string;
  selectFieldLabel?: string;
  rightIcon?: () => JSX.Element;
  options?: Array<string>;
};

export default function TableToolbar({
  numSelected,
  filterName,
  handleKeyDown,
  onFilterName,
  searchPlaceholder = "Type here to search",
  selectedOptionValue = "",
  rightIcon,
  onSelectOptionValue,
  options,
  selectFieldLabel,
  showSearchBar = true
}: TableToolbarProps) {
  const theme = useTheme();
  const isLight = theme.palette.mode === "light";

  const renderRight = () => {
    if (rightIcon) {
      return rightIcon();
    }
    return numSelected > 0 ? (
      <div />
    ) : (
      <Tooltip title="Filter list">
        <IconButton>
          <Icon icon={roundFilterList} />
        </IconButton>
      </Tooltip>
    );
  };

  return (
    <RootStyle
      sx={{
        ...(numSelected > 0 && {
          color: isLight ? "primary.main" : "text.primary",
          bgcolor: isLight ? "primary.lighter" : "primary.dark",
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography component="div" variant="subtitle1">
          {numSelected} selected
        </Typography>
      ) : (
        <>
          {Boolean(showSearchBar) && (
            <SearchStyle
              value={filterName}
              onChange={(e) => onFilterName(e.target.value)}
              onKeyDown={handleKeyDown}
              placeholder={searchPlaceholder}
              startAdornment={
                <InputAdornment position="start">
                  <Box
                    component={Icon}
                    icon={searchFill}
                    sx={{ color: "text.disabled" }}
                  />
                </InputAdornment>
              }
            />
          )}
        </>
      )}

      {Boolean(options?.length) && Boolean(onSelectOptionValue) && (
        <Box minWidth={300}>
          <DropDownSelector
            onChange={(event) =>
              onSelectOptionValue?.(event?.target?.value as TranslationStatus)
            }
            defaultValue={"Select"}
            label={ selectFieldLabel ?? "Filter By Status"}
            value={selectedOptionValue}
            disabled={false}
            options={options ?? []}
          />
        </Box>
      )}

      {renderRight()}
    </RootStyle>
  );
}
