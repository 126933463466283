import { InitialValues, UserCreditsContextValue } from "../../../types/user";
import { createContext } from "react";

export default createContext<UserCreditsContextValue>({
  inputValues: InitialValues,
  formDialogIsOpen: false,
  usersCreditsInfo: {
    data: {
      remainingCredits: undefined,
      extraCredits: 0,
      creditsUsed: 0,
    },
  },
  usersResponse: {
    userInfo: undefined,
  },
  handleClickOpen: () => {},
  handleClose: () => {},
  handleSubmit: () => Promise.resolve(),
  handleSearchInputChange: () => Promise.resolve(),
});
