import React, { ChangeEvent, useState } from 'react';
import {
  Card,
  Checkbox,
  CircularProgress,
  Container,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';

import Scrollbar from '../../Scrollbar';
import SearchNotFound from '../../SearchNotFound';
import AuditDetailsPopup from '../audit-logs/AuditDetailsPopup';
import Page from '../../Page';
import useSettings from '../../../hooks/useSettings';
import { useChangelog } from '../../../hooks/changelog/useChangelog';
import TableToolbar from '../../common/TableToolbar';
import { applySortFilter, getComparator } from '../../../utils/list';
import { Changelog } from '../../../types/changelog';
import MoreMenu from '../../common/MoreMenu';
import TableHeader from '../../common/TableHeader';

// ----------------------------------------------------------------------

const CHANGELOG_TABLE_HEAD = [
  { id: 'operation', label: 'Operation', alignRight: false },
  { id: 'time', label: 'performed At', alignRight: false },
  { id: 'performedBy', label: 'Performed By', alignRight: false },
  { id: 'actions', label: 'actions', alignRight: true }
];

// ----------------------------------------------------------------------

export default function ChangeLogsComponent(props: { collectionName: string; docId?: string }) {
  const { themeStretch } = useSettings();
  const [filterName, setFilterName] = useState('');
  const { changelog, loading } = useChangelog(props.collectionName, props.docId);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [selected, setSelected] = useState<string[]>([]);
  const [orderBy, setOrderBy] = useState('time');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [viewDetailsId, setViewDetailsId] = useState<string>('');

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (checked: boolean) => {
    if (checked) {
      const newSelecteds = (changelog || [])?.map?.((n: any) =>
        n?.time?.toDate?.()?.toISOString?.()
      );
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const handleFilterByName = (filterName: string) => {
    setFilterName(filterName);
  };

  const handleClick = (name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const filteredChangelog = applySortFilter(
    changelog,
    getComparator(order, orderBy),
    filterName,
    (query, _logItem: Changelog) => {
      return (
        _logItem?.time
          ?.toDate()
          ?.toISOString()
          ?.toLowerCase?.()
          ?.indexOf?.(query?.toLowerCase?.() || '') !== -1 ||
        _logItem?.performedBy?.toLowerCase?.()?.indexOf?.(query?.toLowerCase?.() || '') !== -1
      );
    }
  );

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - changelog.length) : 0;
  const isChangelogNotFound = filteredChangelog.length === 0;

  const onChangelogView = (id: string) => setViewDetailsId(id);

  return (
    <Page title="">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <Card sx={{ p: 2, overflow: 'hidden' }}>
          <TableToolbar
            numSelected={selected?.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            searchPlaceholder={'search by operation OR performed At'}
          />
          <Scrollbar>
            <TableContainer sx={{ minWidth: 'lg' }}>
              <Table>
                <TableHeader
                  order={order}
                  orderBy={orderBy}
                  headLabel={CHANGELOG_TABLE_HEAD}
                  rowCount={changelog.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                {loading ? (
                  <CircularProgress />
                ) : (
                  <TableBody>
                    {filteredChangelog
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row: any) => {
                        const time = row?.time?.toDate?.()?.toISOString?.() || '',
                          operation = row?.operation || '',
                          performedBy = row?.performedBy.email ?? row?.performedBy.uid ?? row?.performedBy  ??'';

                        const isItemSelected = selected.indexOf(time) !== -1;

                        return (
                          <TableRow
                            hover
                            key={time}
                            tabIndex={-1}
                            role="checkbox"
                            selected={isItemSelected}
                            aria-checked={isItemSelected}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                checked={isItemSelected}
                                onClick={() => handleClick(time)}
                              />
                            </TableCell>
                            <TableCell align="left">{operation}</TableCell>
                            <TableCell component="th" scope="row" padding="none">
                              <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography variant="subtitle2" noWrap>
                                  {time}
                                </Typography>
                              </Stack>
                            </TableCell>
                            <TableCell align="left">{performedBy}</TableCell>
                            <TableCell align="right">
                              <MoreMenu
                                itemIdentifier={time}
                                onView={(itemID) => {
                                  onChangelogView(itemID);
                                }}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                )}
                {isChangelogNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        {filteredChangelog?.length ? (
                          <SearchNotFound searchQuery={filterName} />
                        ) : (
                          <></>
                        )}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={changelog.length}
            rowsPerPage={rowsPerPage}
            page={page}
            // eslint-disable-next-line @typescript-eslint/no-shadow
            onPageChange={(e, page) => setPage(page)}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
        <AuditDetailsPopup
          data={
            (changelog || [])?.find?.(
              (item: Changelog) => item.time.toDate().toISOString() === viewDetailsId
            ) ?? {}
          }
          isOpen={!!viewDetailsId}
          onClose={() => setViewDetailsId('')}
        />
      </Container>
    </Page>
  );
}
