import {db} from 'contexts/FirebaseContext';
import {useEffect, useState} from 'react';

import {PaymentLink} from '../../types/invitation/code';
import {collections} from "../constants";

export function useGetPaymentLinks() {
    const [links, setLinks] = useState<PaymentLink[]>([]);
    useEffect(() => {
        const unsub = db
            .collection(collections.PAYMENT_LINKS)
            .orderBy("createdAt", "desc")
            .onSnapshot(async (snap) => {
                const docs = snap.docs.map((doc) => {
                    return {
                        id: doc.id,
                        ...doc.data()
                    }
                });
                setLinks(docs as PaymentLink[]);
            });
        return () => unsub();
    }, []);

    return links;
}

export async function getSpecificPaymentLink(paymentLinkId: string) {
    const docRef = await db
        .collection(collections.PAYMENT_LINKS).doc(paymentLinkId).get();

    return docRef.data() as PaymentLink
}

export async function deleteSpecificPaymentLink(paymentLinkId: string) {
   return db.collection(collections.PAYMENT_LINKS).doc(paymentLinkId).delete();
}
