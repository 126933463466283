import { Box } from "@mui/material";
import { pick } from "lodash";
import { ContentFromFirestoreOptions, ContentID } from "models/ContentUnit";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ContentAutocomplete from "../content/ContentAutocomplete";
import { PATH_DASHBOARD } from "../../../routes/paths";
import ModulesContentForm from "./ModulesContentForm";
import { LanguageSelectionDropDown, MULTI_LINGUAL_OPTION } from "../../LanguageSelectionDropDown";
import { ContentUnitsContext } from "../../../contexts/ContentUnitsContext";
import { ContentCollection, deleteContentItem, updateModuleContent } from "../../../utils/firebase/contentUtils";
import { SupportedLanguagesEnum } from "utils/constants";
import { SupportedLanguages } from "types/invitation/code";
import MultiLingualAutocomplete from "../content/MultiLingualAutocomplete";
import { MultilingualLocalesTypes } from "../../../types/translations";
import useSettings from "../../../hooks/useSettings";

export default function ModulesFormContainer() {
  const [selectedContentUnit, setSelectedContentUnit] = useState<ContentID>();
  const [selectedTargetLanguages, setSelectedTargetLanguages] = useState<MultilingualLocalesTypes[]>([]);

  const {
    modulesContent,
    contentUnits,
    setModulesContentLanguage,
    setContentLanguage,
    moduleContentLanguage,
  } = useContext(ContentUnitsContext);

  const { localeList } = useSettings()

  const { language, moduleId } = useParams();

  const navigate = useNavigate();

  const handleLanguageChange = (newLanguage: SupportedLanguages) => {
    setModulesContentLanguage(newLanguage)
    setContentLanguage(newLanguage)
    navigate("/content/content-modules/")
    if ((newLanguage as string) === MULTI_LINGUAL_OPTION) {
      setSelectedTargetLanguages(localeList)
    }
  }

  const onSelectMultilingualLanguage = (selectedLangs: MultilingualLocalesTypes[]) => {
    setSelectedTargetLanguages(selectedLangs)
  }

  useEffect(() => {
    if (moduleId && moduleId !== selectedContentUnit) {
      setSelectedContentUnit(moduleId);
    }
  }, [moduleId, selectedContentUnit]);
  
  useEffect(() => {
    if (language && language !== moduleContentLanguage) {
      // we know language param is set by the ContentAutocomplete to type SupportedLanguages
      setModulesContentLanguage(language as SupportedLanguages);
      setContentLanguage(language as SupportedLanguages)
    }
  }, [language, moduleContentLanguage, setModulesContentLanguage, setContentLanguage]);

  useEffect(() => {
    if (language as string === MULTI_LINGUAL_OPTION && moduleId) {
      const data = modulesContent?.[moduleId]
      setSelectedTargetLanguages(data?.targetMultilingualLocales ?? [])
    }
  }, [language, moduleId, modulesContent]);


  if (!modulesContent) {
    return null;
  }

  const moduleContentOptions = Object.values(modulesContent).map(
    (moduleContent) => {
      return pick(moduleContent, [
        "id",
        "title",
        "type",
      ]) as ContentFromFirestoreOptions;
    }
  );

  const contentUnitOptions = Object.keys(contentUnits);

  const defaultValue = moduleContentOptions.find(
    (moduleContent) => moduleContent?.id === selectedContentUnit
  );

  const data = moduleId ? modulesContent?.[moduleId] : undefined;

  const deleteModule = async (id: string, selectedLanguage: SupportedLanguagesEnum | string): Promise<void> => {
    await deleteContentItem(ContentCollection.MODULES, id, selectedLanguage, localeList);
    setSelectedContentUnit("")
  };

  return (
    <Box>
      <Box my={2}>
        <LanguageSelectionDropDown
          onSelectionChanged={(fieldKey, value) => {
            handleLanguageChange(value);
          }}
          fieldName={"selectedLanguage"}
          selectedValue={moduleContentLanguage}
          label={"Select Language"}
          showMultiLingualOption
          localeList={ localeList }
        />
      </Box>

      <ContentAutocomplete
        setSelectedContentUnit={setSelectedContentUnit}
        contentUnitOptions={moduleContentOptions}
        defaultValue={defaultValue}
        path={PATH_DASHBOARD.contentModules.root}
        textFieldLabel={"Search for modules content by title or ID"}
        language ={moduleContentLanguage}
      />

      <MultiLingualAutocomplete
        selectedTargetLanguages={ selectedTargetLanguages }
        onSelectMultilingualLanguage={ onSelectMultilingualLanguage }
        localeList={ localeList }
        selectedLanguage={ moduleContentLanguage }/>

      <ModulesContentForm
        data={data}
        moduleId={moduleId}
        selectedMultilingualLanguages={selectedTargetLanguages}
        selectedLanguage={moduleContentLanguage}
        contentUnitOptions={contentUnitOptions}
        onSubmit={updateModuleContent}
        deleteModule={deleteModule}
      />
    </Box>
  );
}
