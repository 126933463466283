import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
// material
import {
  Card,
  Chip,
  Container,
  Link,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography
} from "@mui/material";

// routes
import { PATH_DASHBOARD } from "../../../routes/paths";

// hooks
import useSettings from "../../../hooks/useSettings";

// components
import Page from "../../../components/Page";
import Scrollbar from "../../../components/Scrollbar";
import HeaderBreadcrumbs from "../../../components/HeaderBreadcrumbs";
import { useSnackbar } from "notistack";
import TableHeader from "../../../components/common/TableHeader";
import MoreMenu from "../../../components/common/MoreMenu";

import { format } from "date-fns";
import { AudioMeditationGenerationType } from "../../../types/audioMeditationGenerations";
import { dateTimeDisplayFormat } from "../../../utils/analytics/analyticsDefaults";
import LoadingScreen from "../../../components/LoadingScreen";
import { LANGUAGE_CODE_TO_NAMES } from "../../../utils/constants";
import {
  deleteUserGeneratedAudioMeditationByIdFromDB,
  useUserGeneratedAudioMeditations
} from "../../../utils/firebase/userGeneratedAudioMeditationsUtil";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "title", label: "Title", alignRight: false },
  { id: "voiceId", label: "Voice ID", alignRight: false },
  { id: "sourceLang", label: "Source Language", alignRight: false },
  { id: "targetLang", label: "Target Language", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
  { id: "audioUrl", label: "Audio Meditation", alignRight: false },
  { id: "isActive", label: "Is Active", alignRight: false },
  { id: "createdAt", label: "Created At", alignRight: false },
  { id: "actions", label: "Actions", alignRight: true },
];

// ----------------------------------------------------------------------


export default function UserGeneratedAudioMeditationsList() {
  const { themeStretch } = useSettings();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [deletingId, setDeletingId] = useState<string>();

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const {
    audioMeditations = [],
    loading,
    isMoreMeditationsAvailable
  } = useUserGeneratedAudioMeditations(page, rowsPerPage)

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event?.target?.value ?? 0, 10));
    setPage(0);
  };

  const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, _page: number) => {
    setPage(_page);
  };

  const handleDeleteAudioMeditation = async (selectedMeditation: AudioMeditationGenerationType) => {
    try {
      const selectedMeditationId = selectedMeditation.id

      if (!selectedMeditationId) return;

      setDeletingId(selectedMeditationId);

      if (window.confirm(`Do you really want to delete this user generated audio meditation (${ selectedMeditation.title })?`)) {
        await deleteUserGeneratedAudioMeditationByIdFromDB(selectedMeditation)
        enqueueSnackbar("User Generated Audio Meditation Deleted Successfully", {
          variant: "success",
        });
      } else {
        enqueueSnackbar("Delete User Generated Audio meditation cancel by user", {
          variant: "error",
        });
      }
    } catch (e) {
      console.error("FailedToDeleteAudioMeditation", e);
      enqueueSnackbar("Failed to delete audio meditation", {
        variant: "error",
      });
    } finally {
      setDeletingId("");
    }
  };

  const onDeleteAction = (audioMeditationItem: AudioMeditationGenerationType) => {
    return () => handleDeleteAudioMeditation(audioMeditationItem)
  }

  if (loading) {
    return <LoadingScreen/>;
  }

  return (
    <Page title="User Generated Audio Meditations">
      <Container maxWidth={ themeStretch ? false : "lg" }>
        <HeaderBreadcrumbs
          heading=""
          links={ [] }
          action={ null }
        />

        <Card>
          <Scrollbar>
            <TableContainer sx={ { minWidth: 800 } }>
              <Table>
                <TableHeader
                  order={ "desc" }
                  orderBy={ "createdAt" }
                  headLabel={ TABLE_HEAD }
                  numSelected={ 0 }
                  rowCount={ audioMeditations.length }
                  hideCheckbox/>
                <TableBody>
                  { audioMeditations
                    .map((audioMeditationItem: AudioMeditationGenerationType) => {
                      const {
                        id,
                        title,
                        sourceLang,
                        targetLang,
                        status,
                        targetLanguageVoiceUrl,
                        createdAt,
                        isActive,
                        voiceConfig: { id: dbVoiceId = "" } = { id: "" },
                      } = audioMeditationItem;

                      return (
                        <TableRow
                          hover
                          key={ `${ title }-${ id }` }
                          tabIndex={ -1 }
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            padding="normal"
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={ 2 }
                            >
                              <Typography variant="subtitle2" noWrap>
                                { title }
                              </Typography>
                            </Stack>
                          </TableCell>

                          <TableCell
                            component="th"
                            scope="row"
                            padding="normal"
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={ 2 }
                            >
                              <Typography variant="subtitle2" noWrap>
                                { dbVoiceId ?? "Unknown" }
                              </Typography>
                            </Stack>
                          </TableCell>

                          <TableCell
                            component="th"
                            scope="row"
                            padding="normal"
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={ 2 }
                            >
                              <Typography variant="subtitle2" noWrap>
                                { LANGUAGE_CODE_TO_NAMES[sourceLang?.toUpperCase()] ?? sourceLang }
                              </Typography>
                            </Stack>
                          </TableCell>

                          <TableCell
                            component="th"
                            scope="row"
                            padding="normal"
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={ 2 }
                            >
                              <Typography variant="subtitle2" noWrap>
                                { LANGUAGE_CODE_TO_NAMES[targetLang?.toUpperCase()] ?? targetLang }
                              </Typography>
                            </Stack>
                          </TableCell>

                          <TableCell
                            component="th"
                            scope="row"
                            padding="normal"
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={ 2 }>
                              <Chip label={ status }
                                    color="primary"
                                    variant="outlined"/>
                            </Stack>
                          </TableCell>

                          <TableCell
                            component="th"
                            scope="row"
                            padding="normal"
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={ 2 }
                            >
                              { targetLanguageVoiceUrl ?
                                <Link href={ targetLanguageVoiceUrl } target="_blank">Download Audio File</Link> :
                                <Typography variant="subtitle2" noWrap>---</Typography> }
                            </Stack>
                          </TableCell>

                          <TableCell
                            component="th"
                            scope="row"
                            padding="normal">
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={ 2 }>
                              <Chip label={ isActive ? 'Active' : 'Inactive' }
                                    color={ isActive ? 'success' : 'error' }/>
                            </Stack>
                          </TableCell>

                          <TableCell
                            component="th"
                            scope="row"
                            padding="normal">
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={ 2 }>
                              <Typography variant="body1" noWrap>
                                { createdAt ? format(createdAt, dateTimeDisplayFormat) : "---" }
                              </Typography>
                            </Stack>
                          </TableCell>

                          <TableCell align="right">
                            <MoreMenu
                              deleting={ deletingId === id }
                              onDelete={ onDeleteAction(audioMeditationItem) }
                              onView={ () => {
                                navigate(
                                  `${ PATH_DASHBOARD.userGeneratedAudioMeditations.root }/${ id }/view`,
                                  { state: { audioMeditation: audioMeditationItem } }
                                );
                              } }
                            />
                          </TableCell>
                        </TableRow>
                      );
                    }) }
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={ [5, 10, 25, 50, 100, 125, 150] }
            component="div"
            count={ isMoreMeditationsAvailable ? -1 : ((rowsPerPage * page) + audioMeditations.length) }
            rowsPerPage={ rowsPerPage }
            page={ page }
            onPageChange={ handlePageChange }
            onRowsPerPageChange={ handleChangeRowsPerPage }
          />
        </Card>
      </Container>
    </Page>
  );
}
