import {db} from "../../contexts/FirebaseContext";
import {NotificationHistory, NotificationPayload} from "../../types/notifications";
import {collections} from "../constants";

export async function getNotificationHistoryById(
    id: string,
): Promise<NotificationPayload> {
    const doc = await db.collection(collections.NOTIFICATIONS_HISTORY)
        .doc(id)
        .get();

    if (!doc.exists) {
        throw new Error("No history exists with this Id");
    }

    return doc.data() as NotificationPayload
}

export async function getNotificationHistory(){
    const documentReference = await db
        .collection(collections.NOTIFICATIONS_HISTORY)
        .orderBy("createdAt", "desc")
        .get();

    return documentReference.docs.map((snapshot) => {
        const documentData = snapshot.data() ?? {};
        return {
            ...documentData,
            id: snapshot.id,
            createdAt: documentData.createdAt.toDate(),
        } as NotificationHistory;
    })
}
