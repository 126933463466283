import { ListItemButton, ListItemText } from "@mui/material";
import RevisionBirthListIcon from "../atoms/RevisionBirthListIcon";
import RevisionDeleteListIcon from "../atoms/RevisionDeleteListIcon";
import RevisionUpdateListIcon from "../atoms/RevisionUpdateListIcon";
import { Revision } from "../types/Revision";

/**
 * Helper to create ListItems for revisions without repeating yourself
 * too much
 */
export default function createRevisionItem(
  revision: Revision,
  selectedRevision: string,
  onSelect: (revisionId: string) => void
) {
  let itemIcon = null;
  switch (revision.status) {
    case 2:
      itemIcon = <RevisionUpdateListIcon />;
      break;
    case 1:
      itemIcon = <RevisionBirthListIcon />;
      break;
    case 0:
      itemIcon = <RevisionDeleteListIcon />;
      break;
    default:
      return null;
  }

  const primaryText = revision.checksum;
  const dateText = revision.timestamp.toUTCString();
  const itemText = <ListItemText primary={dateText} secondary={primaryText} />;
  return (
    <ListItemButton
      onClick={() => onSelect(revision.id)}
      selected={revision.id === selectedRevision}
    >
      {itemIcon}
      {itemText}
    </ListItemButton>
  );
}
