import { Avatar, Box, Grid, Skeleton, Stack } from "@mui/material";

function LoadingListItem() {
  return (
    <>
      <Grid container>
        <Grid item xs={2}>
          <Skeleton variant="circular">
            <Avatar />
          </Skeleton>
        </Grid>
        <Grid item xs={10}>
          <Stack>
            <Skeleton variant="text" />
            <Skeleton variant="text" />
          </Stack>
        </Grid>
      </Grid>
    </>
  );
}

export default function LoadingRevisionList() {
  return (
    <>
      <Box p={2}>
        <LoadingListItem />
      </Box>
      <Box p={2}>
        <LoadingListItem />
      </Box>
      <Box p={2}>
        <LoadingListItem />
      </Box>
    </>
  );
}
