import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
} from "@mui/material";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import QRCodeFromDeepLink from "pages/invitationCode/QRCodeFromDeepLink";
import {
  ContentLinkData,
  createDeepLink,
} from "pages/invitationCode/QRCodePage";
import StringWithCopyOption from "components/StringWithCopyOption";
import useSettings from "hooks/useSettings";
import { MultilingualLocalesTypes } from "types/translations";

interface Props {
  contentUnit?: {
    tags: string[];
    title?: string;
    id: string;
  };
}

function removeLocale(
  contentId: string,
  localeList: MultilingualLocalesTypes[]
): string {
  const locales = localeList.map((locale) => locale.locale.toLowerCase());
  // Check for 2-letter locales
  const lastTwo = contentId.slice(-2).toLowerCase();
  if (lastTwo === "en" || locales.includes(lastTwo)) {
    return contentId.slice(0, -3);
  }

  // Check for 4-letter locales with hyphen
  const lastFive = contentId.slice(-5).toLowerCase();
  if (locales.includes(lastFive)) {
    return contentId.slice(0, -6);
  }
  throw new Error("Invalid content ID format");
}

const GenerateQRCodeForContentButton: React.FC<Props> = ({ contentUnit }) => {
  const [open, setOpen] = useState(false);
  const [campaign, setCampaign] = useState("");
  const [deepLink, setDeepLink] = useState<string | null>(null);
  const { localeList } = useSettings();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setDeepLink(null);
    setOpen(false);
  };

  const handleGenerateCode = async () => {
    if (!contentUnit) {
      return;
    }

    const deepLinkScreen = contentUnit.tags.includes("course")
      ? "moduleScreen"
      : "contentScreen";

    const linkData: ContentLinkData = {
      screen: deepLinkScreen,
      campaign: campaign,
      route_params: JSON.stringify({
        // we are removing the locale from the content ID to make the deep
        // link work for default locale of the user
        contentId: removeLocale(contentUnit.id, localeList),
        screen: "content", // module.id // "Search" in search
        showOverviewCards: !contentUnit.tags.includes("assessment"),
      }),
    };

    const contentLink = await createDeepLink(linkData);
    setDeepLink(contentLink);
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        startIcon={<QrCodeScannerIcon />}
        onClick={handleClickOpen}
        sx={{ my: 2 }}
        disabled={!contentUnit}
      >
        Generate QR code
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Generate QR code</DialogTitle>
        <DialogContent>
          <Typography variant="subtitle1">
            {contentUnit?.title
              ? `Content unit: ${contentUnit.title}`
              : "Content unit: N/A"}
          </Typography>
          <TextField
            margin="dense"
            label="Campaign"
            type="text"
            fullWidth
            value={campaign}
            onChange={(e) => setCampaign(e.target.value)}
            sx={{ mt: 2 }}
          />
          {deepLink && (
            <>
              <QRCodeFromDeepLink
                url={deepLink}
                fileName={contentUnit?.title || ""}
              />
              <StringWithCopyOption text={deepLink} variant="h6" />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
          <Button onClick={handleGenerateCode} color="primary">
            Generate
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default GenerateQRCodeForContentButton;
