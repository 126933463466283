import React from "react";
import { Form, FormikProvider, useFormik } from "formik";

// material
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Card,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField
} from "@mui/material";

import { AudioMeditationGenerationType } from "../../../types/audioMeditationGenerations";
import { capitalizeFirstLetter } from "../../../utils/stringUtils";
import LoadingScreen from "../../LoadingScreen";
import { LANGUAGE_CODE_TO_NAMES, LANGUAGE_NAMES_TO_CODE } from "../../../utils/constants";
import MeditationVoiceAutocomplete from "../audio-meditation-generations/MeditationVoiceAutocomplete";
import { MenuProps } from "../audio-meditation-generations/AudioMeditationGenerationForm";

// ----------------------------------------------------------------------

type UserGeneratedAudioMeditationFormProps = {
  loading: boolean;
  audioMeditation?: AudioMeditationGenerationType;
};


export default function UserGeneratedAudioMeditationForm({
                                                           audioMeditation,
                                                           loading
                                                         }: UserGeneratedAudioMeditationFormProps) {
  const isView = true
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: audioMeditation?.id ?? "",
      title: audioMeditation?.title ?? "",
      sourceText: audioMeditation?.sourceText ?? "",
      sourceLang: audioMeditation?.sourceLang ?? "",
      targetLang: audioMeditation?.targetLang,
      formality: audioMeditation?.formality ?? "",
      voiceId: audioMeditation?.voiceConfig?.id ?? "",
      comment: audioMeditation?.comment ?? "",
      audioUrl: audioMeditation?.targetLanguageVoiceUrl ?? "",
      skipExternalProcessing: audioMeditation?.skipExternalProcessing ?? false,
      isActive: audioMeditation?.isActive ?? false,
      translator: audioMeditation?.translator ?? "google",
      meditationId: audioMeditation?.meditationId ?? "",
      stability: audioMeditation?.voiceConfig?.setting?.stability ?? '0.70',
      similarityBoost: audioMeditation?.voiceConfig?.setting?.similarityBoost ?? '0.10',
      style: audioMeditation?.voiceConfig?.setting?.style ?? '0.0',
      useSpeakerBoost: audioMeditation?.voiceConfig?.setting?.useSpeakerBoost ?? false,
    },
    onSubmit: () => {
    }
  });

  const { errors, touched, handleSubmit, getFieldProps, setFieldValue } = formik;
  const submitBtnTitle = "View Only"


  const renderTranslatedFields = () => {
    return (<>
      { Boolean(audioMeditation?.translatedTextFromTranslator) && <TextField
          fullWidth
          multiline
          minRows={ 5 }
          value={ audioMeditation?.translatedTextFromTranslator }
          label={ `Translated Text From ${ capitalizeFirstLetter(audioMeditation?.translator ?? '') }` }
          disabled
      />
      }

      { Boolean(audioMeditation?.AIProcessedTranslatedText) && <TextField
          fullWidth
          multiline
          minRows={ 5 }
          value={ audioMeditation?.AIProcessedTranslatedText }
          label="AI Processed Translated Text"
          disabled
      />
      }
    </>)
  }

  const renderStatusAndMetaFields = () => {
    return (<>
      { Boolean(audioMeditation?.failureReason) && <TextField
          fullWidth
          multiline
          minRows={ 2 }
          value={ audioMeditation?.failureReason }
          label="Failure Reason"
          disabled
      />
      }
      { Boolean(audioMeditation?.status) &&
          <TextField
              fullWidth
              value={ audioMeditation?.status }
              label="Status"
              disabled
          />
      }
    </>)
  }

  if (loading) {
    return <LoadingScreen/>;
  }

  return (
    <FormikProvider value={ formik }>
      <Form noValidate autoComplete="off" onSubmit={ handleSubmit }>
        <fieldset disabled={ isView } style={ { border: "none" } }>
          <Grid container spacing={ 3 }>
            <Grid item xs={ 12 }>
              <Card sx={ { p: 3 } }>
                <Stack spacing={ 3 }>
                  <TextField
                    fullWidth
                    label="Title"
                    { ...getFieldProps("title") }
                    disabled={ isView }
                    error={ Boolean(touched.title && errors.title) }
                    helperText={ touched.title && errors.title }
                  />

                  <TextField
                    fullWidth
                    label="Source Language"
                    disabled={ isView }
                    { ...getFieldProps("sourceLang") }
                    error={ Boolean(touched.sourceLang && errors.sourceLang) }
                    helperText={ touched.sourceLang && errors.sourceLang }
                  />

                  <TextField
                    fullWidth
                    multiline
                    minRows={ 5 }
                    disabled={ isView }
                    label="Source Text"
                    { ...getFieldProps("sourceText") }
                    error={ Boolean(touched.sourceText && errors.sourceText) }
                    helperText={ touched.sourceText && errors.sourceText }
                  />

                  <FormControl fullWidth>
                    <InputLabel id="target-lang">Target Languages</InputLabel>
                    <Select
                      label="Target Languages"
                      disabled={ isView }
                      multiple={ false }
                      { ...getFieldProps("targetLang") }
                      input={ <OutlinedInput id="select-multiple-chip" label="Chip" color="primary"/> }
                      MenuProps={ MenuProps }
                      renderValue={ (selected: any) => {
                        return (
                          <Box sx={ { display: 'flex', flexWrap: 'wrap', gap: 0.5 } }>
                            { selected?.map?.((value: string) => (
                              <Chip key={ value } label={ LANGUAGE_CODE_TO_NAMES[value] ?? value } color="info"
                                    variant="outlined"
                                    onMouseDown={ (event) => event.stopPropagation() }/>
                            )) ?? <Chip color="info" variant="outlined"
                                        label={ LANGUAGE_CODE_TO_NAMES[selected] ?? "" }/> }
                          </Box>
                        );
                      } }>
                      { Object.keys(LANGUAGE_NAMES_TO_CODE).map((key: string) => (
                        <MenuItem
                          key={ key }
                          value={ LANGUAGE_NAMES_TO_CODE[key] }>
                          { key }
                        </MenuItem>
                      )) }
                    </Select>
                  </FormControl>

                  <FormControl fullWidth>
                    <InputLabel id="translator-select-label">Translator</InputLabel>
                    <Select
                      label="Translator"
                      disabled={ isView }
                      { ...getFieldProps("translator") }>
                      <MenuItem value="google">Google</MenuItem>
                      <MenuItem value="deepl">Deepl</MenuItem>
                    </Select>
                  </FormControl>

                  <FormControl fullWidth>
                    <InputLabel id="formality-select-label">Formality</InputLabel>
                    <Select
                      label="Formality"
                      disabled={ isView }
                      { ...getFieldProps("formality") }>
                      <MenuItem value="less">Less</MenuItem>
                      <MenuItem value="default">Default</MenuItem>
                      <MenuItem value="more">More</MenuItem>
                      <MenuItem value="prefer_more">Prefer More</MenuItem>
                      <MenuItem value="prefer_less">Prefer Less</MenuItem>
                    </Select>
                  </FormControl>

                  { renderTranslatedFields() }

                  <TextField
                    fullWidth
                    label="Comment"
                    disabled={ isView }
                    { ...getFieldProps("comment") }
                    error={ Boolean(touched.comment && errors.comment) }
                    helperText={ touched.comment && errors.comment }
                  />


                  <TextField
                    fullWidth
                    label="Meditation"
                    disabled={ isView }
                    { ...getFieldProps("meditationId") }
                    error={ Boolean(touched.meditationId && errors.meditationId) }
                    helperText={ touched.meditationId && errors.meditationId }
                  />

                  <MeditationVoiceAutocomplete
                    freeSolo={ false }
                    disabled={ isView }
                    onChangeValue={ (value) => {
                      setFieldValue("voiceId", value)
                    } }
                    { ...getFieldProps("voiceId") }
                    helperText={ touched.voiceId && errors.voiceId }
                  />

                  <TextField
                    fullWidth
                    label="Voice Stability"
                    disabled={ isView }
                    { ...getFieldProps("stability") }
                    error={ Boolean(touched.stability && errors.stability) }
                    helperText={ touched.stability && errors.stability }/>

                  <TextField
                    fullWidth
                    disabled={ isView }
                    label="Voice Similarity Boost"
                    { ...getFieldProps("similarityBoost") }
                    error={ Boolean(touched.similarityBoost && errors.similarityBoost) }
                    helperText={ touched.similarityBoost && errors.similarityBoost }/>

                  <TextField
                    fullWidth
                    label="Voice Style"
                    disabled={ isView }
                    { ...getFieldProps("style") }
                    error={ Boolean(touched.style && errors.style) }
                    helperText={ touched.style && errors.style }/>

                  { renderStatusAndMetaFields() }


                  <FormControlLabel
                    label="Is Active"
                    disabled={ isView }
                    control={ <Checkbox
                      defaultChecked={ audioMeditation?.isActive ?? false } { ...getFieldProps("isActive") } /> }
                  />


                  <FormControlLabel
                    label="Use Speaker Boost"
                    disabled={ isView }
                    control={ <Checkbox
                      defaultChecked={ audioMeditation?.voiceConfig?.setting?.useSpeakerBoost ?? false } { ...getFieldProps("useSpeakerBoost") } /> }
                  />

                  <FormControlLabel
                    label="Skip External Translation Processing"
                    disabled={ isView }
                    control={ <Checkbox
                      defaultChecked={ audioMeditation?.skipExternalProcessing ?? false } { ...getFieldProps("skipExternalProcessing") } /> }
                  />

                  { Boolean(audioMeditation?.targetLanguageVoiceUrl) &&
                      <Link href={ audioMeditation?.targetLanguageVoiceUrl } target="_blank">Download Audio File</Link>
                  }


                  <Box sx={ { mt: 3, display: "flex", justifyContent: "flex-end" } }>
                    <LoadingButton
                      type="submit"
                      variant="contained">
                      { submitBtnTitle }
                    </LoadingButton>
                  </Box>
                </Stack>
              </Card>
            </Grid>
          </Grid>
        </fieldset>
      </Form>
    </FormikProvider>
  );
}