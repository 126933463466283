import React from 'react';
// material
import {Stack, TextField, Typography,} from '@mui/material';
import {DateTimePicker} from '@mui/lab';
import {createStyles, makeStyles} from '@mui/styles';
import {dateTimeDisplayFormat} from '../../../utils/analytics/analyticsDefaults';
import {formatDateInTimezone} from "../../../utils/dateUtils";

// ----------------------------------------------------------------------
type Props = {
    date: Date | number;
    userTimezone: string;
    therapistTimezone: string;
    onChange: (date: Date | number | null) => void;

};


const useStyles = makeStyles(() =>
    createStyles({
        timePicker: {
            width: '100%',
            '& .MuiFormControl-root': {
                width: ' 100%',
            },
        }
    })
);

export default function EditCallTime({userTimezone, therapistTimezone, date, onChange}: Props) {
    const classes = useStyles();

    return (
        <Stack
            sx={{
                py: 2,
            }}
            direction="column"
        >
            <div className={classes.timePicker}>
                <DateTimePicker
                    label="Select Date"
                    renderInput={(params) => <TextField {...params} />}
                    inputFormat={'hh:mm aa dd.MM.yyyy'}
                    mask="__:__ __ __.__.____"
                    value={date}
                    onChange={onChange}
                />
            </div>
         <Stack marginTop={1}>
                <Stack direction={'row'}>
                    <Typography fontWeight={"bold"} marginRight={1} fontSize={10}>User Timezone:</Typography>
                    <Typography fontSize={10}>{formatDateInTimezone(
                        date,
                        userTimezone,
                        dateTimeDisplayFormat,
                    )}</Typography>
                </Stack>

                <Stack direction={'row'}>
                    <Typography fontWeight={"bold"} marginRight={1} fontSize={10}>Counselor Timezone:</Typography>
                    <Typography fontSize={10}>{formatDateInTimezone(
                        date,
                        therapistTimezone,
                        dateTimeDisplayFormat,
                    )}</Typography>
                </Stack>
            </Stack>
        </Stack>
    );
}
