import {createSlice} from '@reduxjs/toolkit';
// ----------------------------------------------------------------------


export type ContentVersions = {
  translationsVersion: number;
  botDataVersion: number;
  settingsVersion: number;
  ratingQuestionsVersion: number;
  multilingualLocalesVersion: number;
  suggestedTranslationsVersion: number;
};

type ContentVersionState = {
  versions: ContentVersions,
};

const initialState: ContentVersionState = {
  versions: {
    translationsVersion: 0,
    botDataVersion: 0,
    settingsVersion: 0,
    ratingQuestionsVersion: 0,
    multilingualLocalesVersion: 0,
    suggestedTranslationsVersion: 0,
  },
};

const slice = createSlice({
  name: 'contentVersions',
  initialState,
  reducers: {
    // Set content versions data
    setContentVersion(state, action) {
      return {
        ...state,
        versions: action.payload,
      };
    },
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { setContentVersion } = slice.actions;
