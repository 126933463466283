import { db } from "contexts/FirebaseContext";
import { useEffect, useState } from "react";
import {ThemeFromFirestore} from "../models/Theme";




export default function useGetThemeContent() {
    const [themeContent, setThemeContent] = useState<Array<ThemeFromFirestore>>();

    useEffect(() => {


        const listener = db.collection("themes").onSnapshot(async (snapshot) => {

            const docs = snapshot.docs.map((doc) => {
                const data = doc?.data();
                return {id: doc?.id, ...data} as ThemeFromFirestore;
            })

            setThemeContent(docs);
        });

        return () => {
            if (listener) {
                listener();
            }
        };
    }, []);

    return themeContent;
}
