// material
import { Box, BoxProps } from '@mui/material';

// ----------------------------------------------------------------------

export default function Logo({
  sx = {
    width: 37,
    height: 37
  }
}: BoxProps) {
  return (
    <Box sx={{ ...sx }}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 37 37"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.9726 15.0701C3.73685 16.7321 6.51435 16.6491 8.17631 14.8849L10.157 16.7507C7.46455 19.6089 2.9649 19.7432 0.10675 17.0508L1.9726 15.0701Z"
          fill="#1F1E5F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37 14.161C24.3864 14.161 14.161 24.3864 14.161 37H0C0 16.5655 16.5655 -1.78644e-06 37 0L37 14.161ZM37 26.1729C31.0203 26.1729 26.1729 31.0203 26.1729 37H19.4596C19.4596 27.3127 27.3127 19.4597 37 19.4597L37 26.1729Z"
          fill="url(#paint0_linear_6542_51122)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.54773 25.3581C10.3747 27.1851 13.3368 27.1851 15.1637 25.3581L17.2148 27.4092C14.2551 30.3689 9.45639 30.3689 6.49664 27.4092L8.54773 25.3581Z"
          fill="#1F1E5F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.8084 14.7312C21.5726 16.3932 24.3501 16.3103 26.0121 14.546L27.9928 16.4119C25.3003 19.27 20.8007 19.4044 17.9425 16.7119L19.8084 14.7312Z"
          fill="#1F1E5F"
        />
        <defs>
          <linearGradient
            id="paint0_linear_6542_51122"
            x1="26.9157"
            y1="36.9524"
            x2="22.4713"
            y2="3.74604"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F35C5C" />
            <stop offset="1" stopColor="#FD7373" />
          </linearGradient>
        </defs>
      </svg>
    </Box>
  );
}
