import {WellbeingColorPalette} from "../../../theme/palette";
import {sum} from "lodash";
import {TFunction} from "react-i18next";
import {
  CounsellorProgressAssessmentQuestion,
  GroupProgressInsightsData
} from "../../../types/CounsellorProgressDasboard";
import {AllCounsellorProgressAssessmentQuestions, REVERSE_SCORE_QUESTIONS} from "./constants";


export const getColorScoreForNormalQuestion = (
    value: number
): WellbeingColorPalette => {
  if (value >= 9) return "darkGreen";
  if (value >= 7) return "lightGreen";
  if (value >= 5) return "lightOrange";

  return "darkOrange";
};
export const getColorScoreForReversedQuestion = (
    value: number
): WellbeingColorPalette => {
  if (value >= 9) return "darkOrange";
  if (value >= 7) return "lightOrange";
  if (value >= 5) return "lightGreen";

  return "darkGreen";
};

export const getColorForScore = (
    value: number,
    selectedAssessment?: CounsellorProgressAssessmentQuestion
): WellbeingColorPalette => {
  if (
      selectedAssessment &&
      REVERSE_SCORE_QUESTIONS.includes(selectedAssessment)
  ) {
    return getColorScoreForReversedQuestion(value);
  }

  return getColorScoreForNormalQuestion(value);
};

export const getPreCounsellingSessionTitle = (
  totalSessions: number,
  t: TFunction
) => {
  const categories = [];
  for (let sessionCount = 0; sessionCount < totalSessions; sessionCount++) {
    if (sessionCount === 0) {
      categories.push(t("preCounsellingSession"));
      continue;
    }

    categories.push(`${t("postCounsellingSession")} ${sessionCount}`);
  }

  return categories;
};

export const getGroupProgressQuestionScoreChangeInfo = (
    score: number,
    selectedAssessmentQuestion: CounsellorProgressAssessmentQuestion
) => {
  const isReverseScoreQuestion = REVERSE_SCORE_QUESTIONS.includes(
      selectedAssessmentQuestion
  );
  const isPositiveScore = isReverseScoreQuestion ? score <= 0 : score >= 0;

  const formattedScoreForNormalQuestion = isPositiveScore ? `+${score}` : score;
  const formattedScoreForReverseQuestion = isPositiveScore
      ? `-${Math.abs(score)}`
      : `+${score}`;

  const formattedScore = isReverseScoreQuestion
      ? formattedScoreForReverseQuestion
      : formattedScoreForNormalQuestion;

  const scoreColor: WellbeingColorPalette = isPositiveScore
      ? "lightGreen"
      : "darkOrange";

  return { score: formattedScore, scoreColor };
};


export const getOverallScoreForAllClients = (
    groupProgressInsightsData?: GroupProgressInsightsData
) => {
  const questionsScores = AllCounsellorProgressAssessmentQuestions.map(
      (question) => {
        return groupProgressInsightsData?.assessmentQuestionsData?.[question]
                ?.overallScore || 0
      }
  );

  return Math.round(sum(questionsScores) / questionsScores.length);
};
