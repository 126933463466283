import {
  FieldInputProps,
  FormikErrors,
  FormikTouched,
} from "formik/dist/types";
import { FormikValues } from "formik";
import { SupportedLanguages } from "../invitation/code";
import { LastChange } from "../changelog/changelog.types";

export type BotDataType = {
  [key in SupportedLanguages]?: BotDataItem;
};

export type BotDataItem = {
  journeys?: Journey;
  exercises?: Exercises;
  misc?: Miscellaneous;
  meditations?: Meditations;
  toolkitExercises?: ToolKitExercise;
  titles?: TitleDetails;
  botsPreview?: BotPreviewDetails;
  subJourneys?: SubJourneys;
  sleepMeditations?: Meditations;
  lastChange?: LastChange;
};

export type RatingData = Record<
  string,
  {
    ratingTitle: string;
    question: {
      title: string;
      options: Array<string>;
    };
  }
>;

export type BotsRatingCategories = {
  journeys?: Record<string, RatingData>;
  exercises?: RatingData;
  toolkitExercises?: RatingData;
  lastChange?: LastChange;
};

export type BotsRatingData = {
  [key in SupportedLanguages]?: BotsRatingCategories;
};

export type Journey = {
  burnout?: BurnOut;
  conflict?: Conflict;
  managingFrustration?: ManagingFrustration;
  selfConfidence?: SelfConfidence;
  stressAndAnxiety?: StressAndAnxiety;
  worry?: Worry;
};

export type Exercises = {
  anger: ExerciseItem;
  celebration: ExerciseItem;
  confidence: ExerciseItem;
  conflict: ExerciseItem;
  frustration: ExerciseItem;
  panic: ExerciseItem;
  procrastination: ExerciseItem;
  sleep: ExerciseItem;
  stress: ExerciseItem;
  whatIsHappening: ExerciseItem;
  worry: ExerciseItem;
  [key: string]: ExerciseItem;
};

export type AbstractExerciseItem = {
  id: string;
  duration: number;
  key?: string;
  description?: string;
  header?: string;
  title: string;
};

export type ExerciseItem = AbstractExerciseItem & {
  sortPlace: string;
  progress?: string;
  name?: string;
  isFeatured?: boolean;
  isDisabled?: boolean;
  emotion: string;
};

export type ToolkitExerciseItem = AbstractExerciseItem & {
  sortPlace: string;
  progress?: string;
  themeColor: string;
  emotion: string;
};

export type MiscellaneousExerciseItem = AbstractExerciseItem & {
  themeColor: string;
};

export type Miscellaneous = {
  onboarding: MiscellaneousExerciseItem;
  privacy: MiscellaneousExerciseItem;
  [key: string]: MiscellaneousExerciseItem;
};

export type ToolKitExercise = {
  JB_1_P2T_DEMONSONBOAT: ToolkitExerciseItem;
  JB_2_P5T_FUNCTIONAL_ANALYSIS: ToolkitExerciseItem;
  JB_3_P1T_MILK: ToolkitExerciseItem;
  JB_3_P2T_LEAVESONSTREAM: ToolkitExerciseItem;
  JB_3_P3T_METAPHORS: ToolkitExerciseItem;
  JB_3_P3T_VOICES: ToolkitExerciseItem;
  JB_4_P1E_RAISIN: ToolkitExerciseItem;
  JB_4_P2T_PMR: ToolkitExerciseItem;
  JB_4_P3T_BODYSCAN: ToolkitExerciseItem;
  JB_4_P4T_SITTINGMEDITATION: ToolkitExerciseItem;
  JB_4_P4T_WALKINGMEDITATION: ToolkitExerciseItem;
  JB_4_P5T_LISTENINGMINDFULLY: ToolkitExerciseItem;
  JB_5_P2T_RADICALACCEPTANCEMEDITATION: ToolkitExerciseItem;
  JB_6_P1E_CHESSMATCH: ToolkitExerciseItem;
  JB_6_P2T_OBSERVERSELF: ToolkitExerciseItem;
  JB_6_P4T_SELFCOMPASSION: ToolkitExerciseItem;
  JB_8_P4T_VALUEAFFIRMATIONS: ToolkitExerciseItem;
  SC_P_P1T_GOAL_SETTING: ToolkitExerciseItem;
  SC_P_P2T_PROGRESS_TRACKING: ToolkitExerciseItem;
  SC_P_P3T_IMPLEMENTATING_INTENTIONS: ToolkitExerciseItem;
  SC_P_P4T_INCREASING_VALUE: ToolkitExerciseItem;
  SC_P_P5T_PEER_SUPPORT: ToolkitExerciseItem;
  SC_P_P7T_REWARD_PER_TASK_COMPLETED: ToolkitExerciseItem;
  SC_P_P8T_EXTENDED_WILL_TECHNIQUE: ToolkitExerciseItem;
  [key: string]: ToolkitExerciseItem;
};

//journeys type
type BurnOut = {
  [key: string]: {
    [key: string]: JourneyBotDataItem;
  };
};

type Conflict = {
  [key: string]: {
    [key: string]: JourneyBotDataItem;
  };
};

type ManagingFrustration = {
  [key: string]: {
    [key: string]: JourneyBotDataItem;
  };
};

type SelfConfidence = {
  [key: string]: {
    [key: string]: JourneyBotDataItem;
  };
};

type StressAndAnxiety = {
  [key: string]: {
    [key: string]: JourneyBotDataItem;
  };
};

type Worry = {
  [key: string]: {
    [key: string]: JourneyBotDataItem;
  };
};

export type Meditations =
  | {
      breakingHabits: MeditationsItem;
      daysToBeatStress: MeditationsItem;
      daysToMindfulness: MeditationsItem;
      daysToMoreConfidence: MeditationsItem;
      daysToReduceAnger: MeditationsItem;
      daysToReduceAnxiety: MeditationsItem;
      energyBoost: MeditationsItem;
      essentialLifeSkills: MeditationsItem;
      relaxation: MeditationsItem;
      sleep: MeditationsItem;
      sosConfidentBoost: MeditationsItem;
      sosLetGoOfAnger: MeditationsItem;
      sosLetGoOfAnxiety: MeditationsItem;
      sosLetGoOfStress: MeditationsItem;
      [key: string]: MeditationsItem;
    }
  | SleepMeditations;

type SleepMeditations = {
  meditation: MeditationsItem;
  nature: MeditationsItem;
  powerNap: MeditationsItem;
  stories: MeditationsItem;
  [key: string]: MeditationsItem;
};

export type MeditationsItem = {
  emotion: string;
  practices: {
    [key: string]: Practice;
  };
  sortPlace: string;
  subtitle: string;
  themeColor: string;
  title: string;
  isFeatured?: boolean;
  isDisabled?: boolean;
};

export type Practice = {
  animationSpeed: number;
  duration: number;
  meditationTitle: string;
  description: string;
  meditationUri: string;
  sortPlace: string;
};

export type ExercisesBotPropsType = {
  exercises: Exercises;
  selectedLanguage: SupportedLanguages;
  botsPreviewData?: BotPreviewItem;
};

export type MeditationBotPropsType = {
  meditations: Meditations;
  selectedLanguage: SupportedLanguages;
  botsPreviewData?: BotPreviewItem;
  selectedBotCategoryKey: "meditations" | "sleepMeditations";
};

export type MiscBotPropsType = {
  misc: Miscellaneous;
  selectedLanguage: SupportedLanguages;
};

export type ToolkitExercisesBotPropsType = {
  toolkitExercises: ToolKitExercise;
  selectedLanguage: SupportedLanguages;
  botsPreviewData?: BotPreviewItem;
};

export type JourneysBotPropsType = {
  journeys?: {
    [key: string]: Journey;
  };
  selectedLanguage: SupportedLanguages;
};

export type ExercisesOverviewPropsType = {
  exercises?: Exercises;
  selectedLanguage: SupportedLanguages;
  botsPreviewData?: BotPreviewItem;
};

export type MeditationOverviewPropsType = {
  meditations?: Meditations;
  selectedLanguage: SupportedLanguages;
  botsPreviewData?: BotPreviewItem;
};

export type ToolkitExercisesOverviewPropsType = {
  toolkitExercises?: ToolKitExercise;
  selectedLanguage: SupportedLanguages;
  botsPreviewData?: BotPreviewItem;
};

export type JourneysOverviewPropsType = {
  journeys?: {
    [key: string]: Journey;
  };
  selectedLanguage: SupportedLanguages;
  botsPreviewData?: BotPreviewItem;
};

export type BotRatingPropsType = {
  botData?: BotDataType;
  selectedLanguage: SupportedLanguages;
  selectedBotCategory: RatingBotCategory;
  botsRatingData?: BotsRatingData;
};

export type TitleDetails = {
  [key: string]: {
    [key: string]: {
      title: string;
      sortPlace: number;
    };
  };
};

type BotPreviewItem = {
  [key: string]: {
    tabTitle: string;
    description: string;
    isMandatory?: boolean;
  };
};

export type BotPreviewDetails = {
  [key: string]: BotPreviewItem;
};

export type TitlesPropsType = {
  journeys?: {
    [key: string]: Journey;
  };
  exercises: any;
  meditations: any;
  titles?: TitleDetails;
  selectedLanguage: SupportedLanguages;
  subJourneysKeys: Array<string>;
};

type SubJourneyMeditation = {
  title: string;
  sortPlace: number;
  emotion: string;
  subtitle: string;
  themeColor: string;
  animationSpeed: number;
  duration: number;
  meditationTitle: string;
  description: string;
  meditationUri: string;
  meditationType: string;
  header: string;
};

type SubJourneyBot = {
  type: SubJourneyTypes;
  id: string;
  key: string;
  progress: string;
  description: string;
  title: string;
  sortPlace: number;
  duration: number;
  emotion: string;
  header: string;
  name: string;
  themeColor: string;
};

type SubJourneyReflection = {
  type: SubJourneyTypes;
};

type SubJourneyTypes = "reflection" | "boxBreathing" | "meditation" | "bot";

type SubJourneys = {
  [key: string]: Array<
    SubJourneyMeditation | SubJourneyBot | SubJourneyReflection
  >;
};

export type SubJourneysPropsType = {
  selectedLanguage: SupportedLanguages;
  subJourneys?: SubJourneys;
};

export type CategoriesKeysTypes = {
  journeys: string[];
  exercises: string[];
  meditations: string[];
};

export type TitlesCategoriesTypes = "journeys" | "exercises" | "meditations";

export type BotDataItemType = JourneyBotDataItem & SortPlace;

export type SubJourney = "reflection" | "boxBreathing" | "478Breathing";

export type JourneyBotDataItem = {
  id: string;
  description: string;
  title: string;
  header?: string;
  emotion?: string;
  name: string;
  subJourneyType: SubJourney;
};

type SortPlace = {
  sortPlace: string;
};

export const BOT_DATA_ITEM_KEYS = [
  "id",
  "key",
  "progress",
  "description",
  "title",
  "sortPlace",
  "duration",
  "emotion",
  "updatedEmotion",
  "header",
  "name",
  "themeColor",
];

export const BOT_DATA_EXERCISES_ITEM_KEYS = [
  "id",
  "key",
  "progress",
  "description",
  "title",
  "sortPlace",
  "emotion",
  "updatedEmotion",
  "header",
  "name",
  "duration",
  "assessmentBotKey",
  "toolkitExerciseBotKeys",
  "darkBackgroundColor",
  "midBackgroundColor",
  "lightBackgroundColor",
];

export const MED_BOT_DATA_ITEM_KEYS = [
  "title",
  "sortPlace",
  "emotion",
  "subtitle",
  "themeColor",
  "practices",
];

export const MED_BOT_PRACTICES_ITEM_KEYS = [
  "animationSpeed",
  "duration",
  "meditationTitle",
  "description",
  "meditationUri",
  "sortPlace",
  "emotion",
  "animatedEmotion",
];

export const SUB_JOURNEY_MEDITATION_KEYS = [
  "title",
  "sortPlace",
  "emotion",
  "subtitle",
  "themeColor",
  ...MED_BOT_PRACTICES_ITEM_KEYS,
];

export type AddNewExerciseFormProps = {
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<FormikErrors<FormikValues>> | Promise<void>;
  getFieldProps: (nameOrOptions: any) => FieldInputProps<any>;
  exercises?: Exercises;
  errors: FormikErrors<FormikValues>;
  touched: FormikTouched<FormikValues>;
  isSubmitting: boolean;
};

export type AddNewMiscFormProps = {
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<FormikErrors<FormikValues>> | Promise<void>;
  getFieldProps: (nameOrOptions: any) => FieldInputProps<any>;
  misc: Miscellaneous;
  errors: FormikErrors<FormikValues>;
  touched: FormikTouched<FormikValues>;
  isSubmitting: boolean;
};

export type AddNewToolKitExerciseFormProps = {
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<FormikErrors<FormikValues>> | Promise<void>;
  getFieldProps: (nameOrOptions: any) => FieldInputProps<any>;
  toolkitExercises?: ToolKitExercise;
  errors: FormikErrors<FormikValues>;
  touched: FormikTouched<FormikValues>;
  isSubmitting: boolean;
};

export type AddNewJourneyFormProps = {
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<FormikErrors<FormikValues>> | Promise<void>;
  getFieldProps: (nameOrOptions: any) => FieldInputProps<any>;
  journeys?: Journey;
  errors: FormikErrors<FormikValues>;
  touched: FormikTouched<FormikValues>;
  isSubmitting: boolean;
  isEdit: boolean;
  isNew: boolean;
  journeyKey: string;
  outerIndex: string | number;
  innerIndex: string | number;
};

export type AddJourneyPreviewFormProps = {
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<FormikErrors<FormikValues>> | Promise<void>;
  getFieldProps: (nameOrOptions: any) => FieldInputProps<any>;
  journeys?: Journey;
  errors: FormikErrors<FormikValues>;
  touched: FormikTouched<FormikValues>;
  isSubmitting: boolean;
};

export type EditBotsRatingProps = {
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<FormikErrors<FormikValues>> | Promise<void>;
  getFieldProps: (nameOrOptions: any) => FieldInputProps<any>;
  isSubmitting: boolean;
  selectedBotCategory: RatingBotCategory;
  selectedBot?: string;
  selectedJourney: string;
};

export type AddNewMeditationFormProps = {
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<FormikErrors<FormikValues>> | Promise<void>;
  getFieldProps: (nameOrOptions: any) => FieldInputProps<any>;
  meditations?: Meditations;
  selectedLanguage: SupportedLanguages;
  errors: FormikErrors<FormikValues>;
  touched: FormikTouched<FormikValues>;
  isSubmitting: boolean;
};

export type AddNewPracticeFormProps = {
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<FormikErrors<FormikValues>> | Promise<void>;
  getFieldProps: (nameOrOptions: any) => FieldInputProps<any>;
  practices: {
    [key: string]: Practice;
  };
  selectedLanguage: SupportedLanguages;
  errors: FormikErrors<FormikValues>;
  touched: FormikTouched<FormikValues>;
  selectedMeditationKey: string;
};

export type SaveTitlesFormProps = {
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<FormikErrors<FormikValues>> | Promise<void>;
  getFieldProps: (nameOrOptions: any) => FieldInputProps<any>;
  errors: FormikErrors<FormikValues>;
  touched: FormikTouched<FormikValues>;
  isSubmitting: boolean;
  selectedValue: string;
  selectedCategory: TitlesCategoriesTypes;
  subJourneysKeys: Array<string>;
};

export type BotPreviewFormProps = {
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<FormikErrors<FormikValues>> | Promise<void>;
  getFieldProps: (nameOrOptions: any) => FieldInputProps<any>;
  errors?: FormikErrors<FormikValues>;
  touched?: FormikTouched<FormikValues>;
  itemKey: string;
};

export type SubJourneyProps = {
  getFieldProps: (nameOrOptions: any) => FieldInputProps<any>;
  fieldKey: string;
};

export type BotCategory =
  | "Journeys"
  | "Meditations"
  | "Exercises"
  | "Toolkit Exercises"
  | "Misc"
  | "Titles"
  | "Sub-Journeys"
  | "SleepMeditations";

export type RatingBotCategory = "journeys" | "exercises" | "toolkitExercises";
