import React, {useEffect, useState} from "react";
import {useLocation, useParams} from "react-router-dom";
// material
import {Card, Container, Grid, Stack, TextField, Typography} from "@mui/material";
import {Form, FormikProvider, useFormik} from "formik";
import {LoadingButton} from "@mui/lab";
import {useSnackbar} from "notistack";
import * as Yup from "yup";

// hooks
import useSettings from "../../hooks/useSettings";
// components
import Page from "../../components/Page";
import HeaderBreadcrumbs from "../../components/HeaderBreadcrumbs";
import {getSpecificPaymentLink} from "../../utils/firebase/paymentLinksUtils";
import LoadingScreen from "../../components/LoadingScreen";
import {getUserInfoForPaymentLink} from "../../utils/firebase/cloudFunctions";
import StringWithCopyOption from "../../components/StringWithCopyOption";
import {Locale} from "../../types/user";

type UserInfo = {
    email: string;
    uid: string;
    locale: Locale;
}


// ----------------------------------------------------------------------

export default function GetPaymentLinkForUser() {
    const {themeStretch} = useSettings();
    const {state} = useLocation();
    const {id: linkId} = useParams();
    const [loading, setLoading] = useState<boolean>(false);
    const routeState = state as { paymentLinkUrl?: string }
    const [selectedPaymentLinkUrl, setSelectedPaymentLinkUrl] = useState<string | undefined>(routeState.paymentLinkUrl);
    const [userInfo, setUserInfo] = useState<UserInfo>();

    useEffect(() => {
        if (!selectedPaymentLinkUrl && linkId) {
            setLoading(true)
            getSpecificPaymentLink(linkId)
                .then(async (paymentLink) => {
                    setSelectedPaymentLinkUrl(paymentLink.paymentLinkUrl);
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    setLoading(false)
                });
        }
    }, [linkId, selectedPaymentLinkUrl]);


    const {enqueueSnackbar} = useSnackbar();


    const PaymentLinkSchema = Yup.object().shape({
        query: Yup.string().required("Email or user id is required"),
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            query: "",
        },

        validationSchema: PaymentLinkSchema,
        onSubmit: async (values) => {
            try {
                setUserInfo(undefined);
                await getUserInfoForPaymentLink({query: values.query}).then((res) => {
                    const {email, uid, locale = "en"} = res.data;

                    if (email && uid) {
                        setUserInfo({
                            email,
                            uid,
                            locale
                        })
                    } else {
                        enqueueSnackbar("User not found", {variant: "error"})
                    }
                })
            } catch (e) {
                enqueueSnackbar("Something went wrong", {variant: "error"})
                console.error(e)
            }
        },
    });


    const {
        errors,
        touched,
        handleSubmit,
        isSubmitting,
        getFieldProps,
    } = formik;


    if (loading) {
        return <LoadingScreen/>
    }


    return (
        <Page title="Get link for user">
            <Container maxWidth={themeStretch ? false : "lg"}>
                <HeaderBreadcrumbs
                    heading="Get payment link for user"
                    links={[]}
                />
                <FormikProvider value={formik}>
                    <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
                        <fieldset style={{border: "none"}}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Card sx={{p: 3}}>
                                        <Stack
                                            direction={{xs: 'column', sm: 'column'}}
                                            spacing={1}
                                            sx={{justifyContent: 'space-between'}}
                                        >
                                            <TextField
                                                fullWidth
                                                label="Enter Email or uid of user"
                                                {...getFieldProps("query")}
                                                error={Boolean(
                                                    //@ts-ignore
                                                    touched?.query &&
                                                    // @ts-ignore
                                                    errors?.query
                                                )}
                                                helperText={
                                                    //@ts-ignore
                                                    touched?.query &&
                                                    // @ts-ignore
                                                    errors?.query
                                                }
                                                type="text"
                                            />
                                        </Stack>
                                        <LoadingButton
                                            sx={{
                                                mt: 2,
                                            }}
                                            type="submit"
                                            variant="contained"
                                            loading={isSubmitting}
                                        >
                                            Get link
                                        </LoadingButton>
                                    </Card>
                                </Grid>
                            </Grid>
                        </fieldset>
                    </Form>
                </FormikProvider>

                {userInfo && selectedPaymentLinkUrl && <Card sx={{mt: 5, padding: 2}}>
                    <Typography sx={{
                        mb: 1,
                        fontWeight:600
                    }}>Click on link to copy</Typography>
                    <StringWithCopyOption
                        text={`${selectedPaymentLinkUrl}?prefilled_email=${userInfo.email}&client_reference_id=${userInfo.uid}&locale=${userInfo.locale}`}/>
                </Card>}
            </Container>
        </Page>
    );
}
