// material
import { visuallyHidden } from "@mui/utils";
import {
  Box,
  TableRow,
  TableCell,
  TableHead,
  TableSortLabel,
  Popover,
  Button,
  Typography,
  Stack,
} from "@mui/material";
import React from "react";
import InfoIcon from "@mui/icons-material/Info";
import { format } from "date-fns";

// ----------------------------------------------------------------------

type UserListHeadProps = {
  order: "asc" | "desc";
  orderBy: string;
  rowCount: number;
  headLabel: any[];
  numSelected: number;
  onRequestSort: (id: string) => void;
  onSelectAllClick: (checked: boolean) => void;
};

export default function UserListHead({
  order,
  orderBy,
  headLabel,
  onRequestSort,
}: UserListHeadProps) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <TableHead>
      <TableRow>
        {headLabel.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignRight ? "right" : "left"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <Stack direction={"row"}>
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={() => onRequestSort(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box sx={{ ...visuallyHidden }}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>

              {headCell.id === "createdAt" && (
                <Stack>
                  <Button
                    sx={{ width: 25, height: 25, top: -10, right: 20 }}
                    onClick={handleClick}
                  >
                    <InfoIcon />
                  </Button>
                  <Popover
                    id={headCell.id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                  >
                    <Typography sx={{ p: 2 }}>
                      Default times in {format(new Date(), "z")}
                    </Typography>
                  </Popover>
                </Stack>
              )}
            </Stack>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
