import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
// material
import { Box, Container } from "@mui/material";
// hooks
import useSettings from "../../../hooks/useSettings";
// components
import Page from "../../../components/Page";
import HeaderBreadcrumbs from "../../../components/HeaderBreadcrumbs";
import { SupportedLanguagesEnum } from "../../../utils/constants";
import { LanguageSelectionDropDown } from "../../../components/LanguageSelectionDropDown";
import { SupportedLanguages } from "../../../types/invitation/code";
import { getPrivacyPolicyById } from "../../../utils/firebase/PrivacyPolicyUtils";
import { PrivacyPolicy } from "../../../types/privacyPolicies";
import PrivacyPolicyNewForm from "../../../components/_dashboard/privacy-policy/PrivacyPolicyNewForm";

// ----------------------------------------------------------------------

export default function CreatePrivacyPolicy() {
  const { themeStretch } = useSettings();
  const { pathname } = useLocation();
  const { state } = useLocation();
  const { id: privacyPolicyId } = useParams();

  const [privacyPolicy, setPrivacyPolicy] = useState<PrivacyPolicy>(
    state as PrivacyPolicy
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [language, setLanguage] = useState<SupportedLanguages>(
    (privacyPolicy?.locale as SupportedLanguagesEnum) ?? SupportedLanguagesEnum.ENGLISH
  );

  const isEdit = pathname.includes("edit");
  const isView = pathname.includes("view");
  const isNew = pathname.includes("new");

  const fetchPrivacyPolicy = useCallback(() => {
    if (privacyPolicy && privacyPolicyId && !loading && (isView || isEdit)) {
      setLoading(true);
      getPrivacyPolicyById(privacyPolicyId, language)
        .then((policy) => {
          setPrivacyPolicy(policy);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [privacyPolicy, privacyPolicyId, loading, isView, isEdit, language]);

  useEffect(() => {
    fetchPrivacyPolicy();
  }, [fetchPrivacyPolicy, privacyPolicyId]);

  let pageTitle = "Create a new Privacy Policy";
  if (isView) {
    pageTitle = "View Privacy Policye";
  } else if (isEdit) {
    pageTitle = "Edit Privacy Policy";
  }

  return (
    <Page title={pageTitle}>
      <Container maxWidth={themeStretch ? false : "lg"}>
        <HeaderBreadcrumbs heading={pageTitle} links={[]} />

        {isNew && (
          <Box sx={{ margin: 3, marginLeft: 0 }}>
            <LanguageSelectionDropDown
              fieldName={"language"}
              selectedValue={language}
              onSelectionChanged={(key, value) => setLanguage(value)}
            />
          </Box>
        )}

        <PrivacyPolicyNewForm
          isEdit={isEdit}
          isView={isView}
          isNew={isNew}
          selectedLanguage={language}
          privacyPolicy={privacyPolicy}
        />
      </Container>
    </Page>
  );
}
