import Page from "components/Page";
import {Container} from "@mui/material";
import useSettings from "hooks/useSettings";
import {useTranslation} from "react-i18next";
import {useState} from "react";
import {CounsellorProgressAssessmentQuestion} from "types/CounsellorProgressDasboard";
import GroupProgressContentContainer from "../../../components/counsellor-group-progress/GroupProgressContentContainer";

export function GroupProgress() {
  const [showProgressOverview, setShowProgressOverview] =
    useState<CounsellorProgressAssessmentQuestion>(CounsellorProgressAssessmentQuestion.Z1);
  const { themeStretch } = useSettings();
  const { t } = useTranslation();


  return (
      <Page title={t("groupProgress")}>
        <Container maxWidth={themeStretch ? false : "lg"}>
          <GroupProgressContentContainer
            showProgressOverview={showProgressOverview}
            setShowProgressOverview={setShowProgressOverview}
          />
        </Container>
      </Page>
  );
}
