import React from 'react';
import {CreditLinksProps, PaymentLink} from '../../../../types/invitation/code';
import {Stack} from '@mui/material';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import Divider from "@mui/material/Divider";
import PaymentLinkInputForm from "./PaymentLinkInputForm";



export default function DynamicPaymentLinks({
                                             setFieldValue,
                                             getFieldProps,
                                             touched,
                                             errors,
                                             editing
                                         }: CreditLinksProps) {
    const paymentLinks: any = getFieldProps("paymentLinks")?.value || [];

    return (
        <Stack spacing={3}>
            {paymentLinks?.map((item: PaymentLink, index: number) => {
                return (
                    <>
                        <Stack
                            key={String(item.id)}
                            direction={{ xs: 'column', sm: 'column' }}
                            spacing={1}
                            sx={{ justifyContent: 'space-between' }}
                        >

                            <PaymentLinkInputForm
                                editing={editing}
                                getFieldProps={getFieldProps}
                                setFieldValue={setFieldValue}
                                fieldsName={`paymentLinks[${index}]`}
                                touched={touched}
                                errors={errors}
                            />

                            <Button
                                color="error"
                                variant="outlined"
                                endIcon={<DeleteIcon />}
                                onClick={() => {
                                    const updatedAnswers = (paymentLinks || [])?.filter?.(
                                        (ans: any) => ans?.id !== item?.id
                                    );
                                    setFieldValue("paymentLinks", updatedAnswers);
                                }}
                            >
                                delete
                            </Button>
                            <Divider/>
                        </Stack>
                    </>
                );
            })}
        </Stack>
    );
}
