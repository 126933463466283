type Input = Record<string, Record<string, Record<string, unknown>>>;

export const filterUndefinedBotData = (botData: Readonly<Input>) => {
  const clone: Input = JSON.parse(JSON.stringify(botData));
  for (let [key, course] of Object.entries(clone)) {
    if (key === "undefined") {
      delete clone[key];
      continue;
    }
    for (let [innerKey, module] of Object.entries(course)) {
      if (JSON.stringify(module) === '{"0":{}}') {
        delete course[innerKey];
        continue;
      }

      for (let [innerInnerKey, exercise] of Object.entries(module)) {
        if (JSON.stringify(exercise) === "{}") {
          delete module[innerInnerKey];
        }
      }
    }
  }
  return clone;
};
