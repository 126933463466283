// material
import { Box, Card, Grid, Stack, TextField, Button } from "@mui/material";
// utils
//
import { useSnackbar } from "notistack";
import { Form, FormikProvider, useFormik } from "formik";
import { FocusError } from "focus-formik-error";
import { LoadingButton } from "@mui/lab";

import { object, string } from "yup";
import { ThemeFromFirestore } from "../../../models/Theme";

import {updateThemeContent} from "../../../utils/firebase/contentUtils";
import DeleteButton from "../content/DeleteButton";
import DropDownSelector from "components/DropdownSelector";

// ----------------------------------------------------------------------

const ThemeContentSchema = object({
  id: string()?.required(),
  emotion: string(),
  imageId: string(),
  svgImage: string(),
  backgroundColorKey: string(),
  colourPalette: string(),
});

type Props = {
  data?: ThemeFromFirestore;
  themeId?: string;
  deleteTheme: (id: string) => void;
  colourPalettesOptions: string[];
};

export default function ThemeContentForm({ data, themeId, deleteTheme, colourPalettesOptions }: Props) {
  const { enqueueSnackbar } = useSnackbar();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: data ?? {
      id: themeId ?? "",
      emotion: "",
      imageId: "",
      svgImage: "",
      backgroundColorKey: "none",
      colourPalette: colourPalettesOptions[0] ?? "",
    },
    validationSchema: ThemeContentSchema,
    onSubmit: async (values) => {
      try {
        enqueueSnackbar(`Updating ${values?.id}`);

        const doc = {
          ...values,
        };

        await updateThemeContent(doc);
        enqueueSnackbar("Saved successfully!", { variant: "success" });
      } catch (error) {
        console.error("An error occurred: ", error);
        enqueueSnackbar("Failed to save", { variant: "error" });
      }
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, handleReset } = formik;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card sx={{ p: 3 }}>
          <FormikProvider value={formik}>
            <Form noValidate onSubmit={handleSubmit}>
              <fieldset style={{ border: "none" }}>
                <FocusError formik={formik} />
                <Stack spacing={3}>
                  <Stack
                    direction={{ xs: "column" }}
                    spacing={{ xs: 3, sm: 2 }}
                  >
                    <Stack spacing={3}>
                      <TextField
                        fullWidth
                        label="Id"
                        {...getFieldProps("id")}
                        error={Boolean(touched.id && errors.id)}
                        helperText={touched.id && errors.id}
                      />

                      <TextField
                        fullWidth
                        label="svgImage"
                        {...getFieldProps("svgImage")}
                        error={Boolean(touched?.svgImage && errors?.svgImage)}
                        helperText={touched?.svgImage && errors?.svgImage}
                      />

                      <DropDownSelector 
                        label={"Select a colour Palette"} disabled={false} options={colourPalettesOptions}
                        {...getFieldProps("colourPalette")}  
                      />

                      <DropDownSelector 
                        label={"Select a color index from the colour palette to be the BGColor"} 
                        disabled={false} 
                        options={[
                          "color1",
                          "color2",
                          "color3",
                          "color4",
                          "color5",
                          "color6",
                          "color7",
                        ]}
                        {...getFieldProps("backgroundColorKey")}     
                      />

                      <TextField
                        fullWidth
                        label="Emotion (for compatibility with the old branding)"
                        {...getFieldProps("emotion")}
                        error={Boolean(touched?.emotion && errors?.emotion)}
                        helperText={touched?.emotion && errors?.emotion}
                      />

                      <TextField
                        fullWidth
                        label="ImageId (for compatibility with the old branding)"
                        {...getFieldProps("imageId")}
                        error={Boolean(touched?.imageId && errors?.imageId)}
                        helperText={touched?.imageId && errors?.imageId}
                      />
                    </Stack>
                  </Stack>
                </Stack>

                <Box
                  sx={{ mt: 3, display: "flex", justifyContent: "flex-end" }}
                >
                  <Button sx={{ mr: 1 }} onClick={handleReset} type="button">Reset</Button>
                  {themeId && (
                    <DeleteButton
                    deleteItem={() => {deleteTheme(themeId)}}
                    />
                  )}
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                  >
                    Save Changes
                  </LoadingButton>
                </Box>
              </fieldset>
            </Form>
          </FormikProvider>
        </Card>
      </Grid>
    </Grid>
  );
}
