import { UserIdentifier } from "../types/Identifier";
import { ValidationResult } from "../types/ValidationResult";
import { ValidationStatus } from "../types/ValidationStatus";

const NOT_VALIDATED: ValidationStatus = {
  isValid: false,
  isInvalid: false,
};

export default function createValidationStatus(
  validationResult: ValidationResult
) {
  return (identifier: UserIdentifier) => {
    const [category, id] = identifier.keyValue();
    if (!(id in validationResult[category])) {
      return NOT_VALIDATED;
    }

    const isValid = validationResult[category][id];
    return {
      isValid,
      isInvalid: !isValid,
    };
  };
}
