import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
// material
import { Container, Stack, Typography } from "@mui/material";
// redux
// routes
// hooks
import useSettings from "../../hooks/useSettings";
// components
import Page from "../../components/Page";
import HeaderBreadcrumbs from "../../components/HeaderBreadcrumbs";
import InvitationNewForm from "../../components/_dashboard/invitation/InvitationNewForm";
import LoadingScreen from "../../components/LoadingScreen";
import Page404 from "../Page404";

// utils
import { getCode } from "../../utils/firebase";
import { printLogs } from "../../utils/logs";

// types
import { InvitationCode } from "../../types/invitation/code";
import { collections } from "../../utils/constants";
import ChangeLogsComponent from "../../components/_dashboard/changelog/ChangeLogsComponent";
import firebase from "firebase/app";

// ----------------------------------------------------------------------

export default function CodeCreate() {
  const { themeStretch } = useSettings();
  const { pathname } = useLocation();
  const { code = "" } = useParams();
  const isEdit = pathname.includes("edit");
  const isView = pathname.includes("view");
  const isDuplicate = pathname.includes("duplicate");
  const [currentCode, setCurrentCode] = useState<InvitationCode | undefined>(
    undefined
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    async function getInvitationCode() {
      try {
        setLoading(true);
        setError(null);

        const invitationCode = await getCode(code);
        setCurrentCode(invitationCode);
        setLoading(false);
      } catch (e) {
        setError((e as Error)?.message || "something went wrong.");
        printLogs({ errorGettingInvitationCode: e });
      }
    }
    if (code) {
      getInvitationCode();
    } else if (!isEdit) {
      setLoading(false);
    }
  }, [code, isEdit]);

  let ContentToDisplay = <LoadingScreen />;
  if (error) {
    ContentToDisplay = <Page404 />;
  }

  let duplicatedCode = currentCode;
  if (isDuplicate && currentCode) {
    const {
      code: oldInvitationCode,
      invitationDeepLinkUrl,
      ...rest
    } = currentCode;
    duplicatedCode = {
      ...rest,
      code: "",
      oldInvitationCode,
      expiresAt: firebase.firestore.Timestamp.fromDate(
      new Date(Date.now() + 365 * 24 * 60 * 60 * 1000) // Set it to one year in the future
      ),
    };
  }

  if (!loading && !error) {
    ContentToDisplay = (
      <InvitationNewForm
        isEdit={isEdit}
        isView={isView}
        isDuplicate={isDuplicate}
        currentCode={isDuplicate ? duplicatedCode : currentCode}
      />
    );
  }

  let heading = "Create a new invitation code";
  if (isEdit) {
    heading = "Edit invitation code";
  } else if (isView) {
    heading = "invitation code details";
  } else if (isDuplicate) {
    heading = `Duplicate invitation code: ${code}`;
  }

  return (
    <Page title="Create a new code">
      <Container maxWidth={themeStretch ? false : "lg"}>
        <HeaderBreadcrumbs heading={heading} links={[]} />
        {isDuplicate && (
          <Typography variant={"subtitle1"} sx={{ px: 4, my: 2 }}>
            {`You are creating a duplicate of the invitation code ${code}. This action will create a new invitation code with a reference to the old one. The old invitation code will not be impacted. This action does not migrate the users associated with the old invitation code.`}
          </Typography>
        )}
        {ContentToDisplay}

        {isView && (
          <Stack spacing={3}>
            <Typography
              variant={"subtitle2"}
              sx={{ px: 4, mt: 3 }}
            >{`Changelogs for: ${code}`}</Typography>
            <ChangeLogsComponent
              collectionName={collections.INVITATION_CODES_CHANGELOG}
              docId={code || ""}
            />
          </Stack>
        )}
      </Container>
    </Page>
  );
}
