import { Autocomplete, TextField, createFilterOptions } from "@mui/material";
import { flatten, uniq } from "lodash";
import { filterUndefined } from "utils/typescript";
import { useContext } from "react";
import { ContentUnitsContext } from "../../../contexts/ContentUnitsContext";

type Props = {
  showModulesThemeIds?: boolean;
  selectedThemes: string;
  setSelectedThemes: (selectedThemes: string) => void;
};

type ThemeOptions = string;

export default function ThemeAutocomplete({
  showModulesThemeIds,
  selectedThemes,
  setSelectedThemes,
}: Props) {
  const filter = createFilterOptions<ThemeOptions>();

  const { contentUnits, modulesContent } = useContext(ContentUnitsContext);

  let nestedThemes = Object.values(contentUnits ?? []).map((contentUnit) => {
    if (!contentUnit.themeId) {
      return undefined;
    }
    return contentUnit.themeId;
  });

  if (showModulesThemeIds) {
    nestedThemes = Object.values(modulesContent ?? []).map((contentUnit) => {
      if (!contentUnit.themeId) {
        return undefined;
      }
      return contentUnit.themeId;
    });
  }

  const themes = uniq(flatten(nestedThemes).filter(filterUndefined));

  return (
    <Autocomplete
      id="themes"
      options={themes}
      value={selectedThemes}
      getOptionLabel={(option) => option}
      filterSelectedOptions
      renderInput={(params) => (
        <TextField {...params} label="Theme id" placeholder="Theme id" />
      )}
      onChange={(_, newValue) => {
        setSelectedThemes(newValue);
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        if (params.inputValue !== "") {
          filtered.push(params.inputValue);
        }

        return filtered;
      }}
    />
  );
}
