import {
    TextField,
  } from "@mui/material";
import { InnerFormProps } from "./ContentPracticeForm";
  
export function ContentVideoForm({
  getFieldProps,
  touched,
  errors,
  isChatVideo,
}: InnerFormProps)  {
  return (
    <>
      <TextField
        fullWidth
        label="Media URL"
        {...getFieldProps("mediaUrl")}
        error={Boolean(touched.mediaUrl && errors.mediaUrl)}
        helperText={touched.mediaUrl && errors.mediaUrl}
      />

      <TextField
        fullWidth
        label="Thumbnail"
        {...getFieldProps("thumbnail")}
        error={Boolean(touched.thumbnail && errors.thumbnail)}
        helperText={touched.thumbnail && errors.thumbnail}
      />        

      {isChatVideo && (
        <>
          <TextField
            fullWidth
            label="Subtitle"
            {...getFieldProps("subtitle")}
            error={Boolean(touched.subtitle && errors.subtitle)}
            helperText={touched.subtitle && errors.subtitle}
          />

          <TextField
            fullWidth
            label="Poster URL"
            {...getFieldProps("posterUrl")}
            error={Boolean(touched.posterUrl && errors.posterUrl)}
            helperText={touched.posterUrl && errors.posterUrl}
          />
        </>
      )}
    </>
  );
}
