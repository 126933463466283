/**
 * sort
 */
function sortKeys(input: Record<string, unknown>): Record<string, unknown> {
  const intermediate: Record<string, unknown> = {};
  const sortedKeys = Object.keys(input).sort();
  for (const key of sortedKeys) {
    let val = input[key];
    if (typeof val === "object" && val) {
      val = sortKeys(val as Record<string, unknown>);
    }
    intermediate[key] = val;
  }

  return intermediate;
}

/**
 * Given two objects with the same keys and values, ensure that the output is
 * always equal no matter the order of keys.
 */
export default function stableJSONStringify(input: unknown): string {
  let intermediate;
  if (input && typeof input === "object" && !(input instanceof Array)) {
    intermediate = sortKeys(input as Record<string, unknown>);
  } else {
    intermediate = input;
  }

  return JSON.stringify(intermediate);
}
