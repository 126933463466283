import { Stack, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import React from "react";
import { FieldInputProps } from "formik/dist/types";
import { SetFieldValueType } from "../../../../types/invitation/code";
import RegistrationCodeAnswerEvents from "./RegistrationCodeAnswerEvents";

type Props = {
  label: string;
  inputFormKey: string;
  textInputKey: string;
  getFieldProps: (nameOrOptions: any) => FieldInputProps<any>;
  setFieldValue: SetFieldValueType;
  onClickDelete: () => void;
  showAnswerEvents?: boolean;
  showCommonIdInput?: boolean;
};

export default function InputWithDeleteButton({
  label,
  inputFormKey,
  getFieldProps,
  onClickDelete,
  setFieldValue,
  showAnswerEvents,
  textInputKey,
  showCommonIdInput,
}: Props) {
  return (
    <Stack direction={"column"} spacing={1}>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        spacing={1}
        sx={{ justifyContent: "space-between" }}
      >
        {showCommonIdInput && (
          <TextField
            label={`Common id`}
            {...getFieldProps(`${inputFormKey}.commonId`)}
            type="text"
            sx={{ width: 200 }}
          />
        )}
        <TextField
          fullWidth
          label={label}
          {...getFieldProps(`${inputFormKey}.${textInputKey}`)}
          type="text"
        />
        <Button
          color="error"
          variant="outlined"
          endIcon={<DeleteIcon />}
          onClick={onClickDelete}
        >
          delete
        </Button>
      </Stack>
      <RegistrationCodeAnswerEvents
        showAnswerEvents={showAnswerEvents}
        inputFormKey={inputFormKey}
        getFieldProps={getFieldProps}
        setFieldValue={setFieldValue}
      />
    </Stack>
  );
}
