import React, { useEffect, useRef } from "react";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";

// material
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { isEqual, cloneDeep } from "lodash";
import { useNavigate } from "react-router-dom";

import {
  AudioMeditationChunks,
  AudioMeditationGenerationType,
  AudioMeditationPreviewGenerationRequestType, AudioMeditationVoiceConfigType,
  MixAudioMeditationsGenerationRequestType,
} from "../../../types/audioMeditationGenerations";
import LoadingScreen from "../../LoadingScreen";
import { CustomDropdownOption } from "../../CustomDropdown";
import { getNWordsFromString } from "../../../utils/stringUtils";
import { generatePreviewAudioMeditation, mixUpdatedAudioMeditations } from "../../../utils/firebase/cloudFunctions";
import { formatTimeDuration } from "../../../utils/formatTime";
import MeditationVoiceAutocomplete from "./MeditationVoiceAutocomplete";

// ----------------------------------------------------------------------

type AudioMeditationUpdationFormProps = {
  loading: boolean;
  audioMeditation: AudioMeditationGenerationType;
};

export default function AudioMeditationUpdationForm(
  props: AudioMeditationUpdationFormProps
) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const originalAudioMeditationTextChunkRef = useRef(cloneDeep(props.audioMeditation.textsAudiosChunksWithDuration || []))

  const { audioMeditation, loading } = props;

  const AudioMeditationSchema = Yup.object().shape({
    voiceId: Yup.string().required("Voice ID is required"),
    selectedChunkIndex: Yup.string().required("Selected Chunk  is required"),
    stability: Yup.number().required("Voice Stability is required"),
    similarityBoost: Yup.number().required(
      "Voice Similarity Boost is required"
    ),
    selectedChunkText: Yup.string().required(""),
    style: Yup.number().required("Voice Style is required"),
    useSpeakerBoost: Yup.boolean().optional(),
    newTextAtEnd: Yup.string().optional(),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: audioMeditation?.id ?? "",
      voiceId: "",
      stability: audioMeditation?.voiceConfig?.setting?.stability ?? "0.70",
      similarityBoost:
        audioMeditation?.voiceConfig?.setting?.similarityBoost ?? "0.10",
      style: audioMeditation?.voiceConfig?.setting?.style ?? "0.0",
      selectedChunkIndex: "",
      selectedChunkText: "",
      selectedChunkAudioUrl: "",
      previewAudioUrl: "",
      previewAudioDuration: "",
      useSpeakerBoost:
        audioMeditation?.voiceConfig?.setting?.useSpeakerBoost ?? false,
      newTextAtEnd: ""
    },

    validationSchema: AudioMeditationSchema,
    onSubmit: async (values) => {
      const {
        id,
        selectedChunkText,
        selectedChunkIndex = 0,
        voiceId,
        similarityBoost,
        stability,
        style,
        useSpeakerBoost,
      } = values

      if (isEqual(selectedChunkText, originalAudioMeditationTextChunkRef.current?.[Number(selectedChunkIndex || 0)].text) || !voiceId?.length) {
        enqueueSnackbar('Please Change/Update the current chunk text and make the necessary edits and updates before generating a audio preview of the meditation.', { variant: 'error' });
        return;
      }

      if (formik.values?.previewAudioUrl) {
        setFieldValue("previewAudioUrl", "")
        setFieldValue("previewAudioDuration", "")
      }

      try {
        const response = await generatePreviewAudioMeditation({
          documentId: id,
          text: selectedChunkText,
          voiceId,
          voiceSetting: {
            similarityBoost,
            stability,
            style,
            useSpeakerBoost
          }
        } as AudioMeditationPreviewGenerationRequestType)


        const { previewAudioUrl = "", previewAudioDuration = "" } = response?.data ?? {}
        setFieldValue("previewAudioUrl", previewAudioUrl)
        setFieldValue("previewAudioDuration", previewAudioDuration)
        enqueueSnackbar('The audio meditation preview has been successfully generated and can be viewed/downloaded at the end of the section.', { variant: 'success' })
      } catch (error) {
        console.error(error)
        enqueueSnackbar('Error in Generating Audio Preview for Updated text', { variant: 'error' })
      }
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, setFieldValue } = formik;

  useEffect(() => {
    const previousSelectedChunkIndex = formik.values.selectedChunkIndex
    if (previousSelectedChunkIndex !== "") {
      const selectedTextAudioChunkRef = audioMeditation?.textsAudiosChunksWithDuration?.[parseInt(previousSelectedChunkIndex ?? 0)] as AudioMeditationChunks
      selectedTextAudioChunkRef.text = formik.values.selectedChunkText
      selectedTextAudioChunkRef.isEdited = true;
      selectedTextAudioChunkRef.voiceConfig = (selectedTextAudioChunkRef.voiceConfig || {}) as AudioMeditationVoiceConfigType
      selectedTextAudioChunkRef.voiceConfig.id = formik.values.voiceId
      selectedTextAudioChunkRef.voiceConfig.setting = selectedTextAudioChunkRef.voiceConfig.setting || {}
      selectedTextAudioChunkRef.voiceConfig.setting.similarityBoost = formik.values.similarityBoost
      selectedTextAudioChunkRef.voiceConfig.setting.stability = formik.values.stability
      selectedTextAudioChunkRef.voiceConfig.setting.style = formik.values.style
      selectedTextAudioChunkRef.voiceConfig.setting.useSpeakerBoost = formik.values.useSpeakerBoost
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.selectedChunkIndex, formik.values.selectedChunkText, formik.values.useSpeakerBoost, formik.values.style, formik.values.stability, formik.values.similarityBoost, formik.values.voiceId]);

  const handleOnTextDropdownChanged = (event: any) => {
    const selectedChunkIndex = event?.target?.value ?? 0
    const selectedMeditation = audioMeditation?.textsAudiosChunksWithDuration?.[selectedChunkIndex ?? 0] as AudioMeditationChunks
    formik.setValues({
      ...formik.values,
      selectedChunkIndex,
      selectedChunkText: selectedMeditation.text,
      selectedChunkAudioUrl: selectedMeditation.audio,
      stability: selectedMeditation?.voiceConfig?.setting?.stability ?? audioMeditation?.voiceConfig?.setting?.stability,
      similarityBoost: selectedMeditation?.voiceConfig?.setting?.similarityBoost ?? audioMeditation?.voiceConfig?.setting?.similarityBoost,
      voiceId: selectedMeditation?.voiceConfig?.id ?? audioMeditation?.voiceConfig?.id,
      style: selectedMeditation?.voiceConfig?.setting?.style ?? audioMeditation?.voiceConfig?.setting?.style,
      useSpeakerBoost: selectedMeditation?.voiceConfig?.setting?.useSpeakerBoost ?? audioMeditation?.voiceConfig?.setting?.useSpeakerBoost,
      previewAudioUrl: "",
      previewAudioDuration: ""
    })
  }

  const onSavePreviewAudio = () => {
    mixUpdatedAudioMeditations({
      documentId: formik.values.id,
      textsAudiosChunksWithDuration: audioMeditation?.textsAudiosChunksWithDuration ?? [],
      newTextAtEnd: formik.values.newTextAtEnd.trim()
    } as MixAudioMeditationsGenerationRequestType)
      .catch((error) => {
        console.error("FailedToGenerateAudioMeditation", error);
      })

    navigate(-1);
    enqueueSnackbar("Audio meditation generation has started. You can view the current status in real time on the Audio Meditation Generation listing page.", { variant: "success" });
  }


  const onReset = () => {
    audioMeditation.textsAudiosChunksWithDuration = cloneDeep(originalAudioMeditationTextChunkRef.current)
    handleOnTextDropdownChanged({ target: { value: formik.values.selectedChunkIndex } })
    enqueueSnackbar('The audio chunk form has been successfully restored to its initial state.', { variant: 'success' });
  }

  if (loading) {
    return <LoadingScreen/>;
  }

  const durationArray: Array<number> = audioMeditation?.textsAudiosChunksWithDuration?.map(({ duration }) => duration) ?? []

  const dropDownOptions: Array<CustomDropdownOption> =
    audioMeditation?.textsAudiosChunksWithDuration?.map((item, index) => ({
      label: getNWordsFromString(item?.text, 5) + "....  " + formatTimeDuration(durationArray?.slice(0, index), item?.duration),
      value: index,
    })) ?? [];

  const renderSelectedTextChunkFields = () => {
    if (formik.values.selectedChunkIndex === "") return null;

    return <Card sx={ { p: 3 } }>
      <Stack spacing={ 3 }>
        <TextField
          fullWidth
          multiline
          minRows={ 5 }
          label="Text"
          { ...getFieldProps("selectedChunkText") }
          error={ Boolean(touched.selectedChunkText && errors.selectedChunkText) }
          helperText={ touched.selectedChunkText && errors.selectedChunkText }
        />

        <MeditationVoiceAutocomplete
          freeSolo
          placeholder="Select or Type Voice ID"
          onChangeValue={ (value) => {
            setFieldValue("voiceId", value)
          } }
          { ...getFieldProps("voiceId") }
          helperText={ touched.voiceId && errors.voiceId }
        />

        <TextField
          fullWidth
          label="Voice Stability"
          { ...getFieldProps("stability") }
          error={ Boolean(touched.stability && errors.stability) }
          helperText={ touched.stability && errors.stability }
        />

        <TextField
          fullWidth
          label="Voice Similarity Boost"
          { ...getFieldProps("similarityBoost") }
          error={ Boolean(
            touched.similarityBoost && errors.similarityBoost
          ) }
          helperText={
            touched.similarityBoost && errors.similarityBoost
          }
        />

        <TextField
          fullWidth
          label="Voice Style"
          { ...getFieldProps("style") }
          error={ Boolean(touched.style && errors.style) }
          helperText={ touched.style && errors.style }
        />

        <FormControlLabel
          label="Use Speaker Boost"
          control={
            <Checkbox
              checked={
                Boolean(getFieldProps("useSpeakerBoost").value)
              }
              { ...getFieldProps("useSpeakerBoost") }
            />
          }
        />

        { Boolean(formik?.values.selectedChunkAudioUrl) && (
          <Link
            href={ formik?.values?.selectedChunkAudioUrl ?? "" }
            target="_blank"
          >
            Download/View Audio Chunk File
          </Link>
        ) }

        { Boolean(formik?.values.previewAudioUrl) && (
          <Link
            href={ formik?.values?.previewAudioUrl ?? "" }
            target="_blank"
          >
            Download/View Updated Audio Chunk File
          </Link>
        ) }

        <TextField
          fullWidth
          sx={ { mt: 5 } }
          multiline
          minRows={ 5 }
          label="Append Text at the End of the Meditation"
          { ...getFieldProps("newTextAtEnd") }
        />

        <Box sx={ { mt: 3, display: "flex", justifyContent: "flex-end" } }>
          <Tooltip title="This will generate a preview audio clip of the Chunk Text" placement="top"
                   arrow>
            <LoadingButton
              type="submit"
              variant="contained"
              sx={ { mr: 4 } }
              loading={ isSubmitting }
            >
              Generate Updated Text Chunk Preview Audio
            </LoadingButton>
          </Tooltip>

          <Tooltip title="This will generate a full meditation audio track using your edited text chunks" placement="top"
                   arrow>
            <LoadingButton
              type="button"
              disabled={ isSubmitting }
              sx={ { mr: 4 } }
              onClick={ onSavePreviewAudio }
              variant="contained">
              Generated Complete Audio
            </LoadingButton>
          </Tooltip>

          <LoadingButton
            type="button"
            variant="outlined"
            onClick={ onReset }>
            Reset
          </LoadingButton>
        </Box>
      </Stack>
    </Card>
  }

  return (
    <FormikProvider value={ formik }>
      <Form noValidate autoComplete="off" onSubmit={ handleSubmit }>
        <fieldset style={ { border: "none" } }>
          <Grid container spacing={ 3 }>
            <Grid item xs={ 12 }>
              <FormControl fullWidth>
                <InputLabel id="text-chunk-select-label">Select a Text Chunk</InputLabel>
                <Select
                  label="Select a Text Chunk"
                  { ...getFieldProps("selectedChunkIndex") }
                  onChange={ handleOnTextDropdownChanged }>
                  {
                    dropDownOptions.map(option =>
                      <MenuItem key={ option.value } value={ option.value }>
                        { option.label }
                      </MenuItem>)
                  }
                </Select>
              </FormControl>

              { renderSelectedTextChunkFields() }
            </Grid>
          </Grid>
        </fieldset>
      </Form>
    </FormikProvider>
  );
}
