import { Container } from "@mui/material";
import HeaderBreadcrumbs from "components/HeaderBreadcrumbs";
import Page from "components/Page";
import ContentUnitForm from "components/_dashboard/content/ContentUnitForm";
import useSettings from "hooks/useSettings";

export default function AddEditContent() {
  const { themeStretch } = useSettings();

  return (
    <Page title="Add Edit Journey Table">
      <Container maxWidth={themeStretch ? false : "lg"}>
        <HeaderBreadcrumbs heading={"Content unit"} links={[]} />

        <ContentUnitForm />
      </Container>
    </Page>
  );
}
