import { BaseQueryFn } from "@reduxjs/toolkit/query/react";
import * as CloudFunctions from "utils/firebase/cloudFunctions";
import {
  NewRenewalChallenge,
  RenewalChallenge,
} from "../../types/invitation/renewalChallenge";

type InvitationCode = string;

type ListQuery = {
  method: "list";
  invitationCode: InvitationCode;
};

type UpdateQuery = {
  method: "update";
  challenge: RenewalChallenge;
  invitationCode: InvitationCode;
};

type CreateQuery = {
  method: "create";
  challenge: NewRenewalChallenge;
  invitationCode: InvitationCode;
};

export type ApiParams = CreateQuery | ListQuery | UpdateQuery;

export function renewalChallengeRestApi(): BaseQueryFn<ApiParams, any> {
  return async (payload) => {
    const { invitationCode } = payload;

    switch (payload.method) {
      case "list":
        const challenges = await CloudFunctions.listRenewalChallenges(
          invitationCode
        );
        return { data: challenges };
      case "create":
        await CloudFunctions.createRenewalChallenge(
          payload.challenge,
          invitationCode
        );
        return { data: null };
      case "update":
        await CloudFunctions.updateRenewalChallenge(
          invitationCode,
          payload.challenge
        );
        return { data: null };
    }
  };
}
