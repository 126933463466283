import React, { ReactNode, useEffect, useState } from "react";
import { db } from "./FirebaseContext";
import { ContentFromFirestore, ModuleFromFirestore } from "../models/ContentUnit";
import { SupportedLanguagesEnum } from "../utils/constants";
import { SupportedLanguages } from "../types/invitation/code";

export const ContentUnitsContext = React.createContext({
  contentUnits: {} as Record<string, ContentFromFirestore>,
  modulesContent: {} as Record<string, ModuleFromFirestore>,
  parentModulesContent: {} as Record<string, ModuleFromFirestore>,
  contentLanguage: SupportedLanguagesEnum.ENGLISH,
  moduleContentLanguage: SupportedLanguagesEnum.ENGLISH,
  setContentLanguage: (contentLanguage: SupportedLanguagesEnum) => {},
  setModulesContentLanguage: (
    moduleContentLanguage: SupportedLanguagesEnum
  ) => {},
});

export const ContentUnitsProvider = ({ children }: { children: ReactNode }) => {
  const [contentUnits, setContentUnits] = useState<
    Record<string, ContentFromFirestore>
  >({});
  const [modulesContent, setModulesContent] = useState<
    Record<string, ModuleFromFirestore>
  >({});
  const [parentModulesContent, setParentModulesContent] = useState<
    Record<string, ModuleFromFirestore>
  >({});

  const [contentLanguage, setContentLanguage] = useState<SupportedLanguages>(
    SupportedLanguagesEnum.ENGLISH
  );

  const [moduleContentLanguage, setModulesContentLanguage] =
    useState<SupportedLanguages>(SupportedLanguagesEnum.ENGLISH);

  useEffect(() => {
    const listener = db
      .collection("content")
      .doc(contentLanguage)
      .onSnapshot(async (snapshot) => {
        const docs =
          (snapshot.data() as Record<string, ContentFromFirestore>) || {};
        setContentUnits(docs);
      });

    return () => {
      if (listener) {
        listener();
      }
    };
  }, [contentLanguage]);

  useEffect(() => {
    const listener = db
      .collection("modules")
      .doc(moduleContentLanguage)
      .onSnapshot(async (snapshot) => {
        const docs =
          (snapshot.data() as Record<string, ModuleFromFirestore>) || {};

        setModulesContent(docs);
      });

    return () => {
      if (listener) {
        listener();
      }
    };
  }, [moduleContentLanguage]);

  useEffect(() => {
    const listener = db
      .collection("parent_modules")
      .doc(moduleContentLanguage)
      .onSnapshot(async (snapshot) => {
        const docs =
          (snapshot.data() as Record<string, ModuleFromFirestore>) || {};

          setParentModulesContent(docs);
      });

    return () => {
      if (listener) {
        listener();
      }
    };
  }, [moduleContentLanguage]);

  return (
    <ContentUnitsContext.Provider
      value={{
        contentUnits,
        modulesContent,
        parentModulesContent,
        moduleContentLanguage,
        setModulesContentLanguage,
        contentLanguage,
        setContentLanguage,
      }}
    >
      {children}
    </ContentUnitsContext.Provider>
  );
};
