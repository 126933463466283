export function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

/**
 * from the given string {str} return {n} number of words, but atmost {maxCharacters} characters
 * if n words has more characters than {maxCharacters} it returns upto maxCharacters,
 * otherwise {n} words are returned.
 * @param str
 * @param n
 * @param maxCharacters
 */
export function getNWordsFromString(str: string = "", n = 1, maxCharacters = 100) {
  return str.split(/\s+/).slice(0, n).join(" ").slice(0, maxCharacters);
}


export function IsJsonString(str: string) {
  try {
    const json = JSON.parse(str);
    return (typeof json === 'object');
  } catch (e) {
    return false;
  }
}
