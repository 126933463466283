import {
  Button,
  Container,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import Scrollbar from "components/Scrollbar";
import { ReactChild, useState } from "react";
import Page from "components/Page";
import { useGetChineseUserRelationships } from "hooks/useGetChineseUserRelationships";
import { 
  ChineseUserRelationship,
  deleteChineseUserRelationship,
  deleteSession,
} from "utils/firebase/chineseUsersRelationshipsUtils";
import HeaderBreadcrumbs from "components/HeaderBreadcrumbs";
import { Link as RouterLink } from "react-router-dom";
import { Icon } from "@iconify/react";
import plusFill from "@iconify/icons-eva/plus-fill";
import { PATH_DASHBOARD } from "routes/paths";
import RelationshipRow from "./RelationshipRow";
import ChangeLogsComponent from "components/_dashboard/changelog/ChangeLogsComponent";
import { collections } from "utils/constants";

const TABLE_HEAD = [
  { id: "emptyHeaderColumn", label: "", alignRight: false },
  { id: "userEmail", label: "User Email" },
  { id: "counsellor", label: "Counsellor" },
  { id: "status", label: "Status" },
  { id: "invitationCode", label: "Invitation Code" },
  { id: "completedSessions", label: "Completed Sessions" },
  { id: "firstSession", label: "First Session" },
  { id: "lastSession", label: "Last Session" },
  { id: "lastRequestAt", label: "Last request at" },
  { id: "actions", label: "Actions" },
];
  
type WrapperProps = {
  children: ReactChild;
};
  
function PageWrapper({ children }: WrapperProps) {
  return (
    <Page title={"Chinese User-Counsellor relationships"}>
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        {children}
      </Container>
    </Page>
  );
}
  
export default function ChineseUsersRelationships() {
  const {
    loading,
    error,
    relationships
  } = useGetChineseUserRelationships();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openRowId, setOpenRowId] = useState<string>("");
  const [isDeleting, setIsDeleting] = useState(false); 
  
  if (loading) {
    return (
      <PageWrapper>
        <Typography>Loading...</Typography>
      </PageWrapper>
    );
  }

  if (error) {
    return (
      <PageWrapper>
        <Typography>There was an error loading the data.</Typography>
      </PageWrapper>
    );
  }

  const handleDeleteRelationship = async (id: string) => {
    if (!window.confirm("Are you sure you want to delete this relationship?")) return;

    setIsDeleting(true);
    await deleteChineseUserRelationship(id);
    setIsDeleting(false);
  }

  const handleDeleteSession = async (relationshipId: string, sessionId: string) => {
    if (!window.confirm("Are you sure you want to delete this session?")) return;

    setIsDeleting(true);
    await deleteSession(relationshipId, sessionId);
    setIsDeleting(false);
  }
    
  return (
    <PageWrapper>
      <Scrollbar>
        <TableContainer>
          <HeaderBreadcrumbs
          heading=""
          links={[]}
          action={
            <>
              <Button
                style={{ marginLeft: 30, color: "white" }}
                variant="contained"
                color="primary"
                component={RouterLink}
                to={PATH_DASHBOARD.therapist.chineseUserRelationships.new}
                startIcon={<Icon icon={plusFill} />}
              >
                Add New Relationship
              </Button>
            </>
          }
        />
          <Table>
            <TableHead>
              <TableRow>
                {TABLE_HEAD.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    align={"center"}
                  >
                    {headCell.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {relationships.length === 0 && (
                <TableRow>
                  <TableCell colSpan={6}>
                    <Typography align="center">No relationships found.</Typography>
                  </TableCell>
                </TableRow>
              )}
              {relationships.map((row: ChineseUserRelationship) => {
                const isOpen = openRowId === row.id;

                return (
                  <RelationshipRow 
                    key={row.id}
                    relationship={row}
                    isOpen={isOpen}
                    isDeleting={isDeleting}
                    setOpenRowId={setOpenRowId}
                    handleDeleteRelationship={handleDeleteRelationship}
                    handleDeleteSession={handleDeleteSession}
                  />
                );
              })}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={relationships.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(event, newPage) => setPage(newPage)}
            onRowsPerPageChange={(event) =>
              setRowsPerPage(parseInt(event.target.value, 10))
            }
          />
        </TableContainer>
        <Stack spacing={3}>
          <Typography
            variant={"subtitle2"}
            sx={{ px: 4, mt: 3}}
          >{`Changelogs for Relationships and Sessions`}</Typography>
          <ChangeLogsComponent
            collectionName={collections.CHINESE_USER_RELATIONSHIPS_CHANGELOG}
          />
        </Stack>
      </Scrollbar>
    </PageWrapper>
  );
}
  
