import RootPage from "components/Page";
import { Box, Container, Paper, Typography, useTheme } from "@mui/material";
import EllipseCounsellorsSearch from "assets/illustration_ellipse_counsellors_search";
import { ReactNode } from "react";
import Logo from "components/Logo";
import { PATH_DASHBOARD } from "routes/paths";
import { Link } from "react-router-dom";

/**
 * A page that displays a paper at the center of the screen with a
 * logo and headline.
 */
export function PaperPage({
  children,
  headTitle,
  title,
}: {
  children: ReactNode;
  headTitle: string;
  title: string;
}) {
  const theme = useTheme();
  return (
    <RootPage
      title={headTitle}
      bgcolor={theme.palette.grey["100"]}
      minHeight="100vh"
    >
      <Container maxWidth="sm">
        <Box paddingTop="20vh">
          <Paper elevation={1}>
            <Box padding="40px">
              <Box display="flex" flexDirection="column" alignItems="center">
                <Link to={PATH_DASHBOARD.root}>
                  <Logo sx={{ width: 80, height: 80 }} />
                </Link>
                <Typography
                  variant="h4"
                  gutterBottom
                  marginTop="20px"
                  lineHeight="36px"
                >
                  {title}
                </Typography>
              </Box>
              {children}
            </Box>
          </Paper>
        </Box>
      </Container>

      <Box
        position="absolute"
        right="0"
        bottom="0"
        display={{ xs: "none", lg: "block" }}
      >
        <EllipseCounsellorsSearch />
      </Box>
    </RootPage>
  );
}
