import * as Yup from "yup";

export const RegistrationCodeSchema = Yup.object().shape({
  code: Yup.string().required("code is required."),
  usageLimit: Yup.number().required("usage Limit is required."),
  timesChecked: Yup.number().default(0),
  trackPrivateInfo: Yup.boolean().default(false).required(),
  suspended: Yup.boolean().default(false).required(),
  usernameAllowed: Yup.boolean().default(false).required(),
  expiresAt: Yup.string().default(function () {
    return new Date().toISOString().split("T")[0];
  }),
  createdAt: Yup.string().default(function () {
    return new Date().toISOString().split("T")[0];
  }),
});
