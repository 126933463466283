import { Form, FormikProvider, useFormik } from "formik";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import * as Yup from "yup";

import {
  CallRatingDetail,
  updateCallRating,
} from "utils/firebase/callRatingUtils";

const validationSchema = Yup.object().shape({
  overall: Yup.number().required("Required"),
  counselor: Yup.number(),
  recommend: Yup.number(),
  comment: Yup.string(),
  updateReason: Yup.string().required("Required"),
});

interface Props {
  rating: CallRatingDetail | undefined;
  open: boolean;
  onClose: () => void;
  currendUserUid: string;
}

export default function EditCallRatingDialog(props: Props) {
  const { open, onClose, currendUserUid } = props;
  const { rating, chatId, messageId, therapistProfile, userProfile } =
    props.rating || {};

  const { enqueueSnackbar } = useSnackbar();

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema,
    initialValues: {
      overall: rating?.overall || 0,
      counselor: rating?.counselor,
      recommend: rating?.recommend,
      comment: rating?.comment,
      updateReason: "",
    },
    onSubmit: async (values, { setSubmitting }) => {
      try {
        if (!chatId || !messageId || !currendUserUid || !rating) {
          throw new Error("Missing required parameters to update call rating");
        }
        await updateCallRating({
          chatId,
          messageId,
          rating: values,
          ratingUpdateBy: currendUserUid,
          ratingBefore: rating,
        });
        enqueueSnackbar("Changes in rating successfully saved", {
          variant: "success",
        });
      } catch (error) {
        const errorMessage = (error as Error).message || "Unknown error";
        console.error("Error while saving changes", error);
        enqueueSnackbar(`Error while saving changes: ${errorMessage}`, {
          variant: "error",
        });
      }
      onClose();
      setSubmitting(false);
    },
  });

  const {
    errors,
    touched,
    getFieldProps,
    setFieldValue,
    handleSubmit,
    isSubmitting,
  } = formik;

  return (
    <Dialog open={open} onClose={onClose}>
      <FormikProvider value={formik}>
        <Form autoComplete="off" onSubmit={handleSubmit}>
          <DialogTitle>{`Edit Call Rating by ${userProfile?.name}`}</DialogTitle>
          <DialogContent>
            <Box mb={2} mt={1}>
              <DialogContentText>{`Chat ID: ${chatId}`}</DialogContentText>
              <DialogContentText>{`Message ID: ${messageId}`}</DialogContentText>
              <DialogContentText>
                {`Counsellor: ${therapistProfile?.name}`}
              </DialogContentText>
            </Box>
            {rating?.ratingUpdateBy && (
              <Box mb={2}>
                <Typography variant="body2" color="textSecondary">
                  {`Last updated by ${
                    rating?.ratingUpdateBy
                  } on ${rating?.ratingUpdateTime?.toDate().toLocaleString()}`}
                </Typography>
              </Box>
            )}

            <Box mb={2}>
              <TextField
                label="Overall Rating"
                type="number"
                fullWidth
                name="overall"
                value={formik.values.overall}
                onChange={formik.handleChange}
                error={formik.touched.overall && Boolean(formik.errors.overall)}
                helperText={formik.touched.overall && formik.errors.overall}
                margin="dense"
              />
              <TextField
                label="Counsellor Rating"
                type="number"
                fullWidth
                name="counselor"
                value={formik.values.counselor}
                onChange={formik.handleChange}
                error={
                  formik.touched.counselor && Boolean(formik.errors.counselor)
                }
                helperText={formik.touched.counselor && formik.errors.counselor}
                margin="dense"
              />
              <TextField
                label="Recommend Rating"
                type="number"
                fullWidth
                name="recommend"
                value={formik.values.recommend}
                onChange={formik.handleChange}
                error={
                  formik.touched.recommend && Boolean(formik.errors.recommend)
                }
                helperText={formik.touched.recommend && formik.errors.recommend}
                margin="dense"
              />
              <TextField
                label="Comment"
                {...getFieldProps("comment")}
                error={Boolean(touched.comment && errors.comment)}
                margin="dense"
                type="text"
                fullWidth
                multiline
                name="comment"
                onChange={(event) =>
                  setFieldValue("comment", event.target.value)
                }
              />
            </Box>

            <TextField
              label="Update reason"
              {...getFieldProps("updateReason")}
              error={Boolean(touched.updateReason && errors.updateReason)}
              margin="dense"
              type="text"
              fullWidth
              multiline
              name="updateReason"
              onChange={(event) =>
                setFieldValue("updateReason", event.target.value)
              }
            />
            <Box mb={2}>
              <Typography variant="body2">{`Your uid: ${currendUserUid} will be recorded.`}</Typography>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={onClose}>
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              loading={isSubmitting}
              type="submit"
              autoFocus
            >
              Save Changes
            </LoadingButton>
          </DialogActions>
        </Form>
      </FormikProvider>
    </Dialog>
  );
}
