import React, { useMemo } from "react";
import { useLocation, useParams } from "react-router-dom";
// material
import { Container } from "@mui/material";
// hooks
import useSettings from "../../../../hooks/useSettings";
// components
import Page from "../../../../components/Page";
import HeaderBreadcrumbs from "../../../../components/HeaderBreadcrumbs";
import { useTheme } from "@mui/material/styles";

import LoadingScreen from "../../../../components/LoadingScreen";
import { useCurrentRole } from "../../../../hooks/useCurrentRole";
import { useGetAllMultilingualLocales } from "utils/firebase/multilingualLocaleUtils";
import SuggestedTranslationForm from "./SuggestedTranslationForm";
import { useTranslationKeyWithDescription, useTranslationsValueAndApprovedBy } from "utils/firebase/translationUtils";
// ----------------------------------------------------------------------

export default function CreateSuggestedTranslation() {
  const { themeStretch } = useSettings();
  const { pathname } = useLocation();
  const theme = useTheme();
  const { id } = useParams();
  const currentRoles = useCurrentRole();

  const { translationKeyWithDescription, loading } = useTranslationKeyWithDescription(id ?? "", true)
  const {
    translationsValueAndApprovedBy,
    loading: translatedValuesLoading,
    accessToAllLanguagesTranslation,
    accessLanguagesTranslationList,
    showEnglishTranslationViewOnly
  } = useTranslationsValueAndApprovedBy(currentRoles, id ?? "", true);
  const {multilingualLocalesCodeToName} = useGetAllMultilingualLocales()

  const TRANSLATION_LANGUAGE_CODE_TO_NAME: Record<string,string> = useMemo(() => ({
    en: "English",
    es: "Spanish",
    fr: "French",
    de: "German",
    ...multilingualLocalesCodeToName
  }), [multilingualLocalesCodeToName]);

  const isEdit = pathname.includes("edit");
  const isView = pathname.includes("view");
  const isNew = pathname.includes("new");


  let pageTitle = "Create a new Suggested translation";
  if (isView) {
    pageTitle = "View Suggested Translation";
  } else if (isEdit) {
    pageTitle = "Edit Suggested Translation";
  }

  const isLoading = loading || translatedValuesLoading

  return (
    <Page title={ pageTitle }>
      <Container maxWidth={ themeStretch ? false : "lg" }>
        <HeaderBreadcrumbs heading={ pageTitle } links={ [] }/>

        { Boolean(isLoading) && (
          <LoadingScreen/>
        ) }

        { !Boolean(isLoading) && (
            <SuggestedTranslationForm
                isEdit={ isEdit }
                isView={ isView }
                isNew={ isNew }
                theme={ theme }
                accessToAllLanguagesTranslation={accessToAllLanguagesTranslation}
                accessLanguagesTranslationList={accessLanguagesTranslationList}
                translationsValueAndApprovedBy={ translationsValueAndApprovedBy }
                translationKeyWithDescription={ translationKeyWithDescription }
                showEnglishTranslationViewOnly={showEnglishTranslationViewOnly}
                translationsCodeToName={TRANSLATION_LANGUAGE_CODE_TO_NAME}
            />
        )}

      </Container>
    </Page>
  );
}
