import { db } from "contexts/FirebaseContext";
import {
  Collections,
  UpdateInvitationCodeCreditPeriodProps,
} from "pages/invitationCode/types";

export const updateInvitationCodeCreditPeriod = async ({
  invitationCode,
  docId,
  formValues,
}: UpdateInvitationCodeCreditPeriodProps) => {
  if (!docId || !invitationCode)
    throw new Error("Missing Document ID or Invitation Code");

  const { active, allowedCredits, unlimitedCredits } = formValues;
  return db
    .collection(
      `${Collections.INVITATION_CODES}/${invitationCode}/${Collections.INVITATION_CODE_CREDIT_PERIODS}`
    )
    .doc(docId)
    .update({
      active,
      allowedCredits,
      unlimitedCredits,
    });
};
