import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
// material
import { Box, Container } from "@mui/material";
// hooks
import useSettings from "../../../hooks/useSettings";
// components
import Page from "../../../components/Page";
import HeaderBreadcrumbs from "../../../components/HeaderBreadcrumbs";
import ReleaseNoteNewForm from "../../../components/_dashboard/release-notes/ReleaseNoteNewForm";
import { collections, SupportedLanguagesEnum } from "../../../utils/constants";
import { LanguageSelectionDropDown } from "../../../components/LanguageSelectionDropDown";
import { SupportedLanguages } from "../../../types/invitation/code";
import { ReleaseNotes } from "../../../types/releaseNotes";
import { getReleaseNoteById } from "../../../utils/firebase/releaseNotesUtils";
import { MultilingualLocalesTypes } from "../../../types/translations";
import MultiLingualGenerationProgress from "../../../components/_dashboard/content/MultiLingualGenerationProgress";
import useMultiLingualLocales from "../../../hooks/useMultiLingualContentGenerationProgress";
import { Locale } from "../../../types/user";

// ----------------------------------------------------------------------

export default function CreateReleaseNote() {
  const { themeStretch } = useSettings();
  const { pathname } = useLocation();
  const { state } = useLocation();
  const { id, language: selectedLanguage } = useParams();
  const [releaseNoteId, setReleaseNoteId] = useState<string>(id ?? "");

  const [releaseNote, setReleaseNote] = useState<ReleaseNotes>(
    state as ReleaseNotes
  );
  const [loading, setLoading] = useState(false);
  const [language, setLanguage] = useState<SupportedLanguages | string>(
    selectedLanguage ?? SupportedLanguagesEnum.ENGLISH
  );
  const { localeList } = useSettings()
  const [enableFormView, setEnableFormView] = useState<boolean>(false);
  const [selectedTargetLanguages, setSelectedTargetLanguages] = useState<MultilingualLocalesTypes[]>([]);

  const {
    targetMultilingualLocales,
    processedMultilingualLocales
  } = useMultiLingualLocales(language, releaseNoteId ?? "", collections.RELEASE_NOTES, Locale.EN);

  const targetLocalesSize = targetMultilingualLocales?.length ?? 0
  const processedLocalesSize = processedMultilingualLocales?.length ?? 0

  const isEdit = pathname.includes("edit");
  const isView = pathname.includes("view");
  const isNew = pathname.includes("new");

  const fetchReleaseNote = useCallback(() => {
    if (!releaseNote && releaseNoteId && !loading && (isView || isEdit)) {
      setLoading(true);
      getReleaseNoteById(releaseNoteId, language)
        .then((note) => {
          setReleaseNote(note);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [releaseNote, releaseNoteId, loading, isView, isEdit, language]);

  useEffect(() => {
    fetchReleaseNote();
  }, [fetchReleaseNote, releaseNoteId]);

  useEffect(() => {
    if (language === Locale.EN) {
      setSelectedTargetLanguages(localeList)
    }
  }, [language, localeList]);

  let pageTitle = "Create a new Release Note";
  if (isView) {
    pageTitle = "View Release Note";
  } else if (isEdit) {
    pageTitle = "Edit Release Note";
  }

  const onSwitchToFormView = () => {
    setEnableFormView(true)
  }

  const isProgressView = Boolean(!enableFormView && targetLocalesSize && processedMultilingualLocales && targetLocalesSize !== processedLocalesSize)

  return (
    <Page title={pageTitle}>
      <Container maxWidth={themeStretch ? false : "lg"}>
        <HeaderBreadcrumbs heading={pageTitle} links={[]} />

        { !isProgressView && (<Box sx={ { margin: 3, marginLeft: 0 } }>
          <LanguageSelectionDropDown
            fieldName={ "language" }
            label={ 'Select Language' }
            showMultiLingualOption
            skipMultiLingualSelection
            disabled={ isView || isEdit }
            selectedValue={ language }
            localeList={ localeList }
            onSelectionChanged={ (fieldKey, value) => {
              setLanguage(value);
            } }
          />
        </Box>)
        }

        { !isProgressView && (<ReleaseNoteNewForm
          isEdit={ isEdit }
          isView={ isView }
          isNew={ isNew }
          setEnableFormView={ setEnableFormView }
          setReleaseNoteId={ setReleaseNoteId }
          targetMultilingualLocales={ selectedTargetLanguages }
          selectedLanguage={ language }
          releaseNote={ releaseNote }
        />)
        }

        { isProgressView && (<MultiLingualGenerationProgress processedLocalesSize={ processedLocalesSize }
                                                                      targetLocalesSize={ targetLocalesSize }
                                                                      onSwitchToFormView={ onSwitchToFormView }
                                                                      processedMultilingualLocales={ processedMultilingualLocales }/>)
        }
      </Container>
    </Page>
  );
}
