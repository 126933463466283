import Container from "@mui/material/Container";
import HeaderBreadcrumbs from "components/HeaderBreadcrumbs";

import { PATH_DASHBOARD } from "routes/paths";
import Page from "components/Page";

import Button from "@mui/material/Button/Button";
import plusFill from "@iconify/icons-eva/plus-fill";
import { Icon } from "@iconify/react";
import useSettings from "hooks/useSettings";
import * as branch from "branch-sdk";
import QRCodeFromDeepLink from "./QRCodeFromDeepLink";
import { useEffect, useState } from "react";
import StringWithCopyOption from "components/StringWithCopyOption";
import { Box } from "@mui/material";
import { useParams } from "react-router";
import { editCodeOrCreateInvitationCode, getCode } from "utils/firebase";
import { InvitationCode } from "types/invitation/code";
import { RenewalChallengeLinkData } from "./RenewalChallengeUpdatePage";

type InvitationCodeLinkData = {
  invitationCode: string;
  marketingTitle?: string;
  $desktop_url: "https://care.kyanhealth.com";
};

export type ContentLinkData = {
  screen: string;
  route_params: string;
  campaign?: string;
};

type DeepLinkData =
  | InvitationCodeLinkData
  | RenewalChallengeLinkData
  | ContentLinkData;

export const createDeepLink = (linkData: DeepLinkData) => {
  return new Promise<string>((resolve, reject) => {
    branch.link(
      {
        data: linkData,
      },
      (error, deepLink) => {
        if (error) {
          console.error("Failed to create link", error);
          reject(error);
        } else {
          resolve(deepLink ?? "");
        }
      }
    );
  });
};

export const saveDeepLink = (url: string, code: InvitationCode) => {
  return editCodeOrCreateInvitationCode({
    ...code,
    invitationDeepLinkUrl: url,
  });
};

function QRCodePage() {
  const { themeStretch } = useSettings();
  const { code: codeId } = useParams();
  const [deepLink, setDeepLink] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [invitationCode, setInvitationCode] = useState<
    InvitationCode | undefined
  >(undefined);

  const createQRCode = async (code: InvitationCode | undefined) => {
    if (!code) {
      return;
    }

    const linkData: InvitationCodeLinkData = {
      invitationCode: code.code || "",
      marketingTitle: `Invite for ${code}`,
      $desktop_url: "https://care.kyanhealth.com",
    };
    const link = await createDeepLink(linkData);
    setDeepLink(link);
    saveDeepLink(link, code);
  };

  useEffect(() => {
    const fetchCode = async () => {
      setIsLoading(true);
      if (codeId) {
        const selectedCode = await getCode(codeId);
        setInvitationCode(selectedCode);
      }
      setIsLoading(false);
    };

    fetchCode();
  }, [codeId]);

  useEffect(() => {
    setDeepLink(invitationCode?.invitationDeepLinkUrl || null);
    console.log("updated selectedCode", invitationCode);
  }, [invitationCode]);

  return (
    <Page title="Create QR Code">
      <Container maxWidth={themeStretch ? false : "lg"}>
        <HeaderBreadcrumbs
          heading="Create QR Code"
          links={[
            {
              name: "Invitation Code List",
              href: PATH_DASHBOARD.invitationCodes.root,
            },
            { name: codeId || "Create QR Code" },
          ]}
          action={
            <Button
              disabled={isLoading || !!deepLink}
              onClick={() => {
                createQRCode(invitationCode);
              }}
              variant="contained"
              startIcon={<Icon icon={plusFill} />}
            >
              QR Code
            </Button>
          }
        />
      </Container>
      {deepLink && (
        <Box
          display={"flex"}
          justifyContent={"center"}
          flexDirection={"column"}
          alignItems={"center"}
        >
          <QRCodeFromDeepLink url={deepLink} fileName={codeId || "no_code"} />
          <StringWithCopyOption text={deepLink} variant="h6" />
        </Box>
      )}
    </Page>
  );
}

export default QRCodePage;
