import { RenewalChallenge } from "../../types/invitation/renewalChallenge";

/**
 * Three-way sort function for renewal challenges. Sorts active challenges
 * before inactive ones.
 */
export default function byActive(
  a: RenewalChallenge,
  b: RenewalChallenge
): number {
  if (a.active === b.active) {
    return 0;
  }

  if (a.active === true) {
    return -1;
  } else {
    return 1;
  }
}
