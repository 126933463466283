import SpeechBubble from '../../components/SpeechBubble';
import Logo from '../../components/Logo';

import { styled } from '@mui/material/styles';

const Container = styled('div')(({ theme }) => ({
  position: 'relative'
}));

const SpeechBubbleContainer = styled('div')(({ theme }) => ({
  position: 'absolute',
  width: '100%',
  top: '-25%',
  zIndex: 1
}));

export default function LogoWithSpeechBubble({ text }: { text: string }) {
  return (
    <Container>
      <SpeechBubbleContainer>
        <SpeechBubble text={`Hi, ${text}`} />
      </SpeechBubbleContainer>
      <Logo
        sx={{
          paddingLeft: 8,
          position: 'relative',
          top: '7px'
        }}
      />
    </Container>
  );
}
