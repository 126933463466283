import React from "react";
import { useLocation, useParams } from "react-router-dom";
// material
import { Container } from "@mui/material";
// hooks
import useSettings from "../../../hooks/useSettings";
// components
import Page from "../../../components/Page";
import HeaderBreadcrumbs from "../../../components/HeaderBreadcrumbs";

import MeditationVoiceCreateForm from "../../../components/_dashboard/audio-meditation-voice/MeditationVoiceCreateForm";
import { useMeditationVoice } from "../../../utils/firebase/audioMeditationVoicesUtil";
import { MeditationVoice } from "../../../types/meditationVoice";

// ----------------------------------------------------------------------

export default function CreateMeditationVoice() {
  const { themeStretch } = useSettings();
  const { pathname } = useLocation();
  const { id: meditationVoiceId } = useParams();

  const { meditationVoice, loading } = useMeditationVoice(meditationVoiceId ?? "");

  const isEdit = pathname.includes("/edit");
  const isView = pathname.includes("/view");
  const isNew = pathname.includes("/new");

  let pageTitle = "Create Meditation Voice";
  if (isView) {
    pageTitle = `View Meditation Voice`;
  } else if (isEdit) {
    pageTitle = `Edit Meditation Voice`;
  }

  return (
    <Page title={ pageTitle }>
      <Container maxWidth={ themeStretch ? false : "lg" }>
        <HeaderBreadcrumbs heading={ pageTitle } links={ [] }/>

        <MeditationVoiceCreateForm
          isEdit={ isEdit }
          isView={ isView }
          isNew={ isNew }
          loading={ loading }
          meditationVoice={ meditationVoice as MeditationVoice }
        />

      </Container>
    </Page>
  );
}
