import { useEffect, useState } from "react";
import { RelationshipSession, getRelationshipSessions } from "utils/firebase/chineseUsersRelationshipsUtils";

export function useGetRelationshipSessions(relationshipId: string) {
  const [sessions, setSessions] = useState<RelationshipSession[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    let unListen: any;
    if (!relationshipId) {
      return;
    }
    
    try {
      unListen = getRelationshipSessions(relationshipId, (res: RelationshipSession[]) => {
        setSessions(res);
        setLoading(false);
      });
    } catch (err) {
      console.error(err);
      setError(true);
    }

    // clear listening
    return () => {
      unListen?.();
    };
  }, [relationshipId]);

  return {
    loading,
    error,
    sessions,
  };
}
