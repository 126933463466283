import { useEffect, useState } from "react";
import { Therapist } from "types/therapists";
import { getAllTherapistsFromDB } from "utils/firebase/therapistUtils";

export function useGetAllTherapists() {
  const [therapists, setTherapists] = useState<Therapist[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    let unListen: any;
    try {
      unListen = getAllTherapistsFromDB((res: Therapist[]) => {
        setTherapists(res);
        setLoading(false);
      });
    } catch (err) {
      console.error(err);
      setError(true);
    }

    // clear listening
    return () => {
      unListen?.();
    };
  }, []);

  return {
    loading,
    error,
    therapists,
  };
}
