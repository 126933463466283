import React from "react";
import Button from "@mui/material/Button/Button";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";

import { AudioMeditationGenerationType } from "../types/audioMeditationGenerations";
import { useApprovedBy } from "../utils/firebase/audioMeditationGenerationsUtil";

type Props = {
  audioMeditation?: AudioMeditationGenerationType
}

enum ApproveButtonStates {
  APPROVE = "Approve",
  UN_APPROVE = "Unapprove"
}

export function ApproveActionButton({ audioMeditation }: Props) {
  const {
    approving,
    handleApproveToggle,
    approvedByName
  } = useApprovedBy(audioMeditation)

  const approvedBy = audioMeditation?.approvedBy ?? ""

  let buttonText = ApproveButtonStates.APPROVE
  if (approvedBy && !approving) {
    buttonText = ApproveButtonStates.UN_APPROVE
  }

  return (
    <Box
      display={ "flex" }
      justifyContent={ "flex-start" }
      alignItems={ "flex-end" }
      flexDirection={ "column" }
      minWidth={ 300 }
    >
      <Button
        style={ { width: 120, alignSelf: "flex-end" } }
        onClick={ handleApproveToggle }
        variant="contained"
        color={ "primary" }
        disabled={ approving }
      >
        { buttonText }
      </Button>

      { Boolean(approvedByName?.length) &&
          <Typography mt={ 2 } color={ "gray" }>
            { "Approved By: " + approvedByName }
          </Typography>
      }
    </Box>
  )
}
