import {
  TextField,
} from "@mui/material";
import { FieldInputProps, FormikErrors, FormikTouched } from "formik";

export type InnerFormProps = {
  getFieldProps: (nameOrOptions: any) => FieldInputProps<any>,
  touched: FormikTouched<any>;
  errors: FormikErrors<any>,
  isChatVideo?: boolean,
}

export function ContentPracticeForm(
  {
    getFieldProps,
    touched,
    errors,
  }: InnerFormProps)  {

    return (
          <>
            <TextField
              fullWidth
              label="Landbot ID"
              {...getFieldProps("landbotId")}
              error={Boolean(touched.landbotId && errors.landbotId)}
              helperText={touched.landbotId && errors.landbotId}
            />          
          </>
    );
}
