import {Collections, UserData, UserId} from "../../types/user";
import { db } from "contexts/FirebaseContext";

export const searchUserById = async (
  userId: UserId
): Promise<UserData | undefined> => {
  try {
    const response = await db.collection(Collections.USERS).doc(userId).get();
    if (!response?.exists) {
      return;
    }

    return { ...response.data(), id: response.id } as UserData;
  } catch (error) {
    console.log(error);
    return;
  }
};
