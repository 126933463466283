import React, {useState} from "react";
// material
import {
  Button,
  Card,
  Container,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { format } from "date-fns";


// hooks
import useSettings from "../../../hooks/useSettings";

// components
import Page from "../../../components/Page";
import Scrollbar from "../../../components/Scrollbar";
import SearchNotFound from "../../../components/SearchNotFound";
import HeaderBreadcrumbs from "../../../components/HeaderBreadcrumbs";
import TableToolbar from "../../../components/common/TableToolbar";
import TableHeader from "../../../components/common/TableHeader";
import {filter} from "lodash";
import {Anonymous} from "../../../types/list";
import {useGetNotificationHistory} from "../../../hooks/useGetNotificationHistory";
import MoreMenu from "../../../components/common/MoreMenu";
import {PATH_DASHBOARD} from "../../../routes/paths";
import {NotificationHistory} from "../../../types/notifications";
import {Link as RouterLink, useNavigate} from "react-router-dom";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "date", label: "Sent At", alignRight: false },
  { id: "invitationCode", label: "Invitation code", alignRight: false },
  { id: "notificationTitle.en", label: "Title", alignRight: false },
  { id: "notificationBody.en", label: "Body", alignRight: false },
  { id: "recipientsCount", label: "Recipients Count ", alignRight: false },
  { id: "actions", label: "Actions", alignRight: true },
];

// ----------------------------------------------------------------------

function applySortFilter(
  array: NotificationHistory[],
  comparator: (a: any, b: any) => number,
  query: string
) {
  const stabilizedThis = array.map((el, index) => [el, index] as const);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (group) => group.invitationCode.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

function descendingComparator(a: Anonymous, b: Anonymous, orderBy: string) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order: string, orderBy: string) {
  return order === "desc"
    ? (a: Anonymous, b: Anonymous) => descendingComparator(a, b, orderBy)
    : (a: Anonymous, b: Anonymous) => -descendingComparator(a, b, orderBy);
}

export default function NotificationsHistory() {
  const { themeStretch } = useSettings();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = useState("invitationCode");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const navigate = useNavigate();


  const { history = [], loading } = useGetNotificationHistory();

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (SelectedFilterName: string) => {
    setFilterName(SelectedFilterName);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - history.length) : 0;

  const filteredGroup = applySortFilter(
    history,
    getComparator(order, orderBy),
    filterName
  );

  const isGroupNotFound = filteredGroup.length === 0 && !loading;

  return (
    <Page title="Notification History">
      <Container maxWidth={themeStretch ? false : "lg"}>
        <HeaderBreadcrumbs
          heading=""
          links={[]}
          action={
              <Button
                  variant="contained"
                  component={RouterLink}
                  to={PATH_DASHBOARD.notifications.tool}
              >
                Send Notification
              </Button>
          }
        />

        <Card>
          <TableToolbar
            numSelected={0}
            filterName={filterName}
            onFilterName={handleFilterByName}
            searchPlaceholder={"Search by invitation code"}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <TableHeader
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={history.length}
                  numSelected={0}
                  onRequestSort={handleRequestSort}
                  hideCheckbox
                />
                <TableBody>
                  {filteredGroup
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row: NotificationHistory) => {
                      const {id, createdAt,  invitationCode = "", notificationTitle, notificationBody, recipientsCount } = row;

                      return (
                        <TableRow
                          hover
                          key={id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={false}
                          aria-checked={false}
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            padding="normal"
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                            >
                              <Typography variant="subtitle2" noWrap>
                                {format(createdAt, "dd-MMM-yyyy, HH:mm")}
                              </Typography>
                            </Stack>
                          </TableCell>

                          <TableCell
                            component="th"
                            scope="row"
                            padding="normal"
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                            >
                              <Typography variant="body1" noWrap>
                                {invitationCode}
                              </Typography>
                            </Stack>
                          </TableCell>

                          <TableCell
                            component="th"
                            scope="row"
                            padding="normal"
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                            >
                              <Typography variant="body1" noWrap>
                                {notificationTitle?.en ?? ""}
                              </Typography>
                            </Stack>
                          </TableCell>

                          <TableCell
                            component="th"
                            scope="row"
                            padding="normal"
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                            >
                              <Typography variant="body1" noWrap>
                                {notificationBody?.en ?? ""}
                              </Typography>
                            </Stack>
                          </TableCell>

                          <TableCell
                            component="th"
                            scope="row"
                            padding="normal"
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                            >
                              <Typography variant="body1" noWrap>
                                {recipientsCount || 0}
                              </Typography>
                            </Stack>
                          </TableCell>

                          <TableCell align="right">
                            <MoreMenu
                                editButtonText={"View/Resend"}
                                onEdit={() => {
                                  navigate(
                                      `${PATH_DASHBOARD.notifications.tool}/${id}`,
                                      {
                                        state: row,
                                      }
                                  );
                                }}
                            />
                          </TableCell>

                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isGroupNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={history.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(e, _page) => setPage(_page)}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />

        </Card>
      </Container>
    </Page>
  );
}
