export const getAttendStatus = (status: string) => {
    switch (status) {
        case "pending":
            return "Pending";
        case "requested":
            return "Requested";
        case "waitingForClientConfirmation":
            return "Waiting for client confirmation";
        case "requestExpired":
            return "Request expired";
        case "scheduled":
            return "Scheduled";
        case "cancelled":
            return "Cancelled";
        case "rescheduled":
            return "Rescheduled";
        case "completed":
            return "Completed";
        case "userDidntJoin":
            return "User didn't join";
        case "therapistDidntJoin":
            return "Therapist didn't join";
        case "noOneJoined":
            return "No one joined";
        case "bothJoined":
            return "Both joined";
        default:
            return status;
    }
  };
