import React from "react";
import { useParams } from "react-router-dom";

// material
import { Container } from "@mui/material";

// hooks
import useSettings from "../../../hooks/useSettings";

// components
import Page from "../../../components/Page";
import HeaderBreadcrumbs from "../../../components/HeaderBreadcrumbs";
import { useAudioMeditation } from '../../../utils/firebase/audioMeditationGenerationsUtil';
import AudioMeditationUpdationForm
  from '../../../components/_dashboard/audio-meditation-generations/AudioMeditationUpdationForm';
import { AudioMeditationGenerationType } from '../../../types/audioMeditationGenerations';
import { ApproveActionButton } from "../../../components/ApproveActionAction";

// ----------------------------------------------------------------------

export default function UpdateAudioMeditation() {
  const { themeStretch } = useSettings();
  const { id: audioMeditationIdToBeUpdated } = useParams();

  const { loading, audioMeditation } = useAudioMeditation(audioMeditationIdToBeUpdated ?? "");

  let pageTitle = `Update Audio Meditation - ${ audioMeditation?.title ?? "" }`;

  return (
    <Page title={ pageTitle }>
      <Container maxWidth={ themeStretch ? false : "lg" }>
        <HeaderBreadcrumbs heading={ pageTitle } links={ [] }
                           action={ audioMeditation?.id ?
                             <ApproveActionButton audioMeditation={ audioMeditation }/> : null }
        />

        { Boolean(audioMeditation?.id) &&
          (<AudioMeditationUpdationForm
            loading={ loading }
            audioMeditation={ audioMeditation as AudioMeditationGenerationType }
          />)
        }
      </Container>
    </Page>
  );
}
