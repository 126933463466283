import React, { useState } from 'react';
import { Stack, TextField, Typography } from '@mui/material';

export function Practices(props: any) {
  const [collapsed, setCollapsed] = useState(true);
  const practices = props?.practices || {};

  // @ts-ignore
  const practicesKeys = Object.keys(practices || {});

  practicesKeys.sort(
    (a: any, b: any) => Number(practices[a]?.sortPlace) - Number(practices[b]?.sortPlace)
  );

  // @ts-ignore
  return (
    <>
      <Typography
        mt={1}
        mb={3}
        variant="subtitle2"
        onClick={() => {
          setCollapsed(!collapsed);
        }}
      >
        <h3> practices (click to {collapsed ? 'show' : 'hide'})</h3>
      </Typography>

      {!collapsed && (
        <>
          {practicesKeys.map((practiceKey) => {
            // @ts-ignore
            const innerObject = practices?.[practiceKey] || {};
            const innerKeys = Object.keys(innerObject);
            return (
              <>
                <Typography key={String(practiceKey)} mt={2} variant="subtitle1">
                  <h4>{`${practiceKey}`}</h4>
                </Typography>
                {innerKeys.map((keyToRender) => {
                  // @ts-ignore
                  const itemToRender = innerObject[keyToRender] || {};
                  return (
                    <Stack
                      key={keyToRender}
                      direction={{ xs: 'column', sm: 'row' }}
                      spacing={1}
                      mt={3}
                      mb={3}
                      sx={{ justifyContent: 'space-between' }}
                    >
                      <Typography
                        key={String(practiceKey + keyToRender + 'label')}
                        mt={2}
                        variant={'subtitle1'}
                      >
                        {`${keyToRender}`}
                      </Typography>
                      <TextField
                        sx={{ m: 1, width: '80%' }}
                        // @ts-ignore
                        value={itemToRender || ''}
                        type="text"
                        inputProps={{ readOnly: true }}
                      />
                    </Stack>
                  );
                })}
              </>
            );
          })}
        </>
      )}
    </>
  );
}
