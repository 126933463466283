import { InputProps as StandardInputProps } from "@mui/material/Input/Input";
import { Stack, TextField, Typography } from "@mui/material";
import React from "react";

type DropDownSelectorProps = {
  onChange: StandardInputProps["onChange"];
  value?: string;
  placeholder?: string;
  name?: string;
  label: string;
  disabled: boolean;
  options: Array<string>;
  defaultValue?: string;
};

export default function DropDownSelector({
  onChange,
  value,
  placeholder,
  name,
  disabled,
  options,
  label = "",
  defaultValue,
}: DropDownSelectorProps) {
  return (
    <Stack
      direction={{ xs: "column", sm: "row" }}
      spacing={1}
      sx={{ justifyContent: "space-between" }}
    >
      {Boolean(placeholder) && (
        <Typography mt={2} variant="subtitle1">
          {placeholder}
        </Typography>
      )}
      <TextField
        select
        disabled={disabled}
        sx={{ m: 1, width: "80%" }}
        name={name}
        label={label}
        placeholder={placeholder}
        onChange={onChange}
        defaultValue={defaultValue}
        value={value}
        SelectProps={{ native: true }}
      >
        {Boolean(name) && (
          <option value="" disabled selected>
            {name}
          </option>
        )}
        {options.map?.((option: string) => {
          return <option key={option}>{option}</option>;
        })}
      </TextField>
    </Stack>
  );
}
