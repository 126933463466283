import {
  TextField,
} from "@mui/material";
import { InnerFormProps } from "./ContentPracticeForm";

export function ContentMeditationForm({
  getFieldProps,
  touched,
  errors,
}: InnerFormProps)  {
  return (
    <>
      <TextField
        fullWidth
        label="Media URL"
        {...getFieldProps("mediaUrl")}
        error={Boolean(touched.mediaUrl && errors.mediaUrl)}
        helperText={touched.mediaUrl && errors.mediaUrl}
      />
    </>
  );
}
