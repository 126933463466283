import { Avatar, ListItemAvatar } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

export default function RevisionDeleteListIcon() {
  return (
    <ListItemAvatar>
      <Avatar>
        <DeleteIcon />
      </Avatar>
    </ListItemAvatar>
  );
}
