import { useSnackbar } from "notistack";
import React, { useCallback, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { createInvitationCodeCreditsPeriod } from "utils/firebase/cloudFunctions";
import { updateInvitationCodeCreditPeriod } from "utils/firebase/creditsPeriodsUtils";
import { CreditPeriodsContextValue, InitialValues, FormValues } from "../types";

import CreditPeriodsContext from "./CreditPeriodsContext";

const CreditPeriodsProvider: React.FC = ({ children }) => {
  const [formDialogIsOpen, setFormDialogIsOpen] = useState<boolean>(false);
  const formValues = useRef<FormValues | undefined>();

  const { code: invitationCode } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const handleClickOpen = useCallback((values?: FormValues) => {
    formValues.current = values ? values : undefined;

    setFormDialogIsOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setFormDialogIsOpen(false);
  }, []);

  const handleSubmit = useCallback(
    async (formData, { setSubmitting, resetForm, setErrors }) => {
      try {
        if (formValues.current) {
          await updateInvitationCodeCreditPeriod({
            formValues: formData,
            docId: formValues.current.id,
            invitationCode,
          });
        } else {
          await createInvitationCodeCreditsPeriod({
            ...formData,
            invitationCode,
          });
        }
        enqueueSnackbar("Create success ", {
          variant: "success",
        });
        resetForm();
        setSubmitting(false);
        setFormDialogIsOpen(false);
      } catch (error) {
        setSubmitting(false);
        enqueueSnackbar("Something went wrong :(", {
          variant: "error",
        });
        setErrors(error);
      }
    },
    [enqueueSnackbar, invitationCode]
  );

  const value: CreditPeriodsContextValue = {
    values: formValues.current || InitialValues,
    invitationCode,
    handleClickOpen,
    handleClose,
    formDialogIsOpen,
    handleSubmit,
  };

  return (
    <CreditPeriodsContext.Provider value={value}>
      {children}
    </CreditPeriodsContext.Provider>
  );
};

export default CreditPeriodsProvider;
