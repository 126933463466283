import {
    BotDataItem,
    BotDataType,
    BotsRatingCategories,
    BotsRatingData
} from '../../types/bot-data/botDataType';
import { db } from '../../contexts/FirebaseContext';
import {SupportedLanguages} from '../../types/invitation/code';
import {incrementContentVersion} from "./cloudFunctions";
import {ContentVersionsField} from "../../types/translations";

export function onBotDataChanged(onSnapshot: (data: BotDataType) => void) {
  const unListen = db
    .collection('bot_data')

    .onSnapshot((snapshot) => {
      const result: BotDataType = {
        // @ts-ignore
        en: {},
        fr: {},
        es: {},
        de: {}
      };
      for (const doc of snapshot.docs) {
        // @ts-ignore
        result[doc.id] = doc.data();
      }
      return onSnapshot(result);
    });

  return unListen;
}

export async function getBotsRatingQuestions() {
  return db.collection('bots_rating_questions')
        .get().then((snapshot) => {
            const result: BotsRatingData = {
                en: {},
                fr: {},
                es: {},
                de: {}
            };
            for (const doc of snapshot.docs) {
                result[doc.id as SupportedLanguages] = doc.data();
            }
            return result;
        });

}

export async function updateBotData(language: string, data: BotDataItem) {
    await db.collection('bot_data').doc(language).set(data, { merge: true });
    await incrementContentVersion({fieldName: ContentVersionsField.BOT_DATA_VERSION});
}

export async function updateBotsRatingData(language: string, data: BotsRatingCategories) {
    await db.collection('bots_rating_questions').doc(language).set(data, { merge: true });
    await incrementContentVersion({fieldName: ContentVersionsField.RATING_QUESTIONS_VERSION});
}
