import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  Paper,
  Typography,
} from "@mui/material";
import UserMigrationForm from "./UserMigrationForm";
import CustomBulletedList from "components/CustomBulletedList";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoIcon from "@mui/icons-material/Info";

const migrationSteps = [
  "Select the source invitation code or add users by identifier",
  "Select the target invitation code. Users will be moved to this invitation code.",
  "Ensure that either users are selected or a source invitation code is set, you can't specify both.",
  "Submit",
];

const migrationDetailsDemographicQuestions = [
  "If the user has already answered some questions on the previous code: nothing happens, even if the questions on the new code are different.",
  "If the user has not answered any questions on the old code and there are questions on the new code: the user will be asked to fill them on login.",
];

const migrationDetailsCreditBillingPeriods = [
  "In the moment of migration only the invitationCode is saved on the user profile.",
  "Immediately after migration (and a refresh / app restart) user sees updated information about remaining free sessions in app / care.",
  "The information about the new period is saved in user_credit_period collection on the user profile in the moment when a session request is sent.",
  "The process works correctly even if the session request is sent on old code and call confirmed on new.",
  "The information about previous periods and booked calls is left unchanged on the user profile.",
  "If the user books a call on the old code and cancels it after migration, on the new one, the credit remains booked in the old credit period and is reimbursed in the new one (user has +1 credit that will show in their account).",
];

export default function UserMigrationPage() {
  return (
    <Container>
      <Paper elevation={3}>
        <Box p={12}>
          <Box mb={3}>
            <Typography variant="h3">Migrate users</Typography>
            <CustomBulletedList items={migrationSteps} />
            <Typography>
              <strong>Note:</strong> Migrations will fail if a user doesn't
              exist. A migration might be partially executed in case of failure.
            </Typography>
            <Accordion style={{ marginTop: "10px" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Box display="flex" alignItems="center" gap="8px">
                  <Typography variant="body1" color="textSecondary">
                    Learn more details about migrating users
                  </Typography>
                  <Typography color="textSecondary">
                    <InfoIcon fontSize="small" />
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Typography component={"div"}>
                  <strong>Enforcing demographic questions</strong>
                  <CustomBulletedList
                    items={migrationDetailsDemographicQuestions}
                  />
                  <strong>Credit/Billing Periods</strong>
                  <CustomBulletedList
                    items={migrationDetailsCreditBillingPeriods}
                  />
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Box>

          <UserMigrationForm />
        </Box>
      </Paper>
    </Container>
  );
}
