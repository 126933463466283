import { useEffect, useState } from "react";

import { db } from "../contexts/FirebaseContext";
import { SETTINGS_ITEM_ID } from "../utils/constants";
import { SettingsTypes } from "../types/settings";
import useAuth from "./useAuth";

export default function useEmailsAndNotificationsSettings() {
  const { isAuthenticated } = useAuth();
  const [loading, setLoading] = useState<boolean>(false);
  const [emailsAndNotificationsSettings, setEmailsAndNotificationsSettings] = useState<SettingsTypes>({} as SettingsTypes);

  useEffect(() => {
    let unsub = () => {};

    async function getSettingData() {
      try {
        setLoading(true);
        unsub = db.collection("settings")
          .doc(SETTINGS_ITEM_ID)
          .onSnapshot((snapshot) => {
            setEmailsAndNotificationsSettings(snapshot.data() as SettingsTypes);
          });
        setLoading(true);
      } catch (e) {
        setLoading(false);
        console.error(e);
      }
    }

    if (isAuthenticated) {
      getSettingData();
    }

    return unsub
  }, [isAuthenticated]);

  return { loading, emailsAndNotificationsSettings }
}
