import { Button, Card, Grid, Typography } from "@mui/material";
import Box from "@mui/system/Box";
import { styled, useTheme } from "@mui/material/styles";
import { AssessmentRecord } from "types/CounsellorProgressDasboard";
import { useTranslation } from "react-i18next";
import { WellbeingColorPalette } from "../../theme/palette";
import ScoresCircle from "./ScoresCircle";

const Title = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 700,
  color: theme.palette.blue.dark,
  marginLeft: theme.spacing(1),
}));

const SeeMoreButton = styled(Button)(({ theme }) => ({
  alignSelf: "flex-start",
  textTransform: "uppercase",
  color: theme.palette.blue.dark,
  opacity: 0.5,
  marginTop: theme.spacing(1.5),
}));

const Question = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  fontWeight: 400,
  color: theme.palette.blue.dark,
  opacity: 0.6,
  marginTop: theme.spacing(3),
}));

type Props = {
  item: AssessmentRecord;
  index: number;
  score: number | string;
  color: WellbeingColorPalette;
  validUserCount: number;
  onClickSeeMore?: () => void;
};

export default function AssessmentItem({
  item,
  score,
  color,
  index,
  validUserCount,
  onClickSeeMore,
}: Props) {
  const theme = useTheme();
  const { t } = useTranslation();

  const renderSeeMoreButton = () => {
    return (
      <SeeMoreButton disableRipple variant="text" onClick={onClickSeeMore}>
        {t("seeMore")}
      </SeeMoreButton>
    );
  };

  const { title, question, icon } = item;
  return (
    <Grid item xs={12} sm={6}>
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          padding: theme.spacing(2.5),
        }}
        key={title}
      >
        <Box display="flex" justifyContent="space-between">
          <Box display="flex" alignItems={"center"}>
            {icon}
            <Title>{t(title)}</Title>
          </Box>


          {validUserCount > 0 ? 
              <ScoresCircle
                score={score}
                bgcolor={
                  color
                }
              />
            : <Typography style={{opacity: 0.4}} variant="h6" >N/A</Typography>}
        </Box>

        <Question>{`“${t("abbreviatedQ")}${index + 1}. ${t(
          question
        )}”`}</Question>

        {renderSeeMoreButton()}
      </Card>
    </Grid>
  );
}
