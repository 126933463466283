import {
    Checkbox,
    FormControlLabel,
    Stack,
    TextField,
  } from "@mui/material";
import { FieldInputProps } from "formik";

type Props = {
    titleLabel: string;
    getFieldProps: (nameOrOptions: any) => FieldInputProps<any>;
    index: number ;
    descriptionLabel: string;
}

export function ContentOverviewCardFields({
    titleLabel,
    getFieldProps,
    index,
    descriptionLabel
}: Props) {

    return (
        <Stack 
            spacing={3} 
            key={index} 
            style={{ 
                backgroundColor: "#f7f7f7",
                padding: "15px",
                borderRadius: "10px"
            }}
            >
            <TextField
                sx={{ width: "100%" }}
                label={titleLabel}
                defaultValue={""}
                {...getFieldProps(
                    `overviewCards.${index}.tabTitle`
                )}
                type={"text"}
            />

            <TextField
                sx={{ width: "100%" }}
                multiline
                label={descriptionLabel}
                {...getFieldProps(
                    `overviewCards.${index}.description`
                )}
                defaultValue={""}
                type={"text"}
            />

            <FormControlLabel
                label="Show only for Counsellors"
                control={
                    <Checkbox
                      checked={Boolean(getFieldProps(`overviewCards.${index}.onlyForCounsellor`).value)}
                    {...getFieldProps(`overviewCards.${index}.onlyForCounsellor`)}
                    />
                }
            />
        </Stack>
    )
}
