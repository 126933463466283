import CheckIcon from "@mui/icons-material/Check";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import { Tooltip } from "@mui/material";
import { ValidationStatus } from "../types/ValidationStatus";

/**
 * Render an icon showing the validation status. If it's neither valid nor
 * invalid, it's considered not having been validated and returns null.
 */
export default function ValidationIcon(props: ValidationStatus) {
  const { isValid, isInvalid } = props;
  if (isInvalid) {
    return (
      <Tooltip title="This is not a valid user">
        <ReportProblemIcon color="warning" />
      </Tooltip>
    );
  }
  if (isValid) {
    return <CheckIcon color="success" />;
  }
  return null;
}
