import { db } from "../contexts/FirebaseContext";
import { Snippet } from "../types/snippet";

export const createOrUpdateSnippets = async (
  snippetId: string,
  snippet: Snippet
): Promise<void> => {
  await db
    .collection("snippets")
    .doc(snippetId)
    .set({ ...snippet }, { merge: true });
};

export async function getSnippetById(snippetId: string): Promise<Snippet> {
  const doc = await db.collection("snippets").doc(snippetId).get();

  if (!doc.exists) {
    throw new Error("No snippet exists with this Id");
  }

  return {
    id: snippetId,
    ...doc?.data(),
  } as Snippet;
}
