import { Timestamp } from "../components/_dashboard/codes/StatusCell";

export type AudioMeditationVoiceConfigSettingType = {
  stability: number;
  similarityBoost: number;
  style: number,
  useSpeakerBoost: boolean;
}

export type AudioMeditationVoiceConfigType = {
  id: string;
  setting: AudioMeditationVoiceConfigSettingType
}

export type AudioMeditationChunks = {
  audio: string;
  duration: number;
  text: string;
  isEdited?: boolean;
  voiceConfig?: AudioMeditationVoiceConfigType;
}

export type MeditationVoiceType = {
  name: string;
}

export type AudioMeditationGenerationType = {
  id: string;
  title: string;
  sourceText: string;
  sourceLang: string;
  targetLang: string;
  skipExternalProcessing: boolean;
  isActive: boolean;
  meditationId: string;
  voiceName?: string;
  isManualCreated?: boolean;
  translator: string;
  openAIModel: string;
  status?: string;
  userId?: string;
  approvedBy?: string;
  comment?: string;
  failureReason?: string;
  voiceConfig: AudioMeditationVoiceConfigType;
  formality?: string;
  translatedTextFromTranslator?: string;
  AIProcessedTranslatedText?: string;
  targetLanguageVoiceUrl?: string;
  createdAt: Date
  textsAudiosChunksWithDuration?: Array<AudioMeditationChunks>
};

export type AudioMeditationWithoutGenerationType = {
  title: string;
  isActive: boolean;
  meditationId: string;
  comment?: string;
};

export type AudioMeditationGenerationRequestType = {
  documentId: string;
  title: string;
  sourceText: string;
  sourceLang: string;
  targetLang: string[];
  skipExternalProcessing: boolean;
  translator: string;
  isActive: boolean;
  meditationId: string;
  voiceId: string;
  voiceSetting: AudioMeditationVoiceConfigSettingType;
  formality?: string;
  comment?: string;
};

export type AudioMeditationPreviewGenerationRequestType = {
  documentId: string;
  text: string;
  voiceId: string;
  voiceSetting: AudioMeditationVoiceConfigSettingType;
};

export type MixAudioMeditationsGenerationRequestType = {
  documentId: string;
  textsAudiosChunksWithDuration: Array<AudioMeditationChunks>;
};

export type ManualAudioMeditationCreationRequestType = {
  documentId?: string;
  title: string;
  sourceText: string;
  sourceLang: string;
  targetLang: string;
  isActive: boolean;
  meditationId: string;
  voiceId: string;
  audioUrl: string;
  isManualCreated: boolean,
  createdAt: Timestamp
}

export type AudioContentAccessType = {
  accessToAllLanguages: boolean;
  accessLanguages: string[];
}

export enum AudioMeditationGenerationStatus {
  SUCCEEDED = "Succeeded",
}
