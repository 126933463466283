import {Card, Popover, Stack, TextField, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import InfoIcon from "@mui/icons-material/Info";
import CloseIcon from "@mui/icons-material/Close";
import React, {useEffect, useState} from "react";
import {getUsersWithCode} from "../../../utils/firebase";
import {useLocation, useParams} from "react-router-dom";

type Props = {
    queryPathName: "invitationCode" | "registrationCode"
}

type UserInfo = { id: string; email: string }

export default function CodeUsersPopover({queryPathName}: Props) {
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const [usersWithCode, setUsersWithCode] = useState<Array<UserInfo>>([]);
    const { pathname } = useLocation();
    const isEditingCode = pathname.includes("edit");
    const { code } = useParams();

    useEffect(() => {
        if(isEditingCode && code) {
            getUsersWithCode(code, queryPathName).then((res) => {
                setUsersWithCode(res);
            })
        }
    }, [code, isEditingCode, queryPathName])


    const open = Boolean(anchorEl);

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    if(!isEditingCode || !usersWithCode.length){
        return null;
    }

    return (
        <Stack
            direction={{xs: 'column', sm: 'row'}}
            spacing={1}
            sx={{justifyContent: 'space-between'}}
        >
            <TextField
                fullWidth
                label="Times Used"
                value={usersWithCode?.length ?? 0}
                disabled
            />
            <Button onClick={handlePopoverOpen}>
                <InfoIcon/>
            </Button>
            <Popover
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
                onClose={handlePopoverClose}
            >
                <Card sx={{py: 4, px: 2}}>
                    <Button
                        onClick={handlePopoverClose}
                        sx={{
                            width: 50,
                            height: 50,
                            position: 'absolute',
                            right: 0,
                            top: 0
                        }}
                    >
                        <CloseIcon/>
                    </Button>
                    {usersWithCode?.map((user) => (
                        <Typography key={user.id} sx={{p: 1}}>
                            {user?.email || ''}
                        </Typography>
                    ))}
                </Card>
            </Popover>
        </Stack>
    )
}