import React from 'react';
import {
  CodeConfigsFormProps,
  SContactNum,
  SupportContact
} from '../../../../types/invitation/code';
import { Stack, TextField, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { v4 } from 'uuid';
import Divider from '@mui/material/Divider';
import { prependItem } from '../../../../utils/firebase';

export default function DynamicSupportContacts({
  setFieldValue,
  getFieldProps,
  invitationCode,
  featureName,
  touched,
  errors,
  selectedLanguage
}: CodeConfigsFormProps) {
  const fieldKey = `${featureName}.${selectedLanguage}`;
  const supportContacts: any = getFieldProps(fieldKey)?.value || [];

  return (
    <Stack spacing={3}>
      {supportContacts?.map((item: SupportContact, index: number) => {
        const qFieldName = `${fieldKey}[${index}].title`;
        return (
          <>
            <Stack key={item.id}>
              <Stack
                key={String(item.id) + '_1'}
                direction={{ xs: 'column', sm: 'row' }}
                spacing={1}
                sx={{ justifyContent: 'space-between' }}
              >
                <TextField
                  fullWidth
                  label={`title# ${index + 1}`}
                  {...getFieldProps(qFieldName)}
                  defaultValue={item?.title}
                  error={Boolean(
                    // @ts-ignore
                    touched?.supportContacts?.[selectedLanguage]?.[index]?.title &&
                      // @ts-ignore
                      errors?.supportContacts?.[selectedLanguage]?.[index]?.title
                  )}
                  helperText={
                    // @ts-ignore
                    touched?.supportContacts?.[selectedLanguage]?.[index]?.title &&
                    // @ts-ignore
                    errors?.supportContacts?.[selectedLanguage]?.[index]?.title
                  }
                />
                <Button
                  color="error"
                  variant="outlined"
                  endIcon={<DeleteIcon />}
                  onClick={() => {
                    const updatedSupportContact = supportContacts?.filter(
                      (sContact: any, ind: number) => sContact?.id !== item?.id
                    );
                    setFieldValue(fieldKey, updatedSupportContact);
                  }}
                >
                  delete
                </Button>
              </Stack>
            </Stack>

            <Numbers
              key={item.id + '_3'}
              nestIndex={index}
              {...{
                invitationCode,
                setFieldValue,
                getFieldProps,
                featureName,
                selectedLanguage,
                touched,
                errors
              }}
            />
          </>
        );
      })}
    </Stack>
  );
}

function Numbers({
  nestIndex,
  setFieldValue,
  getFieldProps,
  touched,
  errors,
  selectedLanguage,
  featureName
}: {
  nestIndex: number;
} & CodeConfigsFormProps) {
  const fieldName = `${featureName}.${selectedLanguage}[${nestIndex}].numbers`;
  const numbers = getFieldProps(fieldName)?.value || [];

  return (
    <Stack spacing={2}>
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        sx={{ justifyContent: 'space-between', my: 2 }}
      >
        <Typography variant="subtitle2">Numbers</Typography>

        <Button
          color="success"
          variant="contained"
          endIcon={<AddIcon />}
          onClick={() => {
            setFieldValue(
              fieldName,
              prependItem(
                {
                  id: v4(),
                  title: ''
                },
                numbers || []
              )
            );
          }}
        >
          add number
        </Button>
      </Stack>
      {(numbers || [])?.map((item: SContactNum, k: number) => {
        return (
          <>
            <Divider variant={'inset'} sx={{ width: 300, alignSelf: 'center' }} />
            <Stack
              key={String(item.id)}
              direction={{ xs: 'column', sm: 'row' }}
              spacing={1}
              sx={{ justifyContent: 'space-between' }}
            >
              <TextField
                fullWidth
                label={`Contact Name# ${k}`}
                {...getFieldProps(
                  `supportContacts.${selectedLanguage}[${nestIndex}].numbers[${k}].title`
                )}
                error={Boolean(
                  //@ts-ignore
                  touched?.supportContacts?.[selectedLanguage]?.[nestIndex]?.numbers?.[k]?.title &&
                    // @ts-ignore
                    errors?.supportContacts?.[selectedLanguage]?.[nestIndex]?.numbers?.[k]?.title
                )}
                helperText={
                  //@ts-ignore
                  touched?.supportContacts?.[selectedLanguage]?.[nestIndex]?.numbers?.[k]?.title &&
                  //@ts-ignore
                  errors?.supportContacts?.[selectedLanguage]?.[nestIndex]?.numbers?.[k]?.title
                }
                type="text"
              />
            </Stack>

            <Stack
              key={String(item.id) + 1}
              direction={{ xs: 'column', sm: 'row' }}
              spacing={1}
              sx={{ justifyContent: 'space-between' }}
            >
              <TextField
                fullWidth
                label={`Contact Number# ${k}`}
                {...getFieldProps(
                  `supportContacts.${selectedLanguage}[${nestIndex}].numbers[${k}].number`
                )}
                error={Boolean(
                  //@ts-ignore
                  touched?.supportContacts?.[selectedLanguage]?.[nestIndex]?.numbers?.[k]?.number &&
                    // @ts-ignore
                    errors?.supportContacts?.[selectedLanguage]?.[nestIndex]?.numbers?.[k]?.number
                )}
                helperText={
                  //@ts-ignore
                  touched?.supportContacts?.[selectedLanguage]?.[nestIndex]?.numbers?.[k]?.number &&
                  // @ts-ignore
                  errors?.supportContacts?.[selectedLanguage]?.[nestIndex]?.numbers?.[k]?.number
                }
                defaultValue={item?.number}
                type="text"
              />
              <Button
                color="error"
                variant="outlined"
                endIcon={<DeleteIcon />}
                onClick={() => {
                  const updatednumber = (numbers || [])?.filter?.(
                    (ans: any, index: number) => ans?.id !== item?.id
                  );
                  setFieldValue(fieldName, updatednumber);
                }}
              >
                delete
              </Button>
            </Stack>
          </>
        );
      })}
      <hr />
    </Stack>
  );
}
