import { Box } from "@mui/material";
import { pick } from "lodash";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { deleteImage } from "utils/firebase/contentUtils";
import useGetImageContent from "hooks/useGetImageContent";
import ImageAutoComplete from "./ImageAutoComplete";
import ImageContentForm from "./ImageContentForm";

export default function ImagesFormContainer() {
  const [selectedImage, setSelectedImage] = useState<string>();

  const imageContent = useGetImageContent();

  const { imageId } = useParams();

  useEffect(() => {
    if (imageId && imageId !== selectedImage) {
      setSelectedImage(imageId);
    }
  }, [imageId, selectedImage]);

  if (!imageContent) {
    return null;
  }

  const imageOptions = Object.values(imageContent)?.map((image) => {
    return pick(image, ["id", "title"]) as Record<string, string>;
  });

  const defaultValue = imageOptions.find(
    (image) => image?.id === selectedImage
  );

  const data = imageContent?.find((image) => image?.id === imageId);

  const handleDeleteImage = async (id: string): Promise<void> => {
    await deleteImage(id);
    setSelectedImage("")
  };

  return (
    <Box>
      <ImageAutoComplete
        setSelectedImage={setSelectedImage}
        imageOptions={imageOptions}
        defaultValue={defaultValue}
      />

      <ImageContentForm
        data={data}
        imageId={imageId}
        deleteImage={handleDeleteImage}/>
    </Box>
  );
}
