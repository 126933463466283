import { Autocomplete, TextField, createFilterOptions } from "@mui/material";
import { flatten,  uniq } from "lodash";
import { filterUndefined } from "utils/typescript";
import {useContext} from "react";
import {ContentUnitsContext} from "../../../contexts/ContentUnitsContext";

type Props = {
    selectedTags?: string[],
    setSelectedTags: (tags: any[] | string[]) => void
}

type TagOptions = string;

export default function TagsAutocomplete({
  selectedTags,
  setSelectedTags
}: Props) {

  const filter = createFilterOptions<TagOptions>();

  const { contentUnits} = useContext(ContentUnitsContext);

  const nestedTags = Object.values(contentUnits ?? []).map(contentUnit => {

    if(!contentUnit.tags?.length){
        return undefined;
    }

    return contentUnit.tags;
  })

  const tags = uniq(flatten(nestedTags).filter(filterUndefined));

  return (
    <Autocomplete
      multiple
      id="tags"
      options={tags}
      value={selectedTags}
      getOptionLabel={(option) => option}
      filterSelectedOptions
      renderInput={(params) => (
        <TextField {...params} label="Tags" placeholder="Type another tag" />
      )}
      onChange={(_, newValue) => {
        setSelectedTags(newValue);
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        if (params.inputValue !== '') {
          filtered.push(params.inputValue);
        }

        return filtered;
      }}
      
    />
  );
}
