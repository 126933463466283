import { Form, FormikProvider, useFormik, FormikHelpers } from "formik";
import { Stack, TextField, Typography, Switch } from "@mui/material";
import * as Yup from "yup";
import { LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePickerWithFormik from "./DatePickerWithFormik";
import SubmitWithConfirmationButton from "./SubmitWithConfirmationButton";

type FormValues = {
  active: boolean;
  softLockFrom: Date;
  hardLockFrom: Date;
  en: string;
  de: string;
  fr: string;
  es: string;
  passcode: string;
};

const RenewalChallengeFormSchema = Yup.object().shape({
  active: Yup.bool().required("Challenge  status is required"),
  softLockFrom: Yup.date()
    .required()
    .typeError("The value must be a date (DD/MM/YYYY)"),
  hardLockFrom: Yup.date()
    .typeError("The value must be a date (DD/MM/YYYY)")
    .required("Hardlock start date is required")
    .min(Yup.ref("softLockFrom"), "Hardlock must start after Softlock date"),
  en: Yup.string(),
  de: Yup.string(),
  fr: Yup.string(),
  es: Yup.string(),
  passcode: Yup.string().required("Passcode is required"),
});

type FormProps = {
  initialValues: FormValues;
  onSubmit: (
    values: FormValues,
    helpers: FormikHelpers<FormValues>
  ) => Promise<void>;
};

const RenewalChallengeForm = ({ initialValues, onSubmit }: FormProps) => {
  const formik = useFormik<FormValues>({
    initialValues,
    validationSchema: RenewalChallengeFormSchema,
    onSubmit,
    enableReinitialize: true,
  });

  const {
    errors,
    touched,
    setFieldValue,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    submitForm,
  } = formik;

  return (
    <FormikProvider value={formik}>
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <Stack spacing={1}>
            <Switch
              {...getFieldProps("active")}
              onChange={(event) =>
                setFieldValue("active", event.target.checked)
              }
              checked={getFieldProps("active").value}
            />
            {getFieldProps("active").value ? (
              <Typography variant="body2">
                The challenge will be set to active.
              </Typography>
            ) : (
              <Typography variant="body2">
                The challenge will be saved as inactive.
              </Typography>
            )}
          </Stack>
          <Stack spacing={1}>
            <Typography variant="body1">Renewal challenge details</Typography>
            <TextField
              fullWidth
              label="Passcode"
              {...getFieldProps("passcode")}
              error={Boolean(touched.passcode && errors.passcode)}
              helperText={touched.passcode && errors.passcode}
            />
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePickerWithFormik
                label={"Soft lock from"}
                field={"softLockFrom"}
                getFieldMeta={formik.getFieldMeta}
                getFieldProps={formik.getFieldProps}
                setFieldValue={formik.setFieldValue}
              />
              <DatePickerWithFormik
                label={"Hard lock from"}
                field={"hardLockFrom"}
                getFieldMeta={formik.getFieldMeta}
                getFieldProps={formik.getFieldProps}
                setFieldValue={formik.setFieldValue}
              />
            </LocalizationProvider>
          </Stack>
          <Stack spacing={1}>
            <Typography variant="body1">
              Custom note from the client to users
            </Typography>
            <TextField fullWidth label="English" {...getFieldProps("en")} />
            <TextField fullWidth label="German" {...getFieldProps("de")} />
            <TextField fullWidth label="Spanish" {...getFieldProps("es")} />
            <TextField fullWidth label="French" {...getFieldProps("fr")} />
          </Stack>
          <SubmitWithConfirmationButton
            isSubmitting={isSubmitting}
            onSubmit={submitForm}
            expirationDate={formik.getFieldProps("hardLockFrom").value}
          />
        </Stack>
      </Form>
    </FormikProvider>
  );
};

export default RenewalChallengeForm;
