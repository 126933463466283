import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useTranslation } from "react-i18next";

import { CounsellorProgressAssessmentQuestion } from "types/CounsellorProgressDasboard";
import { AssessmentQuestions } from "./utils/constants";

type Props = {
  fullWidth?: boolean;
  value: CounsellorProgressAssessmentQuestion;
  onChange: (selectedValue: CounsellorProgressAssessmentQuestion) => void;
};

export default function AssessmentQuestionsDropdown({
  fullWidth,
  value,
  onChange,
}: Props) {
  const { t } = useTranslation();
  const handleChangeQuestion = (event: SelectChangeEvent) => {
    onChange(event.target.value as CounsellorProgressAssessmentQuestion);
  };

    return (
    <FormControl sx={{ m: 1 }} fullWidth={fullWidth}>
      <Select
        value={value}
        onChange={handleChangeQuestion}
      >
        {AssessmentQuestions.map(({ title, question }) => {
          return (
            <MenuItem key={title} value={question}>
              {t(title)}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
