// routes
import Router from "./routes";
// theme
import ThemeConfig from "./theme";
import GlobalStyles from "./theme/globalStyles";
// hooks
import useAuth from "./hooks/useAuth";
// components
import RtlLayout from "./components/RtlLayout";
import ScrollToTop from "./components/ScrollToTop";
import NotistackProvider from "./components/NotistackProvider";
import ThemePrimaryColor from "./components/ThemePrimaryColor";
import ThemeLocalization from "./components/ThemeLocalization";
import LoadingScreen, { ProgressBarStyle } from "./components/LoadingScreen";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { webClientId } from "./config";
import useSyncContentVersions from "./hooks/useSyncContentVersions";
import { initBranch } from "utils/initBranch";

// ----------------------------------------------------------------------

export default function App() {
  const { isInitialized } = useAuth();
  useSyncContentVersions();
  if (webClientId === undefined) {
    throw new Error("Google Web Client Id required but not configured");
  }
  initBranch();

  return (
    <ThemeConfig>
      <ThemePrimaryColor>
        <ThemeLocalization>
          <RtlLayout>
            <NotistackProvider>
              <GlobalStyles />
              <ProgressBarStyle />
              <ScrollToTop />
              <GoogleOAuthProvider clientId={webClientId}>
                {isInitialized ? <Router /> : <LoadingScreen />}
              </GoogleOAuthProvider>
            </NotistackProvider>
          </RtlLayout>
        </ThemeLocalization>
      </ThemePrimaryColor>
    </ThemeConfig>
  );
}
