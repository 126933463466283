import { Box, CircularProgress, Typography, Tooltip, Button, Stack } from "@mui/material";
import React from "react";

import { MultilingualLocalesTypes } from "../../../types/translations";

type Props = {
  processedMultilingualLocales?: MultilingualLocalesTypes[];
  processedLocalesSize: number;
  targetLocalesSize: number;
  onSwitchToFormView?: () => void;
}


export default function MultiLingualGenerationProgress({
                                                         processedMultilingualLocales,
                                                         processedLocalesSize,
                                                         targetLocalesSize,
                                                         onSwitchToFormView
                                                       }: Props) {

  return <Box>
    <Tooltip
      placement="top"
      title={ `Processed MultiLingual Locales are ${ processedMultilingualLocales?.map(({ name }) => name).join(", ") }` }
      arrow>
      <Box>
        <Box display='flex' justifyContent='center' alignItems='center' sx={ { mt: 6 } }>
          <CircularProgress size={ "5rem" }/>
          <Typography position='absolute'>{ processedLocalesSize } / { targetLocalesSize }</Typography>
        </Box>
        <Typography sx={ { display: 'flex', justifyContent: 'center' } }>Generating Multilingual Content...</Typography>
      </Box>
    </Tooltip>
    { Boolean(onSwitchToFormView) && (<Stack sx={ { display: 'flex', justifyContent: 'center' } }>
      <Button variant="text" onClick={ onSwitchToFormView }>Switch To Form View</Button>
    </Stack>)
    }
  </Box>
}
