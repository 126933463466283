
import React from "react";
import { useLocation, useParams } from "react-router-dom";
// material
import {Container} from "@mui/material";
// hooks
import useSettings from "../../../../hooks/useSettings";
// components
import Page from "../../../../components/Page";
import HeaderBreadcrumbs from "../../../../components/HeaderBreadcrumbs";
import LoadingScreen from "../../../../components/LoadingScreen";
import { useGetRelationshipById } from "hooks/useGetRelationshipById";
import RelationshipsForm from "./RelationshipsForm";
// ----------------------------------------------------------------------

export default function CreateChineseUserRelationship() {
  const { themeStretch } = useSettings();
  const { pathname } = useLocation();
  const { id } = useParams();
  const isEdit = pathname.includes("edit");
  const isView = !isEdit && pathname.includes("view");
  const isNew = !isEdit && !isView && pathname.includes("new");
  const { relationship, loading } = useGetRelationshipById(id ?? "", isNew);

  let pageTitle = isEdit ? "Edit Relationship" : "Create a new Relationship";
  const isLoading = loading;

  return (
    <Page title={pageTitle}>
      <Container maxWidth={themeStretch ? false : "lg"}>
        <HeaderBreadcrumbs heading={pageTitle} links={[]} />

        {Boolean(isLoading) && <LoadingScreen />}

        {!Boolean(isLoading) && (
          <RelationshipsForm
            isEdit={isEdit}
            isNew={isNew}
            relationshipData={relationship}
          />
        )}
      </Container>
    </Page>
  );
}

