import React from "react";
import { Autocomplete, TextField, Typography } from "@mui/material";
import { connectHits, connectSearchBox, Hits } from "react-instantsearch-dom";

import { AppSideTranslations } from "../../types/translations";
import { isObject } from "lodash";

type AlgoliaTextFieldProps = {
  disabled?: boolean;
  value: string;
  label: string;
  onChangeValue: (hits: Hits<AppSideTranslations>, value: string) => void;
  onSelectValue: (value: string) => void;
  helperText?: any;
  multiline?: boolean;
  sx?: object;
  hits: Hits<AppSideTranslations> | any;
  showEnglishValueInSearch?: boolean;
  refine?: (args: string) => void;
};

const AlgoliaTextField = ({
  disabled,
  value,
  label,
  onChangeValue,
  onSelectValue,
  helperText,
  multiline,
  sx,
  hits,
  showEnglishValueInSearch,
  refine,
}: AlgoliaTextFieldProps) => {
  return (
    <>
      <Autocomplete
        disableClearable
        options={
          (value?.length && !disabled ? hits ?? [] : []) as Array<
            AppSideTranslations | string
          >
        }
        freeSolo
        value={value as string}
        getOptionLabel={(option) => {
          const optionLabel = option as AppSideTranslations;
          return optionLabel?.key ?? optionLabel?.valueInEN?? value;
        }}
        onChange={(event, newValue: AppSideTranslations | string | any) => {
          if (typeof newValue === "string") {
            onSelectValue(newValue);
            return;
          }

          onSelectValue(newValue?.key);
        }}
        sx={sx ?? { width: "100%" }}
        filterOptions={(options) => {
          return options.filter((option) => {
            const optionValue = (option as AppSideTranslations)?.valueInEN;
            return !isObject(optionValue);
          });
        }}
        renderOption={(props, option) => {
          const optionKey = (option as AppSideTranslations).key;
          const optionValue = (option as AppSideTranslations)?.valueInEN;
          const enValue = isObject(optionValue)
            ? JSON.stringify(optionValue)
            : optionValue;
          return (
            <li {...props}>
              Edit Existing Translation:&nbsp;
              <Typography
                component="span"
                style={{
                  color: "blue",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={() =>
                  onSelectValue((option as AppSideTranslations).key)
                }
              >
                {showEnglishValueInSearch ? enValue : optionKey}
              </Typography>
            </li>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            onChange={(event) => {
              refine?.(event.currentTarget.value);
              onChangeValue(hits, event.currentTarget.value);
            }}
            multiline={multiline}
            label={label}
            disabled={disabled}
          />
        )}
      />

      {Boolean(helperText) && (
        <Typography
          fontSize={12}
          sx={{ mt: "6px !important", ml: "10px !important" }}
          className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-1fgbinu-MuiFormHelperText-root"
        >
          {helperText}
        </Typography>
      )}
    </>
  );
};

/*
 * The types for connectHits and connectSearchBox are not fully compatible with AlgoliaTextField,
 * causing type inference issues. Using // @ts-ignore as a temporary workaround until
 * a better solution or type definitions are available.
 */
// @ts-ignore
export default connectHits(connectSearchBox(AlgoliaTextField));
