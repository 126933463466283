import React from "react";
import { Icon } from "@iconify/react";
import searchFill from "@iconify/icons-eva/search-fill";
// material
import { styled, useTheme } from "@mui/material/styles";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormLabel,
  InputAdornment,
  OutlinedInput,
  Radio,
  RadioGroup,
  Toolbar,
  Typography,
} from "@mui/material";
import { connectHits, connectSearchBox, Hits } from "react-instantsearch-dom";
import { AppSideTranslations } from "../../../types/translations";
import { TranslationStatus } from "./AppSideTranslationQuickEdit";
import FormControl from "@mui/material/FormControl";

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: "flex",
  justifyContent: "space-between",
  padding: theme.spacing(0, 1, 0, 3),
}));

const searchBarWidth = 380;
const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: searchBarWidth,
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  "&.Mui-focused": {
    width: searchBarWidth + 30,
    boxShadow: theme.customShadows.z8,
  },
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

// ----------------------------------------------------------------------

type TableToolbarProps = {
  numSelected: number;
  filterName: string;
  onFilterName: (value: string) => void;
  handleKeyDown?: (
    hits: Hits<AppSideTranslations>
  ) => (event: React.KeyboardEvent<HTMLInputElement>) => void;
  searchPlaceholder?: string;
  showSearchBar?: boolean;
  onChange?: (status: string) => void;
  onSelectOptionValue?: (value: string) => void;
  selectedOptionValue?: string;
  selectFieldLabel?: string;
  rightIcon?: () => JSX.Element;
  options?: Array<string>;
  hits: Hits<AppSideTranslations> | any;
  refine?: (args: string) => void;
  translationPriority: string;
  approvalStatus: string;
  onApprovalStatusChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onPriorityStatusChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setIsEmptyDescription?: (isEmptyDescription: boolean) => void;
  isEmptyDescription?: boolean;
};

function QuickEditTranslationToolbar({
  numSelected,
  filterName,
  handleKeyDown,
  onFilterName,
  searchPlaceholder = "Type here to search",
  onSelectOptionValue,
  options,
  showSearchBar = true,
  hits,
  refine,
  approvalStatus,
  translationPriority,
  onApprovalStatusChange,
  onPriorityStatusChange,
  setIsEmptyDescription,
  isEmptyDescription,
}: TableToolbarProps) {
  const theme = useTheme();
  const isLight = theme.palette.mode === "light";

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <RootStyle
        sx={{
          ...(numSelected > 0 && {
            color: isLight ? "primary.main" : "text.primary",
            bgcolor: isLight ? "primary.lighter" : "primary.dark",
          }),
        }}
      >
        {numSelected > 0 ? (
          <Typography component="div" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <>
            {Boolean(showSearchBar) && (
              <SearchStyle
                value={filterName}
                onChange={(e) => {
                  onFilterName(e.target.value);
                  refine?.(e.target.value);
                }}
                onKeyDown={handleKeyDown?.(hits)}
                placeholder={searchPlaceholder}
                startAdornment={
                  <InputAdornment position="start">
                    <Box
                      component={Icon}
                      icon={searchFill}
                      sx={{ color: "text.disabled" }}
                    />
                  </InputAdornment>
                }
              />
            )}
          </>
        )}
      </RootStyle>

      {Boolean(options?.length) && Boolean(onSelectOptionValue) && (
        <Box sx={{ display: "flex", flex: 1, mx: 2, mb: 2, alignItems: "flex-end", justifyContent: 'space-between'}}>
          <FormControl>
            <FormLabel id="approved-controlled-radio-buttons-group">
              Approved Status
            </FormLabel>
            <RadioGroup
              name="controlled-radio-buttons-group-1"
              value={approvalStatus}
              onChange={onApprovalStatusChange}
            >
              <Box sx={{ display: "flex", flex: 1 }}>
                <FormControlLabel
                  value={TranslationStatus.ALL}
                  control={<Radio />}
                  label={TranslationStatus.ALL}
                />
                <FormControlLabel
                  value={TranslationStatus.APPROVED}
                  control={<Radio />}
                  label="Approved"
                />
                <FormControlLabel
                  value={TranslationStatus.UNAPPROVED}
                  control={<Radio />}
                  label="UnApproved"
                />
              </Box>
            </RadioGroup>
          </FormControl>

          <FormControl sx={{ ml: 10 }}>
            <FormLabel id="priority-controlled-radio-buttons-group">
              Priority
            </FormLabel>
            <RadioGroup
              name="controlled-radio-buttons-group-2"
              value={translationPriority}
              onChange={onPriorityStatusChange}
            >
              <Box sx={{ display: "flex", flex: 1 }}>
                <FormControlLabel
                  value={TranslationStatus.ALL}
                  control={<Radio />}
                  label={TranslationStatus.ALL}
                />
                <FormControlLabel
                  value={TranslationStatus.CRITICAL}
                  control={<Radio />}
                  label={TranslationStatus.CRITICAL}
                />
                <FormControlLabel
                  value={TranslationStatus.HIGH}
                  control={<Radio />}
                  label={TranslationStatus.HIGH}
                />
                <FormControlLabel
                  value={TranslationStatus.MEDIUM}
                  control={<Radio />}
                  label={TranslationStatus.MEDIUM}
                />
                <FormControlLabel
                  value={TranslationStatus.LOW}
                  control={<Radio />}
                  label={TranslationStatus.LOW}
                />
              </Box>
            </RadioGroup>
          </FormControl>

          <Typography variant="body2">
            <Checkbox
              checked={isEmptyDescription}
              onChange={() => setIsEmptyDescription?.(!isEmptyDescription)}
              sx={{ ml: "10px" }}
            />
            Empty Description
          </Typography>
        </Box>
      )}
    </Box>
  );
}

/*
 * The types for connectHits and connectSearchBox are not fully compatible with AlgoliaTextField,
 * causing type inference issues. Using // @ts-ignore as a temporary workaround until
 * a better solution or type definitions are available.
 */
// @ts-ignore
export default connectHits(connectSearchBox(QuickEditTranslationToolbar));
