import { useEffect, useState } from 'react';

import useAuth from './useAuth';
import { LastChange } from '../types/changelog/changelog.types';

/**
 * custom hook to get the `lastChange` object, for admin operations.
 * @return {LastChange}, the `lastChange` object is required for changelogs.
 * it has two keys, 1. `performedBy` which is the id of user performing the change.
 * and 2. `service`, that is used to identify from where this operation is performed,
 * it could one one of OpServices ('cloud function' | 'kyan-app-directly' | 'kyan-admin-directly')
 */
export function useLastChange(): LastChange {
  const [lastChange, setLastChange] = useState<LastChange>({
    performedBy: 'not-identifiable',
    service: 'kyan-admin-directly'
  });
  const { user } = useAuth();

  useEffect(() => {
    if (user?.uid) {
      setLastChange({
        performedBy: user?.uid,
        service: 'kyan-admin-directly'
      });
    }
  }, [user]);

  return lastChange;
}
