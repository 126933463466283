import { Container } from "@mui/material";
import HeaderBreadcrumbs from "components/HeaderBreadcrumbs";
import Page from "components/Page";
import useSettings from "hooks/useSettings";
import ParentModulesFormContainer from "components/_dashboard/modules-content/ParemtModulesFormContainer";

export default function AddEditParentModulesContent() {
  const { themeStretch } = useSettings();

  return (
    <Page title="Add Edit Journey Table">
      <Container maxWidth={themeStretch ? false : "lg"}>
        <HeaderBreadcrumbs heading={"Parent Modules content"} links={[]} />

        <ParentModulesFormContainer />
      </Container>
    </Page>
  );
}
