import {
  CounsellorSearchFilter,
  FilterData,
} from "../../../types/CounsellorSearchFilters";
import { useCollection } from "react-firebase-hooks/firestore";
import firebase from "firebase/app";
import { collections } from "utils/constants";

function useCounsellorSearchFilters() {
  const [snapshot] = useCollection<FilterData>(
    firebase.firestore().collection(collections.COUNSELLOR_SEARCH_FILTERS)
  );
  if (!snapshot) {
    return undefined;
  }

  return snapshot.docs.map(CounsellorSearchFilter.fromSnapshot);
}

export default useCounsellorSearchFilters;
