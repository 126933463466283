
import React from "react";
import { useLocation, useParams } from "react-router-dom";
// material
import {Container} from "@mui/material";
// hooks
import useSettings from "../../../../hooks/useSettings";
// components
import Page from "../../../../components/Page";
import HeaderBreadcrumbs from "../../../../components/HeaderBreadcrumbs";
import LoadingScreen from "../../../../components/LoadingScreen";
import { useGetRelationshipById } from "hooks/useGetRelationshipById";
import { useGetRelationshipSessionById } from "hooks/useGetRelationshipSessionById";
import SessionsForm from "./SessionsForm";
// ----------------------------------------------------------------------

export default function CreateRelationshipSession() {
  const { themeStretch } = useSettings();
  const { pathname } = useLocation();
  const { id, sessionId } = useParams();
  const isEdit = pathname.includes("edit");
  const { relationship, loading } = useGetRelationshipById(id ?? "");
  const { session } = useGetRelationshipSessionById(id, sessionId ?? "");
  const pageTitle = isEdit ? "Edit Session" : "Create a new Session";
  const isLoading = loading;

  return (
    <Page title={pageTitle}>
      <Container maxWidth={themeStretch ? false : "lg"}>
        <HeaderBreadcrumbs heading={pageTitle} links={[]} />

        {Boolean(isLoading) && <LoadingScreen />}

        {!Boolean(isLoading) && (
          <SessionsForm
            isEdit={isEdit}
            relationshipData={relationship}
            sessionData={session}
          />
        )}
      </Container>
    </Page>
  );
}

