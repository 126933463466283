import GroupProgressOverview from "./GroupProgressOverview";
import OverallGroupProgress from "./OverallGroupProgress";
import React, {useEffect, useState} from "react";
import {Box, Typography} from "@mui/material";
import {styled} from "@mui/material/styles";
import {useTranslation} from "react-i18next";

import {CounsellorProgressAssessmentQuestion, GroupProgressInsightsData} from "../../types/CounsellorProgressDasboard";
import LoadingScreen from "../LoadingScreen";
import {useParams} from "react-router-dom";
import { retrieveLastCounsellorProgress} from "../../utils/firebase/counsellorProgressUtils";
import { getTherapistById } from "utils/firebase/therapistUtils";
import { Therapist } from "types/therapists";
import AssessmentQuestionsDropdown from "./AssessmentQuestionsDropdown";

const LoadingContainer = styled(Box)(({theme}) => ({
    paddingTop: theme.spacing(15),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}));

const CounsellorName = styled(Typography)(({ theme }) => ({
    fontSize: 20,
    fontWeight: 700,
    color: theme.palette.blue.dark,
  }));

type Props = {
    showProgressOverview: CounsellorProgressAssessmentQuestion;
    setShowProgressOverview: (
        showProgressOverview: CounsellorProgressAssessmentQuestion
    ) => void;
};
export default function GroupProgressContentContainer({
                                                          showProgressOverview,
                                                          setShowProgressOverview,
                                                      }: Props) {
    const {id: therapistId} = useParams();
    const [groupProgressAssessmentResult, setGroupProgressAssessmentResult] = useState<GroupProgressInsightsData>()
    const [error, setError] = useState<Error>()
    const [loading, setLoading] = useState<boolean>(true)
    const [therapist, setTherapist] = useState<Therapist>();

    const [modalOpen, setModalOpen] = useState<boolean>(false);

    useEffect(() => {
        if (!therapist && therapistId) {
            getTherapistById(therapistId)
            .then((t) => {
                setTherapist(t);
            })
        }
    }, [therapist, therapistId]);

    useEffect(() => {
        if (!therapistId) {
            return
        }

        setLoading(true)
        retrieveLastCounsellorProgress(therapistId).then((progressData) => {
            if (progressData) {
                setGroupProgressAssessmentResult(progressData);
            }
        }).catch((e) => {
            console.error(e)
            setError(e)
        }).finally(() => {
            setLoading(false)
        })
    }, [therapistId]);

    const {t} = useTranslation();

    if (loading) {
        return (
            <LoadingContainer>
                <LoadingScreen/>
            </LoadingContainer>
        );
    }

    if (error) {
        return (
            <LoadingContainer>
                <Typography>{t("somethingWentWrong")}</Typography>
            </LoadingContainer>
        );
    }

    if (!groupProgressAssessmentResult) {
        return (
            <LoadingContainer>
                <Typography>{t("noProgressFound")}</Typography>
            </LoadingContainer>
        );
    }

    return (
        <>
            <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                <CounsellorName >Counsellor: {therapist?.name}</CounsellorName>

                <AssessmentQuestionsDropdown
                value={showProgressOverview}
                onChange={setShowProgressOverview}
                />
            </Box>

            <GroupProgressOverview
                groupProgressAssessmentResult={groupProgressAssessmentResult}
                selectedAssessment={showProgressOverview}
                modalOpen={modalOpen}
                closeModal={() => setModalOpen(false)}
            />

            <OverallGroupProgress
                groupProgressAssessmentResult={groupProgressAssessmentResult}
                setModalOpen={() => setModalOpen(true)}
                setShowProgressOverview={setShowProgressOverview}
            />
        </>
    );
}
