import React from 'react';
import { Stack, TextField } from '@mui/material';

import { LanguageSelectionProps, SupportedLanguages } from '../types/invitation/code';
import { localeToLowerCase } from 'utils/firebase/multilingualLocaleUtils';

export const MULTI_LINGUAL_OPTION = "multilingual";

export function LanguageSelectionDropDown({
  fieldName,
  selectedValue,
  onSelectionChanged,
  label = 'select language',
  showMultiLingualOption,
  localeList,
  disabled,
  skipMultiLingualSelection
}: LanguageSelectionProps) {

  return (
    <Stack spacing={ 1 }>
      <TextField
        select
        label={ label }
        placeholder={ label }
        disabled={disabled}
        value={ selectedValue }
        onChange={ (e) => onSelectionChanged(fieldName, e.target.value as SupportedLanguages) }
        SelectProps={ { native: true } }
      >
        <option value="en">English</option>
          {!Boolean(showMultiLingualOption) && <>
              <option value="de">German</option>
              <option value="fr">French</option>
              <option value="es">Spanish</option>
          </>
          }
        { Boolean(showMultiLingualOption) && !skipMultiLingualSelection && <option value={ MULTI_LINGUAL_OPTION }>MultiLingual</option> }
        { Boolean(showMultiLingualOption) && Boolean(localeList?.length) && localeList?.map((locale) => <option
          key={ locale.locale } value={ localeToLowerCase(locale.locale) }>{ locale.name } {!skipMultiLingualSelection ? '(MultiLingual)': null}</option>) }
      </TextField>
    </Stack>
  );
}
