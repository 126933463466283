import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
// material
import { Box, Container } from "@mui/material";
// hooks
import useSettings from "../../../hooks/useSettings";
// components
import Page from "../../../components/Page";
import HeaderBreadcrumbs from "../../../components/HeaderBreadcrumbs";
import SnippetForm from "../../../components/_dashboard/snippets/SnippetForm";
import { Snippet } from "../../../types/snippet";
import { getSnippetById } from "../../../utils/snippetsUtils";

// ----------------------------------------------------------------------

export default function CreateSnippet() {
  const { themeStretch } = useSettings();
  const { pathname = "" } = useLocation();
  const { state } = useLocation();
  const { id: snippetID } = useParams();

  const [snippet, setSnippet] = useState<Snippet>(state as Snippet);

  const [loading, setLoading] = useState(false);

  const isEdit = pathname?.includes("edit");
  const isView = pathname?.includes("view");
  const isNew = pathname?.includes("new");

  const fetchSnippet = useCallback(() => {
    if (snippetID && !loading) {
      setLoading(true);
      getSnippetById(snippetID)
        .then((newSnippet) => {
          setSnippet(newSnippet);
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [snippetID, loading]);

  useEffect(() => {
    fetchSnippet();
  }, [fetchSnippet]);

  let pageTitle = "Create a new Snippet";
  if (isView) {
    pageTitle = "View Snippet";
  } else if (isEdit) {
    pageTitle = "Edit Snippet";
  }

  return (
    <Page title={pageTitle}>
      <Container maxWidth={themeStretch ? false : "lg"}>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="flex-start"
          justifyContent="space-between"
          height={50}
        >
          <HeaderBreadcrumbs heading={pageTitle} links={[]} />
        </Box>

        <SnippetForm
          isEdit={isEdit}
          isView={isView}
          isNew={isNew}
          snippet={snippet}
        />
      </Container>
    </Page>
  );
}
