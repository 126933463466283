import {Container} from "@mui/material";
import HeaderBreadcrumbs from "components/HeaderBreadcrumbs";
import Page from "components/Page";
import useSettings from "hooks/useSettings";
import FilterContentContainer from "./FilterContentContainer";

export default function AddEditFilterContent() {
  const { themeStretch } = useSettings();

  return (
    <Page title="Filter content">
      <Container maxWidth={themeStretch ? false : "lg"}>
        <HeaderBreadcrumbs heading={"Filter content for AI Prompts"} links={[]} />

        <FilterContentContainer />
      </Container>
    </Page>
  );
}
