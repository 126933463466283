const Compass = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
    >
      <path
        d="M12.5013 20.362C17.1037 20.362 20.8346 16.631 20.8346 12.0286C20.8346 7.42627 17.1037 3.69531 12.5013 3.69531C7.89893 3.69531 4.16797 7.42627 4.16797 12.0286C4.16797 16.631 7.89893 20.362 12.5013 20.362Z"
        stroke="#1F1E5F"
        strokeWidth="1.22"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.0315 8.49609L14.2648 13.7961L8.96484 15.5628L10.7315 10.2628L16.0315 8.49609Z"
        stroke="#1F1E5F"
        strokeWidth="1.22"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Compass;
