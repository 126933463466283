// This is only a helper for type declarations. Do not export
// and use this, as it's not properly implemented.
type GoogleApiError = {
  code: string;
  message: string;
};

/**
 * Ensure a given object offers the same properties as an Error thrown by the
 * firebase-sdk in response to an API Exception.
 */
export function isGoogleApiError(obj: unknown | any): obj is GoogleApiError {
  return (
    typeof obj?.code === "string" &&
    typeof obj.message === "string"
  );
}
