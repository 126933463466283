import { Container } from "@mui/material";
import HeaderBreadcrumbs from "components/HeaderBreadcrumbs";
import Page from "components/Page";
import ColourPalettesFormContainer from "components/_dashboard/colour-palettes/ColourPalettesFormContainer";
import useSettings from "hooks/useSettings";

export default function AddEditContentColourPalette() {
  const { themeStretch } = useSettings();

  return (
    <Page title="Add Edit Content Colour Palettes">
      <Container maxWidth={themeStretch ? false : "lg"}>
        <HeaderBreadcrumbs heading={"Content Colour Palettes"} links={[]} />

        <ColourPalettesFormContainer />
      </Container>
    </Page>
  );
}
