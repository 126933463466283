import React, {useState} from "react";
import TextField from "@mui/material/TextField";

import Page from "../../components/Page";
import Card from "@mui/material/Card";
import Toolbar from "@mui/material/Toolbar";
import {styled} from "@mui/material/styles";
import Container from "@mui/material/Container";
import useSettings from "hooks/useSettings";
import {Box,} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import {getUserInfoFunction} from "../../utils/firebase/cloudFunctions";
import {UserSearchResult} from "../../types/user";

const ToolbarStyled = styled(Toolbar)(({theme}) => ({
    display: "flex",
    justifyContent: "space-between",
}));

const StyledCard = styled(Card)(({theme}) => ({
    padding: theme.spacing(2),
}));


export default function FindUserInfo() {
    const {themeStretch} = useSettings();
    const [query, setQuery] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const [userInfo, setUserInfo] = useState<UserSearchResult>();

    const onSearch = () => {
        setLoading(true);
        getUserInfoFunction({query}).then((res) => {
            setUserInfo(res.data);
        }).catch(() => {
           setUserInfo(undefined);
        }).finally(() => {
            setLoading(false)
        })
    }

    return (
        <Page title="User Search">
            <Container maxWidth={themeStretch ? false : "lg"}>
                <StyledCard>
                    <ToolbarStyled>
                        <TextField
                            value={query}
                            onChange={(e) => setQuery(e.target.value)}
                            label="Search by user ID or Email"
                            fullWidth
                            InputProps={{
                                type: "search",
                            }}
                        />

                        <LoadingButton sx={{ml: 1}} loading={loading} onClick={onSearch} variant="contained">
                            Search
                        </LoadingButton>
                    </ToolbarStyled>



                    {userInfo && <Box>
                        <TextField
                            sx={{
                                mt: 2
                            }}
                            fullWidth
                            label="UId"
                            value={userInfo?.uid}
                            disabled
                        />

                        <TextField
                            sx={{
                                mt: 2
                            }}
                            fullWidth
                            label="Email"
                            value={userInfo?.email}
                            disabled
                        />

                        <TextField
                            sx={{
                                mt: 2
                            }}
                            fullWidth
                            label="Invitation Code"
                            value={userInfo?.invitationCode}
                            disabled
                        />

                        <TextField
                            sx={{
                                mt: 2
                            }}
                            fullWidth
                            label="Credits Used"
                            value={userInfo?.creditsUsed}
                            disabled
                        />

                        <TextField
                            sx={{
                                mt: 2
                            }}
                            fullWidth
                            label="Remaining Credits"
                            value={userInfo?.remainingCredits}
                            disabled
                        />
                    </Box> }
                </StyledCard>
            </Container>
        </Page>
    );
}
