import React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Box from "@mui/material/Box";

interface CustomBulletedListProps {
  items: string[];
}

const CustomBulletedList: React.FC<CustomBulletedListProps> = ({ items }) => {
  return (
    <List
      component="nav"
      aria-label="migration steps"
      sx={{ py: 1, margin: 0 }}
    >
      {items.map((text, index) => (
        <ListItem
          key={index}
          sx={{
            display: "flex",
            alignItems: "center",
            py: 0,
          }}
        >
          {/* Custom bullet */}
          <Box
            component="span"
            sx={{
              width: "5px",
              height: "5px",
              borderRadius: "50%",
              backgroundColor: "currentColor",
              mr: 2,
            }}
          ></Box>
          <ListItemText primary={text} />
        </ListItem>
      ))}
    </List>
  );
};

export default CustomBulletedList;
