import React from 'react';
import {DemographicQuestion, DemographicQuestionsProps} from '../../../../types/invitation/code';
import {Box, Stack, TextField} from '@mui/material';
import AnswersInput from "./AnswersInput";
import InputWithDeleteButton from "./InputWithDeleteButton";

export default function QuestionsInput(props: DemographicQuestionsProps) {
    const {
        setFieldValue,
        getFieldProps,
        featureName,
        language,
        showAnswerEvents
    } = props
    const questions: DemographicQuestion[] = getFieldProps(featureName)?.value || [];

    const deleteQuestion = (questionId: string) => {
        const updatedQuestions = questions.filter(
            (question) => question.id !== questionId
        );
        setFieldValue(featureName, updatedQuestions);
    }

    return (
        <Stack spacing={3}>
            <Stack spacing={1}>
            </Stack>

            {questions?.map((item, index) => {
                const qFieldName = `demographicQuestions.[${language}][${index}]`;
                return (
                    <Box key={qFieldName}>
                        <InputWithDeleteButton
                            textInputKey={"question"}
                            showAnswerEvents={false}
                            inputFormKey={qFieldName}
                            getFieldProps={getFieldProps}
                            label={`Question# ${index + 1}`}
                            setFieldValue={setFieldValue}
                            onClickDelete={() => deleteQuestion(item.id)}/>

                        <TextField
                            fullWidth
                            sx={{
                                mt: 2
                            }}
                            label={"Mixpanel Question Index"}
                            {...getFieldProps(`${qFieldName}.mixpanelQuestionIndex`)}
                            type="number"
                        />

                        <AnswersInput
                            showAnswerEvents={showAnswerEvents}
                            questionIndex={index}
                            {...props}
                        />
                    </Box>
                );
            })}
        </Stack>
    );
}
