import DeleteIcon from "@mui/icons-material/Delete";
import EmailIcon from "@mui/icons-material/Email";
import MoneyIcon from "@mui/icons-material/Money";
import {
  Avatar,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import { UserIdentifier } from "../types/Identifier";
import { ValidationStatus } from "../types/ValidationStatus";
import ValidationIcon from "./ValidationIcon";

type Props = {
  identifier: UserIdentifier;
  validationStatus: ValidationStatus;
  onDelete: () => void;
};

export default function MigrationCandidateItem(props: Props) {
  const { identifier, validationStatus, onDelete } = props;

  let icon = null;
  let help = "";
  let identifierText = identifier.value;
  if (identifier.key === "email") {
    help = "This user is identified by their email";
    icon = <EmailIcon />;
  } else {
    help = "This is user is identified by their internal id";
    icon = <MoneyIcon />;
  }

  return (
    <ListItem
      divider
      title={help}
      secondaryAction={
        <IconButton onClick={() => onDelete()} edge="end" aria-label="delete">
          <DeleteIcon />
        </IconButton>
      }
    >
      <ListItemAvatar>
        <Avatar>{icon}</Avatar>
      </ListItemAvatar>
      <ListItemText primary={identifierText} />
      <ValidationIcon {...validationStatus} />
    </ListItem>
  );
}
