import firebase from "firebase";
import { SupportedLanguages } from "../../types/invitation/code";
import { SupportedLanguagesEnum } from "../constants";
import { useEffect, useState } from "react";
import { PrivacyPolicy, PrivacyPolicyId } from "../../types/privacyPolicies";

export function usePrivacyPolicyList(
  language: SupportedLanguages = SupportedLanguagesEnum.ENGLISH
) {
  const [loading, setLoading] = useState<boolean>(false);
  const [privacyPolicies, setPrivacyPolicies] = useState<Array<PrivacyPolicy>>(
    []
  );

  useEffect(() => {
    async function getPrivacyPolicies() {
      setLoading(true);
      const documentSnapshot = await firebase
        .firestore()
        .collection("privacy_policies")
        .doc(language)
        .get();

      if (!documentSnapshot.exists) {
        setPrivacyPolicies([]);
        setLoading(false);
      }

      const docData = documentSnapshot.data();
      if (!docData) {
        setLoading(false);
        return [];
      }

      const privacyPoliciesData: Array<PrivacyPolicy> = Object.keys(
        docData
      ).map((key) => ({
        ...docData[key],
        releasedAt: docData[key]?.releasedAt,
      }));

      const sortPrivacyPolicies = privacyPoliciesData.sort(
        (a: PrivacyPolicy, b: PrivacyPolicy) => {
          const dateA = new Date(a.releasedAt);
          const dateB = new Date(b.releasedAt);
          return dateB.getTime() - dateA.getTime();
        }
      );

      setPrivacyPolicies(sortPrivacyPolicies);
      setLoading(false);
    }

    getPrivacyPolicies();
  }, [language]);

  return { privacyPolicies, loading };
}

export async function getPrivacyPolicyById(
  id: PrivacyPolicyId,
  selectedLanguage: SupportedLanguages
): Promise<PrivacyPolicy> {
  const doc = await firebase
    .firestore()
    .collection(`privacy_policies`)
    .doc(selectedLanguage)
    .get();

  if (!doc.exists) {
    throw new Error("No privacy policy with this id exists");
  }

  const docData = doc.data();

  return docData ? docData[id] : {};
}

export const createOrUpdatePrivacyPolicy = async (
  doc: any,
  isCreate: boolean = false,
  selectedLanguage: SupportedLanguages
): Promise<void> => {
  const docName = doc?.id ?? "";
  await firebase
    .firestore()
    .collection("privacy_policies")
    .doc(selectedLanguage)
    .set(
      {
        [docName]: {
          ...doc,
        },
      },
      { merge: true }
    );
};

export const deletePrivacyPolicyById = async (
  privacyPolicyId: string,
  selectedLanguage: SupportedLanguages
): Promise<string> => {
  await firebase
    .firestore()
    .collection("privacy_policies")
    .doc(selectedLanguage)
    .update({ [privacyPolicyId]: firebase.firestore.FieldValue.delete() });
  return "deleted";
};
