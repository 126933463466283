import { useEffect, useState } from "react";

import { db } from "../contexts/FirebaseContext";
import { MultilingualLocalesTypes } from "../types/translations";
import { SupportedLanguages } from "../types/invitation/code";
import { ContentFromFirestore } from "../models/ContentUnit";
import { MULTI_LINGUAL_OPTION } from "../components/LanguageSelectionDropDown";

export default function useMultiLingualLocales(selectedLanguage: SupportedLanguages | string, id: string, collectionName: string, docId?: string) {
  const [targetMultilingualLocales, setTargetMultilingualLocales] = useState<Array<MultilingualLocalesTypes>>([]);
  const [processedMultilingualLocales, setProcessedMultilingualLocales] = useState<Array<MultilingualLocalesTypes>>([]);


  useEffect(() => {
    let unsub = () => {};
    const currentDocId = docId ?? MULTI_LINGUAL_OPTION

    if (!id  || !collectionName || selectedLanguage !== currentDocId) {
      setTargetMultilingualLocales([])
      setProcessedMultilingualLocales([])
      return () => unsub()
    }

    unsub = db
      .collection(collectionName)
      .doc(currentDocId)
      .onSnapshot(async (snapshot) => {
        const content = snapshot.data() as Record<string, Partial<ContentFromFirestore>>
        setTargetMultilingualLocales(content?.[id]?.targetMultilingualLocales ?? [])
        setProcessedMultilingualLocales(content?.[id]?.processedMultilingualLocales ?? [])
      });
    return () => unsub();
  }, [id, collectionName, selectedLanguage, docId])

  return {
    targetMultilingualLocales,
    processedMultilingualLocales,
  }
}