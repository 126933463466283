import { useEffect, useRef } from "react";
import { Box } from "@mui/material";
import QRCode from "qrcode";

type Props = {
  url: string;
  fileName: string;
};

export default function QRCodeFromDeepLink({ url, fileName }: Props) {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    if (canvasRef.current) {
      QRCode.toCanvas(canvasRef.current, url, { scale: 10 });
    }
  }, [url]);

  const handleDownload = () => {
    if (canvasRef.current) {
      const link = document.createElement("a");
      link.download = `${fileName}.png`;
      link.href = canvasRef.current.toDataURL();
      link.click();
    }
  };

  return (
    <Box display={"flex"} justifyContent={"center"} sx={{ my: 2 }}>
      <div onClick={handleDownload} style={{ cursor: "pointer" }}>
        <canvas ref={canvasRef} />
      </div>
    </Box>
  );
}
