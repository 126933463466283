import {
  Checkbox,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import CounsellorSearchFilters, {
  FilterOption,
} from "../../../../types/CounsellorSearchFilters";
import { FieldInputProps, FormikErrors, FormikValues } from "formik";
import { keyBy } from "lodash";
import useCounsellorSearchFilters from "pages/dashboard/therapist/useCounsellorSearchFilters";
import { useState } from "react";

const EXCLUSIVE_SITUATIONS: FilterOption[] = [
  { value: "101", label: "Finance (101)" },
  { value: "102", label: "Legal (102)" },
  { value: "103", label: "Nutrition (103)" },
  { value: "104", label: "CS Corporate Health Management (104)" },
];

const EXCLUSIVE_SITUATIONS_MAP: Record<string, string> = {};
EXCLUSIVE_SITUATIONS.forEach(({ label, value }) => {
  EXCLUSIVE_SITUATIONS_MAP[value] = label;
});

export function ExclusiveSituations({
  setFieldValue,
  getFieldProps,
}: {
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<FormikErrors<FormikValues>> | Promise<void>;
  getFieldProps: (nameOrOptions: any) => FieldInputProps<any>;
}) {
  const [, setSelectedSituations] = useState<string[]>([]);
  const selectedSituations = getFieldProps("exclusiveSituations").value;
  const exclusiveFilters = CounsellorSearchFilters.of(
    useCounsellorSearchFilters()
  )
    .exclusiveFilters()
    .toFilterOptions();

  const exclusiveFiltersMap = keyBy(exclusiveFilters, "value");

  const handleChange = (
    event: SelectChangeEvent<typeof selectedSituations>
  ) => {
    const {
      target: { value },
    } = event;
    // On autofill we get a stringified value.
    const newValue = typeof value === "string" ? value.split(",") : value;
    setSelectedSituations(newValue);
    setFieldValue("exclusiveSituations", newValue);
  };

  return (
    <Select
      sx={{ width: "90%" }}
      renderValue={(selected: string[]) => {
        return selected
          .map((value: string) => {
            // ? can be removed once we delete backwards compatibility
            return exclusiveFiltersMap[value]?.label ?? value;
          })
          .join(", ");
      }}
      multiple
      onChange={handleChange}
      value={selectedSituations}
    >
      {EXCLUSIVE_SITUATIONS.concat(exclusiveFilters).map(({ label, value }) => (
        <MenuItem key={value} value={value}>
          <Checkbox checked={selectedSituations.indexOf(value) > -1} />
          <ListItemText primary={label} />
        </MenuItem>
      ))}
    </Select>
  );
}
