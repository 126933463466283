import {Typography} from "@mui/material";
import EditCallTime from "./EditCallTime";
import React from "react";

type Props = {
    callTime?: Date | number;
    therapistTimezone: string;
    userTimezone: string;
    onChangeCallTime: (availableTimes: Date | number) => void;
}

export default function EditSelectedCallTime({callTime, onChangeCallTime, therapistTimezone, userTimezone}: Props){
    return (
        <div>
            <Typography fontWeight={"bold"}>Call time</Typography>
            {callTime ? <EditCallTime
                    therapistTimezone={therapistTimezone}
                    userTimezone={userTimezone}
                    date={new Date(callTime)}
                    onChange={(newDate) => {
                        if (newDate) {
                            onChangeCallTime(newDate);
                        }
                    }}
                /> :
                <Typography textAlign={"center"}>---</Typography>
            }
        </div>
    )
}