const Chart = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
    >
      <path
        d="M20.8346 12.0312H17.5013L15.0013 19.5312L10.0013 4.53125L7.5013 12.0312H4.16797"
        stroke="#1F1E5F"
        strokeWidth="1.22"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Chart;
