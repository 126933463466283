import firebase from "firebase";
import { Revision } from "../types/Revision";

export const RevisionConverter: firebase.firestore.FirestoreDataConverter<Revision> =
  {
    fromFirestore: (doc: firebase.firestore.QueryDocumentSnapshot) => {
      const docData = doc.data();
      return {
        ...docData,
        timestamp: (docData.timestamp as firebase.firestore.Timestamp).toDate(),
        id: doc.id,
      } as Revision;
    },
    toFirestore: () => {
      throw new Error("Not implemented");
    },
  };
