import { Container, Drawer, IconButton, styled } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";

const DefinitionTitle = styled("dt")({
  fontWeight: "bold",
});

const DefinitionDescription = styled("dd")({
  fontStyle: "italic",
  paddingBottom: "16px",
});

export default function HelpContent(props: {
  open: boolean;
  close: () => void;
}) {
  const { open, close } = props;
  return (
    <Drawer anchor="right" open={open} variant="temporary">
      <Container maxWidth="xs">
        <IconButton onClick={close} title="Close Help">
          <CancelIcon />
        </IconButton>
        <h3>Changelog Help</h3>
        <dl>
          <DefinitionTitle>Live document</DefinitionTitle>
          <DefinitionDescription>
            A live document is the document that is read by clients.
          </DefinitionDescription>
          <DefinitionTitle>Revision</DefinitionTitle>
          <DefinitionDescription>
            A revision is a snapshot of a previous live document version. The
            current data of a live document is usually not available as
            snapshot.
          </DefinitionDescription>
          <DefinitionTitle>Checksum</DefinitionTitle>
          <DefinitionDescription>
            The checksum is calculated based on the revision content. The same
            content will lead to the same checksum. Different revisions can
            share the same checksum (e.g. after rolling back changes).
          </DefinitionDescription>
        </dl>
        <h3>Restoring a previous version</h3>
        <p>
          Reverting to a previous version will write data from the selected
          revision to the live document. As a consequence the live document will
          be stored as revision.
        </p>
        <h3>Rollback document creation</h3>
        <p>Will delete the live document</p>
        <h3>Restoring a live document</h3>
        <p>
          Data for a live document is usually not stored as revision. If you
          want to recover that data you'll need to restore a backup.
        </p>
        <h3>Content of a revision</h3>
        <p>
          We never store the live version of a document. That's why a revision
          for new files doesn't track a value and a revision marking a document
          as deleted is not <em>null</em>, but refers to the previous content.
        </p>
        <h3>Change created</h3>
        <p>
          Refers to moment the revision was created but doesn't match the
          creation of the content. Example: On Monday a document is changed, a
          revision is written. On Wednesday the document is changed again. A
          second revision is written. The second revision contains the content
          from Monday, but uses the timestamp from Wednesday.
        </p>
      </Container>
    </Drawer>
  );
}
