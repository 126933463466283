import * as React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { Box } from "@mui/material";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function FilterByMenu({
  onfilterByChanged = () => {},
  filterableColumnNames = [],
}: {
  onfilterByChanged: (newFilterBy: Array<string>) => void;
  filterableColumnNames: Array<string>;
}) {
  const [selectedFilterableColumnNames, setSelectedFilterableColumnNames] =
    React.useState<Array<string>>(filterableColumnNames);

  const handleChange = (
    event: SelectChangeEvent<typeof selectedFilterableColumnNames>
  ) => {
    const {
      target: { value },
    } = event;

    // On autofill we get a stringified value.
    const newFilterBy = typeof value === "string" ? value.split(",") : value;
    setSelectedFilterableColumnNames(newFilterBy);
    onfilterByChanged(newFilterBy);
  };

  return (
    <Box>
      <FormControl sx={{ m: 1, width: 300 }}>
        <InputLabel id="demo-multiple-checkbox-label">Filter By</InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={selectedFilterableColumnNames}
          onChange={handleChange}
          input={<OutlinedInput label="Filter By" />}
          renderValue={(selected) => selected.join(", ")}
          MenuProps={MenuProps}
        >
          {filterableColumnNames.map((filterByColumn) => (
            <MenuItem key={filterByColumn} value={filterByColumn}>
              <Checkbox
                checked={
                  selectedFilterableColumnNames.indexOf(filterByColumn) > -1
                }
              />
              <ListItemText primary={filterByColumn} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
