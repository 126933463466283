import {
    Button,
    Card,
    CardContent,
    CircularProgress,
    Collapse,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    useTheme,
} from "@mui/material";
import { useGetRelationshipSessions } from "hooks/useGetRelationshipSessions";
import HeaderBreadcrumbs from "components/HeaderBreadcrumbs";
import { Link as RouterLink } from "react-router-dom";
import { Icon } from "@iconify/react";
import plusFill from "@iconify/icons-eva/plus-fill";
import { PATH_DASHBOARD } from "routes/paths";
import SessionsMoreMenu from "./SessionsMoreMenu";
import { getAttendStatus } from "utils/videoCallsUtils";

export default function RelationshipSubTable(props: {
    isOpen: boolean;
    rowId: string;
    handleDeleteSession: (relationshipId: string, sessionId: string) => void;
  }) {
  const theme = useTheme();

  const {
    loading,
    error,
    sessions,
  } = useGetRelationshipSessions(props.rowId);

  if (error) {
    return (
      <TableRow>
        <Typography>There was an error loading the data.</Typography>
      </TableRow>
    );
  }
    
    return (
      <TableRow>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
          }}
          colSpan={6}
        >
          <Collapse in={props.isOpen} timeout="auto">
            <Card
              sx={{
                ml: 3,
                mr: 3,
                border: `1px dashed ${theme.palette.primary.main}`,
                boxShadow: `5px 10px ${theme.palette.grey}`,
              }}
            >
              <CardContent>
              <HeaderBreadcrumbs
                  heading=""
                  links={[]}
                  action={
                    <>
                      <Button
                        style={{ marginLeft: 30, color: "white" }}
                        variant="contained"
                        color="primary"
                        component={RouterLink}
                        to={`${PATH_DASHBOARD.therapist.chineseUserRelationships.list}/${props.rowId}/new-session`}
                        startIcon={<Icon icon={plusFill} />}
                      >
                        Add Session
                      </Button>
                    </>
                  }
                />
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  {loading && (
                    <TableRow>
                      <TableCell colSpan={2} align="center">
                        <CircularProgress />
                      </TableCell>
                    </TableRow>
                  )}
                  {!loading && sessions.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={2}>
                        <Typography align="center">No sessions found.</Typography>
                      </TableCell>
                    </TableRow>
                  )}
                    {sessions.map((session) => {
                      return (
                        <TableRow key={session.id}>
                          <TableCell component="th" scope="row">
                            {session.date.toDate().toString()}
                          </TableCell>
                          <TableCell>{getAttendStatus(session.status)}</TableCell>
                          <TableCell>
                            <SessionsMoreMenu 
                              onDelete={() => props.handleDeleteSession(props.rowId, session.id)} 
                              relationshipId={props.rowId} 
                              sessionId={session.id}                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </CardContent>
            </Card>
          </Collapse>
        </TableCell>
      </TableRow>
    );
  }
