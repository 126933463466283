import { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  Stack,
} from "@mui/material";
import { Icon } from "@iconify/react";
import { ModuleWithCustomisedFields } from "models/ContentUnit";

type Props = {
  currentChildren: ModuleWithCustomisedFields[];
  setNewChildrenOrder: (
    currentChildren: (
      prevOrder: ModuleWithCustomisedFields[]
    ) => ModuleWithCustomisedFields[]
  ) => void;
};

function ReorderCustomisedChildrenButton({
  currentChildren,
  setNewChildrenOrder,
}: Props) {
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [draggedIndex, setDraggedIndex] = useState<number | null>(null);

  const handleSortStart = (index: number) => {
    setDraggedIndex(index);
  };

  const handleSortEnd = (index: number) => {
    setDraggedIndex(null);

    setNewChildrenOrder((prevOrder: ModuleWithCustomisedFields[]) => {
      const updatedChildren = Array.from(prevOrder);
      const [movedItem] = updatedChildren.splice(draggedIndex!, 1);
      updatedChildren.splice(index, 0, movedItem);
      return updatedChildren;
    });
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <Stack
        sx={{
          mt: 1,
          justifyContent: "flex-end",
          alignItems: "flex-end",
        }}
      >
        <Button
          color="success"
          variant="contained"
          endIcon={
            <Icon icon={"fluent:arrow-sort-20-regular"} color={"success"} />
          }
          onClick={() => setOpenDialog(true)}
        >
          Change order
        </Button>
      </Stack>

      <Dialog open={openDialog} onClose={handleClose} scroll={"paper"}>
        <DialogTitle>Reorder children modules by drag and drop</DialogTitle>
        <DialogContent>
          <List>
            <Box mt={"m"}>
              {currentChildren.map((value, index) => (
                <ListItem
                  key={value.contentId}
                  draggable
                  onDragStart={() => handleSortStart(index)}
                  onDragOver={(e) => e.preventDefault()}
                  onDrop={() => handleSortEnd(index)}
                >
                  <Box display={"flex"} mt={"s"}>
                    <Icon
                      icon={"ion:reorder-three-outline"}
                      width={25}
                      height={25}
                    />
                    <ListItemText
                      primary={value.contentId}
                      style={{ marginLeft: 10 }}
                    />
                  </Box>
                </ListItem>
              ))}
            </Box>
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ReorderCustomisedChildrenButton;
