import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import plusFill from "@iconify/icons-eva/plus-fill";
import deleteIcon from "@iconify/icons-eva/trash-2-outline";
import { Link as RouterLink, useNavigate } from "react-router-dom";
// material
import {
  Button,
  Card,
  Container,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";

// routes
import { PATH_DASHBOARD } from "../../../routes/paths";

// hooks
import useSettings from "../../../hooks/useSettings";

// components
import Page from "../../../components/Page";
import Scrollbar from "../../../components/Scrollbar";
import SearchNotFound from "../../../components/SearchNotFound";
import HeaderBreadcrumbs from "../../../components/HeaderBreadcrumbs";
import TableToolbar from "../../../components/common/TableToolbar";
import TableHeader from "../../../components/common/TableHeader";
import MoreMenu from "../../../components/common/MoreMenu";
import { filter } from "lodash";
import { Anonymous } from "../../../types/list";
import { AIPrompt } from "../../../types/aiPrompts";
import { useGetAIPrompts } from "../../../hooks/useGetAIPrompts";
import DeleteConfirmationDialog from "components/_dashboard/content/DeleteConfirmationDialog";
import { useSnackbar } from "notistack";
import LoadingScreen from "../../../components/LoadingScreen";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "id", label: "Id", alignRight: false },
  { id: "content", label: "Content", alignRight: false },
  { id: "model", label: "Model", alignRight: false },
  { id: "temperature", label: "Temperature", alignRight: false },
  { id: "actions", label: "Actions", alignRight: true },
];

// ----------------------------------------------------------------------

function applySortFilter(
  array: AIPrompt[],
  comparator: (a: any, b: any) => number,
  query: string
) {
  const stabilizedThis = array.map((el, index) => [el, index] as const);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (group) => group.id.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

function descendingComparator(a: Anonymous, b: Anonymous, orderBy: string) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order: string, orderBy: string) {
  return order === "desc"
    ? (a: Anonymous, b: Anonymous) => descendingComparator(a, b, orderBy)
    : (a: Anonymous, b: Anonymous) => -descendingComparator(a, b, orderBy);
}

let AIPromptsPersistState = {
  showArchivePrompts: false,
};

export default function AIPromptsList() {
  const { themeStretch } = useSettings();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [selected, setSelected] = useState<string[]>([]);
  const [orderBy, setOrderBy] = useState("name");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [promptToDelete, setPromptToDelete] = useState<string | null>(null);
  const [showArchivePrompts, setShowArchivePrompts] = useState<boolean>(
    AIPromptsPersistState.showArchivePrompts
  );
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();

  const {
    activeAIPrompts,
    archivedAIPrompts = [],
    loading,
    deletePromptAndUpdate,
  } = useGetAIPrompts();

  const aiPrompts = showArchivePrompts ? archivedAIPrompts : activeAIPrompts;

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (checked: boolean) => {
    if (checked) {
      const newSelecteds = aiPrompts?.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    return () => {
      AIPromptsPersistState = { showArchivePrompts };
    };
  }, [showArchivePrompts]);

  const handleFilterByName = (SelectedFilterName: string) => {
    setFilterName(SelectedFilterName);
  };

  const handleOpenDeleteDialog = (promptId: string) => {
    setPromptToDelete(promptId);
    setOpenDeleteDialog(true);
  };

  const handleDelete = async () => {
    let successMessage = "Prompt Archived Successfully";
    let errorMessage = "Error Archiving Prompt";

    if (showArchivePrompts) {
      successMessage = "Prompt UnArchived Successfully";
      errorMessage = "Error UnArchiving Prompt";
    }

    try {
      setLoadingDelete(true);
      await deletePromptAndUpdate(promptToDelete!);
      enqueueSnackbar(successMessage, { variant: "success" });
    } catch (error) {
      enqueueSnackbar(errorMessage, { variant: "error" });
    } finally {
      setLoadingDelete(false);
      setOpenDeleteDialog(false);
    }
  };

  function togglePromptDisplay() {
    setShowArchivePrompts((prev) => !prev);
  }

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - aiPrompts.length) : 0;

  const filteredGroup = applySortFilter(
    aiPrompts,
    getComparator(order, orderBy),
    filterName
  );

  function onEdit(id: string, row: AIPrompt) {
    navigate(`${PATH_DASHBOARD.aiPrompts.root}/${id}/edit`, {
      state: row,
    });
  }

  const isGroupNotFound = filteredGroup.length === 0 && !loading;

  let deleteButtonText = "Archive";
  let archiveButtonText = "Show Archived Prompts";
  let deleteDialogTitle = "You are about to archive this item";
  let deleteButtonIcon = <Icon icon={deleteIcon} />;

  if (showArchivePrompts) {
    deleteDialogTitle = "You are about to un-archive this item";
    deleteButtonText = "UnArchive";
    archiveButtonText = "Show Active Prompts";
    deleteButtonIcon = <Icon icon="fluent:delete-off-24-regular" />;
  }

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <Page title="AI Prompts">
      <Container maxWidth={themeStretch ? false : "lg"}>
        <HeaderBreadcrumbs
          heading=""
          links={[]}
          action={
            <>
              <Button
                variant={showArchivePrompts ? "contained" : "outlined"}
                sx={{ mr: 2 }}
                onClick={togglePromptDisplay}
                startIcon={deleteButtonIcon}
              >
                {archiveButtonText}
              </Button>

              <Button
                variant="contained"
                component={RouterLink}
                to={PATH_DASHBOARD.aiPrompts.new}
                startIcon={<Icon icon={plusFill} />}
              >
                Add New Prompt
              </Button>
            </>
          }
        />

        <Card>
          <TableToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            searchPlaceholder={"Search"}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <TableHeader
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={aiPrompts.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                  hideCheckbox
                />
                <TableBody>
                  {filteredGroup
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row: AIPrompt) => {
                      const { id, content = "", model, temperature } = row;
                      const isItemSelected = selected.indexOf(id) !== -1;

                      return (
                        <TableRow
                          hover
                          key={id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            padding="normal"
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                            >
                              <Typography variant="subtitle2" noWrap>
                                {id}
                              </Typography>
                            </Stack>
                          </TableCell>

                          <TableCell
                            component="th"
                            scope="row"
                            padding="normal"
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                            >
                              <Typography variant="body1" noWrap>
                                {content.substring(0, 50)}
                              </Typography>
                            </Stack>
                          </TableCell>

                          <TableCell
                            component="th"
                            scope="row"
                            padding="normal"
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                            >
                              <Typography variant="body1" noWrap>
                                {model}
                              </Typography>
                            </Stack>
                          </TableCell>

                          <TableCell
                            component="th"
                            scope="row"
                            padding="normal"
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                            >
                              <Typography variant="body1" noWrap>
                                {temperature}
                              </Typography>
                            </Stack>
                          </TableCell>

                          <TableCell align="right">
                            <MoreMenu
                              deleteButtonIcon={deleteButtonIcon}
                              deleteButtonText={deleteButtonText}
                              onEdit={
                                showArchivePrompts
                                  ? undefined
                                  : () => onEdit(id, row)
                              }
                              onView={() => {
                                navigate(
                                  `${PATH_DASHBOARD.aiPrompts.root}/${id}/view`,
                                  {
                                    state: row,
                                  }
                                );
                              }}
                              onDelete={() => handleOpenDeleteDialog(id)}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isGroupNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={aiPrompts.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(e, _page) => setPage(_page)}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          <DeleteConfirmationDialog
            open={openDeleteDialog}
            loading={loadingDelete}
            dialogTitle={deleteDialogTitle}
            dialogDescription={"Are you sure you want to proceed?"}
            deleteButtonText={deleteButtonText}
            onClose={() => {
              setPromptToDelete(null);
              setOpenDeleteDialog(false);
            }}
            handleDelete={handleDelete}
          />
        </Card>
      </Container>
    </Page>
  );
}
