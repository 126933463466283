import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";

// slices
import userReducer from "./slices/user";
import invitationReducer from "./slices/invitation";
import contentVersions from "./slices/contentVersions";
import { renewalChallengeSlice } from "./slices/renewalChallengeSlice";

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: "root",
  storage,
  keyPrefix: "redux-",
  whitelist: [],
};

const rootReducer = combineReducers({
  user: userReducer,
  contentVersions: contentVersions,
  invitation: invitationReducer,
  [renewalChallengeSlice.reducerPath]: renewalChallengeSlice.reducer,
});

export { rootPersistConfig, rootReducer };
