import { Theme } from "@mui/material/styles/createTheme";
import { TFunction } from "react-i18next";
import { getColorForScore } from "./counsellorDashboardUtils";
import { ApexOptions } from "apexcharts";
import { max } from "lodash";
import { CounsellorProgressAssessmentQuestion } from "types/CounsellorProgressDasboard";
import { REVERSE_SCORE_QUESTIONS } from "./constants";

export const getChartGradientColorStops = (theme: Theme) => {
  return [
    {
      offset: 0,
      color: theme.palette.wellbeing.darkGreen,
      opacity: 1,
    },
    {
      offset: 10,
      color: theme.palette.wellbeing.darkGreen,
      opacity: 1,
    },
    {
      offset: 20,
      color: theme.palette.wellbeing.lightGreen,
      opacity: 1,
    },
    {
      offset: 30,
      color: theme.palette.wellbeing.lightGreen,
      opacity: 1,
    },
    {
      offset: 40,
      color: theme.palette.wellbeing.lightOrange,
      opacity: 1,
    },
    {
      offset: 50,
      color: theme.palette.wellbeing.lightOrange,
      opacity: 1,
    },
    {
      offset: 60,
      color: theme.palette.wellbeing.darkOrange,
      opacity: 1,
    },
    {
      offset: 70,
      color: theme.palette.wellbeing.darkOrange,
      opacity: 1,
    },
    {
      offset: 80,
      color: theme.palette.wellbeing.darkOrange,
      opacity: 1,
    },
    {
      offset: 90,
      color: theme.palette.wellbeing.darkOrange,
      opacity: 1,
    },
    {
      offset: 100,
      color: theme.palette.wellbeing.darkOrange,
      opacity: 1,
    },
  ];
};

export const getChartGradientColorStopsForReversedQuestions = (
  theme: Theme
) => {
  return [
    {
      offset: 0,
      color: theme.palette.wellbeing.darkOrange,
      opacity: 1,
    },
    {
      offset: 10,
      color: theme.palette.wellbeing.darkOrange,
      opacity: 1,
    },
    {
      offset: 20,
      color: theme.palette.wellbeing.darkOrange,
      opacity: 1,
    },
    {
      offset: 30,
      color: theme.palette.wellbeing.darkOrange,
      opacity: 1,
    },
    {
      offset: 40,
      color: theme.palette.wellbeing.darkOrange,
      opacity: 1,
    },
    {
      offset: 50,
      color: theme.palette.wellbeing.lightOrange,
      opacity: 1,
    },
    {
      offset: 60,
      color: theme.palette.wellbeing.lightOrange,
      opacity: 1,
    },
    {
      offset: 70,
      color: theme.palette.wellbeing.lightGreen,
      opacity: 1,
    },
    {
      offset: 80,
      color: theme.palette.wellbeing.lightGreen,
      opacity: 1,
    },
    {
      offset: 90,
      color: theme.palette.wellbeing.darkGreen,
      opacity: 1,
    },
    {
      offset: 100,
      color: theme.palette.wellbeing.darkGreen,
      opacity: 1,
    },
  ];
};

const getColorStopsWithOffsetsForChart = (
  theme: Theme,
  selectedAssessment: CounsellorProgressAssessmentQuestion
) => {
  if (REVERSE_SCORE_QUESTIONS.includes(selectedAssessment)) {
    return getChartGradientColorStopsForReversedQuestions(theme);
  }

  return getChartGradientColorStops(theme);
};

export function formatUsersCountChartData(
  theme: Theme,
  assessmentsCountByUsersForAllSessions: number[][],
  t: TFunction,
  maxCount: number
) {
  const maxResult = max(assessmentsCountByUsersForAllSessions?.flat()) || 0;
  const maxYValue = maxResult > 10 ? maxResult : 10;

  const xAxisLabels = [];
  for (let sessionCount = 0; sessionCount < maxCount; sessionCount++) {
    xAxisLabels.push(`${t("Assessment")} ${sessionCount + 1}`);
  }

  const options = {
    chart: {
      type: "bar",
      height: 350,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "75%",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 0,
      colors: ["transparent"],
    },
    xaxis: {
      categories: xAxisLabels,
    },
    colors: [
      theme.palette.wellbeing.darkOrange,
      theme.palette.wellbeing.lightOrange,
      theme.palette.wellbeing.lightGreen,
      theme.palette.wellbeing.darkGreen,
    ],
    fill: {
      opacity: 1,
    },
    yaxis: {
      min: 0,
      max: maxYValue,
      tickAmount: maxYValue,
      labels: {
        formatter: function (val: number = 0) {
          // Show value in integer
          return val.toFixed(0);
        },
      },
    },
  };
  return {
    series: assessmentsCountByUsersForAllSessions.map(
      (assessmentsCountByUsersForAllSession) => {
        return {
          name: "",
          data: assessmentsCountByUsersForAllSession,
        };
      }
    ),
    options: options as ApexOptions,
  };
}

export const formatChartData = (
  theme: Theme,
  data: Array<number>,
  xAxisLabels: Array<string>,
  yAxisMin: number,
  yAxisMax: number,
  selectedAssessment: CounsellorProgressAssessmentQuestion,
  firstSessionWithResult?: number
) => {
  const colorStops = getColorStopsWithOffsetsForChart(
    theme,
    selectedAssessment
  );

  const markerPoints = data.map((assessmentScore, index) => {

    let color;
    let fillColor;

    if(!firstSessionWithResult || index < firstSessionWithResult) {
       color = "transparent"
       fillColor = "transparent"
    }else{

      const calculatedColor = getColorForScore(
        Math.round(assessmentScore / (yAxisMax === 100 ? 10 : 1)),
        selectedAssessment
      );

       color = theme.palette.wellbeing[calculatedColor]
       fillColor = theme.palette.common.white
    }

    return {
      x: xAxisLabels[index],
      y: assessmentScore,
      marker: {
        size: 6,
        strokeColor: color,
        strokeWidth: 3,
        fillColor: fillColor,
      },
    };
  });

  const options = {
    chart: {
      height: 350,
    },
    stroke: {
      width: 5,
      curve: "smooth",
    },
    xaxis: {
      type: "number",
      categories: xAxisLabels,
      tickAmount: data.length,
      labels: {
        formatter: function (value: string) {
          return value;
        },
        style: {
          fontSize: "9px",
          fontWeight: 400,
        },
      },
    },
    annotations: {
      points: markerPoints,
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        type: "vertical",
        opacityFrom: 0.9,
        opacityTo: 0.7,
        colorStops: colorStops,
      },
    },
    yaxis: {
      min: yAxisMin,
      max: yAxisMax,
    },
  };

  return {
    series: [
      {
        name: "",
        data,
      },
    ],
    options: options as ApexOptions,
  };
};
