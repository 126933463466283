import { Autocomplete, TextField, createFilterOptions } from "@mui/material";
import { ThemeId } from "models/ContentUnit";
import { useNavigate } from "react-router";
import { PATH_DASHBOARD } from "routes/paths";

type Props = {
  setSelectedTheme: (ThemeId: ThemeId) => void;
  themeOptions: Array<Record<string, string>>;
  defaultValue?: Record<string, string>;
};

export default function ThemeAutoComplete({
  setSelectedTheme,
  themeOptions,
  defaultValue,
}: Props) {
  const navigate = useNavigate();

  const filter = createFilterOptions<Record<string, string>>();

  return (
    <Autocomplete
      id="contentUnitAutoComplete"
      options={Object.values(themeOptions)}
      defaultValue={defaultValue}
      getOptionLabel={(option) => option?.id}
      renderOption={(props, option) => (
        <li {...props}>
          {option?.title} {option?.id}
        </li>
      )}
      onChange={(_, value) => {
        if (!value) {
          return;
        }

        setSelectedTheme(value?.id);

        navigate(`${PATH_DASHBOARD.contentThemes.root}/${value?.id}`);
      }}
      sx={{ width: 300 }}
      renderInput={(params) => (
        <TextField {...params} label="Search for themes by ID" />
      )}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        if (params?.inputValue !== "") {
          filtered.push({
            id: params?.inputValue,
            title: `Add "${params?.inputValue}"`,
          });
        }

        return filtered;
      }}
    />
  );
}
