import { FocusError } from "focus-formik-error";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import { Form, FormikProvider, useFormik } from "formik";
import TextField from "@mui/material/TextField";
import { CreditsPeriodsFormSchema } from "components/_dashboard/invitation/schemas/InvitationCode";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useCreditPeriods from "./hooks/useCreditPeriods";

const CreditPeriodsFormDialog: React.FC = () => {
  const {
    values: initialValues,
    handleSubmit: onSubmit,
    handleClose,
    formDialogIsOpen,
  } = useCreditPeriods();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: CreditsPeriodsFormSchema,
    onSubmit,
  });

  const {
    errors,
    values,
    touched,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    getFieldProps,
  } = formik;

  return (
    <Dialog maxWidth="md" open={formDialogIsOpen} onClose={handleClose}>
      <FormikProvider value={formik}>
        <Form noValidate onSubmit={handleSubmit}>
          <Grid>
            <DialogTitle>Create </DialogTitle>
          </Grid>
          <DialogContent>
            <fieldset style={{ border: "none" }}>
              <FocusError formik={formik} />
              <Grid item xs={12}>
                <Stack spacing={2}>
                  <TextField
                    focused
                    type="number"
                    variant="standard"
                    label="Allowed credits limit by user"
                    {...getFieldProps("allowedCredits")}
                    error={Boolean(
                      touched.allowedCredits && errors.allowedCredits
                    )}
                    helperText={touched.allowedCredits && errors.allowedCredits}
                  />
                  <Stack>
                    <FormControlLabel
                      labelPlacement="start"
                      label="Active"
                      sx={{
                        width: 1,
                        mx: 0,
                        justifyContent: "space-between",
                      }}
                      control={
                        <Switch
                          checked={values.active}
                          onChange={(event) =>
                            setFieldValue("active", event.target.checked)
                          }
                        />
                      }
                    />
                    <FormControlLabel
                      labelPlacement="start"
                      label="Unlimited Credits"
                      sx={{
                        width: 1,
                        mx: 0,
                        justifyContent: "space-between",
                      }}
                      control={
                        <Switch
                          checked={values.unlimitedCredits}
                          onChange={(event) =>
                            setFieldValue(
                              "unlimitedCredits",
                              event.target.checked
                            )
                          }
                        />
                      }
                    />
                  </Stack>
                </Stack>
              </Grid>
            </fieldset>
            <DialogActions>
              <LoadingButton
                fullWidth
                type="submit"
                variant="contained"
                loading={isSubmitting}
              >
                Save
              </LoadingButton>
            </DialogActions>
          </DialogContent>
        </Form>
      </FormikProvider>
    </Dialog>
  );
};

export default CreditPeriodsFormDialog;
