import { Box } from "@mui/material";
import { pick } from "lodash";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useGetThemeContent from "../../../hooks/useGetThemeContent";
import ThemeAutoComplete from "./ThemeAutoComplete";
import ThemeContentForm from "./ThemeContentForm";
import { deleteTheme } from "utils/firebase/contentUtils";
import useGetColourPalettes from "hooks/useGetColourPalettes";

export default function ThemesFormContainer() {
  const [selectedTheme, setSelectedTheme] = useState<string>();

  const themeContent = useGetThemeContent();
  const colourPalettes = useGetColourPalettes();

  const { themeId } = useParams();

  useEffect(() => {
    if (themeId && themeId !== selectedTheme) {
      setSelectedTheme(themeId);
    }
  }, [selectedTheme, themeId]);

  if (!themeContent) {
    return null;
  }

  const themeOptions = Object.values(themeContent)?.map((theme) => {
    return pick(theme, ["id", "title"]) as Record<string, string>;
  });

  const defaultValue = themeOptions.find(
    (theme) => theme?.id === selectedTheme
  );

  const data = themeContent?.find((theme) => theme?.id === themeId);

  const handleDeleteTheme = async (id: string): Promise<void> => {
    await deleteTheme(id);
    setSelectedTheme("")
  };

  const colourPalettesOptions = colourPalettes?.map((palette) => {
    return palette.id
  });

  return (
    <Box>
      <ThemeAutoComplete
        setSelectedTheme={setSelectedTheme}
        themeOptions={themeOptions}
        defaultValue={defaultValue}
      />

      <ThemeContentForm
        data={data}
        themeId={themeId}
        deleteTheme={handleDeleteTheme}
        colourPalettesOptions={colourPalettesOptions ?? []}
      />
    </Box>
  );
}
