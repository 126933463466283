import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';

// utils
import { InvitationCode } from '../../types/invitation/code';
import { filter } from 'lodash';

// ----------------------------------------------------------------------

type InvitationState = {
  isLoading: boolean;
  error: boolean;
  codes: (InvitationCode | undefined)[];
};

const initialState: InvitationState = {
  isLoading: false,
  error: false,
  codes: []
};

const slice = createSlice({
  name: 'invitation',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET INVITATION CODES
    getCodesSuccess(state, action) {
      state.isLoading = false;
      state.codes = action.payload;
    },

    // DELETE USERS
    deleteCode(state, action) {
      state.codes = filter(state.codes, (code) => code?.code !== action.payload);
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { deleteCode, getCodesSuccess, startLoading, hasError } = slice.actions;

// ----------------------------------------------------------------------

export function getInvitationCodes() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      // const response = await axios.get('/api/user/all');
      dispatch(getCodesSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
