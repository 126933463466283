import {useEffect, useState} from "react";
import {useLocation, useParams} from "react-router-dom";
// material
import {Container} from "@mui/material";
// hooks
import useSettings from "../../hooks/useSettings";
// components
import Page from "../../components/Page";
import HeaderBreadcrumbs from "../../components/HeaderBreadcrumbs";
import {PaymentLink} from "../../types/invitation/code";
import NewPaymentLinkForm from "../../components/_dashboard/payment-links/NewPaymentLinkForm";
import {getSpecificPaymentLink} from "../../utils/firebase/paymentLinksUtils";
import LoadingScreen from "../../components/LoadingScreen";

// ----------------------------------------------------------------------

export default function CreateNewPaymentLink() {
  const { themeStretch } = useSettings();
  const { pathname, state } = useLocation();
  const {id: linkId} = useParams();
  const isEdit = pathname.includes("edit");
  const isView = pathname.includes("view");
  const [loading, setLoading] = useState<boolean>(false);

  const [selectedPaymentLink, setSelectedPaymentLink] = useState<PaymentLink | undefined>(state as PaymentLink);

  useEffect(() => {
    if (!selectedPaymentLink && linkId) {
      setLoading(true)
      getSpecificPaymentLink(linkId)
          .then(async (paymentLink) => {
            setSelectedPaymentLink(paymentLink);
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            setLoading(false)
          });
    }
  }, [linkId, selectedPaymentLink]);





  let heading = "Create a new payment link";
  if (isEdit) {
    heading = "Edit payment link";
  } else if (isView) {
    heading = "View payment link";
  }

  if(loading){
    return <LoadingScreen/>
  }

  return (
    <Page title="Create a new code">
      <Container maxWidth={themeStretch ? false : "lg"}>
        <HeaderBreadcrumbs heading={heading} links={[]} />
        <NewPaymentLinkForm
            isEdit={isEdit}
            isView={isView}
            paymentLink={selectedPaymentLink}
        />
      </Container>
    </Page>
  );
}
