import { Box } from "@mui/material";
import { pick } from "lodash";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useGetColourPalettes from "hooks/useGetColourPalettes";
import ColourPaletteAutoComplete from "./ColourPaletteAutoComplete";
import ColourPaletteContentForm from "./ColourPaletteContentForm";
import { deleteColourPalette } from "utils/firebase/contentUtils";

export default function ColourPalettesFormContainer() {
  const [selectedColourPalette, setSelectedColourPalette] = useState<string>();
  const colourPalettes = useGetColourPalettes();

  const { colourPaletteId } = useParams();

  useEffect(() => {
    if (colourPaletteId && colourPaletteId !== selectedColourPalette) {
      setSelectedColourPalette(colourPaletteId);
    }
  }, [colourPaletteId, selectedColourPalette]);

  if (!colourPalettes) {
    return null;
  }

  const colourPaletteOptions = Object.values(colourPalettes)?.map((colourPalette) => {
    return pick(colourPalette, ["id"]) as Record<string, string>;
  });

  const defaultValue = colourPaletteOptions.find(
    (colourPalette) => colourPalette?.id === selectedColourPalette
  );

  const data = colourPalettes?.find((colourPalette) => colourPalette?.id === colourPaletteId);

  const handleDeleteColourPalette = async (id: string): Promise<void> => {
    await deleteColourPalette(id);
    setSelectedColourPalette("")
  };

  return (
    <Box>
      <ColourPaletteAutoComplete
        setSelectedColourPalette={setSelectedColourPalette}
        colourPaletteOptions={colourPaletteOptions}
        defaultValue={defaultValue}
      />

      <ColourPaletteContentForm
        data={data}
        colourPaletteId={colourPaletteId}
        deleteColourPalette={handleDeleteColourPalette}/>
    </Box>
  );
}
