import { Box, Card, CardContent, Divider, Typography } from "@mui/material";
import ReactApexChart from "react-apexcharts";
import WellbeingColorInfo from "./WellbeingColorInfo";
import React, { ReactNode } from "react";
import { styled, Theme } from "@mui/material/styles";
import { ApexOptions } from "apexcharts";
import { SxProps } from "@mui/system";
import {CounsellorProgressAssessmentQuestion} from "../../types/CounsellorProgressDasboard";

const Header = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  display: "flex",
  justifyContent: "space-between",
}));

const Title = styled(Typography)(({ theme }) => ({
  fontSize: 18,
  fontWeight: 700,
  color: theme.palette.blue.dark,
}));

const SubTitle = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 400,
  mt: theme.spacing(2),
  color: theme.palette.blue.dark,
}));

type Props = {
  title: string;
  subTitle: string;
  series?: ApexOptions["series"];
  options?: ApexOptions;
  showScoresInfo?: boolean;
  type: "line" | "bar";
  chatsCount?: number;
  sx?: SxProps<Theme>;
  headerRight: ReactNode;
  selectedAssessment: CounsellorProgressAssessmentQuestion;
};
export default function GroupProgressChart({
  title,
  subTitle,
  options,
  type,
  series,
  showScoresInfo,
  headerRight,
  sx,
                                             selectedAssessment
}: Props) {
  return (
    <Card sx={sx}>
      <CardContent>
        <Header>
          <Title>{title}</Title>
          {headerRight}
        </Header>

        <Divider sx={{ mb: 1 }} />

        <ReactApexChart
          options={options}
          series={series}
          type={type}
          height={300}
        />

        <SubTitle>{subTitle}</SubTitle>

        <WellbeingColorInfo
            selectedAssessment={selectedAssessment} showScoresInfo={showScoresInfo}
          showInReverseOrder
        />
      </CardContent>
    </Card>
  );
}
