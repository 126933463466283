import React from "react";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";

// material
import { LoadingButton } from "@mui/lab";
import { Box, Card, Grid, Stack, TextField } from "@mui/material";

import { SupportedLanguages } from "../../../types/invitation/code";

import { PrivacyPolicy } from "../../../types/privacyPolicies";
import { createOrUpdatePrivacyPolicy } from "../../../utils/firebase/PrivacyPolicyUtils";
import { format } from "date-fns";

// ----------------------------------------------------------------------

type PrivacyPolicyNewFormProps = {
  isEdit: boolean;
  isView?: boolean;
  isNew?: boolean;
  selectedLanguage: SupportedLanguages;
  privacyPolicy: PrivacyPolicy;
};

export default function PrivacyPolicyNewForm({
  isNew,
  isView,
  isEdit,
  selectedLanguage,
  privacyPolicy,
}: PrivacyPolicyNewFormProps) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const PrivacyPolicySchema = Yup.object().shape({
    id: Yup.string().required(),
    locale: Yup.string().required(),
    title: Yup.string().required(),
    privacyPolicy: Yup.string().required(),
    summary: Yup.string().required(),
    releasedAt: Yup.date().required("Policy date and time is required."),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: privacyPolicy?.id ?? "",
      locale: selectedLanguage,
      title: privacyPolicy?.title ?? "",
      summary: privacyPolicy?.summary ?? "",
      privacyPolicy: privacyPolicy?.privacyPolicy ?? "",
      releasedAt: privacyPolicy ?
        format(new Date(privacyPolicy.releasedAt), "yyyy-MM-dd'T'kk:mm:ss") :
        "",
    },

    validationSchema: PrivacyPolicySchema,
    onSubmit: async (values) => {
      let successMessage = "Successfully saved changes";
      let errorMessage = "Failed to save changes";
      if (isNew) {
        successMessage = "Privacy Policy Created Successfully";
        errorMessage = "Failed to create privacy policy";
      }
      try {
        const finalValues = {
          ...(values || {}),
          releasedAt: new Date(values?.releasedAt ?? "").toISOString(),
        };

        await createOrUpdatePrivacyPolicy(
          { ...finalValues },
          !isEdit,
          selectedLanguage
        );
        navigate(-1);
        enqueueSnackbar(successMessage, { variant: "success" });
      } catch (e) {
        console.error("FailedToCreatePrivacyPolicy", e);
        enqueueSnackbar(errorMessage, { variant: "error" });
      }
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  let submitBtnTitle = "Create Privacy Policy";
  if (isView) {
    submitBtnTitle = "View Only";
  } else if (isEdit) {
    submitBtnTitle = "Save Changes";
  }

  return (
    <FormikProvider value={formik}>
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <fieldset disabled={isView} style={{ border: "none" }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card sx={{ p: 3 }}>
                <Stack spacing={3}>
                  <TextField
                    fullWidth
                    label="Id"
                    {...getFieldProps("id")}
                    error={Boolean(touched.id && errors.id)}
                    helperText={touched.id && errors.id}
                  />

                  <TextField
                    fullWidth
                    label="title"
                    {...getFieldProps("title")}
                    error={Boolean(touched.title && errors.title)}
                    helperText={touched.title && errors.title}
                  />

                  <TextField
                    fullWidth
                    multiline={true}
                    minRows={4}
                    maxRows={5}
                    label="Summary markdown"
                    {...getFieldProps("summary")}
                    error={Boolean(touched.summary && errors.summary)}
                    helperText={touched.summary && errors.summary}
                  />

                  <TextField
                    fullWidth
                    multiline={true}
                    minRows={4}
                    maxRows={5}
                    label="Privacy Policy markdown"
                    {...getFieldProps("privacyPolicy")}
                    error={Boolean(
                      touched.privacyPolicy && errors.privacyPolicy
                    )}
                    helperText={touched.privacyPolicy && errors.privacyPolicy}
                  />

                  <TextField
                    fullWidth
                    label="Released at"
                    {...getFieldProps("releasedAt")}
                    error={Boolean(touched.releasedAt && errors.releasedAt)}
                    helperText={touched.releasedAt && errors.releasedAt}
                    type="datetime-local"
                  />

                  <Box
                    sx={{ mt: 3, display: "flex", justifyContent: "flex-end" }}
                  >
                    <LoadingButton
                      type="submit"
                      variant="contained"
                      loading={isSubmitting}
                    >
                      {submitBtnTitle}
                    </LoadingButton>
                  </Box>
                </Stack>
              </Card>
            </Grid>
          </Grid>
        </fieldset>
      </Form>
    </FormikProvider>
  );
}
