// Used to remove console logs from production builds
// This would more easily be done by using babel-plugin-transform-remove-console but since we're using create-react-app, we can't use babel plugins without ejecting the project config files or running CRA through third party packages.
const removeConsoleLogs = () => {
  if (process.env.NODE_ENV === 'production') {
    console.log = () => {};
    console.debug = () => {};
  }
};

export default removeConsoleLogs;
