import { Button, Grid, TextField } from "@mui/material";
import { useState } from "react";

type Props = {
  onAddUser: (input: string) => void;
};

export default function AddUserField(props: Props) {
  const { onAddUser } = props;
  const [nextUserIdentifier, setNextUserIdentifier] = useState<string>("");

  function wrappedAddUser() {
    onAddUser(nextUserIdentifier);
    setNextUserIdentifier("");
  }
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={11}>
          <TextField
            fullWidth
            label="Add user identifier"
            value={nextUserIdentifier}
            onChange={(event) => setNextUserIdentifier(event.target.value)}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                wrappedAddUser();
              }
            }}
          />
        </Grid>
        <Grid item xs={1} sx={{ display: "flex" }}>
          <Button variant="contained" onClick={wrappedAddUser}>
            Add
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
