import { useEffect, useState } from "react";
import { db } from "../contexts/FirebaseContext";

import { collections } from "utils/constants";
import {Template} from "../types/template";
import firebase from "firebase";

export function useGetTemplates() {
  const [loading, setLoading] = useState<boolean>(false);
  const [templates, setTemplate] = useState<Array<Template>>([]);

  useEffect(() => {
    async function getTemplates() {
      try {
        setLoading(true);
        const documentReference = await db
          .collection(collections.TEMPLATES).orderBy("createdOrUpdatedAt", "desc")
          .get();

        const templatesData = documentReference.docs.map((snapshot) => {
          const templateData =  snapshot.data()

          return {
            id: templateData.id,
            ...templateData,
            createdOrUpdatedAt:
                (templateData.createdOrUpdatedAt as firebase.firestore.Timestamp)?.toDate(),
          } as Template;
        });
        setTemplate(templatesData);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        console.error(e);
      }
    }

    getTemplates();
  }, []);

  const deleteTemplateAndUpdate = async (templateId: string) => {
    try {
      setLoading(true);
      await db.collection(collections.TEMPLATES).doc(templateId).delete();
      // Remove Template from local state
      const updatedTemplates = templates.filter((template) => template.id !== templateId);
      setTemplate(updatedTemplates);
    } catch (e) {
      console.error("FailedToDeleteTemplate", e);
    } finally {
      setLoading(false);
    }
  }

  return { templates, loading, deleteTemplateAndUpdate };
}
