import {Autocomplete, TextField} from "@mui/material";
import React from "react";
import {InvitationCode} from "../../../types/invitation/code";
import {useListCodes} from "../../../utils/firebase";

type Props = {
    value: string;
    onChangeValue: (value: string) => void;
}

export default function InvitationCodeAutocomplete({value, onChangeValue}: Props) {
    const invitationCodes: InvitationCode[] = useListCodes();
    const codes = invitationCodes.map((invitationCode) => invitationCode.code)


    return <Autocomplete
        disableClearable
        options={codes}
        value={value}
        onChange={(event, newValue: string | null) => {
            if (newValue) {
                onChangeValue(newValue)
            }
        }}
        sx={{width: "100%", mt: 2}}
        renderInput={(params) => (
            <TextField
                {...params}
                placeholder="Select Invitation Code"
            />
        )}
    />
}