// material
import { useTheme } from "@mui/material/styles";
import { GlobalStyles as GlobalThemeStyles } from "@mui/material";

// ----------------------------------------------------------------------

export default function GlobalStyles() {
  const theme = useTheme();

  return (
    <GlobalThemeStyles
      styles={{
        "*": {
          margin: 0,
          padding: 0,
          boxSizing: "border-box",
        },
        html: {
          width: "100%",
          height: "100%",
          WebkitOverflowScrolling: "touch",
        },
        body: {
          width: "100%",
          height: "100%",
        },
        "#root": {
          width: "100%",
          height: "100%",
        },
        input: {
          "&[type=number]": {
            MozAppearance: "textfield",
            "&::-webkit-outer-spin-button": {
              margin: 0,
              WebkitAppearance: "none",
            },
            "&::-webkit-inner-spin-button": {
              margin: 0,
              WebkitAppearance: "none",
            },
          },
        },
        textarea: {
          "&::-webkit-input-placeholder": {
            color: theme.palette.text.disabled,
          },
          "&::-moz-placeholder": {
            opacity: 1,
            color: theme.palette.text.disabled,
          },
          "&:-ms-input-placeholder": {
            color: theme.palette.text.disabled,
          },
          "&::placeholder": {
            color: theme.palette.text.disabled,
          },
        },

        img: { display: "block", maxWidth: "100%" },

        // Lazy Load Img
        ".blur-up": {
          WebkitFilter: "blur(5px)",
          filter: "blur(5px)",
          transition: "filter 400ms, -webkit-filter 400ms",
        },
        ".blur-up.lazyloaded ": {
          WebkitFilter: "blur(0)",
          filter: "blur(0)",
        },
        ".ais-SearchBox": { margin: "1em 0" },
        ".ais-Pagination": {
          marginTop: "1em",
          overflow: "hidden",
          margin: "0 auto",
          float: "left",
        },
        ".ais-Pagination-item": {
          display: "inline",
          padding: "5px",
          margin: "0 auto",
        },
        ".ais-Pagination-item--selected": {
          display: "inline",
          padding: "5px",
          margin: "0 auto",
          backgroundColor: "pink",
          borderRadius: "5px",
          overflow: "hidden",
        },
        ".ais-RefinementList-labelText": {
          paddingLeft: "5px",
          paddingBottom: "5px",
          margin: "0 auto",
        },
        ".ais-RefinementList-count": {
          padding: "0.1rem 0.4rem",
          fontSize: ".8rem",
          color: "#3a4570",
          backgroundColor: "#dfe2ee",
          borderRadius: "8px",
          marginLeft: "8px",
        },
        ".left-panel": { float: "left", width: "250px" },
        ".right-panel": { marginLeft: "260px" },
        ".ais-InstantSearch": {
          maxWidth: "960px",
          overflow: "hidden",
          margin: "0 auto",
        },
        ".ais-Hits-item": { marginBottom: "1em", width: "calc(50% - 1rem)" },
        ".ais-Hits-item img": { marginRight: "1em" },
        ".hit-name": { marginBottom: ".5em" },
        ".hit-description": {
          color: "#888",
          fontSize: "14px",
          marginBottom: ".5em",
        },
        ".ais-SearchBox-submit": {
          marginLeft: "3px",
          padding: "0 3px",
        },
        ".ais-ClearRefinements-button": {
          padding: "3px 5px",
          alignSelf: "flex-end",
          float: "right",
          top: "initial",
        },
        ".date-range-picker": {
          display: "flex",
          gap: "1rem",
          flexGrow: 1,
        },
        ".duet-date__input-wrapper": {
          position: "relative",
          background: "#fff",
          border: "1px solid rgba(0, 0, 0, 0.1)",
          borderRadius: "5px",
          boxShadow: "0 4px 10px 0 rgba(0, 0, 0, 0.1)",
        },
        ".duet-date__dialog-content": {
          background: "#fff",
          border: "1px solid rgba(0, 0, 0, 0.1)",
          borderRadius: "5px",
          boxShadow: "0 4px 10px 0 rgba(0, 0, 0, 0.1)",
          marginLeft: "auto",
          marginTop: "8px",
          maxWidth: "310px",
          minWidth: "290px",
          padding: "16px 16px 20px",
          position: "relative",
          transform: "none",
          width: "100%",
          "z-index": "9999999999",
        },
        ".duet-date__dialog": {
          "z-index": "100 !important",
        },
      }}
    />
  );
}
