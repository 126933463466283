import { Autocomplete, TextField, createFilterOptions } from "@mui/material";
import {
  ContentFromFirestoreOptions,
  ContentID,
  ContentUnitCategories,
} from "models/ContentUnit";
import { useNavigate } from "react-router";
import { SupportedLanguages } from "types/invitation/code";

type Props = {
  setSelectedContentUnit: (ContentID: ContentID) => void;
  contentUnitOptions: ContentFromFirestoreOptions[];
  defaultValue?: ContentFromFirestoreOptions;
  path?: string;
  textFieldLabel?: string;
  language: SupportedLanguages;
};

export default function ContentAutocomplete({
  setSelectedContentUnit,
  contentUnitOptions,
  defaultValue,
  path,
  textFieldLabel,
  language,
}: Props) {
  const navigate = useNavigate();

  const filter = createFilterOptions<ContentFromFirestoreOptions>({
    stringify: (option) => {
      return `${option.title}-${option.id}`  
    }
  });

  return (
    <Autocomplete
      id="contentUnitAutoComplete"
      options={Object.values(contentUnitOptions).sort(
        (a, b) => {

          if(a.type && b.type){
            return -b?.type.localeCompare(a?.type)
          }

          return b.title.localeCompare(a.title)
        }
      )}
      defaultValue={defaultValue}
      isOptionEqualToValue={(option, value) =>
        option?.id === value?.id &&
        option?.title === value?.title &&
        option?.type === value?.type
      }
      groupBy={(option) => option.type}
      getOptionLabel={(option) => option.title}
      renderOption={(props, option) => (
        <li {...props}>
            {option.id}
        </li>
      )}
      onChange={(_, value) => {
        if (!value) {
          return;
        }

        setSelectedContentUnit(value?.id);

        navigate(`${path}/${language}/${value?.id}`);
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        if (params.inputValue !== "") {
          filtered.push({
            id: params.inputValue,
            type: ContentUnitCategories.PRACTICE,
            title: params.inputValue,
          });
        }

        return filtered;
      }}
      sx={{ width: 395 }}
      renderInput={(params) => <TextField {...params} label={textFieldLabel} />}
    />
  );
}
