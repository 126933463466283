import React from "react";
import { useLocation, useParams } from "react-router-dom";
// material
import { Container } from "@mui/material";
// hooks
import useSettings from "../../../hooks/useSettings";
// components
import Page from "../../../components/Page";
import HeaderBreadcrumbs from "../../../components/HeaderBreadcrumbs";
import AudioMeditationGenerationForm
  from "../../../components/_dashboard/audio-meditation-generations/AudioMeditationGenerationForm";

import { AudioMeditationGenerationType } from "../../../types/audioMeditationGenerations";
import { useAudioMeditation } from "../../../utils/firebase/audioMeditationGenerationsUtil";

// ----------------------------------------------------------------------

type ManualCreatedType = {
  isManualCreated?: boolean
}

export default function CreateAudioMeditation() {
  const { themeStretch } = useSettings();
  const { pathname, state } = useLocation();
  const { id: audioMeditationGenerationId } = useParams();

  const { audioMeditation, loading } = useAudioMeditation(audioMeditationGenerationId ?? "");

  const isEdit = pathname.includes("/edit");
  const isView = pathname.includes("/view");
  const isNew = pathname.includes("/new");
  const isManualEntered = (state as ManualCreatedType)?.isManualCreated ?? audioMeditation?.isManualCreated ?? false

  let pageTitle = `${isManualEntered ? 'Create a new Manual': 'Generate a new AI'}  Audio Meditation`;
  if (isView) {
    pageTitle = `View ${isManualEntered ? 'Manual': 'AI'} Audio Meditation Generation`;
  } else if (isEdit) {
    pageTitle = `Edit ${isManualEntered ? 'Manual': 'AI'} Audio Meditation Generation`;
  }

  return (
    <Page title={ pageTitle }>
      <Container maxWidth={ themeStretch ? false : "lg" }>
        <HeaderBreadcrumbs heading={ pageTitle } links={ [] }/>

        <AudioMeditationGenerationForm
          isEdit={ isEdit }
          isView={ isView }
          isNew={ isNew }
          isManualEntered={ isManualEntered }
          loading={ loading }
          audioMeditation={ audioMeditation as AudioMeditationGenerationType }
        />
      </Container>
    </Page>
  );
}
