import { IconButton } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import indentJSON from "../utils/indentJSON";
import { useCopyToClipBoard } from "../../../hooks/useCopyToClipBoard";

export default function CopyToClipboardButton(props: {
  content: string;
  title: string;
}) {
  const { content, title } = props;
  const { copyToClipBoard } = useCopyToClipBoard();

  return (
    <IconButton
      title={title}
      onClick={() => {
        copyToClipBoard(indentJSON(content))
      }}
    >
      <ContentCopyIcon />
    </IconButton>
  );
}
