import * as branch from "branch-sdk";

export function initBranch() {
  const branchKey = process.env.REACT_APP_BRANCH_KEY;
  if (!branchKey) {
    throw new Error("Missing REACT_APP_BRANCH_KEY environment variable");
  }

  branch.init(branchKey, {
    retries: 10,
    timeout: 100000,
    tracking_disabled: true,
  });
}
