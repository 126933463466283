import firebase from "firebase";

export interface FilterData {
  translations: Record<string, string>;
  categories: string[];
  flags: string[];
}

export type FilterOption = {
  label: string;
  value: string;
};

export class CounsellorSearchFilter implements FilterData {
  public readonly id: string;

  public readonly translations: Record<string, string>;

  public readonly categories: string[];

  public readonly flags: string[];

  public constructor(id: string, data: FilterData) {
    this.id = id;
    this.translations = data.translations;
    this.categories = data.categories;
    this.flags = data.flags;
  }

  static fromSnapshot(
    doc: firebase.firestore.QueryDocumentSnapshot<FilterData>
  ): CounsellorSearchFilter {
    return new CounsellorSearchFilter(doc.id, doc.data());
  }

  /**
   * The function returns English as first priority and greates a fallback
   * label with a number if English translation is missing.
   */
  public getLabel() {
    const english = this.translations.en;
    const fallback = this.id;
    return english ?? fallback;
  }
}

class CounsellorSearchFilters {
  private static EMPTY: CounsellorSearchFilters = CounsellorSearchFilters.of(
    []
  );

  private filters: CounsellorSearchFilter[];

  constructor(filters: CounsellorSearchFilter[]) {
    this.filters = filters;
  }

  static of(filters: CounsellorSearchFilter[] | undefined) {
    if (!filters) {
      return CounsellorSearchFilters.empty();
    }
    return new CounsellorSearchFilters(filters);
  }

  public static empty() {
    return CounsellorSearchFilters.EMPTY;
  }

  public exclusiveFilters(): CounsellorSearchFilters {
    return CounsellorSearchFilters.of(
      this.filters.filter((f) => f.flags.includes("exclusive"))
    );
  }

  public toFilterOptions(): FilterOption[] {
    return this.filters.map((filter) => {
      return { label: filter.getLabel(), value: filter.id };
    });
  }
}

export default CounsellorSearchFilters;
