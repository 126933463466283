import React from "react";
import { useLocation, useParams } from "react-router-dom";
// material
import { Container } from "@mui/material";
// hooks
import useSettings from "../../../../hooks/useSettings";
// components
import Page from "../../../../components/Page";
import HeaderBreadcrumbs from "../../../../components/HeaderBreadcrumbs";
import LoadingScreen from "../../../../components/LoadingScreen";
import { useGetMultilingualLocaleById } from "utils/firebase/multilingualLocaleUtils";
import MultilingualLocaleForm from "./MultilingualLocaleForm";
// ----------------------------------------------------------------------

export default function CreateMultilingualLocale() {
  const { themeStretch } = useSettings();
  const { pathname } = useLocation();
  const { id } = useParams();

  const { multilingualLocale, loading } = useGetMultilingualLocaleById(
    id ?? ""
  );

  const isEdit = pathname.includes("edit");
  const isView = pathname.includes("view");

  let pageTitle = "Create a new Locale";
  if (isView) {
    pageTitle = "View Locale";
  } else if (isEdit) {
    pageTitle = "Edit Locale";
  }

  const isLoading = loading;

  return (
    <Page title={pageTitle}>
      <Container maxWidth={themeStretch ? false : "lg"}>
        <HeaderBreadcrumbs heading={pageTitle} links={[]} />

        {Boolean(isLoading) && <LoadingScreen />}

        {!Boolean(isLoading) && (
          <MultilingualLocaleForm
            isEdit={isEdit}
            isView={isView}
            multilingualLocale={multilingualLocale}
          />
        )}
      </Container>
    </Page>
  );
}
