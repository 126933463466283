import { TextField } from "@mui/material";
import { InnerFormProps } from "./ContentPracticeForm";
import { ASSESSMENTS_CATEGORIES } from "../../../models/ContentUnit";
import React from "react";

export function ContentAssessmentForm({ getFieldProps }: InnerFormProps) {
  return (
    <>
      <TextField
        select
        label={"Assessment Type"}
        placeholder={"Assessment Type"}
        {...getFieldProps("assessmentCategory")}
        SelectProps={{ native: true }}
      >
        <option value=""></option>
        {Object.entries(ASSESSMENTS_CATEGORIES).map(([key, value]) => {
          return (
            <option key={key} value={value}>
              {value}
            </option>
          );
        })}
      </TextField>
    </>
  );
}
