import { Box, Modal, Typography } from "@mui/material";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { styled, useTheme } from "@mui/material/styles";
import GroupProgressChart from "./GroupProgressChart";
import { getGroupProgressQuestionScoreChangeInfo, getPreCounsellingSessionTitle } from "./utils/counsellorDashboardUtils";
import { AssessmentQuestions } from "./utils/constants";
import { CounsellorProgressAssessmentQuestion, GroupProgressInsightsData } from "types/CounsellorProgressDasboard";
import { formatChartData, formatUsersCountChartData } from "./utils/chartsUtils";
import ScoresCircle from "./ScoresCircle";

const ChatsCount = styled(Typography)(({ theme }) => ({
  fontSize: 18,
  fontWeight: 700,
}));

type Props = {
  groupProgressAssessmentResult: GroupProgressInsightsData | undefined;
  selectedAssessment: CounsellorProgressAssessmentQuestion;
  modalOpen: boolean;
  closeModal: () => void;
};
export default function GroupProgressOverview({
  groupProgressAssessmentResult,
  selectedAssessment,
  modalOpen,
  closeModal
}: Props) {
  const { t } = useTranslation();
  const theme = useTheme();
  const chatsCount = groupProgressAssessmentResult?.totalChats || 0;

  const title = useMemo(() => {
    const selectedAssessmentQuestion = AssessmentQuestions.find(
      (item) => item.question === selectedAssessment
    );
    return selectedAssessmentQuestion?.title ?? "";
  }, [selectedAssessment]);

  const {
    averageScoresForAllSessions = [],
    assessmentsCountByUsersForAllSessions = [[]],
    overallScore = 0,
    firstSessionWithResult
  } = groupProgressAssessmentResult?.assessmentQuestionsData?.[
    selectedAssessment
  ] ?? {};

  const totalSessions = averageScoresForAllSessions?.length ?? 0;

  const { scoreColor, score: formattedScore } =
    getGroupProgressQuestionScoreChangeInfo(overallScore, selectedAssessment);

  const xAxisLabels = useMemo(
    () => getPreCounsellingSessionTitle(averageScoresForAllSessions.length, t),
    [averageScoresForAllSessions.length, t]
  );

  const chartData = useMemo(
    () =>
      formatChartData(
        theme,
        averageScoresForAllSessions,
        xAxisLabels,
        0,
        100,
        selectedAssessment,
        firstSessionWithResult

      ),
    [averageScoresForAllSessions, theme, xAxisLabels, selectedAssessment, firstSessionWithResult]
  );

  const chartDataForAllUsers = useMemo(() => {
    return formatUsersCountChartData(
      theme,
      assessmentsCountByUsersForAllSessions,
      t,
      totalSessions
    );
  }, [theme, t, totalSessions, assessmentsCountByUsersForAllSessions]);

  return (
    <Box display={"flex"} flexDirection={"column"} mb={2}>

      <Modal
        open={modalOpen}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box sx={{
          width: "50vw",
        }}>
          <GroupProgressChart
            selectedAssessment={selectedAssessment}
            options={chartData.options}
            series={chartData.series}
            title={t(title)}
            subTitle={t("scoreForAllClients")}
            showScoresInfo
            type="line"
            headerRight={
            <ScoresCircle score={formattedScore} bgcolor={scoreColor} />
            }
          /> 
        </Box>
      </Modal>

      <GroupProgressChart
        selectedAssessment={selectedAssessment}
        options={chartDataForAllUsers.options}
        series={chartDataForAllUsers.series}
        title={"Number of unique users that have taken an assessment"}
        subTitle={t("explanationOfThis")}
        type="bar"
        chatsCount={chatsCount}
        sx={{ mt: theme.spacing(2) }}
        headerRight={<ChatsCount>{chatsCount}</ChatsCount>}
      />
    </Box>
  );
}
