import React from "react";
import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import {
  getGroupProgressQuestionScoreChangeInfo,
} from "./utils/counsellorDashboardUtils";
import Divider from "@mui/material/Divider/Divider";
import AssessmentItem from "./AssessmentItem";
import { styled, useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import {
  CounsellorProgressAssessmentQuestion,
  GroupProgressInsightsData,
} from "types/CounsellorProgressDasboard";
import ScoresCircle from "./ScoresCircle";
import { AssessmentQuestions } from "./utils/constants";
import UserIcon from "../../assets/icon_user";
import {  Tooltip } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";


const OverallScoreContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

const OverallScoreLabel = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 700,
  color: theme.palette.blue.dark,
}));

const UsersCount = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 700,
  opacity: 0.3,
  marginLeft: 4,
  color: theme.palette.blue.dark,
}));

type Props = {
  groupProgressAssessmentResult: GroupProgressInsightsData;
  setModalOpen: () => void
  setShowProgressOverview: (assessment: CounsellorProgressAssessmentQuestion) => void
};

export default function OverallGroupProgress({
  groupProgressAssessmentResult,
  setModalOpen,
  setShowProgressOverview,
}: Props) {
  const { t } = useTranslation();
  const theme = useTheme();
  const totalValidUsers = groupProgressAssessmentResult.assessmentQuestionsData.Z1.validUserCount || 0;

  return (
    <Box>
      <Card>
        <CardContent>
          <OverallScoreContainer>
            <OverallScoreLabel>{t("overallScoreChange")}</OverallScoreLabel>

            {totalValidUsers > 0 ? 
              <ScoresCircle
                score={groupProgressAssessmentResult.averageScore}
                bgcolor={
                  groupProgressAssessmentResult.averageScore >= 0 ? "lightGreen" : "darkOrange"
                }
              />
            : <Typography style={{opacity: 0.4}} variant="h6" >N/A</Typography>}
          
          </OverallScoreContainer>

          <Divider sx={{ mt: theme.spacing(2) }} />

          <Box display="flex" mt={2.5}>
            <UserIcon />

            <UsersCount>
              {`${totalValidUsers} ${t("eligibleUsers")}`}

              <Tooltip
                title={
                  <>
                    {t("userEligibilityCriteria")}
                    <ul>
                      <li>- {t("atLeastProgressAssessments")}</li>
                      <li>- {t("firstAndLastAssessments")}</li>
                    </ul>
                  </>
                }
              >
                <InfoOutlinedIcon
                  style={{ marginLeft: 5, alignSelf: "center" }}
                />
              </Tooltip>
            </UsersCount>
          </Box>

       {
        totalValidUsers === 0 ?    
          <Box display="flex" mt={2.5}>

            <Typography color="red">
              {t("encourageClients")}
            </Typography>
            
          </Box> : null
        }

        </CardContent>
      </Card>

      <Grid container spacing={2} sx={{ py: theme.spacing(2) }}>
        {AssessmentQuestions.map((item, index) => {
          const { overallScore = 0, validUserCount } =
            groupProgressAssessmentResult?.assessmentQuestionsData?.[
              item.question
            ] ?? {};

          const { scoreColor, score: formattedScore } =
              getGroupProgressQuestionScoreChangeInfo(overallScore,  item.question);

          return (
            <AssessmentItem
              score={formattedScore}
              color={scoreColor}
              key={item.title}
              item={item}
              index={index}
              validUserCount={validUserCount}
              onClickSeeMore={() =>  {
                setShowProgressOverview(item.question)
                setModalOpen()
              }
              }
            />
          );
        })}
      </Grid>
    </Box>
  );
}
