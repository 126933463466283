import Label from "../../../Label";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import {pushItem} from "../../../../utils/firebase";
import {v4} from "uuid";
import {Stack} from "@mui/material";
import React, {useState} from "react";
import {FieldInputProps, FormikErrors} from "formik/dist/types";
import {FormikValues} from "formik";
import {SupportedLanguages} from "../../../../types/invitation/code";
import {LanguageSelectionDropDown} from "../../../LanguageSelectionDropDown";
import {SupportedLanguagesEnum} from "../../../../utils/constants";
import QuestionsInput from "./QuestionsInput";

type Props = {
    setFieldValue: (
        field: string,
        value: any,
        shouldValidate?: boolean | undefined
    ) => Promise<FormikErrors<FormikValues>> | Promise<void>;
    getFieldProps: (nameOrOptions: any) => FieldInputProps<any>;
    setDemographicLanguage?: (demographicLanguage: SupportedLanguages) => void;
    showAnswerEvents?: boolean;
}

export default function DemographicQuestions(props: Props) {
    const {setFieldValue, getFieldProps, showAnswerEvents, setDemographicLanguage} = props
    const [selectedLanguage, setSelectedLanguage] =
        useState<SupportedLanguages>(SupportedLanguagesEnum.ENGLISH);

    const fieldName = `demographicQuestions.${selectedLanguage}`
    const questionsForSelectedLanguage = getFieldProps(fieldName).value || []

    const addNewQuestion = () => {
        setFieldValue(
            fieldName,
            pushItem(
                {
                    id: v4(),
                    question: ''
                },
                questionsForSelectedLanguage
            )
        );
    }


    return <Stack spacing={3}>
        <Label sx={{mt: 3, py: 2, justifyContent: 'flex-start'}}>Demographic Questions</Label>

        <LanguageSelectionDropDown
            fieldName={""}
            selectedValue={selectedLanguage}
            onSelectionChanged={(fieldKey, value) => {
                setSelectedLanguage(value);
                if (setDemographicLanguage) {
                    setDemographicLanguage(value);
                }
            }}
        />

        <Button
            sx={{alignSelf: 'flex-end'}}
            variant="contained"
            color={'success'}
            endIcon={<AddIcon/>}
            onClick={addNewQuestion}
        >
            Add Question
        </Button>

        <Stack spacing={3}>
            <Stack spacing={1}/>
            <QuestionsInput
                showAnswerEvents={showAnswerEvents}
                setFieldValue={setFieldValue}
                getFieldProps={getFieldProps}
                featureName={fieldName}
                language={selectedLanguage}/>
        </Stack>
    </Stack>
}