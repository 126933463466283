// ----------------------------------------------------------------------

import { FormikProps, FormikHelpers } from "formik";
import { InvitationCode } from "./invitation/code";

export type UserInvoice = {
  id: string;
  createdAt: Date | string | number;
  price: number;
};

export type CreditCard = {
  id: string;
  cardNumber: string;
  cardType: string;
};

export type Follower = {
  id: string;
  avatarUrl: string;
  name: string;
  country: string;
  isFollowed: boolean;
};

export type Gallery = {
  id: string;
  title: string;
  postAt: Date | string | number;
  imageUrl: string;
};

export type UserAddressBook = {
  id: string;
  name: string;
  phone: string;
  country: string;
  state: string;
  city: string;
  street: string;
  zipCode: string;
};

export type Profile = {
  id: string;
  cover: string;
  position: string;
  follower: number;
  following: number;
  quote: string;
  country: string;
  email: string;
  company: string;
  school: string;
  role: string;
  facebookLink: string;
  instagramLink: string;
  linkedinLink: string;
  twitterLink: string;
};

export type UserManager = {
  id: string;
  avatarUrl: string;
  name: string;
  email: string;
  phoneNumber: string;
  address: string;
  country: string;
  state: string;
  city: string;
  zipCode: string;
  company: string;
  isVerified: boolean;
  status: string;
  role: string;
};

export type UserData = {
  id: string;
  avatarUrl: string;
  cover: string;
  fullname: string;
  name: string;
  follower: number;
  following: number;
  totalPost: number;
  position: string;
  invitationCode?: InvitationCode;
  isTOTPMFAEnrolled?: boolean;
};

export type NotificationSettings = {
  activityComments: boolean;
  activityAnswers: boolean;
  activityFollows: boolean;
  applicationNews: boolean;
  applicationProduct: boolean;
  applicationBlog: boolean;
};

export type Friend = {
  id: string;
  avatarUrl: string;
  name: string;
  role: string;
};

export type UserPost = {
  id: string;
  author: {
    id: string;
    avatarUrl: string;
    name: string;
  };
  isLiked: boolean;
  createdAt: Date | string | number;
  media: string;
  message: string;
  personLikes: {
    name: string;
    avatarUrl: string;
  }[];
  comments: {
    id: string;
    author: {
      id: string;
      avatarUrl: string;
      name: string;
    };
    createdAt: Date | string | number;
    message: string;
  }[];
};

export type AccountBillingFormikProps = FormikProps<{
  cardName: string;
  cardNumber: string;
  cardExpired: string;
  cardCvv: string;
}>;

export type UserId = string;

export enum Collections {
  USERS = "users",
}

export type State = {
  formDialog: FormDialog;
  usersResponse: UsersResponse;
  usersCreditsInfo: UsersCreditsInfoResponse;
  inputValues: InputValue;
};

export const initialState: State = {
  formDialog: {
    isOpen: false,
    selectedUserId: undefined,
  },
  usersResponse: {
    userInfo: undefined,
  },
  usersCreditsInfo: {
    data: {
      remainingCredits: undefined,
      extraCredits: 0,
      creditsUsed: 0,
    },
  },
  inputValues: {
    extraCredits: 0,
    creditsUsed: 0,
    remainingCredits: "Null",
  },
};

export type Action =
  | { type: ActionTypes.USERS_REQUEST; payload: UserData | undefined }
  | { type: ActionTypes.FETCH_CREDITS_INFO; payload: UsersCreditsInfoResponse }
  | { type: ActionTypes.OPEN_FORM; payload: string }
  | { type: ActionTypes.CLOSE_FORM }
  | { type: ActionTypes.SUBMIT }
  | { type: ActionTypes.SET_FORM_INPUT_VALUES };

export enum ActionTypes {
  OPEN_FORM = "OPEN_FORM",
  CLOSE_FORM = "CLOSE_FORM",
  SUBMIT = "SUBMIT",
  USERS_REQUEST = "USERS_REQUEST",
  FETCH_CREDITS_INFO = "FETCH_CREDITS_INFO",
  SET_FORM_INPUT_VALUES = "SET_FORM_INPUT_VALUES",
}

export const InitialValues = {
  extraCredits: 0,
  creditsUsed: 0,
  remainingCredits: 0,
};

export interface UsersResponse {
  userInfo: UserData | undefined;
}

export type FormDialog = {
  isOpen: boolean;
  selectedUserId: string | undefined;
};

export interface UsersCreditsInfoResponse {
  data: {
    remainingCredits: number | undefined;
    extraCredits: number;
    creditsUsed: number;
  };
}

export type InputValue = {
  extraCredits: number;
  creditsUsed: number;
  remainingCredits: string | number | undefined;
};

export type UserCreditsContextValue = {
  formDialogIsOpen: boolean;
  inputValues: InputValue;
  usersResponse: UsersResponse;
  usersCreditsInfo: UsersCreditsInfoResponse;
  formDialog?: FormDialog;

  handleSubmit: (
    values: InputValue,
    formikHelpers: FormikHelpers<InputValue>
  ) => Promise<void>;

  handleClickOpen: (selectedUserId: string) => void;
  handleClose: () => void;
  handleSearchInputChange: (value: string) => Promise<void>;
};

export enum Locale {
  EN = "en",
  DE = "de",
  FR = "fr",
  ES = "es",
}

export type UserSearchResult = {
  email: string;
  uid: string;
  invitationCode: string;
  creditsUsed: number;
  remainingCredits: number;
}
