import { Anonymous } from '../types/list';
import { filter } from 'lodash';

export function descendingComparator(a: Anonymous, b: Anonymous, orderBy: string) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator(order: string, orderBy: string) {
  return order === 'desc'
    ? (a: Anonymous, b: Anonymous) => descendingComparator(a, b, orderBy)
    : (a: Anonymous, b: Anonymous) => -descendingComparator(a, b, orderBy);
}

export function applySortFilter<Type>(
  array: Array<Type>,
  comparator: (a: any, b: any) => number,
  query: string,
  searchPredicate?: (query: string, item: any) => boolean
) {
  const stabilizedThis = array.map((el, index) => [el, index] as const);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      typeof searchPredicate === 'function'
        ? (item: any) => searchPredicate(query, item)
        : // @ts-ignore
          (_user) => _user?.code?.toLowerCase?.()?.indexOf?.(query?.toLowerCase?.() || '') !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}
