const Like = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M7.83464 10.6953L11.168 3.19531C11.831 3.19531 12.4669 3.4587 12.9357 3.92755C13.4046 4.39639 13.668 5.03227 13.668 5.69531V9.02865H18.3846C18.6262 9.02591 18.8655 9.07573 19.0859 9.17466C19.3064 9.27359 19.5026 9.41926 19.6612 9.60157C19.8197 9.78389 19.9367 9.9985 20.0041 10.2305C20.0714 10.4625 20.0876 10.7064 20.0513 10.9453L18.9013 18.4453C18.841 18.8427 18.6392 19.205 18.3329 19.4653C18.0266 19.7256 17.6366 19.8665 17.2346 19.862H7.83464M7.83464 10.6953V19.862M7.83464 10.6953H5.33464C4.89261 10.6953 4.46868 10.8709 4.15612 11.1835C3.84356 11.496 3.66797 11.92 3.66797 12.362V18.1953C3.66797 18.6373 3.84356 19.0613 4.15612 19.3738C4.46868 19.6864 4.89261 19.862 5.33464 19.862H7.83464"
        stroke="#1F1E5F"
        strokeWidth="1.22"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Like;
