import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import React from "react";

type Props = {
    open: boolean;
    loading: boolean;
    onClose: () => void;
    deleteVideoCallMessage: () => void;
}
export default function DeleteConfirmDialog({open, loading, onClose, deleteVideoCallMessage}: Props){
    return <Dialog
        open={open}
        onClose={onClose}
    >
        <DialogTitle>
            {"Are you sure you want to delete this video call?"}
        </DialogTitle>
        <DialogContent>
            <DialogContentText>
                By deleting this you will be not able recover the call. It would be a permanent delete.
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose}>Cancel</Button>
            <LoadingButton loading={loading} onClick={deleteVideoCallMessage} autoFocus>
                Confirm Delete
            </LoadingButton>
        </DialogActions>
    </Dialog>
}