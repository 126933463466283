import React from "react";
import {
  DemographicQuestionsProps,
  InvitationCodeAnswers,
} from "../../../../types/invitation/code";
import { Stack, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import { v4 } from "uuid";
import { pushItem } from "../../../../utils/firebase";
import InputWithDeleteButton from "./InputWithDeleteButton";

type Props = {
  questionIndex: number;
  showAnswerEvents?: boolean;
} & DemographicQuestionsProps;

export default function AnswersInput(props: Props) {
  const {
    questionIndex,
    setFieldValue,
    getFieldProps,
    language,
    showAnswerEvents,
  } = props;
  const fieldName = `demographicQuestions[${language}][${questionIndex}].answers`;
  const answers: InvitationCodeAnswers[] =
    getFieldProps(fieldName)?.value ?? [];

  const addNewAnswer = () => {
    setFieldValue(
      fieldName,
      pushItem(
        {
          id: v4(),
          commonId: "",
          answer: "",
        },
        answers || []
      )
    );
  };

  const deleteSelectedAnswer = (selectedAnswerId: string) => {
    const updatedAnswers = (answers || [])?.filter?.(
      (answer) => answer?.id !== selectedAnswerId
    );
    setFieldValue(fieldName, updatedAnswers);
  };

  return (
    <Stack spacing={2}>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        sx={{ justifyContent: "space-between", my: 2 }}
      >
        <Typography variant="subtitle2">Answers</Typography>

        <Button
          color="success"
          variant="contained"
          endIcon={<AddIcon />}
          onClick={addNewAnswer}
        >
          Add Answer
        </Button>
      </Stack>

      {answers.map((item, answerIndex) => {
        const answerFormKey = `${fieldName}.${answerIndex}`;
        return (
          <InputWithDeleteButton
            showCommonIdInput
            textInputKey="answer"
            showAnswerEvents={showAnswerEvents}
            key={answerFormKey}
            inputFormKey={answerFormKey}
            getFieldProps={getFieldProps}
            setFieldValue={setFieldValue}
            label={`Answer# ${answerIndex + 1}`}
            onClickDelete={() => deleteSelectedAnswer(item.id)}
          />
        );
      })}
      <hr />
    </Stack>
  );
}
