import { db } from "../contexts/FirebaseContext";
import { Template } from "../types/template";

export const createOrUpdateTemplates = async (
  templateId: string,
  template: Template
): Promise<void> => {
  await db
    .collection("templates")
    .doc(templateId)
    .set(
      {
        ...template,
      },
      { merge: true }
    );
};

export async function getTemplateById(templateId: string): Promise<Template> {
  const doc = await db.collection("templates").doc(templateId).get();

  if (!doc.exists) {
    throw new Error("No template exists with this Id");
  }

  return {
    ...doc?.data(),
  } as Template;
}
