// material
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

// ----------------------------------------------------------------------

const Container = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%'
}));

const Text = styled(Typography)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%,-50%)',
  zIndex: 1,
  paddingBottom: 50,
  color: theme.palette.blue.dark,
  fontWeight: 500
}));

const SvgContainer = styled('div')(({ theme }) => ({
  width: '100%',
  position: 'relative',
  top: 5
}));

export default function SpeechBubble({ text }: { text: string }) {
  return (
    <Container>
      <Text>{text}</Text>

      <SvgContainer>
        <svg
          style={{ width: '100%' }}
          width="280"
          height="175"
          viewBox="0 0 280 175"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_dd_6625_49330)">
            <rect x="30" y="18" width="220" height="88" rx="16" fill="white" />
            <path
              d="M97.2548 129.654C96.6506 132.843 92.7006 134.023 90.4467 131.688L62.5422 102.778C60.091 100.238 61.8907 96 65.4202 96L98.8023 96C101.31 96 103.199 98.2808 102.732 100.745L97.2548 129.654Z"
              fill="white"
            />
          </g>
          <defs>
            <filter
              id="filter0_dd_6625_49330"
              x="0"
              y="0"
              width="280"
              height="174.913"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="12" />
              <feGaussianBlur stdDeviation="15" />
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_6625_49330"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="3" />
              <feGaussianBlur stdDeviation="2" />
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
              <feBlend
                mode="normal"
                in2="effect1_dropShadow_6625_49330"
                result="effect2_dropShadow_6625_49330"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect2_dropShadow_6625_49330"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      </SvgContainer>
    </Container>
  );
}
