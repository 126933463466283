import React, { useEffect, useState } from "react";
// material
import { Container, Stack, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import {
  getChatMessage,
  updateVideoCall,
} from "../../../utils/firebase/chatAnalyticsUtils";
import { differenceInMinutes } from "date-fns";
import { useSnackbar } from "notistack";
import { DefaultTimezone } from "../../../utils/constants";
import { useSearchParams } from "react-router-dom";
import { Conversation } from "../../../types/chat";
import HeaderBreadcrumbs from "../../../components/HeaderBreadcrumbs";
import Page from "../../../components/Page";
import useSettings from "../../../hooks/useSettings";
import EditCallAvailabilities from "./EditCallAvailabilities";
import EditSelectedCallTime from "./EditSelectedCallTime";
import LoadingScreen from "../../../components/LoadingScreen";
import CallInfo from "./CallInfo";
import DeleteButton from "./DeleteButton";
import ToggleButton from "./ToggleButton";

type VideoCallInfo = {
  availableTimes: Array<Date | number>;
  showAlert: boolean;
  cancelled?: boolean;
  adminComment: string;
  callTime?: Date | number;
  billable?: boolean;
};

export default function EditCallDetails() {
  const [searchURLParams] = useSearchParams();
  const chatId = searchURLParams.get("chatId");
  const messageId = searchURLParams.get("messageId");
  const [callMessage, setCallMessage] = useState<Conversation>();
  const [loading, setLoading] = useState<boolean>(true);
  const { therapistProfile, userProfile } = callMessage || {};
  const [availableTimes, setAvailableTimes] = useState<Array<Date | number>>(
    []
  );
  const [callTime, setCallTime] = useState<Date | number>();
  const [cancelled, setCancelled] = useState<boolean>(false);
  const [wasCancelledChanged, setWasCancelledChanged] =
    useState<boolean>(false);
  const [returnCredits, setReturnCredits] = useState<boolean>(false);
  const [isBillable, setIsBillable] = useState<boolean>(false);
  const [wasBillableChanged, setWasBillableChanged] = useState<boolean>(false);
  const [saving, setSaving] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { themeStretch } = useSettings();
  const [adminComment, setAdminComment] = React.useState<string>("");

  useEffect(() => {
    if (!chatId || !messageId) {
      return;
    }

    getChatMessage(chatId, messageId)
      .then((message) => {
        if (!message) {
          return;
        }
        setCallMessage(message);

        const availableTimesToDate =
          message?.availableTimes?.map((availableTime: any) =>
            availableTime.toDate()
          ) ?? [];
        setAvailableTimes(availableTimesToDate);
        setIsBillable(message?.billable || false);
        setCancelled(message?.cancelled ?? false);
        setAdminComment(message?.adminComment ?? "");
        if (message.callTime) {
          setCallTime(message.callTime.toDate());
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [chatId, messageId, saving]);

  const therapistTimezone = therapistProfile?.timezone
    ? therapistProfile?.timezone
    : DefaultTimezone;
  const userTimezone = userProfile?.timezone
    ? userProfile?.timezone
    : DefaultTimezone;

  function saveChanges() {
    const now = new Date();

    let showJoinCallDialog = false;
    if (callTime && !cancelled) {
      const diff = differenceInMinutes(now, callTime);
      // Join call dialog is shown 5 minutes before the call and 50 minutes the call is started
      showJoinCallDialog = diff >= -5 && diff <= 49;
    }

    if (!chatId || !messageId) {
      enqueueSnackbar("Something went wrong", { variant: "error" });
      return;
    }
    setSaving(true);

    const updatedVideoCall: VideoCallInfo = {
      availableTimes,
      showAlert: showJoinCallDialog,
      adminComment,
      ...(callTime && { callTime }),
    };

    if (wasCancelledChanged) {
      updatedVideoCall.cancelled = cancelled;
    }

    if (wasBillableChanged) {
      updatedVideoCall.billable = isBillable;
    }

    updateVideoCall(chatId, messageId, updatedVideoCall, returnCredits)
      .then(() => {
        setSaving(false);
        enqueueSnackbar("Changes Saved Successfully", { variant: "success" });
      })
      .catch((error) => {
        setSaving(false);
        enqueueSnackbar(error?.message ?? "Something went wrong", {
          variant: "error",
        });
      });
  }

  function toggleCancelled() {
    setCancelled((prevState) => !prevState);
    setWasCancelledChanged(true);
  }

  function toggleReturnCredit() {
    setReturnCredits((prevState) => !prevState);
  }

  function toggleBillable() {
    setIsBillable((prevState) => !prevState);
    setWasBillableChanged(true);
  }

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <Page title="Edit Call Details">
      <Container
        sx={{
          p: 2,
        }}
        maxWidth={themeStretch ? false : "md"}
      >
        <HeaderBreadcrumbs heading="Edit Call Details" links={[]} />

        <CallInfo label={"User name"} value={userProfile?.name} />
        <CallInfo label={"User id"} value={userProfile?.uid} />
        <CallInfo label={"Counselor name"} value={therapistProfile?.name} />
        <CallInfo label={"Chat id"} value={chatId} />
        <CallInfo label={"Message id"} value={messageId} />
        <CallInfo label={"Call status"} value={callMessage?.status} />
        <CallInfo label={"User not shown"} value={callMessage?.userNoShow} />
        <CallInfo
          label={"Reason user not Shown"}
          value={callMessage?.reasonNoShow}
        />
        <CallInfo
          label={"Counsellor comment"}
          value={callMessage?.counsellorComment}
        />

        <Stack
          direction={{ xs: "column", sm: "row" }}
          justifyContent={"space-between"}
          marginTop={2}
          marginBottom={1}
        >
          <EditCallAvailabilities
            availableTimes={availableTimes}
            changeAvailableTimes={setAvailableTimes}
            therapistTimezone={therapistTimezone}
            userTimezone={userTimezone}
          />

          <EditSelectedCallTime
            therapistTimezone={therapistTimezone}
            userTimezone={userTimezone}
            callTime={callTime}
            onChangeCallTime={setCallTime}
          />

          <Stack alignItems={"flex-start"} direction="column">
            <DeleteButton messageId={messageId} chatId={chatId} />

            <ToggleButton
              label={"Cancel Call"}
              checked={cancelled}
              onClick={toggleCancelled}
            />

            <ToggleButton
              label={"Refund Credit"}
              checked={
                callMessage && callMessage.refundedCredit
                  ? callMessage.refundedCredit
                  : returnCredits
              }
              onClick={toggleReturnCredit}
              disabled={
                callMessage && callMessage.refundedCredit
                  ? callMessage.refundedCredit
                  : false
              }
            />

            <ToggleButton
              label={"Billable"}
              checked={isBillable}
              onClick={toggleBillable}
            />
          </Stack>
        </Stack>

        <TextField
          value={adminComment}
          onChange={(event) => setAdminComment(event.target.value)}
          fullWidth
          type="text"
          label="Admin Comment"
        />

        <LoadingButton
          sx={{ marginTop: 2 }}
          fullWidth
          loading={saving}
          variant="contained"
          onClick={saveChanges}
        >
          Save
        </LoadingButton>
      </Container>
    </Page>
  );
}
