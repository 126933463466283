import React, {useCallback, useEffect, useState} from "react";
import { useLocation, useNavigate, useParams} from "react-router-dom";
// material
import {Box, Button, Container} from "@mui/material";
// hooks
import useSettings from "../../../hooks/useSettings";
// components
import Page from "../../../components/Page";
import HeaderBreadcrumbs from "../../../components/HeaderBreadcrumbs";
import {getPromptById} from "../../../utils/firebase/aiPromptsUtils";
import AIPromptForm from "../../../components/_dashboard/ai_prompts/AIPromptForm";
import {AIPrompt} from "../../../types/aiPrompts";
import {LIVE_PATH_PARAM_NAME} from "../../changelog/ChangelogExplorerPage";
import LoadingScreen from "../../../components/LoadingScreen";

type Props = {
  multilineContent?: boolean
}
// ----------------------------------------------------------------------

export default function CreateAIPrompt({multilineContent = true}: Props) {
  const { themeStretch } = useSettings();
  const { pathname = "" } = useLocation();
  const { state } = useLocation();
  const { id: aiPromptID } = useParams();
  const navigate = useNavigate();

  const [aiPrompt, setAIPrompt] = useState<AIPrompt>(
    state as AIPrompt
  );

  const [loading, setLoading] = useState(false);

  const isEdit = pathname?.includes("edit");
  const isView = pathname?.includes("view");
  const isNew = pathname?.includes("new");

  const fetchPrompt = useCallback(() => {
    if (aiPromptID && !loading && !aiPrompt) {
      setLoading(true);
      getPromptById(aiPromptID)
        .then((prompt) => {
          setAIPrompt(prompt);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);

        })
        .finally(() => {
          setLoading(false);
        });
    }

    if (isEdit && aiPrompt?.isDeleted) {
      navigate(-1);
    }
  }, [aiPrompt, aiPromptID, loading, isEdit, navigate]);

  useEffect(() => {
    fetchPrompt();
  }, [fetchPrompt]);

  let pageTitle = "Create a new Prompt";
  if (isView) {
    pageTitle = "View Prompt";
  } else if (isEdit) {
    pageTitle = "Edit Prompt";
  }
  const goToChangelog = () => {
    if (isNew) {
      return;
    }

    navigate({
      pathname: '/changelog-explorer',
      search: `?${LIVE_PATH_PARAM_NAME}=ai_prompts/${aiPrompt?.id}`,
    });
  }

  if (loading) {
    return <LoadingScreen/>;
  }

  return (
    <Page title={pageTitle}>
      <Container maxWidth={themeStretch ? false : "lg"}>
        <Box
            display="flex"
            flexDirection="row"
            alignItems="flex-start"
            justifyContent="space-between"
            height={50}
        >
          <HeaderBreadcrumbs heading={pageTitle} links={[]} />
          {
            !isNew &&
              <Button
                  variant="contained"
                  onClick={goToChangelog}
              >
                View Changelog
              </Button>
          }
        </Box>

        <AIPromptForm
          isEdit={isEdit}
          isView={isView}
          isNew={isNew}
          prompt={aiPrompt}
          multilineContent={multilineContent}
        />
      </Container>
    </Page>
  );
}
