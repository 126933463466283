import { LoadingButton } from "@mui/lab";
import { Card, Stack, TextField } from "@mui/material";
import { SessionFromDB } from "../../../types/therapists";
import { Form, FormikProvider, useFormik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { getSessionById, updateSession } from "utils/firebase/therapistUtils";
import * as Yup from 'yup';
import { format } from "date-fns";
import firebase from "firebase";
import { useSnackbar } from "notistack";
import { PATH_DASHBOARD } from "routes/paths";

type InitialValues = { 
  typedCharacters: number;
  checkIn: Date;
  checkOut: Date;
}

const sessionValidationSchema = Yup.object().shape({
  typedCharacters: Yup.number().required("Typed Characters are required"),
  checkIn: Yup.date().required('Check in time is required'),
  checkOut: Yup.date().required('Check out time is required'),
});

export function TherapistSessionEdit() {
  const { sessionId, therapistId } = useParams();
  const [session, setSession] = useState<SessionFromDB>();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const fetchSession = async (idTherapist: string, idSession: string) => {
    try {
      const selectedSession = await getSessionById(idTherapist, idSession);
      setSession(selectedSession);
    } catch (error) {
      console.error("error", error);
    }
  };

  useEffect(() => {
    if (therapistId && sessionId) {
      fetchSession(therapistId, sessionId);
    }
  }, [therapistId, sessionId]);

  const formik = useFormik<InitialValues>({
    initialValues: {
      typedCharacters: 0,
      checkIn: new Date(),
      checkOut: new Date(),
    },
    validationSchema: sessionValidationSchema,
    onSubmit: async (values) => {
      try{
        await onSubmit(values)
        enqueueSnackbar("Session Updated", {variant: "success"});
        navigate(PATH_DASHBOARD.therapistSessions.root);

      } catch(error){
        console.error(error);
        enqueueSnackbar(
          "Error updating the session, please try again", 
          {variant: "error"}
        );
      }
    }
  });

  const onSubmit = (values: InitialValues) => {
    const formattedCheckin = firebase.firestore.Timestamp.fromDate(new Date(values.checkIn));
    const formattedCheckOut = firebase.firestore.Timestamp.fromDate(new Date(values.checkOut));
    const updatedSession = {
      typedCharacters: Number(values.typedCharacters), 
      checkInTime: formattedCheckin, 
      checkOutTime: formattedCheckOut
    }

    if (!therapistId || !sessionId) {
      return;
    }

    return updateSession({therapistId, sessionId, updatedSession});
  }

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps, setFieldValue } = formik;


  useEffect(() => {
    const initDefaultValues = (currentSession: SessionFromDB) => {
      const { checkInTime, checkOutTime, typedCharacters } = currentSession;
      const formattedCheckin = format(checkInTime.toDate(), "yyyy-MM-dd'T'HH:mm:ss.SSS");
      const formattedCheckOut = format(checkOutTime.toDate(), "yyyy-MM-dd'T'HH:mm:ss.SSS");
      
      setFieldValue('checkIn', formattedCheckin);
      setFieldValue('checkOut', formattedCheckOut);
      setFieldValue('typedCharacters', typedCharacters);
    }

    if (!session) {
      return;
    }

    initDefaultValues(session);
  }, [session, setFieldValue]);

  return (
      <Card sx={{ p: 3 }}>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={3} alignItems="flex-end">
              <TextField
                {...getFieldProps('typedCharacters')}
                fullWidth
                autoComplete="on"
                type="number"
                label="Typed Characters"
                error={Boolean(touched.typedCharacters && errors.typedCharacters)}
                helperText={touched.typedCharacters && errors.typedCharacters}
              />
  
              <TextField
                {...getFieldProps('checkIn')}
                fullWidth
                autoComplete="on"
                type="datetime-local"
                label="Check In"
                error={Boolean(touched.checkIn && errors.checkIn)}
                helperText={touched.checkIn && errors.checkIn}
              />

              <TextField
                {...getFieldProps('checkOut')}
                fullWidth
                autoComplete="on"
                type="datetime-local"
                label="Check Out"
                error={Boolean(touched.checkOut && errors.checkOut)}
                helperText={touched.checkOut && errors.checkOut}
              />
  
              <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                Save Changes
              </LoadingButton>
            </Stack>
          </Form>
        </FormikProvider>
      </Card>
    );
}
