import React from 'react';
import ReactJson from 'react-json-view';

// material

// @types
import { DialogAnimate } from '../../animate';
import { AuditLog } from '../../../types/audit-logs/log';

// ----------------------------------------------------------------------

type AuditDetailsProps = {
  data: AuditLog;
  isOpen: boolean;
  onClose: VoidFunction;
};

export default function AuditDetailsPopup({ data, isOpen, onClose }: AuditDetailsProps) {
  return (
    <DialogAnimate fullWidth maxWidth="xl" open={isOpen} onClose={onClose}>
      <ReactJson src={data} theme={'ocean'} style={{ padding: 10 }} />
    </DialogAnimate>
  );
}
