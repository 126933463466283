const CheckCircle = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
    >
      <path
        d="M20.3346 11.7355V12.5022C20.3336 14.2992 19.7517 16.0477 18.6757 17.487C17.5998 18.9263 16.0874 19.9792 14.3641 20.4888C12.6408 20.9983 10.799 20.9371 9.11336 20.3143C7.4277 19.6916 5.98851 18.5406 5.01044 17.0331C4.03236 15.5255 3.56779 13.7422 3.68603 11.9491C3.80427 10.156 4.49897 8.4491 5.66654 7.08306C6.8341 5.71702 8.41196 4.76498 10.1648 4.36895C11.9176 3.97292 13.7515 4.15411 15.393 4.8855"
        stroke="#1F1E5F"
        strokeWidth="1.22"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.3333 5.83203L12 14.1737L9.5 11.6737"
        stroke="#1F1E5F"
        strokeWidth="1.22"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CheckCircle;
