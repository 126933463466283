import { useSnackbar } from "notistack";

export const useCopyToClipBoard = () => {
  const { enqueueSnackbar } = useSnackbar();

  const copyToClipBoard = (content: string) => {
    if (!content?.length) {
      enqueueSnackbar("Failed to copy", {
        variant: "error",
      });

      return
    }

    navigator.clipboard
      .writeText(content)
      .then(() =>
        enqueueSnackbar("Copied to clipboard", {
          variant: "success",
        })
      )
      .catch((e) => {
        console.error(e);
        enqueueSnackbar("Failed to copy", {
          variant: "error",
        });
      });

  };

  return { copyToClipBoard }
}