import React, { useState } from "react";

import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import {
  AppLanguageTranslationType,
  AppTranslationKeyType,
  ContentVersionsField,
} from "../../../types/translations";
import { Theme } from "@mui/material/styles";
import { CCRolesPossibleVals } from "../../../types/role/CCRoles";
import { format } from "date-fns";
import { dateTimeDisplayFormat } from "../../../utils/analytics/analyticsDefaults";
import { addOrUpdateLanguageTranslations } from "../../../utils/firebase/translationUtils";
import { Checkbox, CircularProgress, IconButton } from "@mui/material";
import { incrementContentVersion } from "../../../utils/firebase/cloudFunctions";
import TextField from "@mui/material/TextField";
import { useSnackbar } from "notistack";
import { Box } from "@mui/system";
import firebase from "firebase";

type AppSideTranslationRowType = {
  row: AppTranslationKeyType;
  theme: Theme;
  uid: string;
  currentRoles: CCRolesPossibleVals[];
  translationsCodeToName: Record<string, string>;
};

export const TranslationsFormQuickEdit = (props: AppSideTranslationRowType) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [statusChangeLoading, setStatusChangeLoading] =
    useState<boolean>(false);
  const [value, setValue] = useState(props?.row?.value);
  const [editingId, setEditingId] = useState<string>("");

  const { row } = props;
  const { enqueueSnackbar } = useSnackbar();

  const isEdit = editingId === row?.translationId;

  const handleApprovedStatusChange =
    (languageRow: AppLanguageTranslationType) => async () => {
      try {
        setStatusChangeLoading(true);
        const approvedBy = languageRow?.approvedBy ? null : props?.uid;

        await addOrUpdateLanguageTranslations(row?.translationId, {
          id: languageRow?.locale,
          locale: languageRow?.locale,
          value: value,
          updatedAt: firebase.firestore.Timestamp.fromDate(new Date()),
          approvedBy,
        });

        await incrementContentVersion({
          fieldName: ContentVersionsField.TRANSLATIONS_VERSION,
        });

        const successText = approvedBy
          ? "Translation Approved Successfully!"
          : "Translation Unapproved Successfully!";


        row.approvedBy = approvedBy ? "Approved": null;

        enqueueSnackbar(successText, {
          variant: "success",
        });
      } catch (e) {
        console.error("Failed to update the translation status");
        enqueueSnackbar(`Translation Approval has failed: ${e?.message}`, {
          variant: "error",
        });
      } finally {
        setStatusChangeLoading(false);
      }
    };

  const onSaveTranslation =
    (languageRow: AppLanguageTranslationType) => async () => {
      try {
        setLoading(true);

        await addOrUpdateLanguageTranslations(row?.translationId, {
          id: languageRow?.locale,
          locale: languageRow?.locale,
          value,
          updatedAt: firebase.firestore.Timestamp.fromDate(new Date()),
        });

        enqueueSnackbar("Translation Saved successfully!", {
          variant: "success",
        });
      } catch (e) {
        console.error("Failed to update the translation");
        enqueueSnackbar(`Translation update has failed: ${e?.message}`, {
          variant: "error",
        });
      } finally {
        setEditingId("");
        setLoading(false);
      }
    };

  function renderActionButtons() {
    if (isEdit) {
      if (loading) {
        return <CircularProgress size={20} />;
      }

      return (
        <>
          <IconButton
            disabled={value === row?.value}
            size="small"
            onClick={onSaveTranslation(row)}
          >
            <SaveIcon />
          </IconButton>

          <IconButton size="small" onClick={() => setEditingId("")}>
            <CancelIcon />
          </IconButton>
        </>
      );
    }

    return (
      <IconButton size="small" onClick={() => setEditingId(row?.translationId)}>
        <EditIcon />
      </IconButton>
    );
  }

  return (
    <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
      <TableCell key={row?.translationId}>{row?.translationId}</TableCell>
      <TableCell>
        {isEdit ? (
          <TextField
            id="outlined-basic"
            label="value"
            variant="outlined"
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        ) : (
          value
        )}
      </TableCell>

      <TableCell key={row?.description}>{row?.description}</TableCell>
      <TableCell>{row?.priority}</TableCell>

      <TableCell>
        {row?.createdAt
          ? format(row.createdAt as Date, dateTimeDisplayFormat)
          : "---"}
      </TableCell>

      <TableCell>
        {row?.updatedAt
          ? format(row.updatedAt as Date, dateTimeDisplayFormat)
          : "---"}
      </TableCell>

      <TableCell align="right">
        {statusChangeLoading ? (
          <Box pr={1}>
            <CircularProgress size={22} />
          </Box>
        ) : (
          <Checkbox
            checked={Boolean(row?.approvedBy)}
            onChange={handleApprovedStatusChange(row)}
          />
        )}
      </TableCell>

      <TableCell>{renderActionButtons()}</TableCell>
    </TableRow>
  );
};
