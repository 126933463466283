import { db } from 'contexts/FirebaseContext';
import { useEffect, useState } from 'react';
import * as CloudFunctions from 'utils/firebase/cloudFunctions';
import firebase from 'firebase/app';
import escapeHtml from 'utils/escape-html';

import { ConferenceCallRoom } from '../../types/conference-call';
import { CopyInviteToClipboardProps } from '../../types/room-more-menu';

// useListRooms is a hook that's used to listen for changes to the room's collection, effectively listing all the rooms out.
export function useListRooms() {
  const [rooms, setRooms] = useState<ConferenceCallRoom[]>([]);
  useEffect(() => {
    const unsub = db
      .collection('conference_call_rooms')
      .orderBy('createdAt', 'desc')
      .onSnapshot(async (snap) => {
        const docs = snap.docs.map((doc) => doc.data());
        setRooms(docs as ConferenceCallRoom[]);
      });
    return () => unsub();
  }, []);

  return rooms;
}

// getRoomDocument is used to get a specific room for editing on the edit room page.
export async function getRoomDocument(twilioRoomName: string) {
  const doc = await firebase
    .firestore()
    .collection('conference_call_rooms')
    .doc(twilioRoomName.toUpperCase())
    .get();

  if (!doc.exists) throw new Error('No room with this id exists');

  const data = doc.data();

  //If we find a match, set twilioRoomName to docId so we can update
  if (data) {
    return { ...data, docId: data.twilioRoomName } as ConferenceCallRoom;
  }
}

export function createOrUpdateRoom(data: ConferenceCallRoom) {
  const finalData: any = {
    ...data
  };

  if (finalData?.expiresAt) {
    finalData.expiresAt = finalData?.expiresAt?.toDate?.()?.toISOString?.() || '';
  }

  if (finalData?.opensAt) {
    finalData.opensAt = finalData?.opensAt?.toDate?.()?.toISOString?.() || '';
  }

  return CloudFunctions.createOrUpdateRoom(finalData);
}

export function deleteRoom(docId: string) {
  return CloudFunctions.deleteRoom({ docId });
}

export function formatRoomDetails(room: ConferenceCallRoom) {
  const teleAppDomain = process.env.TELE_APP_URL || 'https://meet.kyanhealth.com';
  const name = room.name || '';
  const url = teleAppDomain + '/conference-call/' + room?.roomToken;
  const opensAtDate = room?.opensAt?.toDate()?.toLocaleDateString() || '';
  const opensAtTime = room?.opensAt?.toDate()?.toLocaleTimeString() || '';
  const opensAt = room?.opensAt?.toDate() || null;
  const expiresAt = room?.expiresAt?.toDate() || null;
  const expiresAtDate = room?.expiresAt?.toDate()?.toLocaleDateString() || '';
  const expiresAtTime = room?.expiresAt?.toDate()?.toLocaleTimeString() || '';
  const docId = room.twilioRoomName || '';

  return {
    name,
    url,
    opensAt,
    opensAtDate,
    opensAtTime,
    expiresAt,
    expiresAtDate,
    expiresAtTime,
    docId
  };
}

const formatDateForGoolgeCalandar = (date: Date) => {
  if (date) {
    return date.toISOString().replace(/-/g, '').replace(/:/g, '').split('.')[0];
  } else {
    //Throw error if we don't have a date so that googleCalendarLink below will never contain undefined
    throw new Error('No date supplied');
  }
};

export async function copyInviteToClipboard({
  name,
  opensAt,
  expiresAt,
  opensAtDate,
  opensAtTime,
  enqueueSnackbar,
  url
}: CopyInviteToClipboardProps) {
  try {
    const googleCalendarStartDate = formatDateForGoolgeCalandar(opensAt);
    const googleCalendarEndDate = formatDateForGoolgeCalandar(expiresAt);
    const googleCalendarLink = `https://www.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(
      name
    )}&dates=${googleCalendarStartDate}/${googleCalendarEndDate}`;

    //Escape any html characters in name
    const escapedName = escapeHtml(name);

    //Clipboard text annoyingly needs this weird formatting. Adding tabs inside the text breaks the formatting when it's copied into an email :(
    const clipboardText = `You're invited to join ${escapedName} on ${opensAtDate} starting at ${opensAtTime}.
  
To join, please click this link: ${url}. 
      
To add the meeting to your Google Calendar, please click this link: ${googleCalendarLink}`;

    await navigator.clipboard.writeText(clipboardText);

    enqueueSnackbar('Invite copied to clipboard!');
  } catch (error) {
    console.error('Failed to copy to clipboard:', error);

    enqueueSnackbar('Copying invite to clipboard failed', { variant: 'error' });
  }
}
