import React from "react";
import { Box, Autocomplete, TextField, Typography } from "@mui/material";
import { useMeditationVoices } from "../../../utils/firebase/audioMeditationVoicesUtil";
import { MeditationVoice } from "../../../types/meditationVoice";

type Props = {
  value: string;
  placeholder?: string;
  freeSolo: boolean;
  disabled?: boolean;
  helperText?: React.ReactNode;
  onChangeValue: (value: string) => void;
}

export default function MeditationVoiceAutocomplete({ value, freeSolo, disabled, onChangeValue, helperText, placeholder }: Props) {
  const { meditationVoices } = useMeditationVoices()

  return <><Autocomplete
    disableClearable
    autoSelect
    options={ meditationVoices }
    freeSolo={ freeSolo }
    value={ value }
    getOptionLabel={ (option) => option?.voiceID ?? value }
    onChange={ (event, newValue: MeditationVoice | string) => {

      if (typeof newValue === "string") {
        onChangeValue(newValue)

        return
      }

      onChangeValue(newValue?.voiceID)
    } }

    sx={ { width: "100%", mt: 2 } }
    renderOption={ (props, option) => (
      <Box component="li" sx={ { '& > img': { mr: 2, flexShrink: 0 } } } { ...props }>
        { option.voiceID } ({ option.name })
      </Box>
    ) }
    renderInput={ (params) => (
      <TextField
        { ...params }
        disabled={Boolean(disabled)}
        placeholder= {placeholder ?? "Select Voice ID"}
      />
    ) }
  />
    { Boolean(helperText) &&
        <Typography fontSize={12} sx={{mt: '6px !important', ml: '10px !important'}}
                    className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-1fgbinu-MuiFormHelperText-root">
          { helperText }
        </Typography>
    }
  </>
}
