import {hasValidPermissions} from "../../../utils/helpers";
import {SUPER_ADMIN_ROLES} from "../../../utils/constants";
import {LoadingButton} from "@mui/lab";
import DeleteIcon from "@mui/icons-material/Delete";
import React, {useState} from "react";
import {useCurrentRole} from "../../../hooks/useCurrentRole";
import DeleteConfirmDialog from "./DeleteConfirmDialog";
import {Box} from "@mui/material";
import {deleteVideoCall} from "../../../utils/firebase/chatAnalyticsUtils";
import {useNavigate} from "react-router-dom";
import {useSnackbar} from "notistack";


type Props = {
    chatId: string | null;
    messageId: string | null;
}


export default function DeleteButton({chatId, messageId}: Props) {
    const usrRoles = useCurrentRole();
    const [deleting, setDeleting] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const navigate = useNavigate();
    const {enqueueSnackbar} = useSnackbar();

    function toggleDeleteDialog() {
        setShowDeleteDialog((prevState) => !prevState)
    }

    function deleteVideoCallMessage() {
        if (!chatId || !messageId) {
            return
        }
        setDeleting(true)
        deleteVideoCall(chatId, messageId).then(() => {
            setDeleting(false)
            enqueueSnackbar("Message Deleted Successfully", {variant: "success"});
        }).catch((error) => {
            enqueueSnackbar(error?.message ?? "Something went wrong", {variant: "error"});
            setDeleting(false)
        }).finally(() => {
            navigate(-1);
        })
    }

    if (!chatId || !messageId || !hasValidPermissions(usrRoles, SUPER_ADMIN_ROLES)) {
        return null
    }

    return (
        <Box>
            <LoadingButton
                loading={deleting}
                startIcon={<DeleteIcon/>}
                onClick={toggleDeleteDialog}
            >
                Delete Message
            </LoadingButton>
            <DeleteConfirmDialog
                open={showDeleteDialog}
                loading={deleting}
                onClose={toggleDeleteDialog}
                deleteVideoCallMessage={deleteVideoCallMessage}
            />
        </Box>
    )
}