import firebase from 'firebase/app';
import { db } from 'contexts/FirebaseContext';
import { useEffect, useState } from 'react';

import {RegistrationCode} from '../../types/invitation/code';
import { createRegistrationCode, editRegistrationCode} from 'utils/firebase/cloudFunctions';

export function useRegistrationCodes() {
  const [codes, setCodes] = useState<RegistrationCode[]>([]);
  useEffect(() => {
    const unsub = db
      .collection('registration_codes')
      .onSnapshot(async (snap) => {
        const docs = snap.docs.map((doc) => doc.data());
        setCodes(docs as RegistrationCode[]);
      });
    return () => unsub();
  }, []);

  return codes;
}

export async function getRegistrationCode(code: string): Promise<RegistrationCode | undefined> {
  if (!code) {
    throw new Error('required param {code} is missing.');
  }

  const doc = await firebase
    .firestore()
    .collection('registration_codes')
    .doc(code.toUpperCase())
    .get();

  if (!doc.exists) throw new Error('No code with this id exists');
  return doc?.data?.() as RegistrationCode;
}

export function editCodeOrCreateRegistrationCode(data: RegistrationCode, isCreate: boolean = false) {
  const finalData: any = data;
  if (finalData?.expiresAt) {
    finalData.expiresAt = finalData?.expiresAt?.toDate?.()?.toISOString?.() || '';
  }

  if (finalData?.createdAt) {
    finalData.createdAt =
      finalData?.createdAt?.toDate?.()?.toISOString?.() || new Date().toISOString();
  }

  /**
   * seperate cloud functions for edit & create, because of seprate permissions for manage & create.
   * manage permissions allow edit but don't allow create
   */
  if (isCreate) {
    return createRegistrationCode(finalData);
  }

  return editRegistrationCode(finalData);
}
