import { db, firebaseAuth } from '../../contexts/FirebaseContext';
import { collections } from '../constants';

export function forceRefreshClaims(uid: string) {
  return db
    .collection(collections.C_CLAIM_AUTOSYNC_UTIL)
    .doc(`${uid}`)
    .onSnapshot(() => {
      firebaseAuth.currentUser?.getIdToken(true);
    });
}
