import { Box, List, Tooltip, Typography } from "@mui/material";
import LoadingRevisionList from "../atoms/LoadingRevisionList";
import { Revision } from "../types/Revision";
import createRevisionListItem from "../utils/createRevisionListItem";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

export default function RevisionList(props: {
  revisions: Revision[] | undefined;
  loading: boolean;
  selectedRevision: string;
  onSelect: (revisionId: string) => void;
}) {
  const { revisions, loading, selectedRevision, onSelect } = props;

  if (loading) {
    return (
      <>
        <h2>Known revisions ({revisions?.length ?? "?"})</h2>
        <List dense>{loading && <LoadingRevisionList />}</List>
      </>
    );
  }
  return (
    <>
      <Box display="flex" alignItems="center" mb={2}>
        <Typography variant="h4">
          Known revisions ({revisions?.length ?? "?"}){" "}
        </Typography>
        <Tooltip title="New revisions are automatically loaded">
          <FiberManualRecordIcon sx={{ marginLeft: 2, color: "orangered" }} />
        </Tooltip>
      </Box>
      <List
        dense
        sx={{ overflowY: "scroll", overflowX: "hidden", height: "60vh" }}
      >
        {revisions?.map((revision: any) =>
          createRevisionListItem(revision, selectedRevision, onSelect)
        )}
        {!revisions || (!revisions.length && <p>No revisions</p>)}
      </List>
    </>
  );
}
