import { Icon } from '@iconify/react';
import { useRef, useState } from 'react';
import editFill from '@iconify/icons-eva/edit-fill';
import personRemoveOutline from '@iconify/icons-eva/person-remove-outline';
import personAddOutline from '@iconify/icons-eva/person-add-outline';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import viewIcon from '@iconify/icons-ant-design/read-outlined';
import barChart from '@iconify/icons-ant-design/bar-chart';

// material
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
  CircularProgress
} from '@mui/material';

// ----------------------------------------------------------------------

type UserMoreMenuProps = {
  onDelete: VoidFunction;
  onEdit: VoidFunction;
  onView?: VoidFunction;
  onViewProgress?: VoidFunction;
  isDeactivating?: boolean;
  isDeactivated: boolean;
};

export default function UserMoreMenu({ isDeactivating, onDelete, onEdit, onView, onViewProgress, isDeactivated }: UserMoreMenuProps) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem onClick={onDelete} sx={{ color: 'text.secondary' }}>
          <ListItemIcon>
            <Icon icon={isDeactivated ? personAddOutline : personRemoveOutline} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary={isDeactivated ? "Activate" : "Deactivate"} primaryTypographyProps={{ variant: 'body2' }} />
          {isDeactivating && (
            <CircularProgress
              sx={{
                position: 'absolute',
                alignSelf: 'center',
                right: 10
              }}
              size={25}
            />
          )}
        </MenuItem>

        <MenuItem onClick={onView} sx={{ color: 'text.secondary' }}>
          <ListItemIcon>
            <Icon icon={viewIcon} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="View" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>

        <MenuItem onClick={onEdit} sx={{ color: 'text.secondary' }}>
          <ListItemIcon>
            <Icon icon={editFill} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Edit" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>

        <MenuItem onClick={onViewProgress} sx={{ color: 'text.secondary' }}>
          <ListItemIcon>
            <Icon icon={barChart} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="View Progress" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
      </Menu>
    </>
  );
}
