import { Autocomplete, TextField } from "@mui/material";
import React, { ChangeEvent } from "react";

import { MultilingualLocalesTypes } from "../../../types/translations";
import { MULTI_LINGUAL_OPTION } from "../../LanguageSelectionDropDown";
import { SupportedLanguages } from "../../../types/invitation/code";

type Props = {
  selectedTargetLanguages?: MultilingualLocalesTypes[];
  onSelectMultilingualLanguage?: (selectedLangs: MultilingualLocalesTypes[]) => void;
  localeList?: MultilingualLocalesTypes[];
  selectedLanguage: SupportedLanguages | string;
  allowMultipleSelect?: boolean;
  freeSolo?: boolean;
  placeholder?: string;
}


export default function MultiLingualAutocomplete({
                                                   selectedTargetLanguages,
                                                   onSelectMultilingualLanguage,
                                                   localeList = [],
                                                   selectedLanguage,
                                                   allowMultipleSelect = true,
                                                   freeSolo = false,
                                                   placeholder
                                                 }: Props) {

  if (!localeList?.length || selectedLanguage !== MULTI_LINGUAL_OPTION) return null

  const handleSelectAll = () => {
    onSelectMultilingualLanguage?.(localeList)
  };

  return <Autocomplete
    multiple={ allowMultipleSelect }
    id="translation-key-dropdown"
    disableClearable
    options={ localeList }
    getOptionLabel={ (option: any) => option.name ?? "" }
    onChange={ (event: any, selectedValues: MultilingualLocalesTypes[] | MultilingualLocalesTypes | any) => {
      onSelectMultilingualLanguage?.((allowMultipleSelect ? selectedValues : [selectedValues]) as MultilingualLocalesTypes[])
    } }
    renderOption={ (props, option: any) =>
      <>
        { option.name === localeList?.[0].name && allowMultipleSelect && (
          <li { ...props } key={ -1 }
              style={ { backgroundColor: selectedTargetLanguages?.length === localeList?.length ? "primary" : "white" } }
              onClick={ handleSelectAll }>All</li>) }

        <li { ...props }>{ option.name }</li>
      </>
    }
    value={ allowMultipleSelect ? selectedTargetLanguages : (selectedTargetLanguages?.[0] ?? selectedTargetLanguages) }
    isOptionEqualToValue={ (option: any, value) => option.locale === value.locale }
    sx={ { width: "100%", mt: 3, mb: 3 } }
    defaultValue={ localeList }
    disableCloseOnSelect={ Boolean(allowMultipleSelect) }
    freeSolo={ freeSolo }
    renderInput={ (params) => (
      <TextField
        { ...params }
        onChange={ (event: ChangeEvent<HTMLInputElement>) => {
          if (freeSolo) {
            onSelectMultilingualLanguage?.([{ name: event.target.value, locale: "" }])
          }
        } }
        placeholder={ placeholder ?? "Select Multilingual Languages To Translate" }
      />
    ) }
  />
}
