import { useEffect, useRef, useState } from "react";
import { AudioMeditationGenerationType } from "../../types/audioMeditationGenerations";
import firebase from "firebase";
import { db } from "../../contexts/FirebaseContext";

export function useUserGeneratedAudioMeditations(pageNo: number, pageSize: number) {
  const [loading, setLoading] = useState<boolean>(true);
  const [isMoreMeditationsAvailable, setIsMoreMeditationsAvailable] = useState<boolean>(true);
  const lastSnapShot = useRef<any>({});
  const [audioMeditations = [], setAudioMeditations] = useState<Array<AudioMeditationGenerationType>>([]);

  useEffect(() => {
    let dbRef: firebase.firestore.CollectionReference | firebase.firestore.Query = db
      .collectionGroup("user_generated_audio_meditations")

    dbRef = dbRef
      .orderBy("createdAt", "desc")

    if (lastSnapShot?.current[pageNo]) {
      dbRef = dbRef.startAfter(lastSnapShot?.current[pageNo] ?? 0)
    }

    setLoading(true);
    let unsub = dbRef
      .limit(pageSize)
      .onSnapshot(async (snapshot) => {
        const snapShotKey = pageNo + 1
        lastSnapShot.current[snapShotKey] = snapshot.docs[snapshot.docs.length - 1]
        const dbAudioMeditations = []
        for await(const doc of snapshot.docs) {
          const meditationItem = doc.data();
          dbAudioMeditations.push({
            ...(meditationItem as AudioMeditationGenerationType), id: doc.id, createdAt:
              (meditationItem.createdAt as firebase.firestore.Timestamp)?.toDate(),
            userId: doc.ref.parent.parent?.id ?? ""
          });
        }

        setAudioMeditations(dbAudioMeditations ?? []);

        setIsMoreMeditationsAvailable(dbAudioMeditations.length === pageSize)

        setLoading(false);
      });
    return () => unsub();
  }, [pageNo, pageSize]);


  return { audioMeditations, loading, isMoreMeditationsAvailable };
}

export const deleteUserGeneratedAudioMeditationByIdFromDB = async (
  selectedMeditation: AudioMeditationGenerationType
): Promise<void> => {
  return db
    .collectionGroup("user_generated_audio_meditations")
    .where(firebase.firestore.FieldPath.documentId(), "==", `/users/${selectedMeditation?.userId}/user_generated_audio_meditations/${ selectedMeditation?.id ?? "" }`)
    .get()
    .then((querySnapshot) => querySnapshot.docs?.[0]?.ref?.delete());
}
