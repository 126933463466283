import {db} from "../../contexts/FirebaseContext";
import {GroupProgressInsightsData} from "../../types/CounsellorProgressDasboard";

export async function retrieveLastCounsellorProgress(therapistId: string) {
   return db.collection(`therapists/${therapistId}/progress_insights`)
        .where("version", "==", 12)
        .orderBy("createdAt", "desc").limit(1)
        .get().then((res) => {
        if (res.size) {
            const lastProgress = res.docs?.[0]?.data();
            return JSON.parse(lastProgress?.data ?? `{}`) as GroupProgressInsightsData;
        }
        return null;
    }).catch((error) => {
        console.error(error)
        return null;
    })
}
