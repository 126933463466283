import { DatePicker } from "@mui/lab";
import { TextField } from "@mui/material";
import { useFormik } from "formik";

type DatePickerProps = {
  label: string;
  field: string;
} & Pick<
  ReturnType<typeof useFormik>,
  "getFieldProps" | "getFieldMeta" | "setFieldValue"
>;
export default function DatePickerWithFormik(props: DatePickerProps) {
  const { label, field } = props;
  const { getFieldProps, setFieldValue, getFieldMeta } = props;
  const { error } = getFieldMeta(field);
  return (
    <DatePicker
      label={label}
      value={getFieldProps(field).value}
      inputFormat={"dd/MM/yyyy"}
      onChange={(value) => {
        setFieldValue(field, value);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          {...getFieldProps(field)}
          fullWidth
          helperText={error}
          error={Boolean(error)}
        />
      )}
    />
  );
}
