import { Icon } from "@iconify/react";
import { useState } from "react";
import plusFill from "@iconify/icons-eva/plus-fill";
import { Link as RouterLink, useNavigate } from "react-router-dom";
// material
import {
  Button,
  Card,
  Container,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";

// routes
import { PATH_DASHBOARD } from "../../../routes/paths";

// hooks
import useSettings from "../../../hooks/useSettings";

// components
import Page from "../../../components/Page";
import Scrollbar from "../../../components/Scrollbar";
import SearchNotFound from "../../../components/SearchNotFound";
import HeaderBreadcrumbs from "../../../components/HeaderBreadcrumbs";
import TableToolbar from "../../../components/common/TableToolbar";
import TableHeader from "../../../components/common/TableHeader";
import MoreMenu from "../../../components/common/MoreMenu";
import { filter } from "lodash";
import { Anonymous } from "../../../types/list";
import DeleteConfirmationDialog from "components/_dashboard/content/DeleteConfirmationDialog";
import { useSnackbar } from "notistack";
import { useGetTemplates } from "../../../hooks/useGetTemplates";
import { Template } from "../../../types/template";
import {format} from "date-fns";
import {dateTimeDisplayFormat} from "../../../utils/analytics/analyticsDefaults";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "id", label: "Id", alignRight: false },
  { id: "createdOrUpdatedAt", label: "Created/Updated At", alignRight: false },
  { id: "actions", label: "Actions", alignRight: true },
];

// ----------------------------------------------------------------------

function applySortFilter(
  array: Array<Template>,
  comparator: (a: any, b: any) => number,
  query: string
) {
  const stabilizedThis = array.map((el, index) => [el, index] as const);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (group) => group.id.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

function descendingComparator(a: Anonymous, b: Anonymous, orderBy: string) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order: string, orderBy: string) {
  return order === "desc"
    ? (a: Anonymous, b: Anonymous) => descendingComparator(a, b, orderBy)
    : (a: Anonymous, b: Anonymous) => -descendingComparator(a, b, orderBy);
}

export default function TemplatesList() {
  const { themeStretch } = useSettings();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [selected, setSelected] = useState<string[]>([]);
  const [orderBy, setOrderBy] = useState("name");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [templateToDelete, setTemplateToDelete] = useState<string | null>(null);
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();

  const {
    templates = [],
    loading,
    deleteTemplateAndUpdate,
  } = useGetTemplates();

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (checked: boolean) => {
    if (checked) {
      const newSelecteds = templates?.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (SelectedFilterName: string) => {
    setFilterName(SelectedFilterName);
  };

  const handleOpenDeleteDialog = (templateID: string) => {
    setTemplateToDelete(templateID);
    setOpenDeleteDialog(true);
  };

  const handleDelete = async () => {
    try {
      setLoadingDelete(true);
      await deleteTemplateAndUpdate(templateToDelete!);
      enqueueSnackbar("Template deleted successfully", { variant: "success" });
    } catch (error) {
      enqueueSnackbar("Error deleting template", { variant: "error" });
    } finally {
      setLoadingDelete(false);
      setOpenDeleteDialog(false);
    }
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - templates.length) : 0;

  const filteredGroup = applySortFilter(
    templates,
    getComparator(order, orderBy),
    filterName
  );

  const isGroupNotFound = filteredGroup.length === 0 && !loading;

  return (
    <Page title="templates">
      <Container maxWidth={themeStretch ? false : "lg"}>
        <HeaderBreadcrumbs
          heading=""
          links={[]}
          action={
            <Button
              variant="contained"
              component={RouterLink}
              to={PATH_DASHBOARD.templates.new}
              startIcon={<Icon icon={plusFill} />}
            >
              Add New Template
            </Button>
          }
        />

        <Card>
          <TableToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            searchPlaceholder={"Search"}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <TableHeader
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={templates.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                  hideCheckbox
                />
                <TableBody>
                  {filteredGroup
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row: Template) => {
                      const { id } = row;
                      const isItemSelected = selected.indexOf(id) !== -1;

                      return (
                        <TableRow
                          hover
                          key={id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            padding="normal"
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                            >
                              <Typography variant="subtitle2" noWrap>
                                {id}
                              </Typography>
                            </Stack>
                          </TableCell>

                          <TableCell
                            component="th"
                            scope="row"
                            padding="normal"
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                            >
                              {row?.createdOrUpdatedAt
                                ? format(
                                    row.createdOrUpdatedAt as Date,
                                    dateTimeDisplayFormat
                                  )
                                : "---"}
                            </Stack>
                          </TableCell>

                          <TableCell align="right">
                            <MoreMenu
                              onEdit={() => {
                                navigate(
                                  `${PATH_DASHBOARD.templates.root}/${id}/edit`,
                                  {
                                    state: row,
                                  }
                                );
                              }}
                              onView={() => {
                                navigate(
                                  `${PATH_DASHBOARD.templates.root}/${id}/view`,
                                  {
                                    state: row,
                                  }
                                );
                              }}
                              onDelete={() => handleOpenDeleteDialog(id)}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isGroupNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={templates.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(e, _page) => setPage(_page)}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          <DeleteConfirmationDialog
            open={openDeleteDialog}
            loading={loadingDelete}
            onClose={() => {
              setTemplateToDelete(null);
              setOpenDeleteDialog(false);
            }}
            handleDelete={handleDelete}
          />
        </Card>
      </Container>
    </Page>
  );
}
