import { TableCell, TableRow, Typography } from "@mui/material";
import { ChineseUserRelationship } from "utils/firebase/chineseUsersRelationshipsUtils";
import FirstAndLastSessionRow from "./FirstAndLastSessionRow";
import RelationshipsMoreMenu from "./RelationshipsMoreMenu";
import RelationshipSubTable from "./RelationshipSubTable";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

type RelationshipRowProps = {
    relationship: ChineseUserRelationship;
    isOpen: boolean;
    isDeleting: boolean;
    setOpenRowId: (id: string) => void;
    handleDeleteRelationship: (id: string) => void;
    handleDeleteSession: (relationshipId: string, sessionId: string) => void;
};

export default function RelationshipRow({
    relationship,
    isOpen,
    isDeleting,
    setOpenRowId,
    handleDeleteRelationship,
    handleDeleteSession,
}: RelationshipRowProps) {
    return (
        <>
        <TableRow key={relationship.id}>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpenRowId(!isOpen ? relationship.id : "")}
            >
              {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell>
            <Typography align="center" variant="body2">{relationship.userEmail}</Typography>
          </TableCell>
          <TableCell>
            <Typography align="center" variant="body2">{relationship.counsellor.name}</Typography>
          </TableCell>
          <TableCell>
            <Typography align="center" variant="body2">{relationship.status}</Typography>
          </TableCell>
          <TableCell>
            <Typography align="center" variant="body2">{relationship.invitationCode ?? "---"}</Typography>
          </TableCell>
          <TableCell>
            <Typography align="center" variant="body2">{relationship.completedSessions?.length}</Typography>
          </TableCell>

          <FirstAndLastSessionRow completedSessions={relationship.completedSessions} />

          <TableCell>
            <Typography align="center" variant="body2">{relationship.lastRequestAt?.toDate().toString() ?? "---"}</Typography>
          </TableCell>
          
          <TableCell>
            <RelationshipsMoreMenu 
                id={relationship.id} 
                onDelete={() => handleDeleteRelationship(relationship.id)} 
                isDeleting={isDeleting}
            />
          </TableCell>
        </TableRow>
        
        <RelationshipSubTable 
          isOpen={isOpen} 
          rowId={relationship.id} 
          handleDeleteSession={handleDeleteSession}
        />
      </>
    )
}
