import React from "react";
import { styled, useTheme } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import { WellbeingColorPalette } from "../../theme/palette";

const OverallScoreCircle = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  borderRadius: theme.spacing(2),
  paddingLeft: 10,
  paddingRight: 10,
}));

const Score = styled(Typography)(() => ({
  fontSize: 16,
  fontWeight: 400,
}));

type Props = {
  score: number | string;
  bgcolor: WellbeingColorPalette;
};
export default function ScoresCircle({ score, bgcolor }: Props) {
  const theme = useTheme();

  return (
    <OverallScoreCircle bgcolor={theme.palette.wellbeing[bgcolor]}>
      <Score fontWeight={600}>{score}</Score>
    </OverallScoreCircle>
  );
}
