import { ReactNode } from "react";

export enum CounsellorProgressAssessmentQuestion {
  Z1 = "Z1",
  Z2 = "Z2",
  Z3 = "Z3",
  Z4 = "Z4",
}

export type AssessmentRecord = {
  title: string;
  question: CounsellorProgressAssessmentQuestion;
  icon: ReactNode;
};

export type AssessmentQuestionsData = Record<
  CounsellorProgressAssessmentQuestion,
  {
    validUserCount: number;
    overallScore: number;
    firstSessionWithResult?: number;
    averageScoresForAllSessions: number[];
    assessmentsCountByUsersForAllSessions: number[][];
  }
>;

export type GroupProgressInsightsData = {
  totalChats: number;
  averageScore: number;
  assessmentQuestionsData: AssessmentQuestionsData;
};
