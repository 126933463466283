import { createContext } from "react";
import { CreditPeriodsContextValue, InitialValues } from "../types";

export default createContext<CreditPeriodsContextValue>({
  invitationCode: undefined,
  formDialogIsOpen: false,
  values: InitialValues,
  handleClickOpen: () => {},
  handleClose: () => {},
  handleSubmit: () => Promise.resolve(),
});
