import { Box, Button, Container } from "@mui/material";
import LoadingScreen from "../../components/LoadingScreen";
import { PATH_DASHBOARD } from "routes/paths";
import { RenewalChallenge } from "../../types/invitation/renewalChallenge";
import { useSelector } from "redux/store";
import {
  getSelectors,
  renewalChallengeSlice,
} from "redux/slices/renewalChallengeSlice";
import Page from "components/Page";
import HeaderBreadcrumbs from "components/HeaderBreadcrumbs";
import useSettings from "hooks/useSettings";
import { useNavigate, useParams } from "react-router-dom";
import RenewalChallengeForm from "./RenewalChallengeForm";
import { Icon } from "@iconify/react";
import plusFill from "@iconify/icons-eva/plus-fill";
import { useEffect, useState } from "react";
import QRCodeFromDeepLink from "./QRCodeFromDeepLink";
import StringWithCopyOption from "components/StringWithCopyOption";
import { createDeepLink } from "./QRCodePage";

export type RenewalChallengeLinkData = {
  renewalChallengeCode: string;
  marketingTitle?: string;
  $desktop_url: "https://care.kyanhealth.com";
};

export default function RenewalChallengeUpdatePage() {
  const { code: invitationCode, challengeId } = useParams();
  const { themeStretch } = useSettings();

  const navigate = useNavigate();
  const [updateChallenge, { isLoading: isUpdating }] =
    renewalChallengeSlice.useUpdateRenewalChallengeMutation();
  const { isFetching } = renewalChallengeSlice.useLoadRenewalChallengesQuery(
    invitationCode ?? ""
  );
  const { selectById } = getSelectors(invitationCode ?? "");
  const existingChallenge = useSelector(selectById(challengeId ?? ""));
  const [deepLink, setDeepLink] = useState<string | null>(null);

  useEffect(() => {
    setDeepLink(existingChallenge?.renewalDeepLinkUrl || null);
  }, [existingChallenge]);

  if (isFetching || isUpdating) {
    return <LoadingScreen />;
  }

  let initial = {
    active: true,
    passcode: "",
    softLockFrom: new Date(new Date().setHours(0, 0, 0, 0)),
    hardLockFrom: new Date(new Date().setHours(0, 0, 0, 0)),
    en: "",
    de: "",
    fr: "",
    es: "",
    renewalDeepLinkUrl: "",
  };
  if (existingChallenge) {
    initial = {
      ...initial,
      ...existingChallenge,
      ...existingChallenge.note,
      renewalDeepLinkUrl: existingChallenge.renewalDeepLinkUrl ?? "",
    };
  }

  const updateWithQRCode = async (renewalChallenge: RenewalChallenge | undefined) => {
    if (!renewalChallenge) {
      return;
    }

    const linkData: RenewalChallengeLinkData = {
      renewalChallengeCode: renewalChallenge.passcode,
      marketingTitle: "Renewal Challenge passcode",
      $desktop_url: "https://care.kyanhealth.com",
    };
    const link = await createDeepLink(linkData);
    setDeepLink(link);

    updateChallenge({
      invitationCode: invitationCode ?? "",
      challenge: {
        ...renewalChallenge,
        renewalDeepLinkUrl: link,
      },
    });
  };


  return (
    <Page title="Renewal Challenges">
      <Container maxWidth={themeStretch ? false : "lg"}>
        <HeaderBreadcrumbs
          heading="Renewal Challenges"
          links={[
            {
              name: "Invitation Code List",
              href: PATH_DASHBOARD.invitationCodes.root,
            },
            {
              name: invitationCode ?? "",
              href:
                PATH_DASHBOARD.invitationCodes.root +
                "/" +
                invitationCode +
                "/" +
                "renewal-challenge",
            },
            {
              name: challengeId ?? "",
            },
          ]
        }
        action={
          <Button
            disabled={!!deepLink}
            onClick={() => {
              updateWithQRCode(existingChallenge);
            }}
            variant="contained"
            startIcon={<Icon icon={plusFill} />}
          >
            QR Code
          </Button>
        }
        />

        {deepLink && (
          <Box
            display={"flex"}
            justifyContent={"center"}
            flexDirection={"column"}
            alignItems={"center"}
          >
            <QRCodeFromDeepLink url={deepLink} fileName={challengeId || "no_code"} />
            <StringWithCopyOption text={deepLink} variant="h6" />
          </Box>
        )}

        <RenewalChallengeForm
          initialValues={initial}
          onSubmit={async (values): Promise<void> => {
            const challenge: RenewalChallenge = {
              id: challengeId ?? "",
              active: values.active,
              passcode: values.passcode,
              softLockFrom: values.softLockFrom,
              hardLockFrom: values.hardLockFrom,
              note: {
                en: values.en,
                de: values.de,
                fr: values.fr,
                es: values.es,
              },
            };

            if (values.passcode !== existingChallenge?.passcode) {
              await updateWithQRCode(challenge);
              return;
            } else {
              await updateChallenge({
                invitationCode: invitationCode ?? "",
                challenge,
              });
            }

            navigate(
              PATH_DASHBOARD.invitationCodes.root +
                "/" +
                invitationCode +
                "/" +
                "renewal-challenge"
            );
          }}
        />
      </Container>
    </Page>
  );
}
