import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import {Button} from "@mui/material";

type Props = {
    checked: boolean;
    onClick: () => void;
    label: string;
    disabled?: boolean;
}

export default function ToggleButton({checked, label, onClick, disabled}: Props){
    return <Button
        startIcon={checked ? <CheckCircleIcon/> : <RadioButtonUncheckedIcon/>}
        onClick={onClick}
        disabled={disabled ? disabled : false}
    >
        {label}
    </Button>
}