import { useEffect, useState } from "react";
import { ChineseUserRelationship, getChineseUserRelationships } from "utils/firebase/chineseUsersRelationshipsUtils";

export function useGetChineseUserRelationships() {
  const [relationships, setRelationships] = useState<ChineseUserRelationship[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    let unListen: any;
    try {
      unListen = getChineseUserRelationships((res: ChineseUserRelationship[]) => {
        setRelationships(res);
        setLoading(false);
      });
    } catch (err) {
      console.error(err);
      setError(true);
    }

    // clear listening
    return () => {
      unListen?.();
    };
  }, []);

  return {
    loading,
    error,
    relationships,
  };
}
