import {useEffect, useState} from "react";
import {db} from "../../contexts/FirebaseContext";

export type PromptContent = {
    filterContentUnits: Array<string>;
    filterContentFieldsByType: {
        article?: Array<string>;
        meditation?: Array<string>;
        reflection?: Array<string>;
        video?: Array<string>;
        contentLink?: Array<string>;
    },
    filterModules: Array<string>;
}

export function usePromptContent() {
    const [loading, setLoading] = useState<boolean>(false);
    const [promptContent, setPromptContent] = useState<PromptContent>()

    useEffect(() => {
        setLoading(true)
        const unsub = db
            .collection("settings")
            .doc("promptContent")
            .onSnapshot(
                (doc) => {
                    if (doc?.exists) {
                        setPromptContent(doc.data() as PromptContent)
                    }
                    setLoading(false)
                }
            );

        return () => unsub();
    }, []);

    return {promptContent, loading}
}

export async function savePromptContentData(data: PromptContent) {
    return db
        .collection("settings")
        .doc("promptContent")
        .set(data, {merge: true});
} 