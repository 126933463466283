import {db} from "contexts/FirebaseContext";
import {useEffect} from "react";
import {ContentVersions, setContentVersion} from "../redux/slices/contentVersions";
import {dispatch} from "../redux/store";

export default function useSyncContentVersions() {
    useEffect(() => {
        const ref = db
            .collection("version_code")
            .doc("contentVersions")
            .onSnapshot(
                (doc) => {
                    if (doc?.exists) {
                        const data = doc.data() as ContentVersions;
                        dispatch(setContentVersion(data))
                    }
                }
            );

        return () => ref()
    }, [])

}