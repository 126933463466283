import * as Yup from "yup";
import { Form, FormikProvider, useFormik } from "formik";
import { useEffect, useState } from "react";
import { Autocomplete } from "@mui/material";
import { collections } from "../utils/constants";

import { LoadingButton } from "@mui/lab";
import {  Stack, TextField, Typography, Container } from "@mui/material";
import { Locale } from "types/user";
import Page from "../components/Page";
import HeaderBreadcrumbs from "../components/HeaderBreadcrumbs";
import firebase from "firebase";
import { sendTemplateEmail } from "utils/firebase/cloudFunctions";
import { useSnackbar } from "notistack";


type FormValues = {
  emailTemplateId: string;
  locale: Locale;
  emailAddresses: string;
};

const EmailTemplateSchema = Yup.object().shape({
  emailTemplateId: Yup.string().required("Email template ID is required"),
  locale: Yup.string().required("Locale is required"),
  emailAddresses: Yup.string()
    .required("Please enter at least one email address")
})

type EmailTemplate = {
  id: string;
  title: string;
};

type LocaleOption = {
  id: string;
  label: string;
};

export function EmailTemplates() {
  const [emailTemplates, setEmailTemplates] = useState<EmailTemplate[]>([]);
  const [localeOptions, setLocaleOptions] = useState<LocaleOption[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [templatesQuery, localesQuery] = await Promise.all([
          firebase.firestore().collection(collections.ONBOARDING_EMAILS).get(),
          firebase.firestore().collection(collections.MULTILINGUAL_LOCALES).get()
        ]);
        
        const templates = templatesQuery.docs.map(doc => ({
          id: doc.id,
          title: doc.data().title || doc.id
        }));

        const locales = localesQuery.docs.map(doc => ({
          id: doc.id.toLowerCase(),
          label: doc.id
        }));

        const localesWithEnglish = [{id: Locale.EN, label: Locale.EN.toUpperCase()}, ...locales];

        setEmailTemplates(templates);
        setLocaleOptions(localesWithEnglish);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const emailForm = useFormik<FormValues>({
    initialValues: {
      emailTemplateId: "",
      locale: Locale.EN as Locale,
      emailAddresses: "",
    },
    validationSchema: EmailTemplateSchema,
    onSubmit: async (values) => {

        try{
          await sendTemplateEmail({
            emailId: values.emailTemplateId,
            locale: values.locale,
            emailAddresses: values.emailAddresses,
          });

          enqueueSnackbar("Email sent successfully!", { variant: "success" });  
        } catch (error) {
          console.error('Error sending email:',error);
          enqueueSnackbar("Failed to send email", { variant: "error" });
        }
    },
  });
  const { isSubmitting, handleSubmit, setFieldValue, values, errors } = emailForm;

  return (
    <Page title="Email Templates">
      <Container>
        <HeaderBreadcrumbs heading="Email Templates" links={[]} />
        <Typography textAlign="center" variant="h6" sx={{ mb: 3 }}>
          Enter details to send the email template
        </Typography>
        <FormikProvider value={emailForm}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            
                <Stack paddingTop="32px" spacing={3}>
                  <Autocomplete
                    fullWidth
                    options={emailTemplates}
                    getOptionLabel={(option) => option.title}
                    value={emailTemplates.find(t => t.id === values.emailTemplateId) || null}
                    onChange={(_, newValue) => {
                      setFieldValue('emailTemplateId', newValue?.id || '');
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Email Template"
                        error={Boolean(errors.emailTemplateId)}
                        helperText={errors.emailTemplateId}
                      />
                    )}
                  />
                  <Autocomplete
                    fullWidth
                    options={localeOptions}
                    getOptionLabel={(option) => option.label}
                    value={localeOptions.find(l => l.id === values.locale) || null}
                    onChange={(_, newValue) => {
                      setFieldValue('locale', newValue?.id || '');
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Locale"
                        error={Boolean(errors.locale)}
                        helperText={errors.locale}
                      />
                    )}
                  />
                  <TextField
                    fullWidth
                    {...emailForm.getFieldProps("emailAddresses")}
                    placeholder="email@example.com"
                    type="text"
                    label="Email addresses"
                    error={Boolean(errors.emailAddresses)}
                    helperText={errors.emailAddresses}
                    multiline
                    rows={4}
                  />
                  <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                  >
                    Send template
                  </LoadingButton>
                </Stack>
          </Form>
        </FormikProvider>
      </Container>
    </Page>
  );
}
