import React from "react";
import { useLocation } from "react-router-dom";
// material
import { Container } from "@mui/material";
// hooks
import useSettings from "../../../hooks/useSettings";
// components
import Page from "../../../components/Page";
import HeaderBreadcrumbs from "../../../components/HeaderBreadcrumbs";
import UserGeneratedAudioMeditationForm
  from "../../../components/_dashboard/user-generated-audio-meditations/UserGeneratedAudioMeditationForm";
import { AudioMeditationGenerationType } from "../../../types/audioMeditationGenerations";

// ----------------------------------------------------------------------

type ViewUserGeneratedAudioMeditationType = {
  audioMeditation?: AudioMeditationGenerationType
}

export default function ViewUserGeneratedAudioMeditation() {
  const { themeStretch } = useSettings();
  const { state } = useLocation();

  const audioMeditation = (state as  ViewUserGeneratedAudioMeditationType)?.audioMeditation

  let pageTitle = "View User Generated Audio Meditation";


  return (
    <Page title={ pageTitle }>
      <Container maxWidth={ themeStretch ? false : "lg" }>
        <HeaderBreadcrumbs heading={ pageTitle } links={ [] }/>

        <UserGeneratedAudioMeditationForm
          loading={false}
          audioMeditation={ audioMeditation }
        />
      </Container>
    </Page>
  );
}
