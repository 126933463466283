import { useRef, useState } from "react";
import { FormikHelpers } from "formik";
import firebase from "firebase/app";
import "firebase/auth";

import {
  TwoFactorVerificationForm,
  VerificationCodeFormValues,
} from "components/authentication/login/TwoFactorVerificationForm";
import {
  PhoneFormValues,
  PhoneNumberForm,
} from "pages/authentication/PhoneNumberForm";
import { ReauthenticateForm, PasswordFormValues } from "./ReauthenticateForm";
import { EmailVerificationDialog } from "./EmailVerificationDialog";

import { AuthErrorCode } from "../types/authentication";

import { useMultiFactorRegistration } from "hooks/useMultiFactorRegistration";

import { obfuscatePhoneNumber } from "utils/obfuscatePhoneNumber";
import { isProviderUsed } from "utils/isProviderUsed";

type EnrollMFADialogProps = {
  onError: () => void;
  onSuccess: () => void;
};

enum EnrollMFADialogSteps {
  PHONE_NUMBER = "phoneNumber",
  REFRESH_LOGIN = "refreshLogin",
  CONFIRM = "confirm",
}

/**
 * A three-step dialog for enrolling another authentication factor involves:
 * 1. Ensuring that the user has recently authenticated themselves.
 * 2. Requesting the user to provide a phone number for enrollment.
 * 3. Sending a verification code to the provided number and confirming the addition of the new authentication factor.
 */
export function EnrollMFADialog({ onError, onSuccess }: EnrollMFADialogProps) {
  const { startRegistration, finishRegistration } = useMultiFactorRegistration(
    firebase.auth()
  );

  const [phone, setPhoneNumber] = useState<string>("");
  const [step, setStep] = useState<EnrollMFADialogSteps>(
    EnrollMFADialogSteps.REFRESH_LOGIN
  );

  const recaptchaVerifier = useRef<firebase.auth.RecaptchaVerifier>();

  const currentUser = firebase?.auth()?.currentUser;
  const email = currentUser?.email;
  if (!currentUser || !email) {
    console.error("No user found to refresh the session for.");
    onError();
    return null;
  }

  const { emailVerified } = currentUser;

  if (!emailVerified) {
    return <EmailVerificationDialog />;
  }

  if (step === EnrollMFADialogSteps.REFRESH_LOGIN) {
    const usesPasswordAuthentication = isProviderUsed(
      currentUser,
      firebase?.auth?.EmailAuthProvider?.PROVIDER_ID
    );
    const usesGoogleSignIn = isProviderUsed(
      currentUser,
      firebase?.auth?.GoogleAuthProvider?.PROVIDER_ID
    );

    const onSubmit = async (
      values: PasswordFormValues,
      helpers: FormikHelpers<PasswordFormValues>
    ) => {
      const { password } = values;
      const cred = firebase?.auth?.EmailAuthProvider?.credential(
        email,
        password
      );

      try {
        await currentUser?.reauthenticateWithCredential(cred);
        setStep(EnrollMFADialogSteps.PHONE_NUMBER);
      } catch (e) {
        if (e.code !== AuthErrorCode.INVALID_PASSWORD) {
          console.error("Error reauthenticating the current user", e);
          onError();
          return;
        }
        helpers.setFieldError(
          "password",
          "The password you entered is incorrect"
        );
      }
    };

    const onGoogleSignIn = () => {
      currentUser
        .reauthenticateWithPopup(new firebase.auth.GoogleAuthProvider())
        .then(() => {
          setStep(EnrollMFADialogSteps.PHONE_NUMBER);
        });
    };

    return (
      <ReauthenticateForm
        showPasswordAuthentication={usesPasswordAuthentication}
        showGoogleAuthentication={usesGoogleSignIn}
        email={email}
        onGoogleSignIn={onGoogleSignIn}
        onSubmit={onSubmit}
      />
    );
  }

  if (step === EnrollMFADialogSteps.PHONE_NUMBER) {
    const onSubmit = async (values: PhoneFormValues) => {
      const { phoneNumber } = values;

      recaptchaVerifier.current = new firebase.auth.RecaptchaVerifier(
        "recaptcha-container-id"
      );

      try {
        await startRegistration(phoneNumber, recaptchaVerifier.current);
        setPhoneNumber(phoneNumber);
        setStep(EnrollMFADialogSteps.CONFIRM);
      } catch (e) {
        if (e.code !== AuthErrorCode.CREDENTIAL_TOO_OLD_LOGIN_AGAIN) {
          console.error("Error occurred trying to enroll a new factor", e);
          onError();
          return;
        }
        setStep(EnrollMFADialogSteps.REFRESH_LOGIN);
      }
    };

    return <PhoneNumberForm onSubmit={onSubmit} />;
  }

  const onSubmit = async (
    values: VerificationCodeFormValues,
    helpers: FormikHelpers<VerificationCodeFormValues>
  ) => {
    const { verificationCode } = values;
    const hint = obfuscatePhoneNumber(phone);
    try {
      await finishRegistration(verificationCode, hint);
      onSuccess();
    } catch (e) {
      switch (e.code) {
        case AuthErrorCode.INVALID_CODE:
          helpers.setFieldError(
            "verificationCode",
            "The code you entered doesn't match"
          );
          break;
        default:
          console.warn(e);
          onError();
          return;
      }
    }
  };

  function onResendRequested() {
    if (recaptchaVerifier?.current) {
      recaptchaVerifier?.current?.clear();
    }
    recaptchaVerifier.current = new firebase.auth.RecaptchaVerifier(
      "recaptcha-container-id"
    );

    startRegistration(phone, recaptchaVerifier?.current);
  }

  return (
    <>
      <TwoFactorVerificationForm
        hint={`Enter the code we send to number ${obfuscatePhoneNumber(phone)}`}
        onResendRequested={onResendRequested}
        onSubmit={onSubmit}
      />
    </>
  );
}
