import {
  Box,
  Button,
  Card,
  Grid,
  Checkbox,
  FormControlLabel,
  Stack,
  TextField,
} from "@mui/material";

import { useSnackbar } from "notistack";
import { Form, FormikProvider, useFormik } from "formik";
import { FocusError } from "focus-formik-error";
import { LoadingButton } from "@mui/lab";

import { array, boolean, object, string } from "yup";
import {
  ContentFromFirestore,
  ContentUnitCategories,
  BotsReflectionCategories,
  Page,
  Preview,
} from "models/ContentUnit";
import React, { useEffect, useRef, useState } from "react";
import { pushItem } from "../../../utils/firebase";
import { ContentCollection, updateContentUnit } from "../../../utils/firebase/contentUtils";
import { SupportedLanguages } from "../../../types/invitation/code";
import { Locale } from "../../../types/user";
import { ContentVideoForm } from "./ContentVideoForm";
import { ContentPracticeForm } from "./ContentPracticeForm";
import { ContentMeditationForm } from "./ContentMeditationForm";
import TagsAutocomplete from "./TagsAutocomplete";
import AddIcon from "@mui/icons-material/Add";
import ThemeAutocomplete from "./ThemeAutocomplete";
import DeleteButton from "./DeleteButton";
import { ContentOverviewCardFields } from "./ContentOverviewCardFields";
import { AddEditArticlePage } from "./AddEditAritclePage";
import { ContentAssessmentForm } from "./ContentAssessmentForm";
import { MULTI_LINGUAL_OPTION } from "../../LanguageSelectionDropDown";
import { generateAndSaveMultilingualContentFunction } from "../../../utils/firebase/cloudFunctions";
import { MultilingualLocalesTypes } from "../../../types/translations";
import useMultiLingualLocales from "../../../hooks/useMultiLingualContentGenerationProgress";
import MultiLingualGenerationProgress from "./MultiLingualGenerationProgress";

// ----------------------------------------------------------------------

const ContentUnitSchema = object({
  id: string().required(),
  type: string().required(),
  locale: string().required(),
  landbotId: string().optional(),
  mediaUrl: string().optional(),
  title: string().required(),
  description: string().optional(),
  thumbnail: string().optional(),
  subtitle: string().optional(),
  linkedModuleId: string().optional(),
  overviewCards: array(
    object().shape({
      tabTitle: string(),
      description: string(),
      onlyForCounsellor: boolean(),
    })
  ),
  pages: array(
    object().shape({
      thumbnail: string(),
      markdown: string(),
      exercises: array(string()),
    })
  ),
  hidden: boolean().optional(),
  previewHiddenToTherapists: boolean().optional(),
  previewHiddenToUsers: boolean().optional(),
  isMandatory: boolean().optional(),
  isFeatured: boolean().optional(),
  tags: array(string()),
  recommendationIds: array(string()).optional(),
  themeId: string().optional(),
  audioMeditationId: string().optional(),
  excludeFromSearchIndex: boolean().optional(),
  disableBackgroundAudio: boolean().optional(),
});

type Props = {
  data?: ContentFromFirestore;
  selectedMultilingualLanguages: MultilingualLocalesTypes[];
  selectedLanguage: SupportedLanguages | string;
  deleteContent: (id: string, selectedLanguage: SupportedLanguages) => void;
  contentUnitOptions: Array<string>;
};

export default function ContentForm({
                                      data,
                                      selectedLanguage,
                                      deleteContent,
                                      contentUnitOptions,
                                      selectedMultilingualLanguages
                                    }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [enableFormView, setEnableFormView] = useState<boolean>(false);
  const [selectedThemes, setSelectedThemes] = useState<string>();

  const tagsRef = useRef<string[] | null>(null);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: data ?? {
      id: "",
      assistantId: "",
      type: ContentUnitCategories.PRACTICE,
      locale: selectedLanguage,
      landbotId: "",
      mediaUrl: "",
      thumbnail: "",
      subtitle: "",
      title: "",
      description: "",
      duration: "",
      linkedModuleId: "",
      overviewCards: [],
      pages: [],
      hidden: false,
      previewHiddenToTherapists: false,
      previewHiddenToUsers: false,
      isFeatured: false,
      tags: [],
      themeId: "",
      audioMeditationId: "",
      disableBackgroundAudio: false,
      excludeFromSearchIndex: false,
    },
    validationSchema: ContentUnitSchema,
    onSubmit: async (values) => {
      try {
        if (window.confirm(`Are you sure to save/update changes?`)) {
          enqueueSnackbar(`Updating ${ values.title }`);

          const doc = {
            ...values,
            tags: selectedTags,
            themeId: selectedThemes,
            locale: values.locale as Locale,
          };

          if (selectedLanguage === MULTI_LINGUAL_OPTION) {
            setEnableFormView(false);
            await generateAndSaveMultilingualContentFunction({
              ...doc, collectionName: ContentCollection.CONTENT,
              targetMultilingualLocales: selectedMultilingualLanguages
            });
          } else {
            await updateContentUnit(doc, selectedLanguage as SupportedLanguages);
          }
          enqueueSnackbar("Saved Successfully", { variant: "success" });
        }
      } catch (error) {
        console.error("An error occurred:", error);
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    getFieldProps,
    handleReset,
  } = formik;

  useEffect(()=> {
    if (enableFormView) {
      setEnableFormView(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguage, data?.id])


  const onSaveNonTranslatedFields = async () => {
    const { values } = formik;
    enqueueSnackbar(`Updating Non Translated Fields ${ values.title }`);

    const doc = {
      ...values,
      tags: selectedTags,
      themeId: selectedThemes,
      locale: values.locale as Locale,
    };

    setEnableFormView(false);
    await generateAndSaveMultilingualContentFunction({
      ...doc, collectionName: ContentCollection.CONTENT,
      targetMultilingualLocales: selectedMultilingualLanguages,
      isOnlySaveNonTranslatedFields: true
    });
    enqueueSnackbar("Saved Non Translated Successfully", { variant: "success" });
  }

  const {
    targetMultilingualLocales,
    processedMultilingualLocales
  } = useMultiLingualLocales(selectedLanguage, data?.id ?? getFieldProps("id").value ?? "", ContentCollection.CONTENT);
  const targetLocalesSize = targetMultilingualLocales?.length ?? 0
  const processedLocalesSize = processedMultilingualLocales?.length ?? 0

  // handle change of data passed to the component (user chooses a different item or data loads)
  useEffect(() => {
    if (tagsRef.current !== data?.tags) {
      // the missing data.tags means the user is creating a new item
      const newTags = data?.tags ? data.tags : [];
      setSelectedTags(newTags);
      tagsRef.current = newTags;
    }
  }, [data?.tags]);

  useEffect(() => {
    if (data?.themeId) {
      setSelectedThemes(data?.themeId);
    }
  }, [data?.themeId]);

  const previewKey = "overviewCards";
  const articleKey = "pages";
  const contentUnitOverview = getFieldProps(previewKey).value || [];
  const contentUnitArticle = getFieldProps(articleKey).value || [];

  const addArticlePage = () => {
    setFieldValue(
      articleKey,
      pushItem(
        {
          thumbnail: "",
          markdown: "",
          exercises: [],
        },
        contentUnitArticle || []
      )
    );
  };

  const addOverviewTab = () => {
    setFieldValue(
      previewKey,
      pushItem(
        {
          tabTitle: "",
          description: "",
          onlyForCounsellor: false,
        },
        contentUnitOverview || []
      )
    );
  };

  const changeSelectedTags = (tags: string[]) => {
    setSelectedTags(tags);
  };

  const onSwitchToFormView = () => {
    setEnableFormView(true)
  }

  const changeSelectedThemes = (themes: string) => {
    setSelectedThemes(themes);
  };
  const renderUniqueFields = () => {
    const props = {
      getFieldProps,
      touched,
      errors,
    };

    const currentContentType = data?.type ?? getFieldProps("type").value;

    if (currentContentType === ContentUnitCategories.VIDEO ) {
      return <ContentVideoForm { ...props } />;
    }

    if (currentContentType === ContentUnitCategories.CHAT_VIDEO) {
return <ContentVideoForm { ...props } isChatVideo={true}/>;
    }

    if (currentContentType === ContentUnitCategories.PRACTICE) {
      return <ContentPracticeForm { ...props } />;
    }

    if (currentContentType === ContentUnitCategories.MEDITATION) {
      return <ContentMeditationForm { ...props } />;
    }

    if (currentContentType === ContentUnitCategories.ASSESSMENT_LINK) {
      return <ContentAssessmentForm { ...props } />;
    }
  };

  if (!enableFormView && targetLocalesSize && processedMultilingualLocales && targetLocalesSize !== processedLocalesSize) {
    return <MultiLingualGenerationProgress processedLocalesSize={ processedLocalesSize }
                                           targetLocalesSize={ targetLocalesSize }
                                           onSwitchToFormView={ onSwitchToFormView }
                                           processedMultilingualLocales={ processedMultilingualLocales }/>
  }

  return (
    <Grid container spacing={ 3 }>
      <Grid item xs={ 12 }>
        <Card sx={ { p: 3 } }>
          <FormikProvider value={ formik }>
            <Form noValidate onSubmit={ handleSubmit }>
              <fieldset style={ { border: "none" } }>
                <FocusError formik={ formik }/>
                <Stack spacing={ 3 }>
                  <Stack
                    direction={ { xs: "column" } }
                    spacing={ { xs: 3, sm: 2 } }
                  >
                    <Stack spacing={ 3 }>
                      <TextField
                        fullWidth
                        label="Id"
                        { ...getFieldProps("id") }
                        error={ Boolean(touched.id && errors.id) }
                        helperText={ touched.id && errors.id }
                      />

                      <TextField
                        fullWidth
                        label="Assistant Id"
                        { ...getFieldProps("assistantId") }
                        error={ Boolean(
                          touched.assistantId && errors.assistantId
                        ) }
                        helperText={ touched.assistantId && errors.assistantId }
                      />

                      <TextField
                        select
                        label={ "Content Unit Type" }
                        placeholder={ "Content Unit Type" }
                        { ...getFieldProps("type") }
                        SelectProps={ { native: true } }
                      >
                        { Object.entries(ContentUnitCategories).map(
                          ([key, value]) => {
                            return (
                              <option key={ key } value={ value }>
                                { value }
                              </option>
                            );
                          }
                        ) }
                      </TextField>

                      { renderUniqueFields() }

                      <TextField
                        fullWidth
                        label="Title"
                        { ...getFieldProps("title") }
                        error={ Boolean(touched.title && errors.title) }
                        helperText={ touched.title && errors.title }
                      />
                      <TextField
                        fullWidth
                        label="Description"
                        { ...getFieldProps("description") }
                        error={ Boolean(
                          touched.description && errors.description
                        ) }
                        helperText={ touched.description && errors.description }
                      />
                      <TextField
                        fullWidth
                        label="Linked Module ID"
                        { ...getFieldProps("linkedModuleId") }
                        error={ Boolean(
                          touched.linkedModuleId && errors.linkedModuleId
                        ) }
                        helperText={
                          touched.linkedModuleId && errors.linkedModuleId
                        }
                      />
                      <TextField
                        fullWidth
                        label="Duration"
                        { ...getFieldProps("duration") }
                        error={ Boolean(touched.duration && errors.duration) }
                        helperText={ touched.duration && errors.description }
                      />
                      <TextField
                        select
                        label={ "Reflection Type" }
                        placeholder={ "Reflection Type" }
                        { ...getFieldProps("reflectionCategory") }
                        SelectProps={ { native: true } }
                      >
                        <option value=""></option>
                        { Object.entries(BotsReflectionCategories).map(
                          ([key, value]) => {
                            return (
                              <option key={ key } value={ value }>
                                { value }
                              </option>
                            );
                          }
                        ) }
                      </TextField>
                      <TextField
                        fullWidth
                        label="Audio Meditation ID"
                        { ...getFieldProps("audioMeditationId") }
                        error={ Boolean(touched.audioMeditationId && errors.audioMeditationId) }
                        helperText={ touched.audioMeditationId && errors.audioMeditationId }
                      />
                      <FormControlLabel
                        label="Disable Background Sound"
                        control={ <Checkbox
                          checked={ Boolean(getFieldProps("disableBackgroundAudio").value) } { ...getFieldProps("disableBackgroundAudio") } /> }
                      />
                      <FormControlLabel
                        label="Hidden"
                        control={ <Checkbox
                          checked={ Boolean(getFieldProps("hidden").value) } { ...getFieldProps("hidden") } /> }
                      />
                      <FormControlLabel
                        label="Preview hidden to therapists"
                        control={
                          <Checkbox
                            checked={ Boolean(getFieldProps("previewHiddenToTherapists").value) } { ...getFieldProps("previewHiddenToTherapists") }
                          />
                        }
                      />
                      <FormControlLabel
                        label="Preview hidden to users"
                        control={
                          <Checkbox
                            checked={ Boolean(getFieldProps("previewHiddenToUsers").value) } { ...getFieldProps("previewHiddenToUsers") }
                          />
                        }
                      />
                      <FormControlLabel
                        label="Is featured"
                        control={ <Checkbox
                          checked={ Boolean(getFieldProps("isFeatured").value) } { ...getFieldProps("isFeatured") } /> }
                      />
                      <FormControlLabel
                        label="Exclude from Algolia search index"
                        control={
                          <Checkbox
                            onChange={ (event) =>
                              setFieldValue(
                                "excludeFromSearchIndex",
                                event.target.checked
                              )
                            }
                            checked={ Boolean(
                              getFieldProps("excludeFromSearchIndex").value
                            ) }
                          />
                        }
                      />

                      <TagsAutocomplete
                        selectedTags={ selectedTags }
                        setSelectedTags={ changeSelectedTags }
                      />
                      <ThemeAutocomplete
                        selectedThemes={ selectedThemes ?? "" }
                        setSelectedThemes={ changeSelectedThemes }
                      />

                      { contentUnitOverview?.map(
                        (item: Preview, index: number) => {
                          let titleLabel: string = "Overview Tab Title";
                          let descriptionLabel: string =
                            "Overview Tab Description";
                          if (contentUnitOverview?.length > 1) {
                            titleLabel = `${ titleLabel }# ${ index + 1 }`;
                            descriptionLabel = `${ descriptionLabel }# ${
                              index + 1
                            }`;
                          }
                          return (
                            <ContentOverviewCardFields
                              key={ index }
                              titleLabel={ titleLabel }
                              getFieldProps={ getFieldProps }
                              index={ index }
                              descriptionLabel={ descriptionLabel }
                            />
                          );
                        }
                      ) }

                      { contentUnitArticle?.map((item: Page, index: number) => (
                        <AddEditArticlePage
                          isContentExist={ contentUnitArticle?.length > 1 }
                          key={ index }
                          index={ index }
                          getFieldProps={ getFieldProps }
                          setFieldValue={ setFieldValue }
                          contentUnitOptions={ contentUnitOptions }
                        />
                      )) }
                      <Stack
                        direction={ { xs: "column", sm: "row" } }
                        spacing={ 1 }
                        sx={ {
                          mt: 1,
                          alignItems: "center",
                        } }
                      >
                        <Button
                          color="success"
                          variant="contained"
                          endIcon={ <AddIcon/> }
                          onClick={ addOverviewTab }
                        >
                          Add Overview Tab
                        </Button>

                        <Button
                          color="success"
                          variant="contained"
                          endIcon={ <AddIcon/> }
                          onClick={ addArticlePage }
                        >
                          Add Article Page
                        </Button>
                      </Stack>
                    </Stack>
                  </Stack>
                </Stack>
                <Box
                  sx={ { mt: 3, display: "flex", justifyContent: "flex-end" } }
                >
                  <Button sx={ { mr: 1 } } onClick={ handleReset } type="button">
                    Reset
                  </Button>
                  { data && (
                    <DeleteButton
                      deleteItem={ () => {
                        deleteContent(data.id ?? "", selectedLanguage as SupportedLanguages);
                      } }
                    />
                  ) }

                  { data && selectedLanguage === MULTI_LINGUAL_OPTION &&
                      <LoadingButton
                          sx={ { mr: 2 } }
                          type="button"
                          variant="contained"
                          onClick={ onSaveNonTranslatedFields }
                          loading={ isSubmitting }>
                          Save Non-Translated Fields Changes
                      </LoadingButton>
                  }

                  <LoadingButton
                    type="submit"
                    variant="contained"
                    loading={ isSubmitting }
                  >
                    Save Changes
                  </LoadingButton>
                </Box>
              </fieldset>
            </Form>
          </FormikProvider>
        </Card>
      </Grid>
    </Grid>
  );
}
