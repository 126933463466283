import { useEffect, useState } from "react";
import { getRelationshipSessionById } from "utils/firebase/chineseUsersRelationshipsUtils";

export function useGetRelationshipSessionById(
    relationshipId: string | undefined,
    sessionId: string | undefined
) {
    const [session, setSession] = useState<any>();

    useEffect(() => {
        let unListen: any;

        if (!relationshipId || !sessionId) {
            return;
        }

        try {
            unListen = getRelationshipSessionById(relationshipId, sessionId, (res: any) => {
                setSession(res);
            });
        } catch (err) {
            console.error(err);
        }

        // clear listening
        return () => {
            unListen?.();
        };
    }, [relationshipId, sessionId]);

    return {
        session,
    };
}
