import { db } from "contexts/FirebaseContext";
import { useEffect, useState } from "react";
import { ColourPaletteFromFirestore } from "models/ColourPalette";




export default function useGetColourPalettes() {
    const [colourPalettes, setColourPalettes] = useState<Array<ColourPaletteFromFirestore>>();

    useEffect(() => {
        const listener = db.collection("colour_palettes").onSnapshot(async (snapshot) => {
            const docs = snapshot.docs.map((doc) => {
                const data = doc?.data();
                return {id: doc?.id, ...data} as ColourPaletteFromFirestore;
            })
            setColourPalettes(docs);
        });

        return () => {
            if (listener) {
                listener();
            }
        };
    }, []);

    return colourPalettes;
}
