import React, { useState } from "react";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";

// material
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Card,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { v4 as UUID } from "uuid";

import LoadingScreen from "../../LoadingScreen";
import { MeditationVoice, MeditationVoiceCreateRequestType } from "../../../types/meditationVoice";
import { createOrUpdateMeditationVoice } from "../../../utils/firebase/audioMeditationVoicesUtil";
import { CHARACTERISTICS_KEY_TO_NAME, CHARACTERISTICS_NAME_TO_KEY } from "../../../utils/constants";
import { MenuProps } from "../audio-meditation-generations/AudioMeditationGenerationForm";

// ----------------------------------------------------------------------

type MeditationVoiceCreateFormProps = {
  isEdit: boolean;
  isView?: boolean;
  isNew?: boolean;
  loading: boolean;
  meditationVoice?: MeditationVoice;
};

export default function MeditationVoiceCreateForm({
                                                    isView, isEdit, meditationVoice, loading
                                                  }: MeditationVoiceCreateFormProps) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [saving, setSaving] = useState(false)

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: meditationVoice?.name,
      voiceID: meditationVoice?.voiceID,
      themeColorLight: meditationVoice?.themeColorLight,
      themeColorDark: meditationVoice?.themeColorDark,
      photo: meditationVoice?.photo,
      sampleAudioUrl: meditationVoice?.sampleAudioUrl,
      characteristics: meditationVoice?.characteristics ?? []
    },

    validationSchema: Yup.object().shape({
      name: Yup.string().required("Title is required"),
      voiceID: Yup.string().required("voiceID is required"),
      themeColorLight: Yup.string().required("theme color light is required"),
      themeColorDark: Yup.string().required("theme color dark is required"),
      photo: Yup.string().required("Title is required"),
      sampleAudioUrl: Yup.string().required("Sample Audio Url is required"),
      characteristics: Yup.array().of(Yup.string().min(1, "Please select at least one Characteristic").required()).required("Characteristics is required"),
    }),

    onSubmit: async (values) => {
      try {
        setSaving(true);
        const meditationVoiceRequest = {
          documentId: meditationVoice?.id ?? UUID(),
          name: values.name,
          themeColorLight: values.themeColorLight,
          themeColorDark: values.themeColorDark,
          voiceID: values.voiceID,
          photo: values.photo,
          sampleAudioUrl: values.sampleAudioUrl,
          characteristics: values?.characteristics ?? []
        } as MeditationVoiceCreateRequestType

        await createOrUpdateMeditationVoice(meditationVoiceRequest)
        navigate(-1);
        enqueueSnackbar("Meditation voice added", { variant: "success" });
      } catch (error) {
        console.error("FailedToCreateOrUpdateMeditationVoice", error);
        navigate(-1);
        enqueueSnackbar("Meditation voice failed: " + error?.message, { variant: "error" });
      } finally {
        setSaving(false)
      }
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, setFieldValue } = formik;

  let submitBtnTitle = "Create Meditation Voice";
  if (isView) {
    submitBtnTitle = "Meditation Voice (View Only)";
  } else if (isEdit) {
    submitBtnTitle = "Save Meditation Voice";
  }

  const onDeleteCharacteristic = (deletionCharacteristicVal: string) => () => {
    setFieldValue('characteristics', (formik.values.characteristics as string[]).filter((characteristic) => {
      return characteristic !== deletionCharacteristicVal
    }))
  }

  if (loading) {
    return <LoadingScreen/>;
  }

  return (
    <FormikProvider value={ formik }>
      <Form noValidate autoComplete="off" onSubmit={ handleSubmit }>
        <fieldset disabled={ isView } style={ { border: "none" } }>
          <Grid container spacing={ 3 }>
            <Grid item xs={ 12 }>
              <Card sx={ { p: 3 } }>
                <Stack spacing={ 3 }>
                  <TextField
                    fullWidth
                    label="Name"
                    { ...getFieldProps("name") }
                    disabled={ isView }
                    error={ Boolean(touched.name && errors.name) }
                    helperText={ touched.name && errors.name }
                  />

                  <TextField
                    fullWidth
                    label="Voice ID"
                    disabled={ isView }
                    { ...getFieldProps("voiceID") }
                    error={ Boolean(touched.voiceID && errors.voiceID) }
                    helperText={ touched.voiceID && errors.voiceID }
                  />

                  <TextField
                    fullWidth
                    minRows={ 5 }
                    disabled={ isView }
                    label="Theme color light"
                    { ...getFieldProps("themeColorLight") }
                    error={ Boolean(touched.themeColorLight && errors.themeColorLight) }
                    helperText={ touched.themeColorLight && errors.themeColorLight }
                  />

                  <TextField
                    fullWidth
                    minRows={ 5 }
                    disabled={ isView }
                    label="Theme color dark"
                    { ...getFieldProps("themeColorDark") }
                    error={ Boolean(touched.themeColorDark && errors.themeColorDark) }
                    helperText={ touched.themeColorDark && errors.themeColorDark }
                  />

                  <TextField
                    fullWidth
                    minRows={ 5 }
                    disabled={ isView }
                    label="Photo"
                    { ...getFieldProps("photo") }
                    error={ Boolean(touched.photo && errors.photo) }
                    helperText={ touched.photo && errors.photo }
                  />

                  <TextField
                    fullWidth
                    minRows={ 5 }
                    disabled={ isView }
                    label="Sample Audio Url"
                    { ...getFieldProps("sampleAudioUrl") }
                    error={ Boolean(touched.sampleAudioUrl && errors.sampleAudioUrl) }
                    helperText={ touched.sampleAudioUrl && errors.sampleAudioUrl }
                  />

                  <FormControl fullWidth>
                    <InputLabel id="characteristics">Characteristics</InputLabel>
                    <Select
                      label="Target Languages"
                      disabled={ isView }
                      multiple
                      { ...getFieldProps("characteristics") }
                      input={ <OutlinedInput id="select-multiple-chip" label="Chip" color="primary"/> }
                      MenuProps={ MenuProps }
                      renderValue={ (selected: any) => {
                        return (
                          <Box sx={ { display: 'flex', flexWrap: 'wrap', gap: 0.5 } }>
                            { selected?.map?.((value: string) => (
                              <Chip key={ value } label={ CHARACTERISTICS_NAME_TO_KEY[value] ?? value } color="info"
                                    variant="outlined"
                                    onMouseDown={ (event) => event.stopPropagation() }
                                    onDelete={ onDeleteCharacteristic(value) }/>
                            )) ?? <Chip color="info" variant="outlined"
                                        label={ CHARACTERISTICS_NAME_TO_KEY[selected] ?? "" }/> }
                          </Box>
                        );
                      } }>
                      { Object.keys(CHARACTERISTICS_KEY_TO_NAME).map((key: string) => (
                        <MenuItem
                          key={ key }
                          value={ CHARACTERISTICS_KEY_TO_NAME[key] }>
                          { key }
                        </MenuItem>
                      )) }
                    </Select>
                  </FormControl>

                  <Box sx={ { mt: 3, display: "flex", justifyContent: "flex-end" } }>
                    <LoadingButton
                      type="submit"
                      variant="contained"
                      loading={ saving || isSubmitting }>
                      { submitBtnTitle }
                    </LoadingButton>
                  </Box>
                </Stack>
              </Card>
            </Grid>
          </Grid>
        </fieldset>
      </Form>
    </FormikProvider>
  );
}
