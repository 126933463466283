import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
// material
import { Box, Container } from "@mui/material";
// hooks
import useSettings from "../../../hooks/useSettings";
// components
import Page from "../../../components/Page";
import HeaderBreadcrumbs from "../../../components/HeaderBreadcrumbs";
import { Template } from "../../../types/template";
import TemplateForm from "../../../components/_dashboard/template/TemplateForm";
import {getTemplateById} from "../../../utils/templateUtils";

// ----------------------------------------------------------------------

export default function CreateTemplate() {
  const { themeStretch } = useSettings();
  const { pathname = "" } = useLocation();
  const { state } = useLocation();
  const { id: templateID } = useParams();

  const [template, setTemplate] = useState<Template>(state as Template);

  const [loading, setLoading] = useState(false);

  const isEdit = pathname?.includes("edit");
  const isView = pathname?.includes("view");
  const isNew = pathname?.includes("new");

  const fetchTemplate = useCallback(() => {
    if (templateID && !loading) {
      setLoading(true);
      getTemplateById(templateID)
        .then((newTemplate) => {
          setTemplate(newTemplate);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [templateID, loading]);

  useEffect(() => {
    fetchTemplate();
  }, [fetchTemplate]);

  let pageTitle = "Create a new Template";
  if (isView) {
    pageTitle = "View Template";
  } else if (isEdit) {
    pageTitle = "Edit Template";
  }

  return (
    <Page title={pageTitle}>
      <Container maxWidth={themeStretch ? false : "lg"}>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="flex-start"
          justifyContent="space-between"
          height={50}
        >
          <HeaderBreadcrumbs heading={pageTitle} links={[]} />
        </Box>

        <TemplateForm
          isEdit={isEdit}
          isView={isView}
          isNew={isNew}
          template={template}
        />
      </Container>
    </Page>
  );
}
