import {Stack, TextField, InputLabel, MenuItem, Select} from "@mui/material";
import Button from "@mui/material/Button";
import React from "react";
import {FieldInputProps} from "formik/dist/types";
import {RegistrationCodeAnswerEvent, SetFieldValueType} from "../../../../types/invitation/code";
import AddIcon from "@mui/icons-material/Add";
import {v4} from "uuid";
import {pushItem} from "../../../../utils/firebase";
import InvitationCodeAutocomplete from "../../registration-codes/InvitationCodeAutocomplete";

type Props = {
    showAnswerEvents?: boolean;
    inputFormKey: string;
    getFieldProps: (nameOrOptions: any) => FieldInputProps<any>;
    setFieldValue: SetFieldValueType;
}

export default function RegistrationCodeAnswerEvents({
                                                         inputFormKey,
                                                         getFieldProps,
                                                         setFieldValue,
                                                         showAnswerEvents
                                                     }: Props) {
    if (!showAnswerEvents) {
        return null;
    }

    const events: RegistrationCodeAnswerEvent[] = getFieldProps(`${inputFormKey}.events`).value ?? []

    const addNewQuestion = () => {
        setFieldValue(
            `${inputFormKey}.events`,
            pushItem(
                {
                    id: v4(),
                    question: ''
                },
                events
            )
        );
    }

    return (
        <Stack
            direction={'column'}
            spacing={1}
        >
            <TextField
                fullWidth
                label={"Position"}
                {...getFieldProps(`${inputFormKey}.position`)}
                type="text"
            />
            {
                events.map((item, index) => {
                    const invitationCodePayloadKey = `${inputFormKey}.events[${index}].payload`
                    return (
                        <Stack key={String(index)}>
                            <InputLabel>Event Type</InputLabel>
                            <Select
                                label="Event Type"
                                {...getFieldProps(`${inputFormKey}.events[${index}].eventType`)}
                            >
                                <MenuItem value={"set_invitation_code"}>Set Invitation Code</MenuItem>
                            </Select>

                            <InvitationCodeAutocomplete
                                value={getFieldProps(invitationCodePayloadKey).value}
                                onChangeValue={(value) => {
                                    setFieldValue(invitationCodePayloadKey, value)
                                }}
                            />
                        </Stack>
                    )

                })
            }
            <Button
                sx={{alignSelf: 'flex-end'}}
                variant="contained"
                color={'success'}
                endIcon={<AddIcon/>}
                onClick={addNewQuestion}
            >
                Add Event
            </Button>
        </Stack>
    )
}