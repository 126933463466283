export type EmailIdentifier = { email: string };

export type UidIdentifier = { uid: string };

export type PrimitiveIdentifier = EmailIdentifier | UidIdentifier;

type SupportedKey = keyof EmailIdentifier | keyof UidIdentifier;

export class UserIdentifier {
  public readonly key: SupportedKey;

  public readonly value: string;

  public static guess(value: string): UserIdentifier {
    let result = new UserIdentifier("uid", value);
    if (value.includes("@")) {
      result = new UserIdentifier("email", value);
    }

    return result;
  }

  public static ToPrimitive(identifier: UserIdentifier): PrimitiveIdentifier {
    return identifier.toPrimitive();
  }

  public constructor(key: SupportedKey, value: string) {
    this.key = key;
    this.value = value;
  }

  public keyValue(): [key: SupportedKey, value: string] {
    return [this.key, this.value];
  }

  public toPrimitive(): PrimitiveIdentifier {
    return {
      [this.key]: this.value,
    } as EmailIdentifier | UidIdentifier;
  }
}
