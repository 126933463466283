import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useFetchCreditPeriods } from "./hooks";

import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import useCreditPeriods from "./hooks/useCreditPeriods";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 2.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

const CreditPeriodsList = () => {
  const { handleClickOpen, invitationCode } = useCreditPeriods();

  const { creditPeriods, loading, error } =
    useFetchCreditPeriods(invitationCode);

  if (error) {
    return <strong>Error: {JSON.stringify(error)}</strong>;
  }

  if (loading) {
    return <span> Loading...</span>;
  }

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell align="center">Allowed Credits</TableCell>
            <TableCell align="center">Active</TableCell>
            <TableCell align="center">Unlimited Credits</TableCell>
            <TableCell>Start</TableCell>
            <TableCell>End</TableCell>
            <TableCell>createdby</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {creditPeriods &&
            creditPeriods.map((creditPeriodsItem) => (
              <TableRow key={creditPeriodsItem.id}>
                <TableCell component="th" scope="row" align="center">
                  <StyledBadge
                    overlap="rectangular"
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    variant={creditPeriodsItem.active ? "dot" : "standard"}
                  >
                    <Avatar
                      style={{
                        width: "100%",
                        minWidth: "40px",
                        padding: 8,
                      }}
                      variant="rounded"
                      alt="allowed Credits"
                    >
                      {creditPeriodsItem.allowedCredits}
                    </Avatar>
                  </StyledBadge>
                </TableCell>
                <TableCell align="center">
                  {creditPeriodsItem.active ? "Period is active" : "-"}
                </TableCell>
                <TableCell align="center">
                  {creditPeriodsItem.unlimitedCredits ? "Unlimited" : "-"}
                </TableCell>
                <TableCell>
                  {creditPeriodsItem.startDate
                    ? creditPeriodsItem.startDate.toDate().toDateString()
                    : "-"}
                </TableCell>
                <TableCell>
                  {creditPeriodsItem.endDate
                    ? creditPeriodsItem.endDate.toDate().toDateString()
                    : "-"}
                </TableCell>
                <TableCell>
                  {creditPeriodsItem.createdBy.name ||
                    creditPeriodsItem.createdBy.email ||
                    "-"}
                </TableCell>
                <TableCell>
                  <Button
                    onClick={() => handleClickOpen(creditPeriodsItem)}
                    size="small"
                    color="secondary"
                  >
                    Edit
                  </Button>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CreditPeriodsList;
