import Like from "../../../assets/icon_like";
import Compass from "../../../assets/icon_compass";
import CheckCircle from "../../../assets/icon_check_circle";
import Chart from "../../../assets/icon_chart";
import {AssessmentRecord, CounsellorProgressAssessmentQuestion} from "types/CounsellorProgressDasboard";

export const AssessmentQuestions: Array<AssessmentRecord> = [
  {
    title: "lifeSatisfaction",
    question: CounsellorProgressAssessmentQuestion.Z1,
    icon: <Like />,
  },
  {
    title: "worthwhile",
    question: CounsellorProgressAssessmentQuestion.Z2,
    icon: <Compass />,
  },
  {
    title: "happiness",
    question: CounsellorProgressAssessmentQuestion.Z3,
    icon: <CheckCircle />,
  },
  {
    title: "anxiety",
    question: CounsellorProgressAssessmentQuestion.Z4,
    icon: <Chart />,
  },
];

export const AllCounsellorProgressAssessmentQuestions: Array<CounsellorProgressAssessmentQuestion> =
  [
    CounsellorProgressAssessmentQuestion.Z1,
    CounsellorProgressAssessmentQuestion.Z2,
    CounsellorProgressAssessmentQuestion.Z3,
    CounsellorProgressAssessmentQuestion.Z4,
  ];

export const CHARTS_PAGE_SIZE = 30;

export const REVERSE_SCORE_QUESTIONS = [
  "Z4",
];
