import React, { useState } from "react";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button/Button";
import plusFill from "@iconify/icons-eva/plus-fill";
import { Icon } from "@iconify/react";

import HeaderBreadcrumbs from "components/HeaderBreadcrumbs";
import useSettings from "hooks/useSettings";

import { PATH_DASHBOARD } from "routes/paths";
import Page from "components/Page";
import { useParams } from "react-router";
import Page404 from "pages/Page404";
import RenewalChallengeList from "./RenewalChallengeList";
import {
  getSelectors,
  renewalChallengeSlice,
} from "redux/slices/renewalChallengeSlice";
import { useSelector } from "redux/store";
import RenewalChallengeCreateDialog from "./RenewalChallengeCreateDialog";

export const RenewalChallengePage = () => {
  const { themeStretch } = useSettings();
  const [open, setOpen] = useState<boolean>(false);
  const { code: invitationCode } = useParams();

  const { isFetching, isError } =
    renewalChallengeSlice.useLoadRenewalChallengesQuery(invitationCode ?? "");
  const { selectAll } = getSelectors(invitationCode ?? "");
  const challenges = useSelector(selectAll);

  if (!invitationCode) {
    return (
      <Page404
        title="Missing an Invitation Code"
        message="A new Renewal Challenge can be only created for a valid invitation code."
      />
    );
  }

  return (
    <Page title="Renewal Challenges">
      <Container maxWidth={themeStretch ? false : "lg"}>
        <HeaderBreadcrumbs
          heading="Renewal Challenges"
          links={[
            {
              name: "Invitation Code List",
              href: PATH_DASHBOARD.invitationCodes.root,
            },
            { name: invitationCode },
          ]}
          action={
            <Button
              onClick={() => setOpen(true)}
              variant="contained"
              startIcon={<Icon icon={plusFill} />}
            >
              Renewal Challenge
            </Button>
          }
        />

        <RenewalChallengeList
          isError={isError}
          isLoading={isFetching}
          challenges={challenges}
        />

        <RenewalChallengeCreateDialog
          invitationCode={invitationCode}
          open={open}
          setOpen={setOpen}
        />
      </Container>
    </Page>
  );
};
