import { Avatar, ListItemAvatar } from "@mui/material";
import PostAddIcon from "@mui/icons-material/PostAdd";

export default function RevisionUpdateListIcon() {
  return (
    <ListItemAvatar>
      <Avatar>
        <PostAddIcon />
      </Avatar>
    </ListItemAvatar>
  );
}
