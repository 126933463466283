import React from 'react';
import { AudioMeditationChunks } from "../../../types/audioMeditationGenerations";
import { Link, Stack, Tooltip, Typography } from "@mui/material";
import { formatTimeDuration } from "../../../utils/formatTime";

type AudioTextComponentProps = {
  audioTextChunks: AudioMeditationChunks[];
}

export default function AudioTextComponent({ audioTextChunks }: AudioTextComponentProps) {
  // Function to split the combined text into clickable chunks
  const renderTextChunks = () => {
    const durationArray: Array<number> = audioTextChunks?.map(({ duration }) => duration) ?? []

    return audioTextChunks.map((audioTextChunk, index) => (
      <Tooltip
        key={ index }
        title={ formatTimeDuration(durationArray?.slice(0, index), audioTextChunk?.duration) }
        arrow>
        <Link href={ audioTextChunk.audio } target='_blank'>
          { `${ audioTextChunk.text } ` }
        </Link>
      </Tooltip>
    ));
  };

  return (
    <>
      <Typography fontWeight={"500"} mt={1} variant="body1">Final Script Text</Typography>
      <Stack display={ "inline" } border={ 1 } borderColor={ "lightgrey" } borderRadius={ 1 } padding={ 2 } mt={0}>
        { renderTextChunks() }
      </Stack>
    </>
  );
};
