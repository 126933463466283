import { LoadingButton } from "@mui/lab";
import {
  Box,
  Divider,
  Grid,
  IconButton,
  Paper,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import { formatRelative } from "date-fns";
import hljs, { HighlightResult } from "highlight.js";
import { Revision } from "../types/Revision";
import { useSnackbar } from "notistack";
import { useState } from "react";
import * as CloudFunctions from "utils/firebase/cloudFunctions";
import indentJSON from "../utils/indentJSON";
import CopyToClipboardButton from "./CopyToClipboardButton";
import CompareIcon from "@mui/icons-material/Compare";
import DiffViewDialog from "./DiffViewDialog";
import PreviewIcon from "@mui/icons-material/Preview";

const CodeHighlight = styled("pre")({
  overflowX: "scroll",
  fontSize: 12,
});

function indentedHighlight(
  content: string | null | undefined
): HighlightResult {
  if (content === undefined) {
    return hljs.highlight(indentJSON("null"), { language: "json" });
  }
  return hljs.highlight(indentJSON(content), { language: "json" });
}

export default function RevisionDetail(props: {
  loading: boolean;
  revision: Revision | undefined;
  liveVersion: string | undefined;
  path: string;
}) {
  const { loading, revision, liveVersion, path } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [rollingback, setRollingback] = useState<boolean>(false);
  const [compareLiveVersion, setCompareLiveVersion] = useState<boolean>(false);
  const [detailedDiff, setDetailedDiff] = useState<boolean>(false);

  if (!revision) {
    return <p>Select a revision</p>;
  }
  if (loading) {
    return <p>Loading</p>;
  }
  const { checksum, timestamp, lastUpdatedBy } = revision;
  const highlightedContent = indentedHighlight(revision.content);
  const highlightedLiveVersion = indentedHighlight(liveVersion);
  const formattedTimestamp = timestamp.toUTCString();

  async function rollbackRevision(revisionId: string): Promise<void> {
    setRollingback(true);
    try {
      await CloudFunctions.rollbackRevision(revisionId);
      enqueueSnackbar("Rolled back revision!", { variant: "success" });
    } catch (e) {
      console.error(e);
      enqueueSnackbar("Failed to rollback revision!", { variant: "error" });
    } finally {
      setRollingback(false);
    }
  }

  return (
    <Stack>
      <Box my={4}>
        <Paper elevation={3}>
          <Box p={4}>
            <h3>Metadata</h3>
            <Box display="flex">
              <Stack flexGrow={1}>
                <Typography variant="body2">
                  Change created: {formattedTimestamp} (
                  {formatRelative(timestamp, new Date())})
                </Typography>
                <Typography variant="body2">Checksum: {checksum}</Typography>
                {lastUpdatedBy && <Typography variant="body2">Change By: {lastUpdatedBy}</Typography>}
              </Stack>
              <LoadingButton
                variant="contained"
                size="small"
                title="Overwrite the live version with this data"
                onClick={() => rollbackRevision(revision.id)}
                loading={rollingback}
              >
                Rollback
              </LoadingButton>
            </Box>
          </Box>
        </Paper>
      </Box>
      <Paper elevation={3}>
        <Box p={4}>
          <h3>
            Document content
            <IconButton
              title="Show live version side-by-side"
              onClick={() => setCompareLiveVersion((old) => !old)}
            >
              <CompareIcon />
            </IconButton>
            <IconButton
              title="Show detailed diff"
              onClick={() => setDetailedDiff((old) => !old)}
            >
              <PreviewIcon />
            </IconButton>
          </h3>
          <Grid container my={2}>
            <Grid item xs={12}>
              <DiffViewDialog
                path={path}
                open={detailedDiff}
                onClose={() => setDetailedDiff(false)}
                revisionContent={revision.content}
                liveContent={liveVersion}
                fullScreen={false}
              />
            </Grid>
            <Grid item xs={compareLiveVersion ? 6 : 12}>
              <Typography pb={1}>
                Selected revision
                <CopyToClipboardButton
                  title="Copy indented content to clipboard"
                  content={indentJSON(revision.content)}
                />
              </Typography>
              <CodeHighlight
                dangerouslySetInnerHTML={{ __html: highlightedContent.value }}
              />
            </Grid>
            {compareLiveVersion && (
              <>
                <Divider sx={{ marginX: 2 }} orientation="vertical" flexItem />
                <Grid xs={5}>
                  <Typography pb={1}>
                    Live version
                    <CopyToClipboardButton
                      title="Copy indented content to clipboard"
                      content={indentJSON(liveVersion ?? null)}
                    />
                  </Typography>
                  <CodeHighlight
                    dangerouslySetInnerHTML={{
                      __html: highlightedLiveVersion.value,
                    }}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Box>
      </Paper>
    </Stack>
  );
}
