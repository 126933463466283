import React, {useCallback, useEffect, useState} from "react";
import Page from "../../../components/Page";
import {Container} from "@mui/material";
import HeaderBreadcrumbs from "../../../components/HeaderBreadcrumbs";
import useSettings from "../../../hooks/useSettings";
import {NotificationPayload} from "../../../types/notifications";
import {useLocation, useParams} from "react-router-dom";
import NotificationForm from "./NotificationForm";
import {getNotificationHistoryById} from "../../../utils/firebase/notificationUtils";

export default function Notifications() {
    const {themeStretch} = useSettings();
    const {id: notificationId} = useParams();
    const {state} = useLocation();
    const [notification, setNotification] = useState<NotificationPayload>(state as NotificationPayload)


    const fetchNotificationHistory = useCallback(() => {
        if (!notification && notificationId) {
            getNotificationHistoryById(notificationId)
                .then((history) => {
                    setNotification(history);
                })
                .catch((error) => {
                    console.error(error);
                })
        }
    }, [notification, notificationId]);

    useEffect(() => {
        fetchNotificationHistory();
    }, [fetchNotificationHistory]);


    return (
        <Page title="Notifications">
            <Container maxWidth={themeStretch ? false : "lg"}>
                <HeaderBreadcrumbs heading={"Push Notifications Tool"} links={[]}/>

                <NotificationForm notification={notification}/>
            </Container>
        </Page>
    );
}
