import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
// material
import {Container} from "@mui/material";
// hooks
import useSettings from "../../../hooks/useSettings";
// components
import Page from "../../../components/Page";
import HeaderBreadcrumbs from "../../../components/HeaderBreadcrumbs";
import AppSideTranslationForm from "../../../components/_dashboard/app-side-translations/AppSideTranslationForm";
import { useTheme } from "@mui/material/styles";
import {
  useTranslationKeyWithDescription,
  useTranslationsValueAndApprovedBy,
} from "../../../utils/firebase/translationUtils";
import LoadingScreen from "../../../components/LoadingScreen";
import { useCurrentRole } from "../../../hooks/useCurrentRole";
import { useGetAllMultilingualLocales } from "utils/firebase/multilingualLocaleUtils";
import algoliasearch from "algoliasearch";
import { SearchClient } from "algoliasearch/dist/algoliasearch";
import { useAlgoliaSearchKey } from "../../../hooks/algolia/useAlgoliaSearchKey";
import { algoliaConfig } from "../../../config";
// ----------------------------------------------------------------------

export default function CreateAppSideTranslation() {
  const { themeStretch } = useSettings();
  const { pathname } = useLocation();
  const theme = useTheme();
  const { id } = useParams();
  const currentRoles = useCurrentRole();

  const [searchClient, setSearchClient] = useState<SearchClient>();
  const algoliaSearchApiKey = useAlgoliaSearchKey();

  const { translationKeyWithDescription, loading } =
    useTranslationKeyWithDescription(id ?? "");
  const {
    translationsValueAndApprovedBy,
    loading: translatedValuesLoading,
    accessToAllLanguagesTranslation,
    accessLanguagesTranslationList,
    showEnglishTranslationViewOnly,
  } = useTranslationsValueAndApprovedBy(currentRoles, id ?? "");
  const { multilingualLocalesCodeToName } = useGetAllMultilingualLocales();

  const TRANSLATION_LANGUAGE_CODE_TO_NAME: Record<string, string> = useMemo(
    () => ({
      en: "English",
      es: "Spanish",
      fr: "French",
      de: "German",
      ...multilingualLocalesCodeToName,
    }),
    [multilingualLocalesCodeToName]
  );

  const isEdit = pathname.includes("edit");
  const isView = !isEdit && pathname.includes("view");
  const isNew = !isEdit && !isView && pathname.includes("new");

  useEffect(() => {
    if (algoliaSearchApiKey) {
      setSearchClient(
        algoliasearch(algoliaConfig?.appId || "", algoliaSearchApiKey)
      );
    }
  }, [algoliaSearchApiKey]);

  let pageTitle = "Create a new translation";
  if (isView) {
    pageTitle = "View Translation";
  } else if (isEdit) {
    pageTitle = "Edit Translation";
  }

  const isLoading = loading || translatedValuesLoading;

  return (
    <Page title={pageTitle}>
      <Container maxWidth={themeStretch ? false : "lg"}>
        <HeaderBreadcrumbs heading={pageTitle} links={[]} />

        {Boolean(isLoading) && <LoadingScreen />}

        {!Boolean(isLoading) && (
          <AppSideTranslationForm
            isEdit={isEdit}
            isView={isView}
            isNew={isNew}
            theme={theme}
            searchClient={searchClient}
            accessToAllLanguagesTranslation={accessToAllLanguagesTranslation}
            accessLanguagesTranslationList={accessLanguagesTranslationList}
            translationsValueAndApprovedBy={translationsValueAndApprovedBy}
            translationKeyWithDescription={translationKeyWithDescription}
            showEnglishTranslationViewOnly={showEnglishTranslationViewOnly}
            translationsCodeToName={TRANSLATION_LANGUAGE_CODE_TO_NAME}
          />
        )}
      </Container>
    </Page>
  );
}
