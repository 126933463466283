import { createApi } from "@reduxjs/toolkit/query/react";
import {
  NewRenewalChallenge,
  RenewalChallenge,
} from "../../types/invitation/renewalChallenge";
import {
  createEntityAdapter,
  createSelector,
  EntityState,
} from "@reduxjs/toolkit";
import byActive from "utils/renewalChallenges/byActive";
import { renewalChallengeRestApi } from "./renewalChallengeRestApi";

type InvitationCode = string;

const renewalChallengeAdapter = createEntityAdapter<RenewalChallenge>({
  selectId: (challenge) => challenge.id,
  sortComparer: byActive,
});
const initialState = renewalChallengeAdapter.getInitialState();

export const renewalChallengeSlice = createApi({
  reducerPath: "renewalChallenge",
  tagTypes: ["renewalChallenge"],
  baseQuery: renewalChallengeRestApi(),
  endpoints: (build) => ({
    loadRenewalChallenges: build.query<
      EntityState<RenewalChallenge>,
      InvitationCode
    >({
      providesTags: ["renewalChallenge"],
      query: (invitationCode: InvitationCode) => ({
        method: "list",
        invitationCode,
      }),
      transformResponse: (response) => {
        return renewalChallengeAdapter.setAll(initialState, response);
      },
    }),
    createRenewalChallenge: build.mutation<
      void,
      { challenge: NewRenewalChallenge; invitationCode: string }
    >({
      invalidatesTags: ["renewalChallenge"],
      query: ({ challenge, invitationCode }) => ({
        method: "create",
        challenge,
        invitationCode,
      }),
    }),
    updateRenewalChallenge: build.mutation<
      void,
      { challenge: RenewalChallenge; invitationCode: InvitationCode }
    >({
      invalidatesTags: ["renewalChallenge"],
      query: ({ challenge, invitationCode }) => ({
        method: "update",
        challenge,
        invitationCode,
      }),
    }),
  }),
});

export function getSelectors(invitationCode: string) {
  const forInvitationCode =
    renewalChallengeSlice.endpoints.loadRenewalChallenges.select(
      invitationCode
    );

  const rootSelector = createSelector(forInvitationCode, (res) =>
    renewalChallengeAdapter.getSelectors(
      () => res?.data ?? renewalChallengeAdapter.getInitialState()
    )
  );

  const selectById = (id: string) =>
    createSelector(rootSelector, (state) => state.selectById(state, id));

  const selectAll = createSelector(rootSelector, (state) =>
    state.selectAll(state)
  );

  return {
    selectById,
    selectAll,
  };
}
