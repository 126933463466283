import { Box, Container, Typography } from "@mui/material";
import { TherapistInvoice } from "../../../types/therapists";
import { format } from "date-fns";
import useSettings from "hooks/useSettings";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getInvoiceById } from "utils/firebase/therapistUtils";
import DownloadPdfInvoiceButton from "components/invoice/DownloadPdfInvoiceButton";

export default function ViewInvoice() {
  const { themeStretch } = useSettings();
  const { id: therapistId, invoiceNumber: invoiceId } = useParams();
  const [invoice, setInvoice] = useState<TherapistInvoice>()

  
  useEffect(() => {
    async function fetchData(idTherapist: string, idInvoice: string) {
      getInvoiceById(idTherapist, idInvoice)
        .then((selectedInvoice) => {
          setInvoice(selectedInvoice);
        })
        .catch((error) => {
          console.error("error", error);
        });
    }

    if (therapistId && invoiceId) {
      fetchData(therapistId, invoiceId);
    }
  }, [invoiceId, therapistId]);
  
  if (!invoice) {
    return (
      <Typography>The invoice cannot be reached. Please try again</Typography>
    );
  }

  const {
    fullName,
    address,
    street,
    houseNumber,
    postCode,
    state,
    country,
    invoiceNumber,
    dateSubmitted,
    year,
    month,
    chatHours,
    valueChatHour,
    videoHours,
    valueVideoHour,
    additionalCostsValue,
    totalAmount,
    accountHolder,
    bankCurrency,
    bankName,
    iban,
    bic,
    swift,
    routingNumber,
    wiseEmail,
    comments,
  } = invoice;

  const hourlyCurrency = 
    valueChatHour && 
    valueChatHour.split(" ").length ? valueChatHour.split(" ")[1] : "EUR";


  const showAccountNumber = iban || routingNumber;
  const showBankIdNumber = bic || swift;

  return (
    <Container>
    {therapistId && invoiceId && (
      <DownloadPdfInvoiceButton
        counsellorId={therapistId}
        invoiceId={invoiceId}
      />
    )}
    <Container
      maxWidth={themeStretch ? false : "lg"}
      style={{
        padding: 20,
        borderRadius: 20,
        border: "1px solid gray"
      }}
    >
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignContent: "center",
        }}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            width: 300,
          }}
        >
          <Typography>{fullName}</Typography>
          {address ? (
            <Typography style={{ marginTop: 10 }}>{address}</Typography>

          ) : (
            <Typography
            paragraph
            style={{ marginTop: 10 }}
          >{`${street}, ${houseNumber}. ${postCode}, ${state}, ${country}`}</Typography>
          )}        
        </Box>
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            width: 300,
          }}
        >
          <Typography>{invoiceNumber}</Typography>
          <Typography style={{ marginTop: 10 }}>{dateSubmitted}</Typography>
        </Box>
      </Box>
      <Box style={{ marginTop: 40 }}>
        <Typography fontWeight={700}>Kyan Health AG</Typography>
        <Typography>Dufourstrasse 38</Typography>
        <Typography>CH-8702 Zollikon</Typography>
        <Typography>
          <a
            target="_blank"
            href="mailto:hello@kyanhealth.com"
            rel="noreferrer"
          >
            hello@kyanhealth.com
          </a>
        </Typography>
      </Box>
      <Typography
        style={{ marginTop: 40 }}
      >{`Invoice for online counselling work for ${format(
        new Date(year, month),
        "MMMM yyyy"
      )}`}</Typography>

      <Typography style={{ marginTop: 40 }}>
        Dear Kyan Health Team,
        </Typography>
      <Typography style={{ margin: 10 }}>
        As agreed, I will charge you for the work I have done as follows:
      </Typography>
      <Typography style={{ display: "flex", alignItems: "end", marginTop: 10 }}>
        {`• ${chatHours} hours of work at ${valueChatHour} per hour for chat counselling.`}
      </Typography>
      <Typography style={{ display: "flex", alignItems: "end", marginTop: 10 }}>
        {`• ${videoHours} hours of work at ${valueVideoHour} per hour for video counselling.`}
      </Typography>
      {additionalCostsValue ? (
        <Typography
          style={{ display: "flex", alignItems: "end", marginTop: 10 }}
        >
          {`• Additional costs: ${additionalCostsValue} ${hourlyCurrency}`}
        </Typography>
      ) : null}
      <Typography
        style={{
          display: "flex",
          alignItems: "end",
          marginTop: 20,
        }}
      >
        {`Please transfer the total amount of ${totalAmount} to the following account:`}
      </Typography>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: 40,
        }}
      >
        <Typography
          style={{ marginTop: 10 }}
        >{`Account Holder: ${accountHolder}`}</Typography>
        <Typography
          style={{ marginTop: 10 }}
        >{`Account currency: ${bankCurrency}`}</Typography>
        <Typography
          style={{ marginTop: 10 }}
        >{`Bank Name: ${bankName}`}</Typography>
        {showAccountNumber && (
          <Typography
            style={{ marginTop: 10 }}
          >{`IBAN / Account number / Routing No: ${
            iban || routingNumber
          }`}</Typography>
        )}
        {showBankIdNumber && (
          <Typography style={{ marginTop: 10 }}>{`BIC / SWIFT: ${
            bic || swift
          }`}</Typography>
        )}
        {wiseEmail && (
          <Typography
            style={{ marginTop: 10 }}
          >{`Wise Email: ${wiseEmail}`}</Typography>
        )}
      </Box>
      <Box style={{ marginTop: 40 }}>
        <Typography>Comments:</Typography>
        <Typography style={{ marginTop: 5 }}>{comments}</Typography>
      </Box>

      <Typography
        style={{
          marginTop: 40,
        }}
      >
        With kind regards,
      </Typography>
      <Typography
        style={{
          marginTop: 10,
          alignSelf: "flex-end",
        }}
      >
        {fullName}
      </Typography>
    </Container>
    </Container>
  );
}
