import { TableCell, Typography } from "@mui/material";
import { RelationshipSession } from "utils/firebase/chineseUsersRelationshipsUtils";

type FirstAndLastSessionRowProps = {
    completedSessions: RelationshipSession[] | undefined;
};

export default function FirstAndLastSessionRow ({
    completedSessions
}: FirstAndLastSessionRowProps) {
    if (!completedSessions || completedSessions.length === 0) {
      return (
        <>
          <TableCell>
            <Typography align="center" variant="body2">---</Typography>
          </TableCell>
          <TableCell>
            <Typography align="center" variant="body2">---</Typography>
          </TableCell>
        </>
      );
    }

    const sortedSessions = completedSessions.sort((a, b) => a.date.toDate().getTime() - b.date.toDate().getTime());

    const firstSession = sortedSessions[0];
    const lastSession = sortedSessions[sortedSessions.length - 1];

    return (
      <>
        <TableCell>
          <Typography align="center" variant="body2">{firstSession.date.toDate().toString()}</Typography>
        </TableCell>
        <TableCell>
          <Typography align="center" variant="body2">{lastSession.date.toDate().toString()}</Typography>
        </TableCell>
      </>
    );
  }
