import { CallStatuses } from "../../types/chat";

export const defaultMinDateString = "01-01-2000";
export const defaultMinDateInMillis = new Date(defaultMinDateString).getTime();
export const defaultMaxDateInMillis = new Date().getTime();
export const defaultRowsPerPage = 50;
export const defaultPage = 0;
export const dateTimeDisplayFormat = "dd-MMM-yyyy HH:mm z";
export const allCallStatuses: Array<CallStatuses> = [
  "slots sent",
  "scheduled",
  "requested",
  "cancelled",
  "completed",
];
