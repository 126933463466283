import { useEffect, useState } from "react";
import { db } from "../contexts/FirebaseContext";

import { collections } from "utils/constants";
import {Snippet} from "../types/snippet";
import firebase from "firebase";

export function useGetSnippets() {
  const [loading, setLoading] = useState<boolean>(false);
  const [snippets, setSnippet] = useState<Array<Snippet>>([]);

  useEffect(() => {
    async function getSnippets() {
      try {
        setLoading(true);
        const documentReference = await db
          .collection(collections.SNIPPETS).orderBy("createdOrUpdatedAt", "desc")
          .get();

        const snippetsData = documentReference.docs.map((snapshot) => {
          const snippetData =  snapshot.data();

          return {
            id: snapshot.id,
            ...snippetData,
            createdOrUpdatedAt:
                (snippetData.createdOrUpdatedAt as firebase.firestore.Timestamp)?.toDate(),
          } as Snippet;
        });
        setSnippet(snippetsData);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        console.error(e);
      }
    }

    getSnippets();
  }, []);

  const deleteSnippetAndUpdate = async (snippetId: string) => {
    try {
      setLoading(true);
      await db.collection(collections.SNIPPETS).doc(snippetId).delete();
      // Remove Snippet from local state
      const updatedSnippets = snippets.filter((snippet) => snippet.id !== snippetId);
      setSnippet(updatedSnippets);
    } catch (e) {
      console.error("FailedToDeleteSnippet", e);
    } finally {
      setLoading(false);
    }
  }

  return {snippets, loading, deleteSnippetAndUpdate};
}
