import React from 'react';
// material
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import {
  ExercisesBotPropsType,
  JourneysBotPropsType,
  MeditationBotPropsType,
  MiscBotPropsType,
  ToolkitExercisesBotPropsType
} from '../../../../types/bot-data/botDataType';
import { Practices } from '../meditation/Practices';

// ----------------------------------------------------------------------
type BotDataModalType = {
  data:
    | MiscBotPropsType
    | ExercisesBotPropsType
    | ToolkitExercisesBotPropsType
    | JourneysBotPropsType
    | MeditationBotPropsType
    | any;
  open: boolean;
  close: void | any;
  title: string;
  isJourney?: boolean;
  isMeditation?: boolean;
};

export default function BotDataModal({
  data,
  open,
  close,
  title,
  isJourney,
  isMeditation
}: BotDataModalType) {
  const allJourneyKeys = Object.keys(data || {});
  return (
    <>
      <Dialog open={open} onClose={close} fullWidth maxWidth="md">
        <DialogTitle sx={{ pb: 2 }}>
          <h3>{title}</h3>
        </DialogTitle>
        <DialogContent>
          <DialogContentText maxWidth={'-moz-fit-content'}>
            {!isJourney &&
              !isMeditation &&
              Object?.keys(data || {}).map((item, index) => {
                return (
                  <div key={index}>
                    <hr />
                    <Typography mt={1} variant="subtitle2">
                      <h2>{item}</h2>
                    </Typography>
                    {
                      // @ts-ignore
                      Object?.keys(data?.[item]).map((key) => {
                        return (
                          <>
                            <Stack
                              key={key}
                              direction={{ xs: 'column', sm: 'row' }}
                              spacing={4}
                              mt={3}
                              mb={3}
                              sx={{ justifyContent: 'space-between' }}
                            >
                              <Typography
                                key={String(item + key + 'label')}
                                mt={2}
                                variant="subtitle1"
                              >
                                {key}:
                              </Typography>
                              <TextField
                                sx={{ m: 1, width: '80%' }}
                                // @ts-ignore
                                value={data?.[item]?.[key]}
                                placeholder={`Enter ${key}`}
                                type="text"
                                inputProps={{ readOnly: true }}
                              />
                            </Stack>
                          </>
                        );
                      })
                    }
                  </div>
                );
              })}

            {isJourney &&
              !isMeditation &&
              allJourneyKeys.map((journeyKey, index) => {
                // @ts-ignore
                const journeyItem = data[journeyKey];
                const outerKeys = Object.keys(journeyItem || {});
                return (
                  <div key={index}>
                    <hr />
                    <Typography key={String(journeyKey + 'label')} mt={1} variant="subtitle2">
                      <h3>{journeyKey}</h3>
                    </Typography>
                    {outerKeys.map((outerKey) => {
                      // @ts-ignore
                      const innerObject = journeyItem?.[outerKey] || {};
                      const innerKeys = Object.keys(innerObject);
                      return (
                        <>
                          {innerKeys.map((innerKey) => {
                            const itemToRender = innerObject[innerKey] || {};
                            const keysToRender = Object.keys(itemToRender);
                            return (
                              <>
                                <Typography
                                  key={String(outerKey + innerKey + 'label')}
                                  mt={1}
                                  variant="subtitle2"
                                >
                                  <h3>{`Item[${outerKey}][${innerKey}]`}</h3>
                                </Typography>

                                {keysToRender.map((keyToRender) => {
                                  return (
                                    <Stack
                                      mt={3}
                                      mb={3}
                                      key={keyToRender}
                                      direction={{ xs: 'column', sm: 'row' }}
                                      spacing={1}
                                      sx={{ justifyContent: 'space-between' }}
                                    >
                                      <Typography
                                        key={String(outerKey + innerKey + keyToRender + 'label')}
                                        mt={1}
                                        variant="subtitle2"
                                      >
                                        <h3>{`${keyToRender}`}</h3>
                                      </Typography>
                                      <TextField
                                        key={String(outerKey + innerKey + keyToRender + 'value')}
                                        sx={{ m: 1, width: '80%' }}
                                        // @ts-ignore
                                        value={itemToRender?.[keyToRender] || ''}
                                        placeholder={`Enter ${keyToRender}`}
                                        type="text"
                                        inputProps={{ readOnly: true }}
                                      />
                                    </Stack>
                                  );
                                })}
                              </>
                            );
                          })}
                        </>
                      );
                    })}
                  </div>
                );
              })}

            {isMeditation &&
              Object?.keys(data || {}).map((meditationKey, index) => {
                // @ts-ignore
                return (
                  <div key={index}>
                    <hr />
                    <Typography mt={1} variant="subtitle2" key={String(meditationKey + 'label')}>
                      <h3>{meditationKey}</h3>
                    </Typography>
                    {Object?.keys(data?.[meditationKey]).map((key) => {
                      if (key === 'practices') {
                        return undefined;
                      }
                      return (
                        <Stack
                          key={meditationKey}
                          direction={{ xs: 'column', sm: 'row' }}
                          spacing={1}
                          mt={3}
                          mb={3}
                          sx={{ justifyContent: 'space-between' }}
                        >
                          <Typography
                            mt={1}
                            variant="subtitle2"
                            key={String(meditationKey + key + 'label')}
                          >
                            <h3>{key}:</h3>
                          </Typography>
                          <TextField
                            key={String(meditationKey + key + 'value')}
                            sx={{ m: 1, width: '80%' }}
                            // @ts-ignore
                            value={data?.[meditationKey]?.[key]}
                            placeholder={`Enter ${meditationKey}`}
                            type="text"
                            inputProps={{ readOnly: true }}
                          />
                        </Stack>
                      );
                    })}
                    <Practices practices={data?.[meditationKey]?.practices || {}} />
                  </div>
                );
              })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={close}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
