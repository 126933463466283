import {Autocomplete, createFilterOptions, TextField} from "@mui/material";

type Props = {
    label?: string,
    placeholder?: string,
    selectedContent?: string[],
    contentOptions?: string[],
    setSelectedContent: (content: any[] | string[]) => void
}

type ContentOptions = string;

export default function FilteredContentAutocomplete({
 label,
 placeholder,
 selectedContent,
 setSelectedContent,
 contentOptions,
 }: Props) {
    const filter = createFilterOptions<ContentOptions>();

    return (
        <Autocomplete
            multiple
            id="contentUnit"
            options={contentOptions ?? []}
            value={selectedContent}
            getOptionLabel={(option) => option}
            filterSelectedOptions
            renderInput={(params) => (
                <TextField {...params} label={label} placeholder={placeholder}/>
            )}
            onChange={(_, newValue) => {
                setSelectedContent(newValue);
            }}
            filterOptions={(options, params) => {
                const filtered = filter(options, params);

                if (params.inputValue !== '') {
                    filtered.push(params.inputValue);
                }

                return filtered;
            }}
            sx={{
                marginTop: 2,
            }}

        />
    );
}
