import {Box, Dialog, DialogContent, DialogTitle, IconButton, styled, Typography} from "@mui/material";
import * as Diff from "diff";
import indentJSON from "../utils/indentJSON";
import React from "react";
import Close from "@mui/icons-material/Close";

const AddedSection = styled("pre")({
  background: "#00800035",
  color: "green",
  fontSize: 12,
});

const RemovedSection = styled("pre")({
  background: "#80000035",
  color: "red",
  fontSize: 12,
});

const UnchangedSection = styled("pre")({
  color: "black",
  fontSize: 12,
});

type Props = {
  open: boolean;
  onClose: () => void;
  path: string;
  revisionContent: string | null;
  liveContent: string | undefined;
  fullScreen?: boolean;
};

export default function DiffViewDialog(props: Props) {
  const { revisionContent, liveContent, onClose, open, path, fullScreen = true } = props;
  const diffResult = Diff.diffJson(
    indentJSON(revisionContent ?? "null"),
    indentJSON(liveContent ?? "null")
  );
  return (
    <>
      <Dialog open={open} fullScreen={fullScreen} onClose={() => onClose()} maxWidth="lg">
        <DialogTitle sx={{ fontWeight: "normal" }}>
          <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between">
            <Typography>
              Diff for path '{path}'
            </Typography>
          <IconButton
              title="Close"
              onClick={() => onClose()}
          >
            <Close />
          </IconButton>
          </Box>
          <Box py={2}>
            <AddedSection>green lines are present in live version</AddedSection>
            <RemovedSection>
              red lines are missing in live version
            </RemovedSection>
            <UnchangedSection>grey lines are in both versions</UnchangedSection>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box overflow={"auto"} flex={1} bgcolor={"#00000045"}>
            {diffResult.map((obj, index) => {
              const { added, removed, value } = obj;
              if (added) {
                return <AddedSection key={index}>{value}</AddedSection>;
              }

              if (removed) {
                return <RemovedSection key={index}>{value}</RemovedSection>;
              }

              return <UnchangedSection key={index}>{value}</UnchangedSection>;
            })}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
