import { Container } from "@mui/material";
import HeaderBreadcrumbs from "components/HeaderBreadcrumbs";
import Page from "components/Page";
import ImagesFormContainer from "components/_dashboard/images/ImagesFormContainer";
import useSettings from "hooks/useSettings";

export default function AddEditContentImage() {
  const { themeStretch } = useSettings();

  return (
    <Page title="Add Edit Content Images">
      <Container maxWidth={themeStretch ? false : "lg"}>
        <HeaderBreadcrumbs heading={"Content Images"} links={[]} />

        <ImagesFormContainer />
      </Container>
    </Page>
  );
}
