import {Alert, Box, Container, Grid, IconButton, TextField, Tooltip,} from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import React, {useState} from "react";
import {useCollectionData, useDocumentData,} from "react-firebase-hooks/firestore";
import {db} from "contexts/FirebaseContext";
import HelpContent from "./components/HelpContent";
import RevisionList from "./components/RevisionList";
import RevisionDetail from "./components/RevisionDetail";
import {Revision} from "./types/Revision";
import {RevisionConverter} from "./utils/RevisionConverter";
import {useSnackbar} from "notistack";
import stableJSONStringify from "./utils/stableJSONStringify";
import validPathOrUndefined from "./utils/validPathOrUndefined";
import {useQuery} from "../../hooks/useQuery";

export const LIVE_PATH_PARAM_NAME = "live_path"
export default function ChangelogExplorerPage() {
  let query = useQuery();
  const livePath: string = query.get(LIVE_PATH_PARAM_NAME) ?? "";

  const [showHelpContent, setShowHelpContent] = useState<boolean>(false);
  const [selectedPath, setSelectedPath] = useState<string>(livePath);
  const [selectedRevision, setSelectedRevision] = useState<string>("");
  const { enqueueSnackbar } = useSnackbar();

  const [data, loading, error] = useCollectionData<Revision>(
    db
      .collection("_global_changelog")
      .where("path", "==", selectedPath)
      .orderBy("timestamp", "desc")
      .withConverter(RevisionConverter)
  );

  const [liveVersion, , liveError] = useDocumentData(
    validPathOrUndefined(selectedPath)
  );

  if (selectedPath.startsWith("/")) {
    setSelectedPath(selectedPath.substring(1));
    enqueueSnackbar("Leading '/' is not allowed for path and was removed", {
      variant: "info",
    });
  }

  if (selectedRevision === "" && data && data[0]?.id) {
    // Pick latest revision available
    setSelectedRevision(data[0].id);
  }

  return (
    <Container>
      <Box>
        <HelpContent
          open={showHelpContent}
          close={() => setShowHelpContent(false)}
        />
        <Box mb={4} display="flex">
          <h2>Changelog browser</h2>
          <Tooltip title="Display help">
            <IconButton onClick={() => setShowHelpContent(true)}>
              <HelpIcon />
            </IconButton>
          </Tooltip>
        </Box>
        {error && (
          <Box mb={8}>
            <Alert severity="error">
              Error loading data: {error.name}: {error.message}
            </Alert>
          </Box>
        )}
        {liveError && (
          <Box mb={8}>
            <Alert severity="error">
              Error loading <strong>live</strong> data: {liveError.name}:{" "}
              {liveError.message}
            </Alert>
          </Box>
        )}
        <Box my={2}>
          <TextField
            label="Path to live document"
            variant="standard"
            value={selectedPath}
            onChange={(ev) => setSelectedPath(ev.target.value)}
            fullWidth
          />
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <RevisionList
              loading={loading}
              revisions={data}
              selectedRevision={selectedRevision}
              onSelect={(revisionId: string) => setSelectedRevision(revisionId)}
            />
          </Grid>
          <Grid item xs={8}>
            <h2>Revision</h2>
            <RevisionDetail
              loading={loading}
              revision={data?.find(
                (document) => document.id === selectedRevision
              )}
              liveVersion={stableJSONStringify(liveVersion)}
              path={selectedPath}
            />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}
