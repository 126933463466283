import { Dialog, DialogContent, DialogTitle, Grid } from "@mui/material";
import { useSnackbar } from "notistack";
import { NewRenewalChallenge } from "../../types/invitation/renewalChallenge";
import { renewalChallengeSlice } from "redux/slices/renewalChallengeSlice";
import RenewalChallengeForm from "./RenewalChallengeForm";

export default function RenewalChallengeCreateDialog(props: {
  open: boolean;
  setOpen: (open: boolean) => void;
  invitationCode: string;
}) {
  const { invitationCode, open, setOpen } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [createChallenge] =
    renewalChallengeSlice.useCreateRenewalChallengeMutation();
  return (
    <Dialog maxWidth="md" open={open} onClose={() => setOpen(false)}>
      <Grid>
        <DialogTitle>Create Renewal Challenge</DialogTitle>
      </Grid>
      <DialogContent>
        <RenewalChallengeForm
          initialValues={{
            active: true,
            passcode: "",
            softLockFrom: new Date(new Date().setHours(0, 0, 0, 0)),
            hardLockFrom: new Date(new Date().setHours(0, 0, 0, 0)),
            en: "",
            de: "",
            fr: "",
            es: "",
          }}
          onSubmit={async (values, helpers) => {
            const challenge: NewRenewalChallenge = {
              active: values.active,
              passcode: values.passcode,
              softLockFrom: values.softLockFrom,
              hardLockFrom: values.hardLockFrom,
              note: {
                en: values.en,
                de: values.de,
                fr: values.fr,
                es: values.es,
              },
            };

            if (!invitationCode) {
              enqueueSnackbar("Creating the challenge has failed", {
                variant: "error",
              });
              console.warn(
                "Invitation code not found in the request, saving new challenge failed"
              );
              return;
            }

            try {
              await createChallenge({
                challenge,
                invitationCode,
              });
              enqueueSnackbar("Successfully created a new challenge", {
                variant: "success",
              });
              helpers.resetForm();
              setOpen(false); // Needs to close the dialog by changing state
            } catch (error) {
              console.warn(error);
              enqueueSnackbar("Creating the challenge has failed", {
                variant: "error",
              });
            }
          }}
        />
      </DialogContent>
    </Dialog>
  );
}
