import { useEffect, useState } from "react";
import { ChineseUserRelationship, getChineseUserRelationshipById } from "utils/firebase/chineseUsersRelationshipsUtils";

export function useGetRelationshipById(relationshipId: string, isNew: boolean = false) {
  const [relationship, setRelationship] = useState<ChineseUserRelationship>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    let unListen: any;
    if (isNew) {
      setLoading(false);
      return;
    }

    try {
      unListen = getChineseUserRelationshipById(relationshipId, (res: ChineseUserRelationship) => {
        setRelationship(res);
        setLoading(false);
      });
    } catch (err) {
      console.error(err);
      setError(true);
    }

    // clear listening
    return () => {
      unListen?.();
    };
  }, [isNew, relationshipId]);

  return {
    loading,
    error,
    relationship,
  };
}
