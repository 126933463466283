import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import React, { useState } from "react";

type Props = {
  isSubmitting: boolean;
  onSubmit: () => {};
  expirationDate: Date;
};

export default function SubmitWithConfirmationButton({
  isSubmitting,
  onSubmit,
  expirationDate,
}: Props) {
  const [open, setOpen] = useState<boolean>(false);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Button variant="contained" onClick={() => setOpen(true)}>
        Submit
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Invitation code expiration</DialogTitle>
        <DialogContent
          sx={{ my: 2, display: "flex", flexDirection: "column", gap: 1 }}
        >
          <DialogContentText>
            Creating or updating a renewal challenge will set an expiry date on
            the invitation code.
          </DialogContentText>
          <DialogContentText>
            New expiry date will be: 
          </DialogContentText>
          {expirationDate instanceof Date && (
            <DialogContentText fontWeight={600} sx={{ textAlign: "center" }}>
              {expirationDate.toLocaleDateString()}
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            loading={isSubmitting}
            onClick={onSubmit}
            autoFocus
          >
            Save my changes
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
