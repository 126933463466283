import {
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Typography,
  IconButton,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { RenewalChallenge } from "../../types/invitation/renewalChallenge";
import { useNavigate } from "react-router";

const TableWrapper: React.FC = ({ children }) => {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell align="center">Passcode</TableCell>
            <TableCell align="center">Active</TableCell>
            <TableCell align="center">Soft lock from</TableCell>
            <TableCell align="center">Hard lock from</TableCell>
            <TableCell align="center">Operations</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{children}</TableBody>
      </Table>
    </TableContainer>
  );
};

function TableWithStatus(props: { text: string }) {
  const { text } = props;
  return (
    <TableWrapper>
      <TableCell align="center" colSpan={5}>
        {text}
      </TableCell>
    </TableWrapper>
  );
}

type ListProps = {
  challenges: RenewalChallenge[];
  isError: boolean;
  isLoading: boolean;
};

const RenewalChallengeList = (props: ListProps) => {
  const navigate = useNavigate();
  const { isError, isLoading, challenges } = props;

  if (isLoading) {
    return <TableWithStatus text={"Loading ..."} />;
  }

  if (isError) {
    return <TableWithStatus text="An error occurred!" />;
  }

  if (challenges.length === 0) {
    return <TableWithStatus text="No data!" />;
  }

  return (
    <TableWrapper>
      {challenges.map((challenge) => (
        <TableRow key={challenge.id}>
          <TableCell align="center">{challenge.passcode}</TableCell>
          <TableCell align="center">
            {challenge.active ? (
              <Typography variant={"body2"}>active</Typography>
            ) : (
              <Typography variant={"body2"} color={"text.disabled"}>
                inactive
              </Typography>
            )}
          </TableCell>
          <TableCell align="center">
            {challenge.softLockFrom.toLocaleString()}
          </TableCell>
          <TableCell align="center">
            {challenge.hardLockFrom.toLocaleString()}
          </TableCell>
          <TableCell align="center">
            <IconButton onClick={() => navigate(challenge.id)}>
              <EditIcon />
            </IconButton>
          </TableCell>
        </TableRow>
      ))}
    </TableWrapper>
  );
};

export default RenewalChallengeList;
