import React, {useState} from 'react';
import {useSnackbar} from 'notistack';
import {useNavigate} from 'react-router-dom';
import {Form, FormikProvider, useFormik} from 'formik';
import {FocusError} from 'focus-formik-error';
import firebase from 'firebase/app';

// material
import {LoadingButton} from '@mui/lab';
import {Box, Card, FormControlLabel, Grid, Stack, Switch, TextField, Typography,} from "@mui/material";

// utils
import {getAnYearAheadDate} from "../../../utils/helpers";

// routes
import {PATH_DASHBOARD} from "../../../routes/paths";

// @types
import {RegistrationCode, SupportedLanguages} from "../../../types/invitation/code";

// components & pages
import {RegistrationCodeSchema} from "./schemas/RegistrationCode";
import {editCodeOrCreateRegistrationCode} from "../../../utils/firebase/registrationCodeUtils";
import DemographicQuestions from "../invitation/demographicQuestions";
import CodeUsersPopover from "./CodeUsersPopover";
import {SupportedLanguagesEnum} from "../../../utils/constants";

// ----------------------------------------------------------------------

type InvitationCodeNewFormProps = {
  isEdit: boolean;
  isView?: boolean;
  currentCode?: RegistrationCode;
};

export default function RegistrationCodeForm({
  isView,
  isEdit,
  currentCode
}: InvitationCodeNewFormProps) {
  const [demographicLanguage, setDemographicLanguage] =
      useState<SupportedLanguages>(SupportedLanguagesEnum.ENGLISH);  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      code: currentCode?.code || '',
      usageLimit: currentCode?.usageLimit || 0,
      demographicQuestions: currentCode?.demographicQuestions || undefined,
      expiresAt:
        currentCode?.expiresAt?.toDate?.()?.toISOString()?.split?.("T")[0] ||
        getAnYearAheadDate().toISOString().split("T")[0],
      createdAt:
        currentCode?.createdAt?.toDate?.()?.toISOString()?.split?.("T")[0] ||
        new Date().toISOString().split("T")[0],
      timesChecked: currentCode?.timesChecked ?? 0,
      usernameAllowed: currentCode?.usernameAllowed ?? false,
    },
    validationSchema: RegistrationCodeSchema,
    onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
      try {
        const demographicQuestions = values?.demographicQuestions;

        if (!demographicQuestions) {
          return enqueueSnackbar("Please add at least one event inside the 'Add question' section", {
            variant: "error"
          });
        }
        const selectedQuestion = demographicQuestions?.[demographicLanguage].find((question) => {
          return question.answers && question.answers.length > 0;
        });

        if (!selectedQuestion?.answers?.length) {
          return enqueueSnackbar("Please add at least one event inside the 'Add answer' section", {
            variant: "error"
          });
        }

        const selectedQuestionEvents = selectedQuestion?.answers.find((answer) => {
          return answer.events && answer.events.length > 0;
        });

        if (!selectedQuestionEvents?.events?.length) {
          return enqueueSnackbar("Please add at least one event", {
            variant: "error"
          });
        }

        if (!selectedQuestionEvents?.events[0]?.payload || !selectedQuestionEvents?.events[0]?.eventType) {
          return enqueueSnackbar("Please select event type and invitation code", {
            variant: "error"
          });
        }

        const finalData: RegistrationCode = {
          ...(values || {}),
          expiresAt: firebase.firestore.Timestamp.fromDate(
            new Date(values?.expiresAt ?? "")
          ),
          createdAt: firebase.firestore.Timestamp.fromDate(
            new Date(values?.createdAt ?? "")
          ),
        };

        await editCodeOrCreateRegistrationCode(finalData, !isEdit);
        resetForm();
        setSubmitting(false);
        enqueueSnackbar(!isEdit ? "Create success" : "Update success", {
          variant: "success",
        });
        navigate(PATH_DASHBOARD.registrationCodes.root);
      } catch (error) {
        setSubmitting(false);
        setErrors(error);
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    getFieldProps,
    values,
  } = formik;
  return (
    <FormikProvider value={formik}>
      <Form noValidate onSubmit={handleSubmit}>
        <fieldset disabled={isView} style={{ border: "none" }}>
          <FocusError formik={formik} />
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card sx={{ p: 3 }}>
                <Stack spacing={3}>
                  <Stack spacing={2}>
                    <TextField
                      fullWidth
                      label="code"
                      {...getFieldProps('code')}
                      error={Boolean(touched.code && errors.code)}
                      helperText={touched.code && errors.code}
                    />

                    <TextField
                      fullWidth
                      label="Max. Uses"
                      {...getFieldProps('usageLimit')}
                      error={Boolean(touched.usageLimit && errors.usageLimit)}
                      helperText={touched.usageLimit && errors.usageLimit}
                    />

                  <CodeUsersPopover queryPathName={"registrationCode"} />

                    <TextField
                      fullWidth
                      label="Expiry Date"
                      {...getFieldProps('expiresAt')}
                      error={Boolean(touched.expiresAt && errors.expiresAt)}
                      helperText={touched.expiresAt && errors.expiresAt}
                      type="date"
                    />
                  </Stack>


                  <DemographicQuestions
                      getFieldProps={getFieldProps}
                      setFieldValue={setFieldValue}
                      setDemographicLanguage={setDemographicLanguage}
                      showAnswerEvents
                  />

                  <FormControlLabel
                      labelPlacement="start"
                      control={
                        <Switch
                            onChange={(event) => setFieldValue('usernameAllowed', event.target.checked)}
                            checked={values.usernameAllowed}
                        />
                      }
                      label={
                        <>
                          <Typography variant="subtitle2">Username Allowed</Typography>
                        </>
                      }
                      sx={{ width: 1, mx: 0, justifyContent: 'space-between' }}
                  />


                  <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                    <LoadingButton
                      disabled={isView}
                      type="submit"
                      variant="contained"
                      loading={isSubmitting}
                    >
                      {isView ? 'View Only' : !isEdit ? 'Create Registration Code' : 'Save Changes'}
                    </LoadingButton>
                  </Box>
                </Stack>
              </Card>
            </Grid>
          </Grid>
        </fieldset>
      </Form>
    </FormikProvider>
  );
}
