import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
// material
import { Container } from '@mui/material';
// redux
// routes
// hooks
import useSettings from '../../../hooks/useSettings';
// components
import Page from '../../../components/Page';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import { SupportedLanguages } from '../../../types/invitation/code';
import JourneysBotData from '../../../components/_dashboard/bot-data/journey/JourneysBotData';
import { onBotDataChanged } from '../../../utils/firebase/botDataUtils';
import Page404 from '../../Page404';
import { BotDataType } from '../../../types/bot-data/botDataType';
import LoadingScreen from '../../../components/LoadingScreen';
import { SupportedLanguagesEnum } from "../../../utils/constants";

// ----------------------------------------------------------------------

export default function AddEditTableList() {
  const { themeStretch } = useSettings();
  const { pathname } = useLocation();
  const isEdit = pathname.includes('edit');
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedLanguage] = useState<SupportedLanguages>(SupportedLanguagesEnum.ENGLISH);
  const [botData, setBotData] = useState<BotDataType>();

  useEffect(() => {
    try {
      setLoading(true);
      setError(null);
      const unListen: any = onBotDataChanged((entries) => {
        setBotData(entries);
        setLoading(false);
      });
      return () => unListen?.();
    } catch (e) {
      setError(e?.message || 'something went wrong.');
    }
  }, []);

  if (error) {
    return <Page404 title={error} />;
  }
  // @ts-ignore
  const journeyData = botData?.[selectedLanguage]?.journeys;

  let ContentToDisplay = <LoadingScreen />;
  if (error) {
    ContentToDisplay = <Page404 />;
  }
  if (!loading && !error) {
    ContentToDisplay = (
      <JourneysBotData journeys={journeyData} selectedLanguage={selectedLanguage} />
    );
  }

  return (
    <Page title="Add Edit Journey Table">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={!isEdit ? 'Create a new Journey Table' : 'Add Edit Journey Table'}
          links={[]}
        />
        {ContentToDisplay}
      </Container>
    </Page>
  );
}
