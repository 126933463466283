import {Stack, Typography} from "@mui/material";
import React from "react";

type Props = {
    label: string;
    value?: string | boolean | null;
}

export default function CallInfo({label, value}: Props) {
    return (
        <Stack direction={'row'}>
            <Typography fontWeight={"bold"} marginRight={1}>{label}:</Typography>
            <Typography>{value ? String(value) : "---"}</Typography>
        </Stack>
    )
}