import { Icon } from "@iconify/react";
import plusFill from "@iconify/icons-eva/plus-fill";
import { Link as RouterLink } from "react-router-dom";

// material
import {
  Button,
  Container,
  TableCell,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";

// routes
import { PATH_DASHBOARD } from "../../../../routes/paths";

// hooks
import useSettings from "../../../../hooks/useSettings";

// components
import Page from "../../../../components/Page";
import HeaderBreadcrumbs from "../../../../components/HeaderBreadcrumbs";

// ----------------------------------------------------------------------
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import React, { useEffect, useState } from "react";
import TableToolbar from "../../../../components/common/TableToolbar";
import TableHeader from "../../../../components/common/TableHeader";
import { isSuperAdminOrAccessToAllLanguages } from "../../../../utils/firebase/translationUtils";
import { useCurrentRole } from "../../../../hooks/useCurrentRole";
import LoadingScreen from "../../../../components/LoadingScreen";
import SearchNotFound from "../../../../components/SearchNotFound";
import { RootState, useSelector } from "../../../../redux/store";
import { useGetMultilingualLocales } from "utils/firebase/multilingualLocaleUtils";
import { MultilingualLocaleRow } from "./MultilingualLocaleRow";
// ----------------------------------------------------------------------

// The code prevents returning to the first page of a list when navigating back from
// a details or update page. It persists state information to maintain the current page, size,
// snapshot, order, and navigation experience.
let MultilingualLocalePersistState = {
  pageNo: 0,
  pageSize: 5,
  snapShot: {},
  orderBy: "locale",
  searchText: "",
  sortingOrder: "desc",
};

const TABLE_HEAD = [
  { id: "locale", label: "Locale", alignRight: false },
  { id: "name", label: "Name", alignRight: false },
  { id: "isActive", label: "Is Active?", alignRight: false },
  { id: "visibilityRestrictions", label: "Visible To", alignRight: false },
  { id: "threeDotMenu", label: "", alignRight: true },
];

export default function AppSideTranslations() {
  const { themeStretch } = useSettings();
  const [filterName, setFilterName] = useState(
    MultilingualLocalePersistState.searchText
  );
  const [pageNo, setPageNo] = useState(MultilingualLocalePersistState.pageNo);
  const [searchText, setSearchText] = useState(
    MultilingualLocalePersistState.searchText
  );
  const [sortingOrder, setSortingOrder] = useState<"asc" | "desc">(
    MultilingualLocalePersistState.sortingOrder as "asc" | "desc"
  );
  const [orderBy, setOrderBy] = useState(
    MultilingualLocalePersistState.orderBy
  );
  const [pageSize, setPageSize] = useState(
    MultilingualLocalePersistState.pageSize
  );
  const currentRoles = useCurrentRole();
  const { multilingualLocales, loading, isMoreLocalesAvailable, lastSnapShot } =
    useGetMultilingualLocales({
      pageNo,
      pageSize,
      orderBy,
      sortingOrder,
      searchText,
      initialSnapShot: MultilingualLocalePersistState.snapShot,
    });
  const multilingualLocalesVersion = useSelector(
    (state: RootState) =>
      state.contentVersions.versions.multilingualLocalesVersion
  );

  useEffect(() => {
    return () => {
      MultilingualLocalePersistState = {
        pageNo,
        pageSize,
        snapShot: lastSnapShot,
        orderBy,
        sortingOrder,
        searchText,
      };
    };
  }, [pageNo, pageSize, lastSnapShot, sortingOrder, orderBy, searchText]);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPageSize(parseInt(event.target.value, 10));
    setPageNo(0);
  };

  const handleFilterByName = (newFilter: string) => {
    setFilterName(newFilter);
    if (!newFilter.length) {
      setPageNo(0);
      setOrderBy("name");
      setSortingOrder("desc");
      setSearchText(newFilter);
    }
  };

  const handleFilterKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter") {
      setPageNo(0);
      if (!filterName.length) {
        setOrderBy("name");
        setSortingOrder("desc");
      } else {
        setOrderBy("");
      }
      setSearchText(filterName);
    }
  };

  const isGroupNotFound = !Boolean(multilingualLocales.length) && !loading;

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <Page title="Multilingual Locales">
      <Container maxWidth={themeStretch ? false : "lg"}>
        <HeaderBreadcrumbs
          heading=""
          links={[]}
          action={
            isSuperAdminOrAccessToAllLanguages(currentRoles) ? (
              <Button
                variant="contained"
                component={RouterLink}
                to={PATH_DASHBOARD.multilingualLocales.new}
                startIcon={<Icon icon={plusFill} />}
              >
                Add New Locale
              </Button>
            ) : null
          }
        />

        <TableToolbar
          numSelected={0}
          filterName={filterName}
          handleKeyDown={handleFilterKeyDown}
          onFilterName={handleFilterByName}
          searchPlaceholder={"Search Locale by name"}
        />

        <TableContainer component={Paper}>
          <Table
            aria-label="collapsible table"
            key={"appSideTranslationsCollapsibleTable"}
          >
            <TableHeader
              order={sortingOrder}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              rowCount={multilingualLocales.length}
              numSelected={0}
              hideCheckbox
            />

            <TableBody>
              {multilingualLocales.map((row) => (
                <MultilingualLocaleRow
                  key={row.id}
                  row={row}
                  currentRoles={currentRoles}
                />
              ))}

              {isGroupNotFound && (
                <TableRow>
                  <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                    <SearchNotFound searchQuery={filterName} />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[2, 5, 10, 25, 50, 100, 125, 150]}
          component="div"
          rowsPerPage={pageSize}
          count={
            isMoreLocalesAvailable
              ? -1
              : pageSize * pageNo + multilingualLocales.length
          }
          page={pageNo}
          onPageChange={(e, newPage) => setPageNo(newPage)}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />

        <Typography textAlign={"center"} mt={1}>
          Version {multilingualLocalesVersion}
        </Typography>
      </Container>
    </Page>
  );
}
