import {Typography} from "@mui/material";
import EditCallTime from "./EditCallTime";
import {clone} from "lodash";
import React from "react";

type Props = {
    availableTimes: Array<Date | number>;
    therapistTimezone: string;
    userTimezone: string;
    changeAvailableTimes: (availableTimes: Array<Date | number>) => void;
}

export default function EditCallAvailabilities({availableTimes, changeAvailableTimes, therapistTimezone, userTimezone}: Props){
    return (
        <div>
            <Typography fontWeight={"bold"}>Available times</Typography>
            {availableTimes?.length ?
                availableTimes.map((availableTime, index: number) => {
                    return (
                        <EditCallTime
                            key={String(index)}
                            therapistTimezone={therapistTimezone}
                            userTimezone={userTimezone}
                            date={availableTime}
                            onChange={(newDate) => {
                                if (newDate) {
                                    const editedAvailableTimes = clone(availableTimes)
                                    editedAvailableTimes[index] = newDate;
                                    changeAvailableTimes(editedAvailableTimes);
                                }
                            }}
                        />
                    );
                }) : <Typography textAlign={"center"}>---</Typography>}
        </div>
    )
}