import { db } from "contexts/FirebaseContext";
import { ImageFromFirestore } from "models/Image";
import { useEffect, useState } from "react";

export default function useGetImageContent() {
  const [imageContent, setImageContent] = useState<Array<ImageFromFirestore>>();

  useEffect(() => {
    const listener = db.collection("images").onSnapshot(async (snapshot) => {
      const docs = snapshot.docs.map((doc) => {
        const data = doc?.data();
        return { id: doc?.id, ...data } as ImageFromFirestore;
      });

      setImageContent(docs);
    });

    return () => {
      if (listener) {
        listener();
      }
    };
  }, []);

  return imageContent;
}
