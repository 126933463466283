import {useEffect, useState} from "react";
import {NotificationHistory} from "../types/notifications";
import {getNotificationHistory} from "../utils/firebase/notificationUtils";

export function useGetNotificationHistory() {
    const [loading, setLoading] = useState<boolean>(false);
    const [history, setHistory] = useState<Array<NotificationHistory>>([]);

    useEffect(() => {
        async function load() {
            try {
                setLoading(true);
                const histories = await getNotificationHistory()
                setHistory(histories);
                setLoading(false);
            } catch (e) {
                setLoading(false);
                console.error(e);
            }
        }

        load();
    }, []);

    return {history, loading};
}
