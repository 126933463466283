import { Action, ActionTypes, State } from "../../../types/user";

export default function UserSearchReducer(state: State, action: Action) {
  switch (action.type) {
    case ActionTypes.OPEN_FORM:
      return {
        ...state,
        formDialog: {
          isOpen: true,
          selectedUserId: action.payload,
        },
      };
    case ActionTypes.CLOSE_FORM:
      return {
        ...state,
        formDialog: {
          isOpen: false,
          selectedUserId: undefined,
        },
      };
    case ActionTypes.SUBMIT:
      return {
        ...state,
        formDialog: {
          isOpen: false,
          selectedUserId: undefined,
        },
      };
    case ActionTypes.USERS_REQUEST:
      return {
        ...state,
        usersResponse: {
          userInfo: action.payload,
        },
      };
    case ActionTypes.FETCH_CREDITS_INFO:
      return {
        ...state,
        usersCreditsInfo: {
          ...action.payload,
        },
      };
    case ActionTypes.SET_FORM_INPUT_VALUES:
      return {
        ...state,
        inputValues: {
          extraCredits: state.usersCreditsInfo.data.extraCredits,
          creditsUsed: state.usersCreditsInfo.data.creditsUsed,
          remainingCredits: state.usersCreditsInfo.data.remainingCredits,
        },
      };
    default:
      return state;
  }
}
