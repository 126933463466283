import { format } from 'date-fns';

// ----------------------------------------------------------------------

export function fDate(date: Date | string | number) {
  return format(new Date(date), 'dd MMMM yyyy');
}

//using toIsoString() on Date returns time to UTC. This function returns in the same format as toIsoString() but with time adjusted to current timezone
export function toIsoStringWithTimezone(date: Date) {
  return format(date, "yyyy-MM-dd'T'kk:mm:ss");
}

export function formatTimeDuration(durationList: Array<number>, endTime: number) {
  const totalDurationSeconds = durationList.reduce((acc, duration) => acc + duration, 0);
  const startSeconds = totalDurationSeconds.toFixed(2);
  const endSeconds = (Number(startSeconds) + Number(endTime)).toFixed(2);

  const formatTime = (seconds: number): string => {
    if (seconds < 60) {
      return `${ seconds } seconds`;
    } else {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = (seconds % 60).toFixed(2);
      return `${ minutes } minute ${ remainingSeconds } seconds`;
    }
  };

  const formattedStart = formatTime(Number(startSeconds));
  const formattedEnd = formatTime(Number(endSeconds));

  return `${ formattedStart } - ${ formattedEnd }`;
}