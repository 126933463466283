import Container from "@mui/material/Container";
import HeaderBreadcrumbs from "components/HeaderBreadcrumbs";

import { PATH_DASHBOARD } from "routes/paths";
import CreditPeriodsFormDialog from "./CreditPeriodsFormDialog";
import Page from "components/Page";

import CreditPeriodsList from "./CreditPeriodsList";
import Button from "@mui/material/Button/Button";
import plusFill from "@iconify/icons-eva/plus-fill";
import { Icon } from "@iconify/react";
import useSettings from "hooks/useSettings";
import useCreditPeriods from "./hooks/useCreditPeriods";

const CreditPeriodsPage = () => {
  const { themeStretch } = useSettings();
  const { handleClickOpen, invitationCode } = useCreditPeriods();

  return (
    <Page title="Create Credits">
      <Container maxWidth={themeStretch ? false : "lg"}>
        <HeaderBreadcrumbs
          heading="Create Credits"
          links={[
            {
              name: "Invitation Code List",
              href: PATH_DASHBOARD.invitationCodes.root,
            },
            { name: invitationCode || "Create Credits" },
          ]}
          action={
            <Button
              onClick={() => handleClickOpen()}
              variant="contained"
              startIcon={<Icon icon={plusFill} />}
            >
              Credits Period
            </Button>
          }
        />
        <CreditPeriodsFormDialog />
        <CreditPeriodsList />
      </Container>
    </Page>
  );
};
export default CreditPeriodsPage;
