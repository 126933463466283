import { Avatar, ListItemAvatar } from "@mui/material";
import CakeIcon from "@mui/icons-material/Cake";

export default function RevisionBirthListIcon() {
  return (
    <ListItemAvatar>
      <Avatar>
        <CakeIcon />
      </Avatar>
    </ListItemAvatar>
  );
}
