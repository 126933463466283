import React, { useState } from "react";
import { Tooltip, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";

interface Props {
  text: string;
  style?: React.CSSProperties;
  variant?:
    | "inherit"
    | "button"
    | "overline"
    | "caption"
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "h5"
    | "h6"
    | "subtitle1"
    | "subtitle2"
    | "body1"
    | "body2";
  noWrap?: boolean;
}

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.grey[700],
    color: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

const StringWithCopyOption: React.FC<Props> = ({
  text,
  style,
  variant,
  noWrap,
}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(text);
    setTooltipOpen(true);
    setTimeout(() => setTooltipOpen(false), 2000);
  };

  return (
    <LightTooltip
      open={tooltipOpen}
      title="Copied to clipboard"
      placement="top-end"
    >
      <Typography
        onClick={copyToClipboard}
        style={{ cursor: "pointer", ...style }}
        variant={variant}
        noWrap={noWrap}
      >
        {text}
      </Typography>
    </LightTooltip>
  );
};

export default StringWithCopyOption;
