import { Icon } from "@iconify/react";
import React, { useState } from "react";
import plusFill from "@iconify/icons-eva/plus-fill";
import { Link as RouterLink, useNavigate } from "react-router-dom";
// material
import {
  Box,
  Button,
  Card,
  Chip,
  Container,
  Link,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";

// routes
import { PATH_DASHBOARD } from "../../../routes/paths";

// hooks
import useSettings from "../../../hooks/useSettings";

// components
import Page from "../../../components/Page";
import Scrollbar from "../../../components/Scrollbar";
import SearchNotFound from "../../../components/SearchNotFound";
import HeaderBreadcrumbs from "../../../components/HeaderBreadcrumbs";
import { useSnackbar } from "notistack";
import TableToolbar from "../../../components/common/TableToolbar";
import TableHeader from "../../../components/common/TableHeader";
import { filter } from "lodash";
import { Anonymous } from "../../../types/list";
import LoadingScreen from "../../../components/LoadingScreen";
import { useCopyToClipBoard } from "../../../hooks/useCopyToClipBoard";
import { MeditationVoice } from "../../../types/meditationVoice";
import {
  deleteMeditationVoiceByIdFromDB,
  useMeditationVoices
} from "../../../utils/firebase/audioMeditationVoicesUtil";
import { format } from "date-fns";
import { dateTimeDisplayFormat } from "../../../utils/analytics/analyticsDefaults";
import MoreMenu from "../../../components/common/MoreMenu";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "name", label: "Name", alignRight: false },
  { id: "voiceID", label: "Voice ID", alignRight: false },
  { id: "themeColorLight", label: "Theme Color Light", alignRight: false },
  { id: "themeColorDark", label: "Theme Color Dark", alignRight: false },
  { id: "photo", label: "Coach Image", alignRight: false },
  { id: "sampleAudioUrl", label: "Sample Audio Url", alignRight: false },
  { id: "createdOrUpdatedAt", label: "Created/Updated At", alignRight: false },
  { id: "actions", label: "Actions", alignRight: true },
];

// ----------------------------------------------------------------------

function applySortFilter(
  audioMeditations: MeditationVoice[],
  comparator: (a: any, b: any) => number,
  query: string
) {
  const stabilizedThis = audioMeditations.map((el, index) => [el, index] as const);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      audioMeditations,
      (group) => group.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

function descendingComparator(a: Anonymous, b: Anonymous, orderBy: string) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order: string, orderBy: string) {
  return order === "desc"
    ? (a: Anonymous, b: Anonymous) => descendingComparator(a, b, orderBy)
    : (a: Anonymous, b: Anonymous) => -descendingComparator(a, b, orderBy);
}

export default function MeditationVoicesList() {
  const { themeStretch } = useSettings();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState<"asc" | "desc">("desc");
  const [selected, setSelected] = useState<string[]>([]);
  const [orderBy, setOrderBy] = useState("createdOrUpdatedAt");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [deletingId, setDeletingId] = useState<string>();

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { copyToClipBoard } = useCopyToClipBoard();
  const { meditationVoices = [], loading } = useMeditationVoices()

  const headerActionNode = <Box sx={ { mt: 3, display: "flex", justifyContent: "flex-end" } }>
    <Button
      variant="contained"
      sx={ { mr: 4 } }
      component={ RouterLink }
      to={ PATH_DASHBOARD.audioMeditationVoices.new }
      startIcon={ <Icon icon={ plusFill }/> }
    >
      Create Meditation Voice
    </Button>
  </Box>

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (checked: boolean) => {
    setSelected(checked ? meditationVoices?.map(meditationVoice => meditationVoice.name) : [])
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (newFilter: string) => {
    setFilterName(newFilter);
  };

  const handleDeleteMeditationVoice = async (selectedMeditationVoice: MeditationVoice) => {
    try {
      const selectedVoiceID = selectedMeditationVoice.id

      if (!selectedVoiceID) return;

      setDeletingId(selectedVoiceID);

      if (window.confirm(`Do you really want to delete this meditation voice (${ selectedMeditationVoice.name })?`)) {
        await deleteMeditationVoiceByIdFromDB(selectedVoiceID);
        enqueueSnackbar("Audio Meditation Deleted Successfully", {
          variant: "success",
        });
      } else {
        enqueueSnackbar("Delete meditation voice, cancelled by user", {
          variant: "error",
        });
      }
    } catch (e) {
      console.error("FailedToDeleteAudioMeditation", e);
      enqueueSnackbar("Failed to delete meditation voice", {
        variant: "error",
      });
    } finally {
      setDeletingId("");
    }
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - meditationVoices.length) : 0;

  const filteredGroup = applySortFilter(
    meditationVoices,
    getComparator(order, orderBy),
    filterName
  );

  const isGroupNotFound = filteredGroup.length === 0 && !loading;

  const onDeleteAction = (meditationVoiceItem: MeditationVoice) => {
    return () => handleDeleteMeditationVoice(meditationVoiceItem)
  }

  const onEditAction = (id?: string) => {
    return () => {
      navigate(
        `${ PATH_DASHBOARD.audioMeditationVoices.root }/${ id }/edit`
      );
    }
  }

  const onCopy = (id: string) => () => {
    copyToClipBoard(id)
  }

  if (loading) {
    return <LoadingScreen/>;
  }

  return (
    <Page title="Audio Meditation Generations">
      <Container maxWidth={ themeStretch ? false : "lg" }>
        <HeaderBreadcrumbs
          heading=""
          links={ [] }
          action={ headerActionNode}
        />

        <Card>
          <TableToolbar
            numSelected={ selected.length }
            filterName={ filterName }
            onFilterName={ handleFilterByName }
            searchPlaceholder={ "Search Meditation Voice" }
          />

          <Scrollbar>
            <TableContainer sx={ { minWidth: 800 } }>
              <Table>
                <TableHeader
                  order={ order }
                  orderBy={ orderBy }
                  headLabel={ TABLE_HEAD }
                  rowCount={ meditationVoices.length }
                  numSelected={ selected.length }
                  onRequestSort={ handleRequestSort }
                  onSelectAllClick={ handleSelectAllClick }
                  hideCheckbox
                />
                <TableBody>
                  { filteredGroup
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((meditationVoiceItem: MeditationVoice) => {
                      const {
                        id, voiceID, name, themeColorLight, themeColorDark, photo, sampleAudioUrl,
                        createdOrUpdatedAt
                      } = meditationVoiceItem;
                      const isItemSelected = selected.indexOf(name) !== -1;

                      return (
                        <TableRow
                          hover
                          key={ `${ name }-${ voiceID }` }
                          tabIndex={ -1 }
                          role="checkbox"
                          selected={ isItemSelected }
                          aria-checked={ isItemSelected }
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            padding="normal"
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={ 2 }
                            >
                              <Typography variant="subtitle2" noWrap>
                                { name }
                              </Typography>
                            </Stack>
                          </TableCell>

                          <TableCell
                            component="th"
                            scope="row"
                            padding="normal"
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={ 2 }
                            >
                              <Typography variant="subtitle2" noWrap>
                                { voiceID }
                              </Typography>
                            </Stack>
                          </TableCell>

                          <TableCell
                            component="th"
                            scope="row"
                            padding="normal"
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={ 2 }
                            >
                              <Chip label={ themeColorLight } color="primary" variant="outlined"/>
                            </Stack>
                          </TableCell>

                          <TableCell
                            component="th"
                            scope="row"
                            padding="normal"
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={ 2 }>
                              <Chip label={ themeColorDark } color="primary" variant="outlined"/>
                            </Stack>
                          </TableCell>

                          <TableCell
                            component="th"
                            scope="row"
                            padding="normal"
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={ 2 }>
                              <Link href={ photo } target="_blank" noWrap>View Coach Image</Link>
                            </Stack>
                          </TableCell>

                          <TableCell
                            component="th"
                            scope="row"
                            padding="normal"
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={ 2 }
                            >
                              <Link href={ sampleAudioUrl } target="_blank" noWrap>Sample Audio</Link>
                            </Stack>
                          </TableCell>

                          <TableCell
                            component="th"
                            scope="row"
                            padding="normal"
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={ 2 }
                            >
                              <Typography variant="body1" noWrap>
                                { createdOrUpdatedAt ? format(createdOrUpdatedAt, dateTimeDisplayFormat) : "---" }
                              </Typography>
                            </Stack>
                          </TableCell>

                          <TableCell align="right">
                            <MoreMenu
                              deleting={ deletingId === id }
                              onDelete={ onDeleteAction(meditationVoiceItem) }
                              onEdit={ onEditAction(id) }
                              onView={ () => {
                                navigate(
                                  `${ PATH_DASHBOARD.audioMeditationVoices.root }/${ id }/view`
                                );
                              } }
                              copyText={ "Copy Voice ID" }
                              onCopy={ onCopy(voiceID) }
                            />
                          </TableCell>
                        </TableRow>
                      );
                    }) }
                  { emptyRows > 0 && (
                    <TableRow style={ { height: 53 * emptyRows } }>
                      <TableCell colSpan={ 6 }/>
                    </TableRow>
                  ) }
                </TableBody>
                { isGroupNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={ 6 } sx={ { py: 3 } }>
                        <SearchNotFound searchQuery={ filterName }/>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) }
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={ [5, 10, 25] }
            component="div"
            count={ meditationVoices.length }
            rowsPerPage={ rowsPerPage }
            page={ page }
            onPageChange={ (e, _page) => setPage(_page) }
            onRowsPerPageChange={ handleChangeRowsPerPage }
          />
        </Card>
      </Container>
    </Page>
  );
}
