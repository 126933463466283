import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import React, { useState } from "react";

type Props = {
    open: boolean;
    loading: boolean;
    onClose: () => void;
    handleDelete: () => void;
    dialogTitle?: string;
    dialogDescription?: string
    deleteButtonText?: string;
    confirmationInputRequired?: boolean;
}

export default function DeleteConfirmationDialog(props: Props) {
    const [confirmationInput, setConfirmationInput] = useState("");

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setConfirmationInput(event.target.value);
    };

    const {
      open,
      dialogTitle,
      dialogDescription,
      loading,
      deleteButtonText,
      onClose,
      handleDelete,
      confirmationInputRequired
    } = props;

    const isDeleteButtonDisabled = confirmationInputRequired && confirmationInput !== "Delete";

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>
          { dialogTitle ?? "You are about to permamently delete this item" }
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            { dialogDescription ?? "This action cannot be reversed. Are you sure you want to proceed?" }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        {confirmationInputRequired && (
          <TextField
            autoFocus
            margin="dense"
            label="Type 'Delete' to confirm"
            fullWidth
            value={confirmationInput}
            onChange={handleInputChange}
          />
        )}
        </DialogActions>
        <DialogActions>
          <Button variant="outlined" onClick={onClose}>Cancel</Button>
          <LoadingButton
            variant="contained"
            loading={loading}
            onClick={handleDelete}
            disabled={isDeleteButtonDisabled}
            autoFocus
          >
            { deleteButtonText ?? "Delete" }
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
