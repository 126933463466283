import {formatInTimeZone} from "date-fns-tz";
import enGB from "date-fns/locale/en-GB";
import {isEmpty} from "lodash";
import {DefaultTimezone} from "./constants";

export const formatDateInTimezone = (date: Date | number | string, timezone: string, dateFormat: string): string => {
    const formattedTimezone = isEmpty(timezone) ? DefaultTimezone : timezone;
    return formatInTimeZone(
        new Date(date),
        formattedTimezone,
        dateFormat,
        {locale: enGB}
    )
}
